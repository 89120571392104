import { combineReducers } from 'redux';
import mainReducer from './mainReducer';
import loginReducer from './loginReducer';
import registerReducer from './registerReducer';
import petTypeReducer from './petTypeReducer';
import adoptFormReducer from './adoptFormReducer';
import petSizeReducer from './petSizeReducer';
import getHamsterRaceReducer from './getHamsterRaceReducer';
import saciPetsReducer from './saciPetsReducer';
import adoptMeFormReducer from './adoptMeFormReducer';
import userPetsReducer from './userPetsReducer';
import outputRequestReducer from './outputRequestReducer';
import questionPetsReducer from './questionPetsReducer';
import questionUsersReducer from './questionUsersReducer';
import authReducer from './authReducer';
import favouritePetsReducer from './favouritePetsReducer';

export default combineReducers({
	main: mainReducer,
	login: loginReducer,
	auth: authReducer,
	register: registerReducer,
	petType: petTypeReducer,
	petSize: petSizeReducer,
	raceData: petSizeReducer,
	hamsterRaceData: getHamsterRaceReducer,
	adoptFormData: adoptFormReducer,
	saciPets: saciPetsReducer,
	adoptMeForm: adoptMeFormReducer,
	userPets: userPetsReducer,
	outputRequest: outputRequestReducer,
	questionPets: questionPetsReducer,
	questionUsers: questionUsersReducer,
	favouritePets: favouritePetsReducer
});
