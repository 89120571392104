/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';
import '../../assets/css/pagination.css';
import { page_saci_pets_action } from '../../redux/actions/saciPets';
import { useMediaQuery, useTheme } from '@material-ui/core';

export default function Pagination() {
	const dispatch = useDispatch(); //Redux hook
	const { mascotas } = useSelector((state) => state.saciPets); // get pet Data from Local Storage
	const [offset, setOffset] = useState(0); // set the offset of the pages
	const [perPage] = useState(20); // set the items showed in each page
	const [pageCount, setPageCount] = useState(0); // set the count to the pagination component
	const [allowPagination, setAllowPagination] = useState(true); // if true display the pagination
	const sliceStart = offset * perPage - perPage; // set the start of array slice
	const sliceEnd = offset * perPage; // set the end of array slice
	const slicePets = mascotas?.slice(0, perPage);
	// to the start display de first elements in array less than mascotas length
	useEffect(() => {
		setPageCount(Math.ceil(mascotas?.length / perPage));
		dispatch(page_saci_pets_action(slicePets));
		if (mascotas?.length <= perPage) {
			setAllowPagination(false);
		}
		if (mascotas?.length > perPage) {
			setAllowPagination(true);
		}
	}, [mascotas]);

	//async function to dispatch by offset slice elements
	const getPageMascotas = async () => {
		const slicePets = mascotas?.slice(sliceStart, sliceEnd);
		dispatch(page_saci_pets_action(slicePets));
		window.scroll(0, 0);
	};
	// onClick event of the pagination
	const handlePageClick = (e) => {
		const selectedPage = e.selected;
		setOffset(selectedPage + 1);
	};
	// execute the async function by the onClick event
	useEffect(() => {
		if (offset !== 0) {
			getPageMascotas();
		}
	}, [offset]);
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.only('xs'))
	/* if the number of cards to display in a page is greater than the perPage const this
	shows the Pagination Component */

	return (
		<>
			{allowPagination ? (
				<div>
					<ReactPaginate
						previousLabel={!matches ? 'Atrás' : '<'}
						nextLabel={!matches ? 'Siguiente' : '>'}
						breakLabel={'...'}
						breakClassName={'break-me'}
						pageCount={pageCount}
						marginPagesDisplayed={2}
						pageRangeDisplayed={!matches ? 3 : 1}
						onPageChange={handlePageClick}
						containerClassName={'pagination'}
						subContainerClassName={'pages pagination'}
						activeClassName={'active'}
					/>
				</div>
			) : null}
		</>
	);
}
