import { makeStyles } from '@material-ui/core/styles';

const petRequestStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(0, 2, 2, 2)
		},
		[theme.breakpoints.only('xs')]: {
			padding: theme.spacing(0, 0, 0, 0)
		}
	},

	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`
	},

	paper: {
		padding: theme.spacing(2),
		'&:hover': {
			boxShadow: '0 0 10px rgba(99, 193, 50), 0px 0px 20px rgba(99, 193, 50)',
			backgroundColor: '#F0F2F5',
		}
	},

	buttonColor: {
		color: '#FFF'
	}


}));

export default petRequestStyles;
