/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Typography, Grid, Tooltip } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import uploadImagesStyles from '../../../assets/css/js/uploadImagesStyles';
//icons
import PublishIcon from '@material-ui/icons/Publish';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';
import { useDispatch, useSelector } from 'react-redux';
import { set_enable_photo_step_action } from '../../../redux/actions/adoptFormAction';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import { useTranslation } from 'react-i18next'
import close_green from '../../../assets/svg/close_green.svg';
import { save_selected_pet_data_action } from '../../../redux/actions/userPetsAction';
import { loginNormalSuccessPhoto } from '../../../redux/actions/loginAction';
import { set_user_pets_state_action } from '../../../redux/actions/saciPets';


export default function UploadImages({
	selectedFiles,
	initialData,
	setSelectedFiles,
	setImageData,
	isUploaded,
	setIsUploaded,
	imageData,
	indexHistory,
	setIndexHistory
}) {
	const [t] = useTranslation('global');
	const classes = uploadImagesStyles();
	const dispatch = useDispatch();
	const { showUserPets } = useSelector((state) => state.saciPets);
	const [verifyArray, setVerifyArray] = useState(false);
	const [indexMemory, setIndexMemory] = useState(null);
	const { fotos } = useSelector((state) => state.userPets.userPetData);
	const { userPetData } = useSelector((state) => state.userPets);
	const [initialArray, setInitialArray] = useState([]);
	const baseUrl = process.env.REACT_APP_BACKEND_URL;



	useEffect(() => {
		if (showUserPets) {
			setInitialArray([
				`${baseUrl}${fotos.split(',')[0]}.jpg`,
				`${baseUrl}${fotos.split(',')[1]}.jpg`,
				`${baseUrl}${fotos.split(',')[2]}.jpg`,
				`${baseUrl}${fotos.split(',')[3]}.jpg`,
				`${baseUrl}${fotos.split(',')[4]}.jpg`
			]);
		}
	}, []);

	const handleImageChange = (e) => {
		if (e.target.files) {
			const filesArray = Array.from(e.target.files).map((file) =>
				URL.createObjectURL(file)
			);
			const filesData = Array.from(e.target.files).map((file) => file);
			setImageData((prevImages) => prevImages.concat(filesData));
			setSelectedFiles((prevImages) => prevImages.concat(filesArray));
			setVerifyArray(true);
		}
	};

	console.log('imageData', imageData);
	const handleQuitImage = (photo, num) => {
		console.log('photo', photo, num);
		if (fotos !== '') {
			const ArrayPhotos = fotos.split(',')
			let newPhotos = ''
			ArrayPhotos.map((item, i) => {
				if (i !== num) {
					newPhotos = newPhotos === '' ? item : newPhotos + "," + item
				}
			})
			dispatch(save_selected_pet_data_action({
				...userPetData,
				fotos: newPhotos
			}))
			let newNum = ArrayPhotos.length - num
			console.log('antes', newNum);
			let contador = 0
			if (photo.includes('blob')) {
				selectedFiles.map(item => {
					if (item.includes('blob')) {
						if (item === photo) {
							console.log('contador', contador);
							setImageData(imageData.filter(i => i.name !== imageData[contador].name))
						} else {
							contador++
						}
					}
				})
			}
			// setSelectedFiles(selectedFiles.filter(i => i !== selectedFiles[num]))
			// let newArray = selectedFiles;
			// let deleteImage = newArray.indexOf(photo);
			// let indexData = initialArray.indexOf(photo);
			// setIndexMemory(indexData);
			// newArray.splice(deleteImage, 1);
			// // setSelectedFiles(newArray);
			// setVerifyArray(true);
		} else {
			setImageData(imageData.filter(i => i.name !== imageData[num].name))
		}
		let newArray = selectedFiles;
		let deleteImage = newArray.indexOf(photo);
		let indexData = initialArray.indexOf(photo);
		setIndexMemory(indexData);
		newArray.splice(deleteImage, 1);
		console.log('newArray', newArray)
		setVerifyArray(true);
		setSelectedFiles(newArray);
		// setSelectedFiles(selectedFiles.filter(i => i !== selectedFiles[num]))
	};
	console.log('selectedFiles', selectedFiles)
	useEffect(() => {
		if (indexMemory !== null) {
			setIndexHistory([...indexHistory, indexMemory]);
		}
	}, [indexMemory]);

	// useEffect(() => {
	// 	// setImageData(selectedFiles);
	// 	// setImageData((prevImages) => prevImages.concat(filesData));
	// }, [selectedFiles]);

	// useEffect(() => { }, [indexHistory]);

	useEffect(() => {
		if (verifyArray) {
			setVerifyArray(false);
		}
	}, [verifyArray]);

	useEffect(() => {
		if (selectedFiles.length !== 0) {
			setIsUploaded(true);
			dispatch(set_enable_photo_step_action(true));
		} else {
			setIsUploaded(false);
			dispatch(set_enable_photo_step_action(false));
		}
	}, [selectedFiles, verifyArray]);

	useEffect(() => {
		if (selectedFiles.length > 5) {
			let arrayData = selectedFiles;

			arrayData.splice(5, 5);
			console.log('arrayData', arrayData);

			setSelectedFiles(arrayData);
		}
	}, [selectedFiles, verifyArray]);

	return (
		<Grid container className={classes.root}>
			{!isUploaded ? (
				<Grid xs={12} item className={classes.mainContainer}>
					<Grid
						container
						justify="center"
						spacing={2}
						className={classes.uploadContainer}
					>
						<label htmlFor="upload-input">
							<Grid item xs={12}>
								<Typography align="center" color="primary">
									{t('upload-photos')}
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<PublishIcon color="primary" className={classes.uploadIcon} />
							</Grid>
						</label>
					</Grid>
				</Grid>
			) : (
				<Grid container className={classes.mainContainer}>
					<Grid
						container
						justify="center"
						spacing={1}
						className={classes.itemPhoto}
					>
						{selectedFiles.length < 5 && (
							<Grid item xs={12}>
								<label htmlFor="upload-input">
									<AddToPhotosIcon color="primary" />
									<Typography align="center" color="primary">
										{t('add-photos')}
									</Typography>
								</label>
							</Grid>
						)}
						{selectedFiles.length >= 5 && (
							<Grid item xs={12}>
								<Grid
									container
									justify="center" /* className={classes.messageContainer} */
								>
									<Grid item>
										<ErrorRoundedIcon color="primary" />
										<Typography align="center" color="primary">
											{!showUserPets
												? t('upload-maximum')
												: t('upload-maximum-5-photos')}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						)}
						{selectedFiles.length &&
							selectedFiles.map((photo, i) => (
								<Grid item key={photo} className={classes.photoContainer}>
									<Grid container style={{ position: 'relative' }}>
										<img className={classes.photos} src={photo} alt="" />
										<Tooltip title="Eliminar" arrow>
											<IconButton
												onClick={() => handleQuitImage(photo, i)}
												className={classes.quitPhotoIcon}
											>
												<img src={close_green} style={{ width: '30px', height: '30px' }} />
											</IconButton>
										</Tooltip>
									</Grid>
								</Grid>
							))}
					</Grid>
				</Grid>
			)}
			<input
				multiple
				id="upload-input"
				type="file"
				accept=".jpg,.jpeg,.gif,.png,.mov,.mp4"
				style={{ display: 'none' }}
				onChange={handleImageChange}
			/>
		</Grid>
	);
}
