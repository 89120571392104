import React from 'react';
import { IconButton, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export const InputAdornmentFirst = (props) => {
	const { handleClickShowPassword1, handleMouseDownPassword1, showPassword1 } =
		props;
	return (
		<InputAdornment position="end">
			<IconButton
				aria-label="toggle password visibility"
				onClick={handleClickShowPassword1}
				onMouseDown={handleMouseDownPassword1}
				edge="end"
			>
				{showPassword1 ? <Visibility /> : <VisibilityOff />}
			</IconButton>
		</InputAdornment>
	);
};

export const InputAdornmentSecond = (props) => {
	const { handleClickShowPassword2, handleMouseDownPassword2, showPassword2 } =
		props;
	return (
		<InputAdornment position="end">
			<IconButton
				aria-label="toggle password visibility"
				onClick={handleClickShowPassword2}
				onMouseDown={handleMouseDownPassword2}
				edge="end"
			>
				{showPassword2 ? <Visibility /> : <VisibilityOff />}
			</IconButton>
		</InputAdornment>
	);
};
