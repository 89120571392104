import React, { useEffect, useState } from 'react';
import PlayForWorkRoundedIcon from '@material-ui/icons/PlayForWorkRounded';
import { IconButton, Tooltip, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { pwaDataAction } from '../../../redux/actions/mainAction';

const ButtonPwa = ({ tittle, color }) => {

    const { isReadyForInstall } = useSelector(
        (state) => state.main
    );
    //Dispatch stance
    const dispatch = useDispatch();

    const [t] = useTranslation("global");

    async function downloadApp() {
        /*   console.log("👍", "butInstall-clicked"); */
        const promptEvent = window.deferredPrompt;
        if (!promptEvent) {
            // The deferred prompt isn't available.
            return;
        }
        // Show the install prompt.
        promptEvent.prompt();
        // Log the result
        const result = await promptEvent.userChoice;
        // Reset the deferred prompt variable, since
        // prompt() can only be called once.
        window.deferredPrompt = null;
        // Hide the install button.
        if (result.outcome === "accepted") {
            dispatch(pwaDataAction({
                isReadyForInstall: false,
                deferredPrompt: null
            }))
        }

    }
    return (
        <>
            {isReadyForInstall && (
                < Tooltip title="Instalar aplicación" >
                    <Button variant="text" color='initial'
                        style={{ marginLeft: '3px' }}
                        startIcon={<PlayForWorkRoundedIcon style={{ color: color, fontSize: '40px' }} />}
                        onClick={downloadApp}
                    >
                        {tittle === true && t("install")}
                    </Button>
                </Tooltip >
            )}
        </>
    )
}

export default ButtonPwa