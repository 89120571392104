/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

// Redux
import { useSelector } from 'react-redux';

// Clsx
import clsx from 'clsx';

// Carousel
import Carousel from 'react-material-ui-carousel';
import { CardMedia, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// iconos de Carousel
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Link, useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
	media: {
		height: 350,
		// cursor: 'pointer',
		[theme.breakpoints.between('md', 'lg')]: {
			height: 340
		},
		[theme.breakpoints.down('sm')]: {
			height: 310
		}
	},
	media2: {
		height: 526,
		backgroundSize: 'contain'
		// paddingTop: '56.25%'
	},

	chipItem: {
		height: 20,
		marginLeft: 3,
		opacity: 0.7
	}
}));

export default function CarouselPhotos({ itemPets, itemProcedure, itemId }) {
	const { userPetModalData } = useSelector((state) => state.userPets);

	const baseURL = process.env.REACT_APP_BACKEND_URL;

	const classes = useStyles();

	// const petPhotos = itemPets;

	const [photo_1] = useState(itemPets?.split(',')[0]);
	const [photo_2] = useState(itemPets?.split(',')[1]);
	const [photo_3] = useState(itemPets?.split(',')[2]);
	const [photo_4] = useState(itemPets?.split(',')[3]);
	const [photo_5] = useState(itemPets?.split(',')[4]);

	const [items, setItems] = useState([
		{
			imgPath: `${baseURL}${photo_1}.jpg`
		}
	]);

	useEffect(() => {
		if (photo_2 > 0) {
			setItems([
				{
					imgPath: `${baseURL}${photo_1}.jpg`
				},
				{
					imgPath: `${baseURL}${photo_2}.jpg`
				}
			]);
			if (photo_3 > 0) {
				setItems([
					{
						imgPath: `${baseURL}${photo_1}.jpg`
					},
					{
						imgPath: `${baseURL}${photo_2}.jpg`
					},
					{
						imgPath: `${baseURL}${photo_3}.jpg`
					}
				]);
				if (photo_4 > 0) {
					setItems([
						{
							imgPath: `${baseURL}${photo_1}.jpg`
						},
						{
							imgPath: `${baseURL}${photo_2}.jpg`
						},
						{
							imgPath: `${baseURL}${photo_3}.jpg`
						},
						{
							imgPath: `${baseURL}${photo_4}.jpg`
						}
					]);
					if (photo_5 > 0) {
						setItems([
							{
								imgPath: `${baseURL}${photo_1}.jpg`
							},
							{
								imgPath: `${baseURL}${photo_2}.jpg`
							},
							{
								imgPath: `${baseURL}${photo_3}.jpg`
							},
							{
								imgPath: `${baseURL}${photo_4}.jpg`
							},
							{
								imgPath: `${baseURL}${photo_5}.jpg`
							}
						]);
					}
				}
			}
		}
	}, []);

	function Item(props) {
		const { id } = useParams();

		// 		const StyledLink = styled(Link)`
		//     text-decoration: none;

		//     &:focus, &:hover, &:visited, &:link, &:active {
		//         text-decoration: none;
		//     }
		// `;

		return (
			<Link
				// className="disabled-link"
				// style={{pointerEvents: 'unset'}}
				to={{
					pathname: `/pet-information/${itemId ? itemId : id}`,
					state: { pageMascotas: itemId ? itemId : id }
				}}
			>
				<CardMedia
					className={clsx({
						[classes.media]: !userPetModalData,
						[classes.media2]: userPetModalData
					})}
					image={props.item.imgPath}
					// onClick={() => dispatch(set_user_pet_modal_data_action(true))}
				>
					<Chip
						className={classes.chipItem}
						color="secondary"
						label={`${activeStep + 1} / ${items.length}`}
					/>
				</CardMedia>
			</Link>
		);
	}

	const [activeStep, setActiveStep] = useState(0);

	const handleStepChange = (item) => {
		setActiveStep(item);
	};

	return (
		<>
			<Carousel
				onChange={handleStepChange}
				animation="fade"
				autoPlay={false}
				cycleNavigation={false}
				// IndicatorIcon={<PetsIcon/>}
				indicatorIconButtonProps={{
					style: {
						padding: '5px',
						color: !userPetModalData ? 'white' : 'black'
					}
				}}
				activeIndicatorIconButtonProps={{
					style: {
						color:
							(itemProcedure === '1' && '#63C132') ||
							(itemProcedure === '2' && '#D8D841') ||
							(itemProcedure === '3' && '#BD3434')
					}
				}}
				indicatorContainerProps={{
					style: {
						marginTop: !userPetModalData ? '-50px' : null
					}
				}}
				navButtonsProps={{
					style: {
						backgroundColor: '#1E3F20',
						// borderRadius: 0,
						width: 10,
						height: 10
					}
				}}
				NextIcon={<KeyboardArrowRight />}
				PrevIcon={<KeyboardArrowLeft />}
			>
				{items.map((item, i) => (
					<Item key={i} item={item} />
				))}
			</Carousel>
		</>
	);
}
