import axiosClient from '../../configAxios/axios';
import {
	SET_RESPONSIVE_MENU,
	HIDE_MENU_BUTTONS,
	GET_COUNTRIES,
	HIDE_LOADING_PAGE,
	PWA_DATA
} from '../types';

export const set_responsive_menu_action = (state) => async (dispatch) => {
	dispatch({
		type: SET_RESPONSIVE_MENU,
		payload: state
	});
};

export const hide_menu_buttons_action = (state) => async (dispatch) => {
	dispatch({
		type: HIDE_MENU_BUTTONS,
		payload: state
	});
};

export const get_countries_action = () => async (dispatch) => {
	axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
	const response = await axiosClient.get('/paises');
	dispatch({
		type: GET_COUNTRIES,
		payload: response.data.paises.sort((a, b) =>
			a.descripcion.localeCompare(b.descripcion)
		)
	});
};

export const hide_loading_page_action = (state) => async (dispatch) => {
	dispatch({
		type: HIDE_LOADING_PAGE,
		payload: state
	})
}

export function pwaDataAction(data) {
	return async (dispatch) => {
	  dispatch(pwaData(data));
	};
  }
  
  const pwaData = (data) => ({
	type: PWA_DATA,
	payload: data,
  });