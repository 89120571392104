//Iniciar sesion
export const INICIAR_SESION_START = 'INICIAR_SESION_START';
export const INICIAR_SESION_ERROR = 'INICIAR_SESION_ERROR';
export const INICIAR_SESION_EXITO = 'INICIAR_SESION_EXITO';
export const INICIAR_SESION_ERROR_GOOGLE = 'INICIAR_SESION_ERROR_GOOGLE';
export const CHECK_LOGIN = 'CHECK_LOGIN';
export const CHECK_LOGOUT = 'CHECK_LOGOUT';
export const LOGIN_ERROR_CLEAN = 'LOGIN_ERROR_CLEAN';
//olvidó contraseña
export const SET_FORGOT_PASSWORD_MODAL = 'SET_FORGOT_PASSWORD_MODAL';
export const GEN_PASSWORD_CODE = 'GEN_PASSWORD_CODE';
export const VERIFY_PASSWORD_CODE = 'VERYFY_PASSWORD_CODE';
//PWA
export const PWA_DATA = 'PWA_DATA';
// Registro
export const REGISTRO_USUARIO_START = 'REGISTRO_USUARIO_START';
export const REGISTRO_USUARIO_ERROR = 'REGISTRO_USUARIO_ERROR';
export const REGISTRO_USUARIO_EXITO = 'REGISTRO_USUARIO_EXITO';
export const SET_CHECK_EMAIL_MODAL = 'SET_CHECK_EMAIL_MODAL';
export const GEN_REGISTER_CODE = 'GEN_REGISTER_CODE';
export const VERIFY_REGISTRY_CODE = 'VERIFY_REGISTRY_CODE';
export const REGISTER_ERROR_CLEAN = 'REGISTER_ERROR_CLEAN';

//obtener países
export const GET_COUNTRIES = 'GET_COUNTRIES';

//Get Size of Pets
export const GET_PET_RACE_DATA = 'GET_PET_RACE_DATA';

//Save Pet Form
export const SAVE_PET_FORM_ACTION = 'GET_PET_FORM_ACTION';

//Save Size Pet selected
export const SMALL_SIZE_PET_ACTION = 'SMALL_SIZE_PET_ACTION';
export const MEDIUM_SIZE_PET_ACTION = 'MEDIUM_SIZE_PET_ACTION';
export const BIG_SIZE_PET_ACTION = 'BIG_SIZE_PET_ACTION';
export const NEXT_STEP_ACTION = 'NEXT_STEP_ACTION';
export const BACK_STEP_ACTION = 'BACK_STEP_ACTION';
export const GET_HAMSTER_RACE_DATA = 'GET_HAMSTER_RACE_DATA';
export const GET_DEPARTMENT_DATA = 'GET_DEPARTMENT_DATA';
export const UPDATE_ADOPT_FORM_DESCRIPTION_DATA =
	'UPDATE_ADOPT_FORM_DESCRIPTION_DATA';
export const ENABLE_PHOTO_STEP = 'ENABLE_PHOTO_STEP';
export const GET_CITY_DATA = 'GET_CITY_DATA';
export const PET_DESCRIPTION_OK = 'PET_DESCRIPTION_OK';
export const GET_ADOPT_FORM_DESCRIPTION_DATA =
	'GET_ADOPT_FORM_DESCRIPTION_DATA';
export const PUSH_DATA_ACTION = 'PUSH_DATA_ACTION';
export const GET_DATA_BY_FORM_REGISTERED = 'GET_DATA_BY_FORM_REGISTERED';
export const SUCCESS_PHOTO_UPLOADS = 'SUCCESS_PHOTO_UPLOADS';
export const SUCCESS_PET_IMAGE_1 = 'SUCCESS_PET_IMAGE_1';
export const SUCCESS_PET_IMAGE_2 = 'SUCCESS_PET_IMAGE_2';
export const SUCCESS_PET_IMAGE_3 = 'SUCCESS_PET_IMAGE_3';
export const SUCCESS_PET_IMAGE_4 = 'SUCCESS_PET_IMAGE_4';
export const SUCCESS_PET_IMAGE_5 = 'SUCCESS_PET_IMAGE_5';
export const RESET_FORM = 'RESET_FORM';
export const SAVE_PET_IMAGE_1 = 'SAVE_PET_IMAGE_1';
export const SAVE_PET_IMAGE_2 = 'SAVE_PET_IMAGE_2';
export const SAVE_PET_IMAGE_3 = 'SAVE_PET_IMAGE_3';
export const SAVE_PET_IMAGE_4 = 'SAVE_PET_IMAGE_4';
export const SAVE_PET_IMAGE_5 = 'SAVE_PET_IMAGE_5';
export const REGISTER_DIALOG_OPEN = 'REGISTER_DIALOG_OPEN';
export const REGISTER_DIALOG_CLOSE = 'REGISTER_DIALOG_CLOSE';
export const LOGIN_DIALOG_OPEN = 'LOGIN_DIALOG_OPEN';
export const LOGIN_DIALOG_CLOSE = 'LOGIN_DIALOG_CLOSE';
export const ALERT_PASSWORD_CODE = 'ALERT_PASSWORD_CODE';
export const ALERT_CLEAN_CODE = 'ALERT_CLEAN_CODE';
export const ADOPT_DIALOG_OPEN = 'ADOPT_DIALOG_OPEN';
export const ADOPT_DIALOG_CLOSE = 'ADOPT_DIALOG_CLOSE';
export const ADOPTSTEPPER_DIALOG_OPEN = 'ADOPTSTEPPER_DIALOG_OPEN';
export const ADOPTSTEPPER_DIALOG_CLOSE = 'ADOPTSTEPPER_DIALOG_CLOSE';
export const CLEAN_PETS_BY_USER = 'CLEAN_PETS_BY_USER';
export const SAVE_IMAGE_DATA = 'SAVE_IMAGE_DATA';
// export const REGISTER_TO_LOGIN = 'REGISTER_TO_LOGIN'
export const SAVE_REGISTER_TO_LOGIN = 'SAVE_REGISTER_TO_LOGIN';
export const GET_SACI_PETS = 'GET_SACI_PETS';
export const GET_SACI_PETS_FILTERS = 'GET_SACI_PETS_FILTERS';
export const GET_SACI_PETS_VALIDATE = 'GET_SACI_PETS_VALIDATE';
export const GET_SACI_PETS_VALIDATE_CLEAN = 'GET_SACI_PETS_VALIDATE_CLEAN';
export const GET_SACI_EMPTY_PETS = 'GET_SACI_EMPTY_PETS';
export const GET_PET_PHOTOS = 'GET_PET_PHOTOS';
export const PET_DESCRIPTION_NOT_OK = 'PET_DESCRIPTION_NOT_OK';
export const RESET_CITY_ACTION = 'RESET_CITY_ACTION';
export const PAGE_SACI_PETS = 'PAGE_SACI_PETS';
export const SELECT_PET_DATA = 'SELECT_PET_DATA';
export const SELECT_PET_FORM_DATA = 'SELECT_PET_FORM_DATA';
export const SET_ADOPT_ME_DIALOG = 'SET_ADOPT_ME_DIALOG';
export const SET_ADOPT_ME_DIALOG_PROCEDURE = 'SET_ADOPT_ME_DIALOG_PROCEDURE';
export const SET_PET_DATA_DIALOG = 'SET_PET_DATA_DIALOG';
export const SET_UNLOGGED_MODAL = 'SET_UNLOGGED_MODAL';
export const GET_ADOPT_ME_FORM_NAME = 'GET_ADOPT_ME_FORM_NAME';
export const UPDATE_ADOPT_ME_FORM = 'UPDATE_ADOPT_ME_FORM';
export const NEXT_STEP_ADOPT_ME_ACTION = 'NEXT_STEP_ADOPT_ME_ACTION';
export const PREVIEW_STEP_ADOPT_ME_ACTION = 'PREVIEW_STEP_ADOPT_ME_ACTION';
export const GET_ADOPT_ME_FORM = 'GET_ADOPT_ME_FORM';
export const ENABLE_STEP_TWO = 'ENABLE_STEP_TWO';
export const SAVE_ADOPT_ME_FORM = 'SAVE_ADOPT_ME_FORM';
export const SET_USER_PETS_MODAL = 'SET_USER_PETS_MODAL';
export const GET_PETS_BY_USER = 'GET_PETS_BY_USER';
////////////////////////////////////
export const SET_USER_PET_MODAL_DATA = 'SET_USER_PET_MODAL_DATA';
export const SAVE_USER_PET_ID = 'SAVE_USER_PET_ID';
export const SAVE_USER_PET_DATA = 'SAVE_USER_PET_DATA';
export const SHOW_USER_PETS = 'SHOW_USER_PETS';
export const RESET_PETS = 'RESET_PETS';
export const SET_EDIT_USER_PET_DIALOG = 'SET_EDIT_USER_PET_DIALOG';
export const SET_STEP = 'SET_STEP';
export const GET_PETS_NO_FILTER = 'GET_PETS_NO_FILTER';
export const ACTIVE_PET_STATE = 'ACTIVE_PET_STATE';
export const SET_PUBLISHED_PET = 'SET_PUBLISHED_PET';
export const REQUEST_PET = 'REQUEST_PET';
export const SELECTED_REQUEST = 'SELECTED_REQUEST';
export const NO_USER_PETS = 'NO_USER_PETS';
export const CLEAN_USER_PETS_DATA = 'CLEAN_USER_PETS_DATA';
export const SAVE_USER_CONTACT_DATA = 'SAVE_USER_CONTACT_DATA';
export const GET_OUTPUT_REQ_PETS = 'GET_OUTPUT_REQ_PETS';
export const GET_OUTPUT_REQ_PETS_CLEAN = 'GET_OUTPUT_REQ_PETS_CLEAN';
export const SHOW_OUTPUT_REQ_PETS = 'SHOW_OUTPUT_REQ_PETS';
export const SET_CHECKBOX_CONTACT_DATA = 'SET_CHECKBOX_CONTACT_DATA';
export const UPDATE_USER_PET_FORMDATA = 'UPDATE_USER_PET_FORMDATA';
export const MY_REQUEST_PAGE = 'MY_REQUEST_PAGE';
export const SEND_REQUEST_PAGE = 'SEND_REQUEST_PAGE';
export const SET_USER_PETS_STATE = 'SET_USER_PETS_STATE';
export const PUBLISH_USER_PET_STATE = 'PUBLISH_USER_PET_STATE';
export const SET_CARD_PETS_TITLE = 'SET_CARD_PETS_TITLE';
export const SET_OUTPUT_REQ_PETS_STATE = 'SET_OUTPUT_REQ_PETS_STATE';
export const SET_ENTRY_REQUEST_PAGE_STATE = 'SET_ENTRY_REQUEST_PAGE_STATE';
export const ALLOW_OR_DENY_USER_PET_REQUEST = 'ALLOW_OR_DENY_USER_PET_REQUEST';
export const DENY_REQUEST_PET = 'DENY_REQUEST_PET';
export const ACCEPT_REQUEST_PET = 'DENY_REQUEST_PET';
export const NEW_REQUEST_PET = 'DENY_REQUEST_PET';
export const CLEAN_REQUEST_DATA_ACTION = 'CLEAN_REQUEST_DATA_ACTION';
export const CANCEL_PET_REQUEST = 'CANCEL_PET_REQUEST';
export const PUBLISH_REQUEST = 'PUBLISH_REQUEST';
export const RESET_ADOPT_ME_FORM = 'RESET_ADOPT_ME_FORM';
export const SET_PROCEDURE = 'SET_PROCEDURE';
export const CLEAN_PETS = 'CLEAN_PETS';
export const RESET_ID_MASCOTA_FORM = 'RESET_ID_MASCOTA_FORM';
export const SET_RESPONSIVE_MENU = 'SET_RESPONSIVE_MENU';
export const HIDE_MENU_BUTTONS = 'HIDE_MENU_BUTTONS';
export const GET_PET_COLORS = 'GET_PET_COLORS';
//GOOGLE DATA
export const GET_GOOGLE_DATA = 'GET_GOOGLE_DATA';
export const LOAD_GOOGLE_DATA = 'LOAD_GOOGLE_DATA';
export const LOAD_SESSION_DATA = 'LOAD_SESSION_DATA';
export const SAVE_USER_TOKEN = 'SAVE_USER_TOKEN';
export const GET_SESSION = 'GET_SESSION';
//////////////////////////////////////////
// USER PROFILE
export const SACI_USER_PROFILE = 'SACI_USER_PROFILE';
export const SACI_PHONE_PROFILE = 'SACI_PHONE_PROFILE';
export const SACI_EMAIL_PROFILE_VALIDATE = 'SACI_EMAIL_PROFILE_VALIDATE';
export const SACI_EMAIL_PROFILE = 'SACI_EMAIL_PROFILE';
export const SACI_PASSWORD_VALIDATE_PROFILE = 'SACI_PASSWORD_VALIDATE_PROFILE';
export const SACI_PASSWORD_PROFILE = 'SACI_PASSWORD_PROFILE';
export const SACI_PHOTO_PROFILE = 'SACI_PHOTO_PROFILE';
export const LOGIN_CLEAN_PHOTO = 'LOGIN_CLEAN_PHOTO';
export const SACI_PHOTO_DELETE_PROFILE = 'SACI_PHOTO_DELETE_PROFILE';
export const INICIAR_SESION_EXITO_PHOTO = 'INICIAR_SESION_EXITO_PHOTO';
// PET QUESTION
export const SEND_QUESTION_PET = 'SEND_QUESTION_PET';
export const SEND_ANSWER_PET = 'SEND_ANSWER_PET';
export const GET_QUESTION_ANSWER_PET = 'GET_QUESTION_ANSWER_PET';
export const CLEAN_QUESTION_ANSWER_PET = 'CLEAN_QUESTION_ANSWER_PET';
// USER QUESTION
export const SEND_QUESTION_USER = 'SEND_QUESTION_USER';
export const SEND_ANSWER_USER = 'SEND_ANSWER_USER';
export const GET_QUESTION_ANSWER_USER = 'GET_QUESTION_ANSWER_USER';
export const GET_QUESTION_ANSWER_USER_2 = 'GET_QUESTION_ANSWER_USER_2';
export const CLEAN_QUESTION_ANSWER_USER = 'CLEAN_QUESTION_ANSWER_USER';
export const DIALOG_SHOW_REQUEST = 'DIALOG_SHOW_REQUEST';
//PRUEBA
export const GET_CITY_DATA_2 = 'GET_CITY_DATA_2';
// FAVORITOS
export const ADD_FAVOURITE_PET = 'ADD_FAVOURITE_PET';
export const GET_FAVOURITE_PET = 'GET_FAVOURITE_PET';
export const SHOW_FAVOURITE_PET = 'SHOW_FAVOURITE_PET';
export const CLEAN_FAVOURITE_PET = 'CLEAN_FAVOURITE_PET';

export const HIDE_LOADING_PAGE = 'HIDE_LOADING_PAGE'
export const UPDATE_COUNTRY_USER = 'UPDATE_COUNTRY_USER'
