import axiosClient from './axios';

export async function addFavourite(favouriteData) {
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post('/favorito', favouriteData);
		return response;
	} catch (error) {
		console.log(error);
	}
}

export async function getFavourite(favouriteData) {
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post('/cantidadfavorito', favouriteData);
		return response;
	} catch (error) {
		console.log(error);
	}
}

export async function showFavorite(favouriteData) {
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post('/mostrarfavorito', favouriteData);
		return response;
	} catch (error) {
		console.log(error);
	}
}
