import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	TwitterIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton
} from 'react-share';

import {
	Box,
	DialogContent,
	Grid,
	TextField,
	Tooltip,
	useMediaQuery,
	useTheme,
	Zoom
} from '@material-ui/core';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { useSelector } from 'react-redux';

import clsx from 'clsx';

import CustomTooltip from './CustomTooltip';

import { useTranslation } from 'react-i18next';

import { DialogTitle } from '../DialogComponents/CustomDialog';

const useStyles = makeStyles((theme) => ({
	buttonShare: {
		borderRadius: 50,
		transition: '0.5s',
		border: 'none',
		padding: '0px'
	},

	buttonShareFb: {
		'&:hover': {
			boxShadow: '0 0 10px rgba(59, 89, 152), 0px 0px 20px rgba(59, 89, 152)'
		},
		backgroundColor: 'rgba(59, 89, 152)'
	},

	buttonShareTw: {
		'&:hover': {
			boxShadow: '0 0 10px rgb(0, 172, 237), 0px 0px 20px rgb(0, 172, 237)'
		},
		backgroundColor: 'rgb(0, 172, 237)'
	},

	buttonShareWs: {
		'&:hover': {
			boxShadow: '0 0 10px rgb(37, 211, 102), 0px 0px 20px rgb(37, 211, 102)'
		},
		backgroundColor: 'rgb(37, 211, 102)'
	},

	buttonShareEm: {
		'&:hover': {
			boxShadow: '0 0 10px rgb(127, 127, 127), 0px 0px 20px rgb(127, 127, 127)'
		},
		backgroundColor: 'rgb(127, 127, 127)'
	},

	shareContainer: {
		margin: theme.spacing(0, 0, 3, 0)
	},

	CustomTooltip: {
		backgroundColor: '#1E3F20',
		color: 'white',
		opacity: 0.7
	},

	dialog: {
		'&& .MuiDialog-paperFullScreen': {
			justifyContent: 'center'
		}
	}
}));

export function ShareDialog(props) {
	const [t] = useTranslation('global');

	const classes = useStyles();

	const { petSelected } = useSelector((state) => state.saciPets);

	const { open, onClose } = props;

	const handleClose = () => {
		onClose();
		setCopySuccess('');
	};

	let shareUrl = '';
	if (typeof window === 'object') {
		shareUrl = String(window.location) + `pet-information/${petSelected}`;
	}

	const [openTooltip, setOpenTooltip] = useState(false);

	const handleTooltipClose = () => {
		setOpenTooltip(false);
	};

	const [copySuccess, setCopySuccess] = useState('');

	const textAreaRef = useRef(null);

	function copyToClipboard(e) {
		setOpenTooltip(true);
		e.target.focus();
		textAreaRef.current.select();
		document.execCommand('copy');
		setCopySuccess(t('copied'));
	}

	let tittleShare =
		'💚🐶 ◦•●◉ Da clic en el siguiente enlace para más información sobre la mascota ◉●•◦ 💚🐱';

	const theme = useTheme();

	const matches = useMediaQuery(theme.breakpoints.only('xs'));

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth={'sm'}
			fullScreen={matches}
			className={classes.dialog}
			fullWidth
		>
			<DialogTitle
				id="customized-dialog-title"
				align="center"
				onClose={handleClose}
			>
				{t('share-pet')}
			</DialogTitle>

			<DialogContent dividers style={{ flex: 'none' }}>
				<Grid
					container
					spacing={2}
					item
					alignItems="center"
					justify="center"
					className={classes.shareContainer}
				>
					<Grid item>
						<CustomTooltip
							title={t('share') + ' ' + t('on') + ' Facebook'}
							id="fb"
						>
							<Box>
								<FacebookShareButton
									url={shareUrl}
									quote={
										'Da clic en la imagen para más información sobre la mascota'
									}
									hashtag={'#PETSACI'}
									resetButtonStyle={false}
									className={clsx(classes.buttonShare, classes.buttonShareFb)}
								>
									<FacebookIcon size={40} round />
								</FacebookShareButton>
							</Box>
						</CustomTooltip>
					</Grid>

					<Grid item>
						<CustomTooltip
							title={t('share') + ' ' + t('on') + ' Twitter'}
							id="tw"
						>
							<Box>
								<TwitterShareButton
									url={shareUrl}
									title={tittleShare}
									hashtags={['PETSACI']}
									resetButtonStyle={false}
									className={clsx(classes.buttonShare, classes.buttonShareTw)}
								>
									<TwitterIcon size={40} round />
								</TwitterShareButton>
							</Box>
						</CustomTooltip>
					</Grid>

					<Grid item>
						<CustomTooltip
							title={t('share') + ' ' + t('on') + ' WhatsApp'}
							id="ws"
						>
							<Box>
								<WhatsappShareButton
									url={shareUrl}
									title={tittleShare}
									// separator=" ➡️ "
									resetButtonStyle={false}
									className={clsx(classes.buttonShare, classes.buttonShareWs)}
								>
									<WhatsappIcon size={40} round />
								</WhatsappShareButton>
							</Box>
						</CustomTooltip>
					</Grid>

					<Grid item>
						<CustomTooltip
							title={t('share') + ' ' + t('by') + ' ' + t('email')}
							id="em"
						>
							<Box>
								<EmailShareButton
									url={shareUrl}
									subject="Información de la mascota"
									resetButtonStyle={false}
									className={clsx(classes.buttonShare, classes.buttonShareEm)}
								>
									<EmailIcon size={40} round />
								</EmailShareButton>
							</Box>
						</CustomTooltip>
					</Grid>
				</Grid>

				<Grid container justify="center" alignContent="center" spacing={1}>
					<Grid item xs={12} sm={9}>
						<TextField
							autoFocus
							size="small"
							variant="outlined"
							value={shareUrl}
							inputRef={textAreaRef}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={2}>
						<ClickAwayListener onClickAway={handleTooltipClose}>
							<Tooltip
								placement={matches ? 'right' : 'top'}
								onClose={handleTooltipClose}
								open={openTooltip}
								disableFocusListener
								disableHoverListener
								disableTouchListener
								title={copySuccess}
								classes={{
									tooltip: classes.CustomTooltip
								}}
								TransitionComponent={Zoom}
								arrow
							>
								<Button
									size="small"
									variant="contained"
									color="primary"
									id="buttonWhite"
									onClick={copyToClipboard}
								>
									{t('copy')}
								</Button>
							</Tooltip>
						</ClickAwayListener>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}

ShareDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired
};
