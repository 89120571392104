import React, { useState } from 'react';

import Snackbar from '@material-ui/core/Snackbar';

import Slide from '@material-ui/core/Slide';
import MuiAlert from '@material-ui/lab/Alert';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import { ThemeProvider } from '@material-ui/styles';

import { createMuiTheme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';

import { green, lightBlue, purple, yellow } from '@material-ui/core/colors';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useSelector } from 'react-redux';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const theme = createMuiTheme({
	palette: {
		primary: {
			main: lightBlue[500]
		}
		// secondary: purple
	}
});

const useStyles = makeStyles((theme) => ({
	alert: {
		'&& .MuiAlert-icon': {
			alignItems: 'center'
		}
	}
}));

export default function CustomSnackbar(props) {
	const classes = useStyles();

	const { getFavourite, showFavourite, addFavourite } = useSelector(
		(state) => state.favouritePets
	);

	const Alert = React.forwardRef((props, ref) => (
		<MuiAlert elevation={6} variant="filled" {...props} ref={ref} />
	));

	// function TransitionSnackbar(props) {
	// 	return <Slide {...props} direction="up" />;
	// }

	const {
		openSnackbar,
		handleCloseSnackbar,
		vertical,
		horizontal,
		transition
	} = props;

	// const {
	// 	TransitionSnackbar,
	// 	transition,
	// 	vertical,
	// 	horizontal,
	// 	openSnackbar,
	// 	handleCloseSnackbar,
	// 	handleOpenSnackbar
	// } = useSnackbar();

	let validateMsg = addFavourite.fav === null;

	return (
		<>
			{/* <Button
				onClick={() => {
					handleOpenSnackbar(
						{
							vertical: 'bottom',
							horizontal: 'right'
						},
						TransitionSnackbar
					);
				}}
			>
				Clic 2
			</Button> */}

			<Snackbar
				open={openSnackbar}
				autoHideDuration={2000}
				onClose={handleCloseSnackbar}
				anchorOrigin={{ vertical, horizontal }}
				TransitionComponent={transition}
			>
				<Alert
					onClose={handleCloseSnackbar}
					severity={validateMsg ? 'success' : 'error'}
					className={classes.alert}
					// action={<Button color="inherit" size="small">OK</Button>}

					iconMapping={{ error: <HighlightOffIcon fontSize="inherit" />}}
				>
					{/* {addFavourite.msg} */}

					{validateMsg
						? 'Mascota agregada a favoritos'
						: 'Mascota eliminada de favoritos'}

					{/* <ThemeProvider theme={theme}>
						{validateMsg && (
							<Button
								// color="primary"
								size="small"
								variant="contained"
								disableElevation
								style={{ marginLeft: 16 }}
							>
								Ver
							</Button>
						)}
					</ThemeProvider> */}
				</Alert>
			</Snackbar>
		</>
	);
}
