import {
	NEXT_STEP_ADOPT_ME_ACTION,
	PREVIEW_STEP_ADOPT_ME_ACTION,
	GET_ADOPT_ME_FORM,
	ENABLE_STEP_TWO,
	SAVE_ADOPT_ME_FORM,
	RESET_ADOPT_ME_FORM
} from '../types';

const initialState = {
	formData: {
		nombre_adoptante: '',
		direccion_adoptante: '',
		id_departamento: '',
		id_codigo: '',
		telefono: '',
		correo: '',
		estado_civil: '',
		ocupacion: '',
		id: '',
		pregunta_1: '',
		pregunta_2: '',
		pregunta_3: '',
		pregunta_4: '',
		pregunta_5: '',
		pregunta_6: '',
		id_formulario: ''
	},	
	activeStepState: null,
	stepTwo: false,
	response: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_ADOPT_ME_FORM:
			return {
				...state,
				formData: action.payload
			};
		case NEXT_STEP_ADOPT_ME_ACTION:
			return {
				...state,
				activeStepState: action.payload
			};
		case PREVIEW_STEP_ADOPT_ME_ACTION:
			return {
				...state,
				activeStepState: action.payload
			};
		case ENABLE_STEP_TWO:
			return {
				...state,
				stepTwo: action.payload
			};
		case SAVE_ADOPT_ME_FORM:
			return {
				...state,
				response: action.payload
			};
		case RESET_ADOPT_ME_FORM:
			return {
				...state,
				formData: {
					nombre_adoptante: '',
					direccion_adoptante: '',
					id_departamento: '',
					id_codigo: '',
					telefono: '',
					correo: '',
					estado_civil: '',
					ocupacion: '',
					id: '',
					pregunta_1: '',
					pregunta_2: '',
					pregunta_3: '',
					pregunta_4: '',
					pregunta_5: '',
					pregunta_6: '',
					id_formulario: ''
				}
			};
		default:
			return state;
	}
};
