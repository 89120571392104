/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
//material ui
import {
	Dialog,
	Grid,
	TextField,
	Typography,
	Button,
	CircularProgress,
	DialogContent,
	FormHelperText
} from '@material-ui/core';
import { forgotPasswordStyles } from '../../assets/css/js/forgotPasswordStyles';
//redux
import {
	gen_password_code_action,
	set_check_email_modal_action,
	set_forgot_password_modal_action,
	update_password_action,
	verify_password_code_action
} from '../../redux/actions/loginAction';
import { useDispatch, useSelector } from 'react-redux';
//icons
import CheckEmailModal from '../CheckEmailModal';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { DialogTitle } from '../SaciComponents/DialogComponents/CustomDialog';
import { useForm } from 'react-hook-form';
import { InputAdornmentFirst, InputAdornmentSecond } from './FormComponents/CustomInputAdornment';
import { useInputAdornment } from '../../hooks/useInputAdornment';
// Notification
// import { useSnackbar } from 'notistack';

function ForgotPassword() {
	const [t] = useTranslation('global');
	const classes = forgotPasswordStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const fullScreenResponsive = useMediaQuery(theme.breakpoints.only('xs'));
	const [submitCode, setSubmitCode] = useState(false);
	const { forgotPasswordModal, checkEmailModal, tipoCode, alertPassword } = useSelector(
		(state) => state.login
	);
	const { correo } = useSelector(
		(state) => state.login.user
	);
	const [displayMsg, setDisplayMsg] = useState(false);
	const [verifyCode, setVerifyCode] = useState('');
	const [verifyMsg, setVerifyMsg] = useState('');
	const [registrySuccess, setRegistrySuccess] = useState(false);
	const [email, setEmail] = useState('');
	const [loader, setLoader] = useState(false);
	const [displayPasswordChange, setDisplayPasswordChange] = useState(false);
	const [newPassword, setNewPassword] = useState({
		password: '',
		password_2: ''
	});
	const [updatePasswordSuccess, setUpdatePasswordSuccess] = useState(false);
	const [id_usuario, setId_Usuario] = useState('');
	const { password } = newPassword;

	const handleChange = ({ target }) => {
		setNewPassword({
			...newPassword,
			[target.name]: target.value
		});
	};

	const onSubmit = (data) => {
		sendNewPassword();
	};

	// const { enqueueSnackbar } = useSnackbar();

	const sendNewPassword = async () => {
		const response = await dispatch(
			update_password_action({
				id_usuario: id_usuario,
				password: password
			})
		);
		if (response) {
			// enqueueSnackbar(
			// 	response.status !== 200
			// 		? t('error-information')
			// 		: t('updated-information'),
			// 	{
			// 		variant: 'success',
			// 	}
			// );
			setUpdatePasswordSuccess(true);
		}
	};

	const onSubmit2 = (data) => {
		console.log('entreonSubmit2')
		generateCode();
	};

	const generateCode = async () => {
		setVerifyMsg(t('validating'));
		setLoader(true);
		setDisplayMsg(false);
		const response = await dispatch(
			gen_password_code_action({ correo: email, tipo: tipoCode })
		);
		console.log('response', response)
		if (response.code !== '2') {
			console.log('response.code', response.code);
			setVerifyMsg(response.msg && t('verification-code-sent'));
			setId_Usuario(response.id_usuario);
			setDisplayMsg(false);
			setLoader(false);
			dispatch(set_check_email_modal_action(true));
		} else {
			setTimeout(() => {
				setTimeout(() => {
					setDisplayMsg(true);
					setLoader(false);
					setVerifyMsg(response.msg);
				}, 1500);
			}, 1500);
		}
	};

	const sendVerifyCode = async () => {
		const response = await dispatch(
			verify_password_code_action({
				correo: email,
				codigo: verifyCode
			})
		);
		if (response) {
			setRegistrySuccess(true);
			setVerifyMsg(response.msg && t('validation-code'));
			setTimeout(() => {
				setDisplayPasswordChange(true);
			}, 2500);
		} else {
			setVerifyMsg(t('invalid-code'));
		}
	};
	// verification code
	useEffect(() => {
		if (submitCode) {
			setTimeout(() => {
				sendVerifyCode();
			}, 1500);
		} else {
			setVerifyMsg(t('validating'));
		}
		setSubmitCode(false);
	}, [submitCode]);

	// set del email de usuario
	useEffect(() => {
		if (correo !== '') {
			setEmail(correo)
		} else {
			setEmail('')
		}
	}, [correo]);

	const handleClose = () => {
		window.location.reload()
		setSubmitCode(false);
		setVerifyMsg('');
		setRegistrySuccess(false);
		setUpdatePasswordSuccess(false);
		setDisplayPasswordChange(false);
		setDisplayMsg(false);
		dispatch(set_check_email_modal_action(false));
		dispatch(set_forgot_password_modal_action(false));
	};

	const { register, errors, handleSubmit, watch } = useForm({
		mode: 'onChange'
	});

	const repeatPassword = useRef({});
	repeatPassword.current = watch('password', '');

	const {
		showPassword1,
		handleClickShowPassword1,
		handleMouseDownPassword1,
		showPassword2,
		handleClickShowPassword2,
		handleMouseDownPassword2
	} = useInputAdornment();

	return (
		<>
			<Dialog
				open={forgotPasswordModal}
				onClose={handleClose}
				fullScreen={fullScreenResponsive}
				className={fullScreenResponsive ? classes.dialog : null}
				maxWidth="sm"
				fullWidth
			>
				{displayPasswordChange ? (
					updatePasswordSuccess ? (
						<>
							<DialogTitle
								id="customized-dialog-title"
								align="center"
								onClose={handleClose}
							>
								Contraseña Actualizada
							</DialogTitle>
							<DialogContent dividers className={classes.dialogContainer}>
								<Grid container justify="center" spacing={2}>
									<Grid item xs={12}>
										<Typography align="center">
											{t('password-updated')}
										</Typography>
									</Grid>

									<Grid item xs={4}>
										<Button
											onClick={handleClose}
											className={classes.button}
											variant="contained"
											color="primary"
											fullWidth
										>
											{t('accept')}
										</Button>
									</Grid>
								</Grid>
							</DialogContent>
						</>
					) : (
						<>
							<DialogTitle
								id="customized-dialog-title"
								align="center"
								onClose={handleClose}
							>
								Ingresa nueva contraseña
							</DialogTitle>

							<DialogContent dividers className={classes.dialogContainer}>
								<form onSubmit={handleSubmit(onSubmit)}>
									<Grid
										container
										alignItems="center"
										justify="center"
										spacing={2}
									>
										{/* {displayMsg && (
											<Grid item>
												<Typography>{verifyMsg}</Typography>
											</Grid>
										)} */}

										{loader && (
											<Grid item xs={10}>
												<CircularProgress />
											</Grid>
										)}

										<Grid item xs={12} sm={8}>
											<TextField
												onChange={handleChange}
												name="password"
												variant="outlined"
												label="Nueva contraseñaa"
												type={showPassword1 ? 'text' : 'password'}
												fullWidth
												InputProps={{
													autoComplete: 'new-password',
													endAdornment: (
														<InputAdornmentFirst
															handleClickShowPassword1={
																handleClickShowPassword1
															}
															handleMouseDownPassword1={
																handleMouseDownPassword1
															}
															showPassword1={showPassword1}
														/>
													)
												}}
												inputRef={register({
													required: {
														value: true,
														message: t('complete-validation')
													},
													pattern: {
														value: /^.{5,15}$/,
														message: t('password-validation')
													}
												})}
												helperText={errors?.password?.message}
												error={errors?.password?.message ? true : false}
											/>
										</Grid>

										<Grid item xs={12} sm={8}>
											<TextField
												onChange={handleChange}
												name="password_2"
												variant="outlined"
												label="Repite Contraseña"
												type={showPassword2 ? 'text' : 'password'}
												fullWidth
												InputProps={{
													autoComplete: 'new-password',
													endAdornment: (
														<InputAdornmentSecond
															handleClickShowPassword2={
																handleClickShowPassword2
															}
															handleMouseDownPassword2={
																handleMouseDownPassword2
															}
															showPassword2={showPassword2}
														/>
													)
												}}
												inputRef={register({
													required: {
														value: true,
														message: t('complete-validation')
													},
													validate: (value) =>
														value === repeatPassword.current ||
														t('same-password')
												})}
												helperText={errors?.password_2?.message}
												error={errors?.password_2?.message ? true : false}
											/>
										</Grid>

										<Grid container item justify="center">
											<Grid item xs={4}>
												<Button
													type="submit"
													className={classes.button}
													variant="contained"
													color="primary"
													fullWidth
												>
													{t('send')}
												</Button>
											</Grid>
										</Grid>
									</Grid>
								</form>
							</DialogContent>
						</>
					)
				) : checkEmailModal ? (
					<>
						<DialogTitle align="center" onClose={handleClose}>
							Codigo Verificación
						</DialogTitle>
						<DialogContent dividers className={classes.dialogContainer}>
							<Grid container>
								<CheckEmailModal
									tipo={2}
									submitCode={submitCode}
									setSubmitCode={setSubmitCode}
									setVerifyCode={setVerifyCode}
									verifyMsg={verifyMsg}
									registrySuccess={registrySuccess}
									generateCode={generateCode}
									loader={loader}
								/>
							</Grid>
						</DialogContent>
					</>
				) : (
					<>
						<DialogTitle align="center" onClose={handleClose}>
							{t('enter-your-email')}
						</DialogTitle>
						<DialogContent dividers className={classes.dialogContainer}>
							<form onSubmit={handleSubmit(onSubmit2)} noValidate>
								<Grid container justify="center" spacing={2}>
									{displayMsg && (
										<Grid container item justify="center">
											<FormHelperText error={true}>{verifyMsg}</FormHelperText>
										</Grid>
									)}
									<Grid container item xs={12} sm={8} justify="center">
										{loader ? (
											<CircularProgress />
										) : (
											<TextField
												label={t('email')}
												name="email"
												type="email"
												variant="outlined"
												onChange={(e) => setEmail(e.target.value)}
												defaultValue={email}
												fullWidth
												inputRef={register({
													required: {
														value: true,
														message: t('complete-validation')
													},
													pattern: {
														value:
															/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
														message: t('email-validation')
													}
												})}
												helperText={errors?.email?.message}
												error={errors?.email?.message ? true : false}
											/>
										)}
									</Grid>
									<Grid container item justify="center">
										<Grid item xs={4}>
											<Button
												fullWidth
												// disabled={loader}
												type="submit"
												className={classes.button}
												variant="contained"
												color="primary"
											>
												{t('next')}
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</form>
						</DialogContent>
					</>
				)}
			</Dialog>
		</>
	);
}

export default ForgotPassword;
