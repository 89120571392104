/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Typography, Button, Box, Toolbar, Tooltip, IconButton } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkEmailModalStyles } from '../assets/css/js/checkEmailModalStyles';
//icons
import { useRef, useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useTimer } from '../hooks/useTimer';
import CloseIcon from '@material-ui/icons/Close';
import { set_check_email_modal_action } from '../redux/actions/loginAction';

export default function CheckEmailModal({
	verifyMsg,
	setVerifyCode,
	setSubmitCode,
	registrySuccess,
	setRegisterToLogin,
	generateCode,
	tipo
}) {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const inputRef_1 = useRef();
	const inputRef_2 = useRef();
	const inputRef_3 = useRef();
	const inputRef_4 = useRef();
	const inputRef_5 = useRef();
	const inputRef_6 = useRef();
	const currentInput_1 = inputRef_1.current;
	const currentInput_2 = inputRef_2.current;
	const currentInput_3 = inputRef_3.current;
	const currentInput_4 = inputRef_4.current;
	const currentInput_5 = inputRef_5.current;
	const currentInput_6 = inputRef_6.current;
	const [readInput_2, setReadInput_2] = useState(true);
	const [readInput_3, setReadInput_3] = useState(true);
	const [readInput_4, setReadInput_4] = useState(true);
	const [readInput_5, setReadInput_5] = useState(true);
	const [readInput_6, setReadInput_6] = useState(true);
	const [fullCode, setFullCode] = useState('');
	const [code, setCode] = useState({
		verify_code_1: '',
		verify_code_2: '',
		verify_code_3: '',
		verify_code_4: '',
		verify_code_5: '',
		verify_code_6: ''
	});
	const [sendButton, setSendButton] = useState(true);
	const [checkInput, setCheckInput] = useState(true);
	const [checkFullInput, setCheckFullInput] = useState(false);
	const {
		verify_code_1,
		verify_code_2,
		verify_code_3,
		verify_code_4,
		verify_code_5,
		verify_code_6
	} = code;
	const classes = checkEmailModalStyles();
	const { registerDialog } = useSelector((state) => state.register);
	// const handleCloseModal = () => {
	//     dispatch(set_check_email_modal_action(false));
	// };
	const handleChange = ({ target }) => {
		// setCode({
		// 	...code,
		// 	[target.name]: target.value.trim()
		// });
		if (target.validity.valid) {
			setCode({ ...code, [target.name]: target.value.trim() });
		}
	};
	useEffect(() => {
		if (verify_code_1.length === 6) {
			setFullCode(verify_code_1);
		}
	}, [verify_code_1]);
	useEffect(() => {
		if (fullCode.length === 6) {
			setCode({
				verify_code_1: fullCode.charAt(0),
				verify_code_2: fullCode.charAt(1),
				verify_code_3: fullCode.charAt(2),
				verify_code_4: fullCode.charAt(3),
				verify_code_5: fullCode.charAt(4),
				verify_code_6: fullCode.charAt(5)
			});
		}
	}, [fullCode]);
	useEffect(() => {
		if (checkInput) {
			if (verify_code_1.length === 1) {
				currentInput_2.focus();
				setReadInput_2(false);
				if (verify_code_2.length === 1) {
					setReadInput_3(false);
					currentInput_3.focus();
					if (verify_code_3.length === 1) {
						setReadInput_4(false);
						currentInput_4.focus();
						if (verify_code_4.length === 1) {
							setReadInput_5(false);
							currentInput_5.focus();
							if (verify_code_5.length === 1) {
								setReadInput_6(false);
								currentInput_6.focus();
								setCheckInput(false);
								setCheckFullInput(true);
							}
						}
					}
				}
			}
		}
	}, [code]);

	useEffect(() => {
		if (checkFullInput) {
			if (verify_code_6.length === 0) {
				setReadInput_6(true);
				currentInput_5.focus();
				if (verify_code_5.length === 0) {
					setReadInput_5(true);
					currentInput_4.focus();
					if (verify_code_4.length === 0) {
						setReadInput_4(true);
						currentInput_3.focus();
						if (verify_code_3.length === 0) {
							setReadInput_3(true);
							currentInput_2.focus();
							if (verify_code_2.length === 0) {
								setReadInput_2(true);
								currentInput_1.focus();
								setCheckFullInput(false);
								setCheckInput(true);
							}
						}
					}
				}
			}
		}
	}, [code]);

	useEffect(() => {
		if (
			verify_code_1.trim().length &&
			verify_code_2.trim().length &&
			verify_code_3.trim().length &&
			verify_code_4.trim().length &&
			verify_code_5.trim().length &&
			verify_code_6.trim().length === 0
		) {
			setCheckInput(true);
		}
	}, [code]);

	useEffect(() => {
		if (
			verify_code_1.trim().length &&
			verify_code_2.trim().length &&
			verify_code_3.trim().length &&
			verify_code_4.trim().length &&
			verify_code_5.trim().length &&
			verify_code_6.trim().length === 1
		) {
			setVerifyCode(
				`${verify_code_1}${verify_code_2}${verify_code_3}${verify_code_4}${verify_code_5}${verify_code_6}`
			);
			setSendButton(false);
		} else {
			setSendButton(true);
		}
	}, [code]);

	const handleNextStep = () => {
		if (registerDialog) {
			setRegisterToLogin(true);
		}
	};

	const [resendCode, setResendCode] = useState(true);

	const [resendTimer, setResendTimer] = useState(false);

	const { minutes, seconds, timeClean } = useTimer(resendTimer);

	useEffect(() => {
		if (timeClean === true && resendTimer === true) {
			setResendCode(false);
			setResendTimer(false);
		}
	}, [timeClean, resendTimer]);

	return (
		<>
			{CheckEmailModal && (
				<Grid container justify="center" alignItems="center" spacing={2}>
					<Grid container justify="flex-end">
						{tipo !== 2 && (
							<Tooltip title={t('close')} >
								<Toolbar>
									<IconButton
										edge="end"
										color="primary"
										aria-label="close"
										onClick={() => dispatch(set_check_email_modal_action(false))}
									>
										<CloseIcon />
									</IconButton>
								</Toolbar>
							</Tooltip>
						)}
					</Grid>
					<Grid item xs={12}>
						<Typography align="center">{verifyMsg}</Typography>
					</Grid>

					{verifyMsg === t('validating') && (
						<Grid container item justify="center">
							<CircularProgress />
						</Grid>
					)}

					{registrySuccess ? null : (
						<>
							<Grid container spacing={1} justify="center" item>
								<Grid item>
									<input
										onChange={handleChange}
										ref={inputRef_1}
										name="verify_code_1"
										value={verify_code_1}
										size="small"
										variant="outlined"
										className={classes.codeInputs}
										type="text"
										pattern="[0-9]*"
									// maxLength="1"
									/>
								</Grid>
								<Grid item>
									<input
										readOnly={readInput_2}
										onChange={handleChange}
										ref={inputRef_2}
										name="verify_code_2"
										value={verify_code_2}
										size="small"
										variant="outlined"
										className={classes.codeInputs}
										type="text"
										pattern="[0-9]*"
										maxLength="1"
									/>
								</Grid>
								<Grid item>
									<input
										readOnly={readInput_3}
										onChange={handleChange}
										ref={inputRef_3}
										name="verify_code_3"
										value={verify_code_3}
										size="small"
										variant="outlined"
										className={classes.codeInputs}
										type="text"
										pattern="[0-9]*"
										maxLength="1"
									/>
								</Grid>
								<Grid item>
									<input
										readOnly={readInput_4}
										onChange={handleChange}
										ref={inputRef_4}
										name="verify_code_4"
										value={verify_code_4}
										size="small"
										variant="outlined"
										className={classes.codeInputs}
										type="text"
										pattern="[0-9]*"
										maxLength="1"
									/>
								</Grid>
								<Grid item>
									<input
										readOnly={readInput_5}
										onChange={handleChange}
										ref={inputRef_5}
										name="verify_code_5"
										value={verify_code_5}
										size="small"
										variant="outlined"
										className={classes.codeInputs}
										type="text"
										pattern="[0-9]*"
										maxLength="1"
									/>
								</Grid>
								<Grid item>
									<input
										readOnly={readInput_6}
										onChange={handleChange}
										ref={inputRef_6}
										name="verify_code_6"
										value={verify_code_6}
										size="small"
										variant="outlined"
										className={classes.codeInputs}
										type="text"
										pattern="[0-9]*"
										maxLength="1"
									/>
								</Grid>
							</Grid>
						</>
					)}
					<Grid container spacing={2} justify="center" item>
						<Grid item xs={5}>
							{registrySuccess ? (
								<Button
									disabled={sendButton}
									className={classes.resendButton}
									fullWidth
									variant="contained"
									color="primary"
									onClick={handleNextStep}
								>
									{t('accept')}
								</Button>
							) : (
								<Button
									disabled={sendButton}
									className={classes.resendButton}
									fullWidth
									variant="contained"
									color="primary"
									onClick={() => {
										setSubmitCode(true);
										!resendTimer && setResendCode(false);
									}}
								>
									{t('send')}
								</Button>
							)}
						</Grid>
					</Grid>
					{registrySuccess ? null : (
						<Grid container item spacing={2} justify="center">
							<Grid item xs={10}>
								<Typography align="center">{t('received-code')}</Typography>
							</Grid>

							<Grid item sm={4}>
								<Button
									fullWidth
									color="primary"
									variant="outlined"
									onClick={() => {
										generateCode();
										setResendCode(true);
										setResendTimer(true);
									}}
									disabled={resendCode}
								>
									{t('resend-code')}
								</Button>

								{(minutes === 0 && seconds === 0) || !resendTimer ? null : (
									<Typography component="div" align="center">
										<Box fontWeight="fontWeightBold" m={1}>
											{minutes} : {seconds < 10 ? `0${seconds}` : seconds}
										</Box>
									</Typography>
								)}
							</Grid>
						</Grid>
					)}
				</Grid>
			)}
		</>
	);
}
