/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Header from '../components/SaciComponents/Header';
import CardsPets from '../components/SaciComponents/Adoption/CardsPets';
import { Grid, Hidden } from '@material-ui/core';
import Pagination from '../components/SaciComponents/Pagination';
import AdoptMeForm from '../components/SaciComponents/Adoption/AdoptMeForm';
import UnLoggedModal from '../components/SaciComponents/UnLoggedModal';
import UserPetFilter from '../components/SaciComponents/UserComponents/UserPetFilter';
import { useDispatch, useSelector } from 'react-redux';
import {
	select_pet_action,
	show_user_pets_action
} from '../redux/actions/saciPets';
import { useHistory } from 'react-router-dom';
import {
	get_pets_by_user_action,
	set_on_send_request_page_action,
	set_publish_pet_state_action
} from '../redux/actions/userPetsAction';
import { userPetStyles } from '../assets/css/js/userPetsStyles';
import {
	save_user_contact_data_action,
	set_checkbox_contactData_action
} from '../redux/actions/adoptFormAction';
import { reset_adopt_me_form_action } from '../redux/actions/adoptMeFormAction';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { get_session_action } from '../redux/actions/loginAction';

export default function UserPets() {
	const { userPetsRegistered } = useSelector((state) => state.userPets);
	const classes = userPetStyles();
	const [storedToken, setStoredToken] = useLocalStorage('token', '');
	const history = useHistory();
	const dispatch = useDispatch();
	const { publishPet, userPetData } = useSelector((state) => state.userPets);
	const { token } = useSelector((state) => state.login);
	const { id, nombres } = useSelector((state) => state.login.user);
	const { correo, telefono1, telefono2 } = useSelector(
		(state) => state.userPets.userPetData
	);
	const { petSelected } = useSelector((state) => state.saciPets);

	useEffect(() => {
		if (!nombres) {
			history.push('/');
		}
	}, []);

	useEffect(() => {
		if (token.length !== 0) {
			setStoredToken(token);
		}
	}, [token]);

	useEffect(() => {
		if (storedToken.length !== 0) {
			dispatch(
				get_session_action({
					tokenlog: storedToken
				})
			);
			dispatch(set_on_send_request_page_action(false));
			dispatch(select_pet_action(null));
			dispatch(reset_adopt_me_form_action());
			dispatch(get_pets_by_user_action({ id_usuario: id }));
		} else {
			history.push('/');
		}
	}, []);

	useEffect(() => {
		if (petSelected !== null) {
			dispatch(
				save_user_contact_data_action({
					telefono1: telefono1?.length ? telefono1 : '',
					telefono2: telefono2?.length ? telefono2 : '',
					correo: correo?.length ? correo : ''
				})
			);
			dispatch(
				set_checkbox_contactData_action({
					telefono1: telefono1 ? true : false,
					telefono2: telefono2 ? true : false,
					correo: correo ? true : false
				})
			);
		}
	}, [userPetData]);

	useEffect(() => {
		dispatch(show_user_pets_action(userPetsRegistered));
	}, []);

	useEffect(() => {
		dispatch(set_publish_pet_state_action('1'));
	}, []);

	useEffect(() => {
		switch (publishPet) {
			case '0':
				// dispatch(set_card_pets_title_action('Mascotas Desactivadas'));
				dispatch(set_publish_pet_state_action(null));
				handleUnpublishPets();
				break;
			case '1':
				// dispatch(set_card_pets_title_action('Tus Publicaciones'));
				dispatch(set_publish_pet_state_action(null));
				handlePublishPets();
				break;
			default:
		}
	}, [publishPet, userPetsRegistered]);

	const handleUnpublishPets = () => {
		let userPet;
		userPet = userPetsRegistered.filter((pet) => pet.publicado === publishPet);
		dispatch(show_user_pets_action(userPet));
		dispatch(set_publish_pet_state_action('0'));
	};

	const handlePublishPets = () => {
		let userPet;
		userPet = userPetsRegistered.filter((pet) => pet.publicado === publishPet);
		dispatch(show_user_pets_action(userPet));
		dispatch(set_publish_pet_state_action('1'));
	};

	return (
		<>
			<Header />
			<Hidden mdUp>
				<Grid container justify="center" alignItems="center">
					<Grid item xs={12}>
						<UserPetFilter />
					</Grid>
				</Grid>
			</Hidden>

			<Grid container>
				<Hidden smDown>
					<Grid item xs={12} sm={12} md={3} lg={3} xl={2}>
						<Grid
							container
							alignItems="center"
							className={classes.userPetFilter}
						>
							<Grid item xs={12}>
								<UserPetFilter />
							</Grid>
						</Grid>
					</Grid>
				</Hidden>

				<Grid item xs={12} sm={12} md={9} lg={9} xl={10}>
					<CardsPets UserPets={true} />
				</Grid>
				<AdoptMeForm />
				<UnLoggedModal />
			</Grid>
			<Grid container justify="center" className={classes.pageContainer}>
				<Pagination />
			</Grid>
		</>
	);
}
