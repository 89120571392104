import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import {
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	useMediaQuery,
	useTheme
} from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';

import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';
import { titleCase } from '../../hooks/useTitleCase';
import { update_country_user_action } from '../../redux/actions/loginAction';
import { useLocalStorage } from '../../hooks/useLocalStorage';

const useStyles = makeStyles((theme) => ({
	dialog: {
		'&& .MuiDialog-paperFullScreen': {
			justifyContent: 'center'
		}
	}
}));

export default function CountryDialog(props) {
	const [t] = useTranslation('global');
	const classes = useStyles();

	// const styles = (theme) => ({
	// 	root: {
	// 		margin: 0,
	// 		padding: theme.spacing(2)
	// 	},
	// 	closeButton: {
	// 		position: 'absolute',
	// 		right: theme.spacing(1),
	// 		top: theme.spacing(1)
	// 	}
	// });

	// const { petSelected } = useSelector((state) => state.saciPets);

	const dispatch = useDispatch();
	const { countries } = useSelector((state) => state.main);
	const { id } = useSelector((state) => state.login.user);

	const [storedToken, setStoredToken] = useLocalStorage('token', '');

	const { open, onClose } = props;

	const handleClose = () => {
		onClose();
	};

	const theme = useTheme();

	const matches = useMediaQuery(theme.breakpoints.only('xs'));

	const [countryValue, setCountryValue] = useState('');
	const [idCountry, setIdCountry] = useState('');

	const optionsCountries = countries.map((option) => {
		const firstLetter = option.descripcion[0].toUpperCase();
		return {
			firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
			...option
		};
	});

	// Validation Form
	const { register, errors, handleSubmit, control } = useForm({
		mode: 'onChange'
	});

	// const [state, setstate] = useState({
	// 	id: id,
	// 	id_pais: countryValue
	// })

	// Submit Form
	const onSubmit = (data, e) => {
		// e.target.reset();
		// e.preventDefault();
		dispatch(update_country_user_action({ id: id, id_pais: idCountry }, storedToken));
		if (id) {
			handleClose();
		}
		// setCountryValue('');
	};

	useEffect(() => {
		if (countryValue?.length !== 0) {
			let idCountry = countries.filter(
				(item) => titleCase(item.descripcion) === countryValue
			);
			if (idCountry.length !== 0) {
				setIdCountry(idCountry[0].id_codigo);
			}
		}
	}, [countryValue, idCountry]);

	//  const onSubmit = (data) => console.log(data);

	return (
		<Dialog
			aria-labelledby="simple-dialog-title"
			open={open}
			maxWidth={'sm'}
			fullScreen={matches}
			fullWidth
			className={classes.dialog}
		>
			<DialogTitle id="customized-dialog-title" align="center" color="primary">
				Ingresa el país para continuar
			</DialogTitle>
			<DialogContent dividers>
				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<Grid container justify="center" alignContent="center" spacing={1}>
						<Grid item xs={12}>
							<Controller
								name="country"
								as={
									<Autocomplete
										fullWidth
										inputValue={countryValue}
										onInputChange={(event, newInputValue) => {
											setCountryValue(newInputValue);
										}}
										noOptionsText={t('country-not-found')}
										options={optionsCountries}
										groupBy={(option) => option.firstLetter}
										getOptionLabel={(option) => titleCase(option.descripcion)}
										getOptionSelected={(option, value) =>
											option.descripcion === value.descripcion
										}
										renderInput={(params) => (
											<TextField
												{...params}
												label={t('country')}
												variant="outlined"
												error={!!errors.country}
												helperText={errors.country && t('complete-validation')}
											/>
										)}
									/>
								}
								control={control}
								rules={{
									required: true
								}}
							/>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								color="primary"
								/* onClick={handleClose} */ type="submit"
							>
								Enviar
							</Button>
						</Grid>
					</Grid>
				</form>
			</DialogContent>
		</Dialog>
	);
}

CountryDialog.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired
};
