export const titleCase = (texto) => {
	const re =
		/(^|[^A-Za-zÁÉÍÓÚÜÑáéíóúüñ])(?:([a-záéíóúüñ])|([A-ZÁÉÍÓÚÜÑ]))|([A-ZÁÉÍÓÚÜÑ]+)/gu;
	return texto.replace(
		re,
		(m, caracterPrevio, minuscInicial, mayuscInicial, mayuscIntermedias) => {
			const locale = ['es', 'gl', 'ca', 'pt', 'en'];
			if (mayuscIntermedias) return mayuscIntermedias.toLocaleLowerCase(locale);
			return (
				caracterPrevio +
				(minuscInicial
					? minuscInicial.toLocaleUpperCase(locale)
					: mayuscInicial)
			);
		}
	);
};
