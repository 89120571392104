import {
	GET_PETS_BY_USER,
	SAVE_USER_PET_ID,
	SET_USER_PETS_MODAL,
	SET_USER_PET_MODAL_DATA,
	SAVE_USER_PET_DATA,
	ACTIVE_PET_STATE,
	SET_PUBLISHED_PET,
	REQUEST_PET,
	SELECTED_REQUEST,
	CLEAN_USER_PETS_DATA,
	MY_REQUEST_PAGE,
	SEND_REQUEST_PAGE,
	PUBLISH_USER_PET_STATE,
	SET_ENTRY_REQUEST_PAGE_STATE,
	ALLOW_OR_DENY_USER_PET_REQUEST,
	DENY_REQUEST_PET,
	ACCEPT_REQUEST_PET,
	NEW_REQUEST_PET,
	CLEAN_REQUEST_DATA_ACTION,
	PUBLISH_REQUEST,
	CANCEL_PET_REQUEST,
	CLEAN_PETS_BY_USER
} from '../types';

const initialState = {
	userPetsModal: false,
	userPetsRegistered: [],
	userPetId: null,
	userPetModalData: false,
	userPetData: {
		id_mascota: '',
		nombre_mascota: '',
		edad_mascota: '',
		escala_edad: '',
		descripcion_mascota: '',
		tipo_tramite: '',
		esterilizado: '',
		id_codigo: '',
		id_municipio: '',
		municipio: '',
		departameto: '',
		id_pais: '',
		pais: '',
		id_color: '',
		color: '',
		id_raza: '',
		raza: '',
		id_tipo_mascota: '',
		id_tamanio: '',
		tamanio: '',
		genero_mascota: '',
		tipo: '',
		id_usuario: '',
		id_departamento: '',
		nombres: '',
		publicado: '',
		id_mascotaa: '',
		fotos: '',
		vacunas: '',
		correo: '',
		telefono1: '',
		telefono2: ''
	},
	activePets: true,
	publishPetResponse: null,
	denyPetRequestArray: [],
	acceptPetRequestArray: [],
	newPetRequestArray: [],
	onRequestPage: false,
	selectRequestData: null,
	onOutputRequestPage: false,
	publishPet: null,
	onEntryRequestPage: null,
	allowOrDenyRequest: 0,
	publishReq: null,
	cancelPetData: [],
	petRequestArray: [],
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_USER_PETS_MODAL:
			return {
				...state,
				userPetsModal: action.payload
			};
		case PUBLISH_REQUEST:
			return {
				...state,
				publishReq: action.payload
			};
		case GET_PETS_BY_USER:
			return {
				...state,
				userPetsRegistered: action.payload
			};
		case CLEAN_PETS_BY_USER:
			return {
				...state,
				userPetsRegistered: []
			};
		case SAVE_USER_PET_ID:
			return {
				...state,
				userPetId: action.payload
			};
		case SET_USER_PET_MODAL_DATA:
			return {
				...state,
				userPetModalData: action.payload
			};
		case SAVE_USER_PET_DATA:
			return {
				...state,
				userPetData: action.payload
			};
		case ACTIVE_PET_STATE:
			return {
				...state,
				activePets: action.payload
			};
		case SET_PUBLISHED_PET:
			return {
				...state,
				publishPetResponse: action.payload
			};
		case REQUEST_PET:
			return {
				...state,
				petRequestArray: action.payload
			};
		case DENY_REQUEST_PET:
			return {
				...state,
				denyPetRequestArray: action.payload
			};
		case ACCEPT_REQUEST_PET:
			return {
				...state,
				acceptPetRequestArray: action.payload
			};
		case NEW_REQUEST_PET:
			return {
				...state,
				newPetRequestArray: action.payload
			};
		case SELECTED_REQUEST:
			return {
				...state,
				selectRequestData: action.payload,
				// userPetData: action.payload,
				onRequestPage: true
			};
		case MY_REQUEST_PAGE:
			return {
				...state,
				onRequestPage: action.payload
			};
		case SEND_REQUEST_PAGE:
			return {
				...state,
				onOutputRequestPage: action.payload
			};
		case PUBLISH_USER_PET_STATE:
			return {
				...state,
				publishPet: action.payload
			};
		case SET_ENTRY_REQUEST_PAGE_STATE:
			return {
				...state,
				onEntryRequestPage: action.payload
			};
		case ALLOW_OR_DENY_USER_PET_REQUEST:
			return {
				...state,
				allowOrDenyRequest: action.payload + 1
			};
		case CLEAN_REQUEST_DATA_ACTION:
			return {
				...state,
				selectRequestData: null
			};
		case CLEAN_USER_PETS_DATA:
			return {
				...state,
				userPetData: {
					id_mascota: '',
					nombre_mascota: '',
					edad_mascota: '',
					escala_edad: '',
					descripcion_mascota: '',
					tipo_tramite: '',
					esterilizado: '',
					id_codigo: '',
					id_municipio: '',
					municipio: '',
					departameto: '',
					id_pais: '',
					pais: '',
					id_color: '',
					color: '',
					id_raza: '',
					raza: '',
					id_tipo_mascota: '',
					id_tamanio: '',
					tamanio: '',
					genero_mascota: '',
					tipo: '',
					id_usuario: '',
					id_departamento: '',
					nombres: '',
					publicado: '',
					id_mascotaa: '',
					fotos: '',
					vacunas: ''
				},
				publishPet: '1'
			};
		case CANCEL_PET_REQUEST:
			return {
				...state,
				cancelPetData: action.payload
			};

		default:
			return state;
	}
};
