/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
	Stepper,
	Step,
	StepLabel,
	Button,
	Grid,
	Typography,
	Hidden,
	Dialog,
	IconButton,
	Toolbar,
	MenuItem,
	Tooltip
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import StepConnector from '@material-ui/core/StepConnector';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Swal from 'sweetalert2';
// import { PetType} from '../AdoptForm/PetType';

//Redux
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

//Redux actions
import { reset_action } from '../../../redux/actions/petTypeAction';
import {
	next_step_action,
	back_step_action,
	update_form_data_action,
	registry_form_adopt,
	upload_pet_image_1,
	upload_pet_image_2,
	upload_pet_image_3,
	upload_pet_image_4,
	upload_pet_image_5,
	reset_form_action,
	set_edit_user_pet_dialog,
	set_step_action,
	update_user_pet_formData_action,
	reset_id_mascota_form,
	upload_pet_image_6
} from '../../../redux/actions/adoptFormAction';

//components
import PetType from './PetType';
import PetDescription from './PetDescription';
import UploadImages from './UploadImages';

//icons
import iconAdopt from '../../../assets/icons/drawer/iconAdopt-final.svg';
import CloseIcon from '@material-ui/icons/Close';
import iconSend from '../../../assets/icons/send.svg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ReplayIcon from '@material-ui/icons/Replay';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import petTypeIcon from '../../../assets/icons/dialogs/pet-type.svg';
import petDateIcon from '../../../assets/icons/dialogs/pet-date.svg';
import petUserDateIcon from '../../../assets/icons/dialogs/pet-user-date.svg';
import petPhotoIcon from '../../../assets/icons/dialogs/pet-photo.svg';

//lotties
import {
	clean_pets_action,
	get_saci_pets_action,
	select_pet_action
} from '../../../redux/actions/saciPets';
//images
import gato from '../../../assets/lotties/Gato.gif';
import perro from '../../../assets/lotties/Perro.gif';
import hamster from '../../../assets/lotties/Hamster.gif';

// open dialog login
import {
	login_dialog_open_action,
	adoptstepper_dialog_open_action,
	adoptstepper_dialog_close_action,
	adopt_dialog_open_action,
	set_procedure_action,
	adopt_dialog_close_action
} from '../../../redux/actions/loginAction';
import { register_dialog_open_action } from '../../../redux/actions/registerAction';
import adoptStepperStyles from '../../../assets/css/js/adoptStepperStyles';
import UserDescription from './UserDescription';
import { useHistory } from 'react-router';
import {
	clean_user_pets_data_action,
	get_pets_by_user_action,
	publish_request
} from '../../../redux/actions/userPetsAction';
import { useTranslation } from 'react-i18next';

const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 22
	},
	active: {
		'& $line': {
			backgroundImage:
				'linear-gradient( 20deg,rgb(99, 193, 50) 100%,rgb(99, 193, 50) 50%,rgb(197, 232, 183) 100%)'
		}
	},
	completed: {
		'& $line': {
			backgroundImage:
				'linear-gradient( 20deg,rgb(99, 193, 50) 100%,rgb(99, 193, 50) 50%,rgb(197, 232, 183) 100%)'
		}
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: '#eaeaf0',
		borderRadius: 1
	}
})(StepConnector);

const useColorlibStepIconStyles = makeStyles((theme) => ({
	test: {
		position: 'relative'
	},
	root: {
		backgroundColor: '#ccc',
		zIndex: 1,
		color: '#fff',
		// [theme.breakpoints.only('xl')]: {
		// 	width: 70,
		// 	height: 70,
		// },
		// [theme.breakpoints.only('lg')]: {
		// 	width: 60,
		// 	height: 60,
		// },
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center'
	},
	active: {
		backgroundImage:
			'linear-gradient( 136deg, rgb(197, 232, 183) 0%, rgb(99, 193, 50) 50%, rgb(99, 193, 50) 100%)',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
	},
	completed: {
		backgroundImage:
			'linear-gradient( 136deg, rgb(197, 232, 183) 0%, rgb(99, 193, 50) 50%, rgb(99, 193, 50) 100%)'
	}
}));

function ColorlibStepIcon(props) {
	const classes = useColorlibStepIconStyles();
	const { showUserPets, active, completed } = props;
	const icons = showUserPets
		? {
			1: <img src={petDateIcon} alt="Pets Dates" />,
			2: <img src={petDateIcon} alt="Pets Dates" />,
			3: <img src={petPhotoIcon} alt="Pets Photos" />
		}
		: {
			1: <img src={petTypeIcon} alt="Pets Types" />,
			2: <img src={petDateIcon} alt="Pets Dates" />,
			3: <img src={petUserDateIcon} alt="Pets Users Dates" />,
			4: <img src={petPhotoIcon} alt="Pets Photos" />
		};

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed
			})}
		>
			{icons[String(props.icon)]}
		</div>
	);
}

export function getSteps(showUserPets, t) {
	return showUserPets
		? [
			// 'Selecciona el tipo de mascota',
			t('pet-data'),
			t('pet-data'),
			t('pet-photos')
		]
		: [t('pet-selection'), t('pet-data'), t('user-data'), t('pet-photos')];
}

function GetStepContent({
	activeStep,
	showUserPets,
	setDescriptionState,
	selectedFiles,
	setSelectedFiles,
	imageData,
	setImageData,
	isUploaded,
	setIsUploaded,
	initialData,
	indexHistory,
	setIndexHistory
}) {

	switch (activeStep) {
		case 0:
			return showUserPets ? <PetDescription /> : <PetType />;
		case 1:
			return showUserPets ? <UserDescription /> : <PetDescription />;
		case 2:
			return showUserPets ? (
				<UploadImages
					selectedFiles={selectedFiles}
					setSelectedFiles={setSelectedFiles}
					imageData={imageData}
					setImageData={setImageData}
					isUploaded={isUploaded}
					setIsUploaded={setIsUploaded}
					initialData={initialData}
					indexHistory={indexHistory}
					setIndexHistory={setIndexHistory}
				/>
			) : (
				<UserDescription />
			);
		case 3:
			return showUserPets ? null : (
				<UploadImages
					selectedFiles={selectedFiles}
					setSelectedFiles={setSelectedFiles}
					imageData={imageData}
					setImageData={setImageData}
					isUploaded={isUploaded}
					setIsUploaded={setIsUploaded}
					initialData={initialData}
					indexHistory={indexHistory}
					setIndexHistory={setIndexHistory}
				/>
			);
		default:
			return 'Unknown step';
	}
}

export default function AdoptStepper() {
	const [t] = useTranslation('global');
	const history = useHistory();
	const { hideMenuButtons } = useSelector((state) => state.main);
	const { adoptDialog, adoptstepperDialog } = useSelector(
		(state) => state.login
	);
	const { nombres, id, id_pais } = useSelector((state) => state.login.user);
	const { petType } = useSelector((store) => store.petType);
	const { activeStepState, photoStep } = useSelector(
		(state) => state.adoptFormData
	);
	const { petDescription } = useSelector((state) => state.adoptFormData);
	const { editPetDialog } = useSelector((state) => state.adoptFormData);
	const { showUserPets } = useSelector((state) => state.saciPets);
	const { descriptionData } = useSelector((state) => state.adoptFormData);
	const { userPetId } = useSelector((state) => state.userPets);
	const { showOutReqPets } = useSelector((state) => state.saciPets);
	const { petSelected } = useSelector((state) => state.saciPets);
	const { onEntryRequestPage } = useSelector((state) => state.userPets);
	const {
		id_vacuna_Rabia,
		id_vacuna_Rinotraqueítis,
		id_vacuna_Parvovirus,
		id_vacuna_Moquillo
	} = useSelector((state) => state.adoptFormData.descriptionData);
	const { savePetImage1 } = useSelector((state) => state.adoptFormData);
	const id_mascota = useSelector(
		(state) => state.adoptFormData.registeredFormData.data.mascota.id_mascota
	);
	const dispatch = useDispatch();
	const classes = adoptStepperStyles();
	const { tipo_tramite } = useSelector((state) => state.userPets.userPetData);
	const [saveFormDescription, setSaveFormDescription] = useState(true);
	const [allowContent, setAllowContent] = useState(false);
	const [setDescriptionState] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const steps = getSteps(showUserPets, t);

	const [selectedFiles, setSelectedFiles] = useState([]);
	const [imageData, setImageData] = useState([]);
	const [isUploaded, setIsUploaded] = useState(false);
	const { fotos } = useSelector((state) => state.userPets.userPetData);
	const baseUrl = process.env.REACT_APP_BACKEND_URL;
	const [indexHistory, setIndexHistory] = useState([]);
	const [disableStepBack, setDisableStepBack] = useState(false);

	useEffect(() => {
		if (showUserPets) {
			if (fotos) {
				let data = fotos;
				let data_1 = data.split(',')[0];
				let data_2 = data.split(',')[1];
				let data_3 = data.split(',')[2];
				let data_4 = data.split(',')[3];
				let data_5 = data.split(',')[4];
				let arrayData = [];
				if (data_1) {
					arrayData.push(data_1);
					if (data_2) {
						arrayData.push(data_2);
						if (data_3) {
							arrayData.push(data_3);
							if (data_4) {
								arrayData.push(data_4);
								if (data_5) {
									arrayData.push(data_5);
								}
							}
						}
					}
				}
				let photos = [];
				const filter = selectedFiles.map(item => {
					if (item.includes('blob')) {
						return item
					}
				})
				console.log('filter', filter);
				console.log('selectedFiles', selectedFiles);
				if (filter.length === 0) {
					switch (arrayData.length) {
						case 1:
							setSelectedFiles([`${baseUrl}${arrayData[0]}.jpg`]);
							console.log('entre1');
							break;
						case 2:
							for (let i = 0; i < arrayData.length; i++) {
								photos.push(`${baseUrl}${arrayData[i]}.jpg`);
							}
							setSelectedFiles(photos);
							console.log('entre2');
							break;
						case 3:
							for (let i = 0; i < arrayData.length; i++) {
								photos.push(`${baseUrl}${arrayData[i]}.jpg`);
							}
							setSelectedFiles(photos);
							console.log('entre3');
							break;
						case 4:
							for (let i = 0; i < arrayData.length; i++) {
								photos.push(`${baseUrl}${arrayData[i]}.jpg`);
							}
							setSelectedFiles(photos);
							console.log('entre4');
							break;
						case 5:
							for (let i = 0; i < arrayData.length; i++) {
								photos.push(`${baseUrl}${arrayData[i]}.jpg`);
							}
							setSelectedFiles(photos);
							console.log('entre5');
							break;
						default:
					}
				}
			}
			setIsUploaded(true);
		}
	}, [showUserPets, petSelected, fotos]);

	useEffect(() => {
		if (showUserPets) {
			dispatch(set_step_action(2));
			setAllowContent(true);
			switch (tipo_tramite) {
				case '1':
					dispatch(set_procedure_action(1));
					break;
				case '2':
					dispatch(set_procedure_action(2));
					break;
				case '3':
					dispatch(set_procedure_action(3));
					break;
				default:
			}
		} else {
			dispatch(set_step_action(1));
		}
		if (showUserPets === false) {
			dispatch(set_edit_user_pet_dialog(false));
			dispatch(adoptstepper_dialog_close_action());
			dispatch(reset_action());
			dispatch(reset_form_action());
			setAllowContent(false);
		}
	}, [showUserPets, editPetDialog]);

	useEffect(() => {
		if (saveFormDescription === true) {
			if (activeStepState === 2) {
				dispatch(update_form_data_action());
				setSaveFormDescription(false);
			}
		}
	}, [activeStepState]);

	useEffect(() => {
		if (activeStepState) {
			switch (activeStepState) {
				case 1:
					if (showUserPets === false) {
						if (petType !== 0) {
							setAllowContent(true);
						} else {
							setAllowContent(false);
						}
					}
					break;

				case 2:
					if (showUserPets === false || showUserPets) {
						if (petDescription === true) {
							setAllowContent(true);
						} else {
							setAllowContent(false);
							setDisableStepBack(true);
						}
					}
					break;

				case 3:
					if (petDescription === true) {
						setAllowContent(true);
						setDisableStepBack(false);
					} else {
						setAllowContent(false);
					}
					break;

				case 4:
					if (showUserPets) {
						if (photoStep === true) {
							setAllowContent(true);
						} else {
							setAllowContent(false);
						}
					} else {
						if (photoStep === true) {
							setAllowContent(true);
						} else {
							setAllowContent(false);
						}
					}
					break;

				default:
			}
		}
	}, [activeStepState, petDescription, petType, savePetImage1, photoStep]);

	ColorlibStepIcon.propTypes = {
		active: PropTypes.bool,
		completed: PropTypes.bool,
		icon: PropTypes.node
	};

	const tramite1 = {
		tipo_tramite: 1
	};

	const handleClickOpenModal = () => {
		dispatch(adopt_dialog_open_action(tramite1));
		dispatch(adoptstepper_dialog_open_action());
	};

	const handleClickCloseModal = () => {
		return Swal.fire({
			title: t('exit-registry'),
			text: t('not-reverse-changes'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#D33',
			cancelButtonColor: '#63C132',
			confirmButtonText: t('yes-exit'),
			cancelButtonText: t('no-cancel'),
			customClass: 'swal-wide'
		}).then((result) => {
			if (result.isConfirmed) {
				if (showUserPets) {
					dispatch(set_edit_user_pet_dialog(false));
					setActiveStep(0);
					dispatch(set_step_action(1));
					dispatch(select_pet_action(null));
					setAllowContent(true);
					setImageData([]);
					setSelectedFiles([]);
					dispatch(clean_user_pets_data_action());
				} else {
					dispatch(set_edit_user_pet_dialog(false));
					dispatch(adoptstepper_dialog_close_action());
					dispatch(reset_action());
					dispatch(reset_form_action());
					dispatch(select_pet_action(null));
					setActiveStep(0);
					setImageData([]);
					setSelectedFiles([]);
				}
				dispatch(adopt_dialog_close_action(false));
				Swal.close();
			}
		});
	};

	const handleNext = () => {
		dispatch(next_step_action());
		if (activeStepState === 2) {
			if (petDescription === true) {
				setTimeout(() => {
					setActiveStep((prevActiveStep) => prevActiveStep + 1);
				}, 100);
			}
		} else {
			setTimeout(() => {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			}, 20);
		}
	};

	const handleBack = () => {
		dispatch(back_step_action());
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const [sendPhotos, setSendPhotos] = useState(null);

	const theme = useTheme();

	const fullScreenResponsive = useMediaQuery(theme.breakpoints.down('sm'));
	const fullScreenDialog = useMediaQuery(theme.breakpoints.down('xs'));

	const handleRegisterForm = () => {
		if (showUserPets) {
			return Swal.fire({
				title: t('update-previous-data'),
				icon: 'question',
				showDenyButton: true,
				confirmButtonColor: '#63C132',
				denyButtonColor: '#D33',
				confirmButtonText: t('update'),
				denyButtonText: t('cancel')
			}).then((result) => {
				if (result.isConfirmed) {
					dispatch(
						update_user_pet_formData_action({
							...descriptionData,
							nombre_mascota: descriptionData.nombre_mascota.length
								? descriptionData.nombre_mascota
								: 'No identificado',
							id_mascota: userPetId,
							id_vacuna_Rabia: String(id_vacuna_Rabia),
							id_vacuna_Rinotraqueítis: String(id_vacuna_Rinotraqueítis),
							id_vacuna_Parvovirus: String(id_vacuna_Parvovirus),
							id_vacuna_Moquillo: String(id_vacuna_Moquillo),
							vacunas: ''
						})
					);

					Swal.fire(t('data-updated-successfully'), '', 'success').then(
						(result) => {
							if (result.isConfirmed) {
								setSendPhotos(true);
								dispatch(set_edit_user_pet_dialog(false));
								dispatch(reset_action());
								dispatch(reset_form_action());
								dispatch(publish_request(true));
								dispatch(select_pet_action(null));
								setActiveStep(0);
								setAllowContent(false);
								setImageData([]);
								setSelectedFiles([]);
								dispatch(set_step_action(1));
								dispatch(clean_user_pets_data_action());
								// window.location.reload(true);
								dispatch(get_pets_by_user_action({ id_usuario: id }));
								Swal.close();
							}
						}
					);
				} else if (result.isDenied) {
					Swal.fire(t('changes-not-saved'), '', 'info');
				}
			});
		} else {
			return Swal.fire({
				title: t('record-data'),
				icon: 'warning',
				showDenyButton: true,
				confirmButtonColor: '#63C132',
				denyButtonColor: '#D33',
				confirmButtonText: t('accept'),
				denyButtonText: t('cancel')
			}).then((result) => {
				if (result.isConfirmed) {
					dispatch(
						registry_form_adopt({
							...descriptionData,
							nombre_mascota: descriptionData.nombre_mascota.length
								? descriptionData.nombre_mascota
								: 'No identificado',
							id_vacuna_Rabia: String(id_vacuna_Rabia),
							id_vacuna_Rinotraqueítis: String(id_vacuna_Rinotraqueítis),
							id_vacuna_Parvovirus: String(id_vacuna_Parvovirus),
							id_vacuna_Moquillo: String(id_vacuna_Moquillo),
							vacunas: ''
						})
					);
					setSendPhotos(true);
					Swal.fire(t('registered-pet'), '', 'success').then((result) => {
						if (result.isConfirmed) {
							dispatch(adoptstepper_dialog_close_action());
							dispatch(reset_action());
							dispatch(reset_form_action());
							setActiveStep(0);
							setAllowContent(false);
							dispatch(clean_user_pets_data_action());
							dispatch(get_pets_by_user_action({ id_usuario: id }));
							setSelectedFiles([]);
							setImageData([]);
							if (id_pais) {
								dispatch(get_saci_pets_action({ id_pais: id_pais }));
							} else {
								dispatch(get_saci_pets_action({ id_tipo_mascota: false }));
							}
							Swal.close();
						}
					});
				} else if (result.isDenied) {
					Swal.fire(t('not-recorded-changes'), '', 'info');
				}
			});
		}
	};

	useEffect(() => {
		if (id_mascota !== 0) {
			setSendPhotos(true);
		} else {
			setSendPhotos(false);
		}
	}, [id_mascota]);

	const upload = () => {
		console.log('siiiii');
		if (showUserPets) {
			console.log('11');
			const newArray = selectedFiles.filter(i => {
				console.log('22');
				return (
					i.includes(process.env.REACT_APP_BACKEND_URL)
				)
			})
			{
				imageData.map((item, i) => {
					dispatch(upload_pet_image_6(item, userPetId, i + 1 + newArray.length));
				})
			}

		}
		setSendPhotos(false);
		dispatch(reset_id_mascota_form());
	}
	// useEffect(() => {
	// 	if (sendPhotos === true) {

	// 	}
	// }, [sendPhotos, userPetId, id_mascota]);

	useEffect(() => {
		if (sendPhotos === true) {
			if (showUserPets) {
				if (imageData.length === 5) {
					console.log('1');
					dispatch(upload_pet_image_1(imageData[0], userPetId));
					dispatch(upload_pet_image_2(imageData[1], userPetId));
					dispatch(upload_pet_image_3(imageData[2], userPetId));
					dispatch(upload_pet_image_4(imageData[3], userPetId));
					dispatch(upload_pet_image_5(imageData[4], userPetId));
				} else if (imageData.length < 5) {
					console.log('2');
					switch (indexHistory.length) {
						case 1:
							console.log('3');
							upload();
							break;
						case 2:
							console.log('4');
							upload();
							break;
						case 3:
							console.log('5');
							upload();
							break;
						case 4:
							console.log('6');
							upload();
							break;
						case 5:
							console.log('7');
							upload();
							break;
						default:
							let data = fotos;
							let data_1 = data.split(',')[0];
							let data_2 = data.split(',')[1];
							let data_3 = data.split(',')[2];
							let data_4 = data.split(',')[3];
							let data_5 = data.split(',')[4];
							let arrayData = [];
							if (data_1) {
								arrayData.push(data_1);
								if (data_2) {
									arrayData.push(data_2);
									if (data_3) {
										arrayData.push(data_3);
										if (data_4) {
											arrayData.push(data_4);
											if (data_5) {
												arrayData.push(data_5);
											}
										}
									}
								}
							}
							upload();
					}
				}
			} else {
				console.log('8', imageData, id_mascota);
				if (id_mascota !== 0) {
					switch (imageData.length) {
						case 1:
							dispatch(upload_pet_image_1(imageData[0], id_mascota));
							break;
						case 2:
							dispatch(upload_pet_image_1(imageData[0], id_mascota));
							dispatch(upload_pet_image_2(imageData[1], id_mascota));
							break;
						case 3:
							dispatch(upload_pet_image_1(imageData[0], id_mascota));
							dispatch(upload_pet_image_2(imageData[1], id_mascota));
							dispatch(upload_pet_image_3(imageData[2], id_mascota));
							break;
						case 4:
							dispatch(upload_pet_image_1(imageData[0], id_mascota));
							dispatch(upload_pet_image_2(imageData[1], id_mascota));
							dispatch(upload_pet_image_3(imageData[2], id_mascota));
							dispatch(upload_pet_image_4(imageData[3], id_mascota));
							break;
						case 5:
							dispatch(upload_pet_image_1(imageData[0], id_mascota));
							dispatch(upload_pet_image_2(imageData[1], id_mascota));
							dispatch(upload_pet_image_3(imageData[2], id_mascota));
							dispatch(upload_pet_image_4(imageData[3], id_mascota));
							dispatch(upload_pet_image_5(imageData[4], id_mascota));
							break;
						default:
					}
				}
			}
			setSendPhotos(false);
			dispatch(reset_id_mascota_form());
		}
	}, [sendPhotos, userPetId, id_mascota]);

	// Open dialog Login
	const handleClickOpenLogin = () => {
		dispatch(adoptstepper_dialog_close_action());
		dispatch(login_dialog_open_action());
	};

	// Open dialog Register
	const openRegister = () => {
		dispatch(adoptstepper_dialog_close_action());
		dispatch(register_dialog_open_action());
	};

	// Close dialog login and Register
	const handleClickCloseDialog = () => {
		dispatch(adoptstepper_dialog_close_action());
	};

	const handleClickSaciPets = () => {
		dispatch(clean_pets_action());
		dispatch(clean_user_pets_data_action());
		dispatch(select_pet_action(null));
		history.push('/');
	};

	const showButton =
		showUserPets || showOutReqPets || onEntryRequestPage || hideMenuButtons;

	return (
		<>
			<Hidden smDown>
				<Button
					color="secondary"
					className={classes.adoptionButton}
					onClick={showButton ? handleClickSaciPets : handleClickOpenModal}
					startIcon={
						showButton ? (
							<ArrowBackIosIcon
								className={classes.menuIcons}
								color="secondary"
							/>
						) : (
							<img src={iconAdopt} alt="LogIn" className={classes.menuIcons} />
						)
					}
				>
					{!showButton && <ArrowDropDownIcon />}

					{showButton ? (
						<Hidden only="sm">{t('back-to')}</Hidden>
					) : (
						<Hidden only="md">{t('give-in')}</Hidden>
					)}
				</Button>
			</Hidden>

			<Hidden mdUp>
				<MenuItem
					color="secondary"
					className={classes.adoptionButton}
					onClick={showButton ? handleClickSaciPets : handleClickOpenModal}
				>
					{showButton ? (
						<ReplayIcon style={{ fontSize: 30 }} color="primary" />
					) : (
						<img src={iconAdopt} alt="LogIn" className={classes.menuIcons} />
					)}

					<ArrowDropDownIcon />
					{showButton ? t('back-to') : t('give-in')}
				</MenuItem>
			</Hidden>

			{nombres ? (
				<>
					{(adoptDialog && adoptstepperDialog) || editPetDialog ? (
						<Dialog
							style={{ zIndex: 2 }}
							open={(adoptDialog && adoptstepperDialog) || editPetDialog}
							onClose={handleClickCloseModal}
							fullWidth
							maxWidth="md"
							fullScreen={fullScreenResponsive}
						>
							<Grid container className={classes.root}>
								<Grid container justify="flex-end" alignItems="flex-start">
									<Grid item>
										<IconButton
											className={classes.closeIconButton}
											edge="end"
											color="inherit"
											aria-label="close"
											onClick={handleClickCloseModal}
										>
											<CloseIcon className={classes.closeButton} />
										</IconButton>
									</Grid>
								</Grid>
								<Grid container justify="center">
									<Grid item sm={12} lg={10}>
										<Hidden only={'xs'}>
											<Stepper
												alternativeLabel
												activeStep={activeStep}
												connector={
													<ColorlibConnector
														setSelectedFiles={setSelectedFiles}
														selectedFiles={selectedFiles}
														showUserPets={showUserPets}
													/>
												}
											>
												{steps.map((label) => (
													<Step key={label}>
														<StepLabel StepIconComponent={ColorlibStepIcon}>
															{label}
														</StepLabel>
													</Step>
												))}
											</Stepper>
										</Hidden>
									</Grid>
								</Grid>
								{activeStep === steps.length ? (
									<Grid
										container
										className={classes.allreadyContainer}
										alignItems="center"
									>
										{showUserPets ? (
											<Grid item xs={12}>
												<Typography
													align="center"
													variant="h5"
													className={classes.instructions}
												>
													{t('everything-ready')}
												</Typography>
												<Grid item xs={12} md={12} style={{ display: 'flex' }}>
													{petType === 1 ? (
														<img src={gato} alt="" className={classes.petsGifs} />
													) : petType === 2 ? (
														<img
															src={perro}
															alt=""
															className={classes.petsGifs}
														/>
													) : (
														<img
															src={hamster}
															alt=""
															className={classes.petsGifs}
														/>
													)}
												</Grid>
												<Grid container justify="center">
													<Typography align="center">
														{t('submit-register-pet')}
													</Typography>
												</Grid>
												<Grid container justify="center">
													<Typography align="center">
														{t('press-back-button')}
													</Typography>
												</Grid>
												<Grid
													container
													justify="center"
													className={classes.buttonContainer}
												>
													<Grid item xs={6}>
														<Grid container spacing={1} justify="center">
															<Grid item xs={6}>
																<Button
																	fullWidth
																	onClick={handleBack}
																	className={classes.button}
																>
																	{t('back')}
																</Button>
															</Grid>
															<Grid item xs={6}>
																<Button
																	fullWidth
																	onClick={handleRegisterForm}
																	variant="contained"
																	color="primary"
																	className={classes.button}
																>
																	{t('send')}
																</Button>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										) : (
											<Grid container alignItems="center">
												<Grid item xs={12}>
													<Grid container justify="center">
														<Grid item xs={12} style={{ textAlign: 'center' }}>
															<Typography
																variant="h5"
																className={classes.instructions}
															>
																{t('everything-ready')}
															</Typography>
														</Grid>
														<Grid
															item
															xs={12}
															md={12}
															style={{ display: 'flex' }}
														>
															{petType === 1 ? (
																<img
																	src={gato}
																	alt=""
																	className={classes.petsGifs}
																/>
															) : petType === 2 ? (
																<img
																	src={perro}
																	alt=""
																	className={classes.petsGifs}
																/>
															) : (
																<img
																	src={hamster}
																	alt=""
																	className={classes.petsGifs}
																/>
															)}
														</Grid>
														<Grid
															container
															justify="center"
															className={classes.infoLastStep}
															spacing={3}
														>
															<Grid item style={{ textAlign: 'center' }}>
																<Typography variant="h6">
																	{t('submit-register-pet')}
																</Typography>
															</Grid>
															<Grid item xs={6}>
																<Grid container spacing={1} justify="center">
																	<Grid item xs={6}>
																		<Button
																			fullWidth
																			onClick={handleBack}
																			className={classes.button}
																		>
																			{t('back')}
																		</Button>
																	</Grid>
																	<Grid item xs={6}>
																		<Button
																			fullWidth
																			onClick={handleRegisterForm}
																			variant="contained"
																			color="primary"
																			className={classes.button}
																		>
																			{t('send')}
																		</Button>
																	</Grid>
																</Grid>
															</Grid>
														</Grid>
													</Grid>
												</Grid>
											</Grid>
										)}
									</Grid>
								) : (
									<Grid
										container
										alignItems="center"
										className={classes.content}
									/* style={{ minHeight: '80vh' }} */ justify="center"
									>
										<Grid item xs={12}>
											<Typography className={classes.instructions}>
												<GetStepContent
													activeStep={activeStep}
													showUserPets={showUserPets}
													setDescriptionState={setDescriptionState}
													selectedFiles={selectedFiles}
													setSelectedFiles={setSelectedFiles}
													imageData={imageData}
													setImageData={setImageData}
													isUploaded={isUploaded}
													setIsUploaded={setIsUploaded}
													indexHistory={indexHistory}
													setIndexHistory={setIndexHistory}
												/>
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Grid container justify="center">
												<Button
													disabled={activeStep === 0 || disableStepBack}
													onClick={handleBack}
													className={classes.button}
												>
													{t('back')}
												</Button>
												<Button
													disabled={allowContent === false}
													variant="contained"
													color="primary"
													onClick={handleNext}
													className={classes.button}
												>
													{activeStep === steps.length - 1
														? t('next')
														: t('next')}
												</Button>
											</Grid>
										</Grid>
									</Grid>
								)}
							</Grid>
						</Dialog>
					)
						:
						null
					}
				</>
			) : (
				<>
					<Dialog
						style={{ zIndex: 2 }}
						open={adoptstepperDialog === true}
						onClose={handleClickCloseDialog}
						fullScreen={fullScreenDialog}
					>
						<Grid container justify="flex-end">
							<Tooltip title={t('close')} >
								<Toolbar>
									<IconButton
										edge="end"
										color="primary"
										aria-label="close"
										onClick={handleClickCloseDialog}
									>
										<CloseIcon />
									</IconButton>
								</Toolbar>
							</Tooltip>
						</Grid>
						<div className={classes.rootDialogStart}>
							<Grid container justify="center">
								<Grid item className={classes.titleDialogStart}>
									<Typography variant="h6">
										{t('have-account-login-register')}
									</Typography>
								</Grid>

								<Grid item xs={12}>
									<Button
										className={classes.buttonStart}
										color="primary"
										variant="contained"
										size="large"
										endIcon={
											<img
												src={iconSend}
												alt="LogIn"
												className={classes.icons2}
											/>
										}
										fullWidth
										type="submit"
										onClick={handleClickOpenLogin}
									>
										{t('log-in-account')}
									</Button>
								</Grid>

								<Grid item xs={12}>
									<Button
										variant="text"
										size="small"
										className={classes.buttonSecondary2}
										onClick={openRegister}
									>
										{t('dont-account')}
									</Button>
								</Grid>
							</Grid>
						</div>
					</Dialog>
				</>
			)}
		</>
	);
}
