import { makeStyles } from '@material-ui/core';
import { mainColor } from './mainTheme';

export const checkEmailModalStyles = makeStyles((theme) => ({
	codeInputs: {
		width: '50px',
		height: '50px',
		borderRadius: '10px',
		borderColor: '#adb5bd',
		border: '2px solid',
		textAlign: 'center',
		fontSize: '20px',
		'&:focus': {
			borderColor: mainColor,
			outline: 'none'
		},
		[theme.breakpoints.only('xs')]: {
			width: '40px'
		},
		'@media (max-width: 320px)': {
			width: '35px'
		}
	},

	resendButton: {
		color: '#fff'
	}
}));
