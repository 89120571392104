import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Hidden } from '@material-ui/core/';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import TranslateIcon from '@material-ui/icons/Translate';

import { ReactComponent as IconEnglish } from '../../assets/icons/IconsFlags/eeuu.svg';
import { ReactComponent as IconSpanish } from '../../assets/icons/traduction/spain.svg';
import { ReactComponent as IconPortuguese } from '../../assets/icons/traduction/portugal.svg';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import ButtonPwa from './Adoption/ButtonPwa';

const useStyles = makeStyles((theme) => ({
	traductionButton: {
		color: '#fff',
		textTransform: 'none',
		fontSize: '15px',
		[theme.breakpoints.between('xs', 'sm')]: {
			color: '#000'
		}
	},
	menuIcons: {
		width: '40px',
		[theme.breakpoints.only('xs')]: {
			width: '30px'
		}
	},

	menuIconsLanguage: {
		fontSize: '40px',
		color: '#63c132',
		[theme.breakpoints.only('xs')]: {
			fontSize: '30px'
		}
	}
}));


const StyledMenu = withStyles((theme) => ({
	paper: {
		border: '1px solid #d3d4d5',
		marginTop: '12px',
		[theme.breakpoints.down('sm')]: {
			marginTop: '7px'
		}
	}
}))((props) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center'
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center'
		}}
		{...props}
	/>
));

const StyledMenuItem = withStyles((theme) => ({
	root: {
		'&:focus': {
			backgroundColor: theme.palette.primary.main,
			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
				color: theme.palette.common.white
			}
		}
	}
}))(MenuItem);

export default function TraductionButton({ handleMenuClose }) {
	const [t, i18n] = useTranslation('global');

	const classes = useStyles();

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleChangeLanguage = (lang) => {
		i18n.changeLanguage(lang);
		setAnchorEl(null);
		handleMenuClose && handleMenuClose();
	};

	return (
		<>
			<Hidden smDown>
				<Button
					color="secondary"
					className={classes.traductionButton}
					onClick={handleClick}
					startIcon={<TranslateIcon />}
				>
					<ArrowDropDownIcon />
					<Hidden mdDown>{t('language')}</Hidden>
				</Button>
			</Hidden>

			<Hidden mdUp>
				<MenuItem
					color="secondary"
					className={classes.traductionButton}
					onClick={handleClick}
				>
					<TranslateIcon className={classes.menuIconsLanguage} />
					<ArrowDropDownIcon />
					{t('language')}
				</MenuItem>
				<ButtonPwa
					color={'#63C132'}
					tittle={true}
				/>
			</Hidden>

			<StyledMenu
				autoFocus={false}
				id="customized-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<StyledMenuItem onClick={() => handleChangeLanguage('en')}>
					<ListItemIcon>
						<IconEnglish className={classes.menuIcons} />
					</ListItemIcon>
					<ListItemText primary="English" />
				</StyledMenuItem>

				<StyledMenuItem onClick={() => handleChangeLanguage('pt')}>
					<ListItemIcon>
						<IconPortuguese className={classes.menuIcons} />
					</ListItemIcon>
					<ListItemText primary="Português" />
				</StyledMenuItem>

				<StyledMenuItem onClick={() => handleChangeLanguage('es')}>
					<ListItemIcon>
						<IconSpanish className={classes.menuIcons} />
					</ListItemIcon>
					<ListItemText primary="Español" />
				</StyledMenuItem>
			</StyledMenu>
		</>
	);
}
