import { GET_GOOGLE_DATA } from "../types";

const initialState = {
	googleData: {
		error: false
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_GOOGLE_DATA:
			return {
				...state,
				googleData: action.payload
			};
		default:
			return state;
	}
};
