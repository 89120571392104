import axiosClient from './axios';

export async function questionUser(petdata) {
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post('/preguntaado', petdata);
		return response;
	} catch (error) {
		console.log(error);
	}
}

export async function answerUser(petdataAnswer) {
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post('/respuestaado', petdataAnswer)
		return response
	} catch (error) {
		console.log(error);
	}
}

export async function getQuestionAnswerUser(questionData) {
	console.log(questionData, 'questionData');
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post('/ObtenerPreguntaRespuestaado', questionData);
		return response;
	} catch (error) {
		console.log(error);
	}
}

export async function getQuestionAnswerUser2(requestData) {
	console.log(requestData, 'questionData2');
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post('/ObtenerPreguntaRespuestaSegundo', requestData);
		return response;
	} catch (error) {
		console.log(error)
	}
}
