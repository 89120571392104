import { useEffect, useState } from 'react';

export const useTimer = (resendTimer) => {
	console.log(resendTimer);
	// const {initialMinute = 0,initialSeconds = 0} = props;
	const [minutes, setMinutes] = useState(3);
	const [seconds, setSeconds] = useState(0);

	const [timeClean, setTimeClean] = useState(false);

	useEffect(() => {
		let myInterval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
			if (seconds === 0) {
				if (minutes === 0) {
					// alert('vacio')
					setMinutes(3);
					setTimeClean(true);
					setTimeClean(false);
					clearInterval(myInterval);
				} else if (resendTimer) {
					// alert('Inicia tiempo')
					setMinutes(minutes - 1);
					setSeconds(59);
				}
			}
		}, 1000);
		return () => {
			clearInterval(myInterval);
		};
	});

	return {
		minutes,
		seconds,
		timeClean
	};
};
