import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
function NotFound() {

    let history = useHistory();

    useEffect(() => {
        history.push('/');
    }, [])

    return (
        <div
            className={'lds-main animate__animated animate__fadeInDown'}
        >
            <h1 className="lds-tittle">PET SACI</h1>

            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div> 
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default NotFound