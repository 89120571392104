import React, { useState } from 'react';
// import Lottie from 'react-lottie';
import { Grid, Typography, Hidden, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

//Redux
import { useDispatch, useSelector } from 'react-redux';

//Redux Actions
import {
	dog_action,
	cat_action,
	hamster_action
} from '../../../redux/actions/petTypeAction';
// import { sizePetData } from '../../../redux/actions/adoptFormAction';

//loties
// import dogPet from '../../../assets/lotties/lottieDog.json';
// import catPet from '../../../assets/lotties/lottieCat.json';
// import hamsterPet from '../../../assets/lotties/lottieHamster.json';
import petType from '../../../redux/reducers/petTypeReducer';

import gato from '../../../assets/lotties/Gato.gif';
import perro from '../../../assets/lotties/Perro.gif';
import hamster from '../../../assets/lotties/Hamster.gif';
import { useTranslation } from 'react-i18next';

//Styles

const useStyles = makeStyles((theme) => ({
	cards: {
		[theme.breakpoints.only('lg')]: {
			width: 150,
			height: 150,
			borderRadius: '100%'
		},
		width: 180,
		height: 180,
		borderRadius: '100%'
	},

	cards2: {
		[theme.breakpoints.only('lg')]: {
			width: 150,
			height: 150,
			borderRadius: '100%'
		},
		width: 180,
		height: 180,
		borderRadius: '100%',
		border: 'solid #4CB333',
		boxShadow: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)'
	},

	root: {
		padding: theme.spacing(0, 10, 2, 10),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0, 0, 2, 0)
		}
		// position: 'absolute'
	},

	spacing: {
		// padding: "50px",
		// margin: "20%",
	},
	title: {
		textAlign: 'center',
		padding: theme.spacing(0, 0, 2, 0)
	},
	petFont: {
		marginTop: '10%'
	},
	petsGifs: {
		width: '120px',
		[theme.breakpoints.only('lg')]: {
			width: '100px'
		}
	},
	petsGifDog: {
		width: '120px',
		[theme.breakpoints.only('lg')]: {
			width: '120px'
		}
	}
}));

export default function PetType() {
	const [t] = useTranslation('global');
	const classes = useStyles();
	const dispatch = useDispatch();
	const petTypeState = useSelector((state) => state.petType.petType);
	const { procedure } = useSelector((state) => state.login);

	//State to select pet
	const [classSelectDog, setClassSelectDog] = useState(classes.cards);
	const [classSelectCat, setClassSelectCat] = useState(classes.cards);
	const [classSelectHamster, setClassSelectHamster] = useState(classes.cards);
	// const classSelect = classes.cards;
	const selectClassDog = classSelectDog;
	const selectClassCat = classSelectCat;
	const selectClassHamster = classSelectHamster;

	const handleClickDog = () => {
		setClassSelectDog(classes.cards2);
		setClassSelectCat(classes.cards);
		setClassSelectHamster(classes.cards);
		dispatch(dog_action(petType));
	};

	const handleClickCat = () => {
		setClassSelectDog(classes.cards);
		setClassSelectCat(classes.cards2);
		setClassSelectHamster(classes.cards);
		dispatch(cat_action(petType));
	};

	const handleClickHamster = () => {
		setClassSelectDog(classes.cards);
		setClassSelectCat(classes.cards);
		setClassSelectHamster(classes.cards2);
		dispatch(hamster_action(petType));
	};

	const [checkClassSelected, setCheckClassSelected] = useState(true);

	if (petTypeState === 1) {
		if (checkClassSelected === true) {
			setClassSelectCat(classes.cards2);
			setCheckClassSelected(false);
		}
	}
	if (petTypeState === 2) {
		if (checkClassSelected === true) {
			setClassSelectDog(classes.cards2);
			setCheckClassSelected(false);
		}
	}
	if (petTypeState === 3) {
		if (checkClassSelected === true) {
			setClassSelectHamster(classes.cards2);
			setCheckClassSelected(false);
		}
	}

	return (
		<Grid container className={classes.root}>
			<Hidden>
				<Grid container justify="center" alignItems="flex-start">
					<Grid item xs={6} className={classes.title}>
						{procedure === 1 ? (
							<Typography variant="h6" fontFamily>
								{t('select-pet')}
							</Typography>
						) : procedure === 2 ? (
							<Typography variant="h6" fontFamily>
								{t('pet-back')}
							</Typography>
						) : procedure === 3 ? (
							<Typography variant="h6" fontFamily>
								{t('pet-lose')}
							</Typography>
						) : null}
					</Grid>
				</Grid>
			</Hidden>
			<Grid item xs={12} sm={4} md={4}>
				<Button className={selectClassCat} onClick={handleClickCat}>
					<Grid container justify="center">
						<Grid item xs={12}>
							<img src={gato} alt="" className={classes.petsGifs} />
						</Grid>
						<Grid item xs={12}>
							<Typography color="primary" align="center">
								{t('cat')}
							</Typography>
						</Grid>
					</Grid>
				</Button>
			</Grid>
			<Grid item xs={12} sm={4} md={4}>
				<Button className={selectClassDog} onClick={handleClickDog}>
					<Grid container justify="center">
						<Grid item xs={12}>
							<img src={perro} alt="" className={classes.petsGifs} />
						</Grid>
						<Grid item xs={12}>
							<Typography color="primary" align="center">
								{t('dog')}
							</Typography>
						</Grid>
					</Grid>
				</Button>
			</Grid>
			<Grid item xs={12} sm={4} md={4}>
				<Button className={selectClassHamster} onClick={handleClickHamster}>
					<Grid container justify="center">
						<Grid item xs={12}>
							<img src={hamster} alt="" className={classes.petsGifs} />
						</Grid>
						<Grid item xs={12}>
							<Typography color="primary" align="center">
								{t('hamster')}
							</Typography>
						</Grid>
					</Grid>
				</Button>
			</Grid>
		</Grid>
	);
}
