import axios from 'axios'
import React, { useEffect } from 'react'
import axiosClient from '../configAxios/axios';
import { loginGoogleAction } from '../redux/actions/googleAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
function AuthFB() {

    let history = useHistory();

    const dispatch = useDispatch()

    const { token } = useSelector(
		(state) => state.login
	);

    useEffect(() => {
        async function auth() {
            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            /*    const formData = new FormData();
               // Obtener el código de la URL
               formData.append('origen_cuenta', 'google')
               
              
               const response = await axiosClient.post(`/login?code=${code}&id_rol=1`, formData); */
            dispatch(loginGoogleAction({
                code
            }))
        }
        auth()
    }, [])

    useEffect(() => {
        if(token){
            history.push('/');
        }
    },[token])


    return (
        <div
            className={'lds-main animate__animated animate__fadeInDown'}
        >
            <h1 className="lds-tittle">PET SACI</h1>

            <div className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default AuthFB