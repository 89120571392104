import { makeStyles, Tooltip, Zoom } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles({
	// CustomTooltip: {
	// 	backgroundColor: '#1E3F20',
	// 	color: 'white',
	// 	opacity: 0.7
	// },

	CustomTooltipFb: {
		backgroundColor: 'rgba(59, 89, 152)'
	},

	CustomTooltipTw: {
		backgroundColor: 'rgb(0, 172, 237)'
	},

	CustomTooltipWs: {
		backgroundColor: 'rgb(37, 211, 102)'
	},

	arrowFb: {
		color: 'rgba(59, 89, 152)'
	},

	arrowTw: {
		color: 'rgb(0, 172, 237)'
	},

	arrowWs: {
		color: 'rgb(37, 211, 102)'
	}
});

export default function CustomTooltip(props) {
	const classes = useStyles();

	return (
		<Tooltip
			{...props}
			arrow
			TransitionComponent={Zoom}
			placement={'top'}
			classes={{
				tooltip:
					(props.id === 'fb' && classes.CustomTooltipFb) ||
					(props.id === 'tw' && classes.CustomTooltipTw) ||
					(props.id === 'ws' && classes.CustomTooltipWs),
				arrow:
					(props.id === 'fb' && classes.arrowFb) ||
					(props.id === 'tw' && classes.arrowTw) ||
					(props.id === 'ws' && classes.arrowWs)
			}}
		/>
	);
}
