import { saveUserRegister } from '../../configAxios/Register';
import {
	REGISTRO_USUARIO_ERROR,
	REGISTRO_USUARIO_EXITO,
	REGISTRO_USUARIO_START,
	REGISTER_DIALOG_OPEN,
	REGISTER_DIALOG_CLOSE,
	SAVE_REGISTER_TO_LOGIN,
	GEN_REGISTER_CODE,
	REGISTER_ERROR_CLEAN
} from '../types';
import Swal from 'sweetalert2';
import axiosClient from '../../configAxios/axios';
import { setEncryptPassword } from '../../components/utils';

// Get Data to localStorage
export function registerAction(data) {
	return async (dispatch) => {
		dispatch(registerStart());
		try {
			const response = await saveUserRegister(data);
			dispatch(registerSuccess(response.data));
			// console.log(response.data)
		} catch (error) {
			dispatch(registerError(error));
			console.log(error.response, 'error')
			Swal.fire(
				'Error',
				`correo ${data.correo} ya se encuentra registrado`,
				'error'
			);
		}
	};
}

const registerStart = () => ({
	type: REGISTRO_USUARIO_START
});

const registerSuccess = (registerData) => ({
	type: REGISTRO_USUARIO_EXITO,
	payload: registerData
});

const registerError = (error) => ({
	type: REGISTRO_USUARIO_ERROR,
	payload: error
});

export const register_dialog_open_action = (registerDialog) => {
	return {
		type: REGISTER_DIALOG_OPEN,
		payload: registerDialog
	};
};

export const register_dialog_close_action = (registerDialog) => {
	return {
		type: REGISTER_DIALOG_CLOSE,
		payload: registerDialog
	};
};

export const save_register_to_login = (registerLoginData) => {
	return {
		type: SAVE_REGISTER_TO_LOGIN,
		payload: registerLoginData
	};
};

export const generate_registry_code_action = (data) => async (dispatch) => {
	console.log(data, 'dataRegister');
	dispatch(registerStart());
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post('/generate-code-register', {
			correo: data.correo,
			telefono: data.telefono.split(' ')[1],
			primer_nombre: data.primer_nombre,
			ubicacion: data.ubicacion,
		});
		dispatch({
			type: GEN_REGISTER_CODE,
			payload: response.data
		});
		return response.data;
	} catch (error) {
		console.log(error.response.data.msg)
		dispatch(registerError(error.response.data.msg));
	}
};

export const verify_registry_code_action = (data) => async (dispatch) => {
	console.log('data', data);
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post('/verify-code-register', { ...data, password: setEncryptPassword(data.password) });
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

export const register_error_clean_action = () => async (dispatch) => {
	dispatch({
		type: REGISTER_ERROR_CLEAN
	});
}