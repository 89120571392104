/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import {
	makeStyles,
	Grid,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Checkbox,
	ListItemText,
	Typography,
	FormHelperText
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Form Validation
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// Dispach Redux
import { useDispatch, useSelector } from 'react-redux';
import {
	big_size_action,
	medium_size_action,
	small_size_action,
	get_pet_size_data
} from '../../../redux/actions/petSizeAction';
import { get_hamster_race_action } from '../../../redux/actions/getHamsterRaceAction';

import {
	get_city_data_action,
	get_department_data_action,
	update_form_data_action,
	full_pet_description_action,
	get_form_data_action,
	push_data_action,
	not_full_pet_description_action
} from '../../../redux/actions/adoptFormAction';
import { titleCase } from '../../../hooks/useTitleCase';
import { useTranslation } from 'react-i18next';
// import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@material-ui/icons/CheckBox';

// import { Input } from '@material-ui/core';
// import { OutlinedInput } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	formPetDescription: {
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(0, 10, 0, 10)
		}
	},
	noOptions: {
		color: 'red'
	}
}));

const MenuPropsCustom = {
	// PaperProps: {
	// 	style: {
	// 		height: 230,
	// 		width: 250
	// 	}
	// },
	getContentAnchorEl: null,
	anchorOrigin: {
		vertical: 'bottom'
	}
};

const ListboxPropsCustom = {
	style: {
		maxHeight: '305px'
	}
};

export default function PetDescription() {
	const [t] = useTranslation('global');
	// redux Actions
	const dispatch = useDispatch();
	const { petType } = useSelector((store) => store.petType);
	const [sendHamsterData, setSendHamsterData] = useState(true);
	const [racesContent, setRacesContent] = useState(false);
	const [allowCities, setAllowCities] = useState(false);
	const [allowDepartments, setAllowDepartments] = useState(false);
	const [allowRaces, setAllowRaces] = useState(false);

	///////////////////////INSERTS
	const raceData = useSelector((store) => store.raceData.raceData);
	const hamsterRaceData = useSelector(
		(state) => state.hamsterRaceData.hamsterRaceData
	);
	const hamster = useSelector((state) => state.petType.hamster);
	const { departments, cities, activeStepState } = useSelector(
		(state) => state.adoptFormData
	);

	const { countries } = useSelector((state) => state.main);

	const { procedure } = useSelector((state) => state.login);
	const { id, id_pais: id_country } = useSelector((state) => state.login.user);
	const { showUserPets } = useSelector((state) => state.saciPets);
	const { descriptionData, colors } = useSelector(
		(state) => state.adoptFormData
	);

	const {
		nombre_mascota,
		edad_mascota,
		escala_edad,
		esterilizado,
		id_tamanio,
		id_raza,
		genero_mascota,
		id_color,
		id_vacuna_Rabia,
		id_vacuna_Rinotraqueítis,
		id_vacuna_Parvovirus,
		id_vacuna_Moquillo,
		id_codigo,
		id_departamento,
		id_pais,
		descripcion_mascota
	} = useSelector((state) => state.adoptFormData.updateDescriptionData);

	const [countryValue, setCountryValue] = useState('');
	const [departmentValue, setDepartmentValue] = useState('');
	const [cityValue, setCityValue] = useState('');
	const [raceValue, setRaceValue] = useState('');
	const [hamsterRaceValue, setHamsterRaceValue] = useState('');
	// const [rabiaState, setRabiaState] = useState(false);
	// const [rinoState, setRinoState] = useState(false);
	// const [parvoState, setParvoState] = useState(false);
	// const [moquilloState, setMoquilloState] = useState(false);

	const [newPet, setnewPet] = useState({
		id_usuario: `${id}`,
		nombre_mascota: `${nombre_mascota}`,
		edad_mascota: `${edad_mascota}`,
		escala_edad: `${escala_edad}`,
		esterilizado: `${esterilizado}`,
		id_tamanio: `${id_tamanio}`,
		id_raza: `${id_raza}`,
		genero_mascota: `${genero_mascota}`,
		id_color: `${id_color}`,
		id_vacuna_Rabia: id_vacuna_Rabia,
		id_vacuna_Rinotraqueítis: id_vacuna_Rinotraqueítis,
		id_vacuna_Parvovirus: id_vacuna_Parvovirus,
		id_vacuna_Moquillo: id_vacuna_Moquillo,
		id_codigo: `${id_codigo}`,
		id_departamento: `${id_departamento}`,

		id_pais: `${id_pais}`,
		descripcion_mascota: `${descripcion_mascota}`
		// vacunas: `${vacunas}`
	});


	// useEffect(() => {
	// 	setnewPet({
	// 		...newPet,
	// 		id_vacuna_Rabia: id_vacuna_Rabia,
	// 		id_vacuna_Rinotraqueítis: id_vacuna_Rinotraqueítis,
	// 		id_vacuna_Parvovirus: id_vacuna_Parvovirus,
	// 		id_vacuna_Moquillo: id_vacuna_Moquillo,
	// 	})
	// }, [])

	useEffect(() => {
		if (hamster === true) {
			setnewPet({ ...newPet, id_tamanio: 2 });
		}
	}, [hamster]);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setnewPet({ ...newPet, [name]: value });
	};

	// useEffect(() => {
	// 	dispatch(get_department_data_action());
	// }, []);

	const hamsterData = {
		id_tipo_mascota: petType
	};

	// eslint-disable-next-line no-unused-vars

	const saveData = () => {
		dispatch(push_data_action());
		setRacesContent(true);
	};

	const saveSmallSize = () => {
		dispatch(small_size_action());
		setRacesContent(true);
		dispatch(
			get_form_data_action({
				...descriptionData,
				escala_edad: `${newPet.escala_edad}`
			})
		);
		dispatch(update_form_data_action());
	};

	const saveMediumSize = () => {
		dispatch(medium_size_action());
		setRacesContent(true);
		dispatch(
			get_form_data_action({
				...descriptionData,
				escala_edad: `${newPet.escala_edad}`
			})
		);
		dispatch(update_form_data_action());
	};
	const saveBigSize = () => {
		dispatch(big_size_action());
		setRacesContent(true);
		dispatch(
			get_form_data_action({
				...descriptionData,
				escala_edad: `${newPet.escala_edad}`
			})
		);
		dispatch(update_form_data_action());
	};

	useEffect(() => {
		if (countryValue.length !== 0) {
			let data = countries.filter(
				(item) => titleCase(item.descripcion) === countryValue
			);
			if (data.length !== 0) {
				let objectData = data[0];
				setnewPet({ ...newPet, id_pais: objectData.id_codigo });
				setDepartmentValue('');
			}
		}
	}, [countryValue]);

	useEffect(() => {
		if (departmentValue.length !== 0) {
			let data = departments.filter(
				(item) => titleCase(item.descripcion) === departmentValue
			);
			if (data.length !== 0) {
				let objectData = data[0];
				setnewPet({ ...newPet, id_departamento: objectData.id_codigo });
				setCityValue('');
			}
		} else {
			setnewPet({ ...newPet, id_departamento: '' });
			setCityValue('');
		}
	}, [departmentValue]);

	useEffect(() => {
		if (cityValue !== '') {
			let data = cities.filter(
				(item) => titleCase(item.descripcion) === cityValue
			);
			if (data.length !== 0) {
				let objectData = data[0];
				setnewPet({ ...newPet, id_codigo: objectData.id_codigo });
			}
		} else {
			setnewPet({ ...newPet, id_codigo: '' });
		}
	}, [cityValue]);

	useEffect(() => {
		if (raceValue?.length !== 0) {
			let data = raceData.filter((item) => item.nombre_raza === raceValue);
			if (data.length !== 0) {
				let objectData = data[0];
				setnewPet({ ...newPet, id_raza: objectData.id_raza });
			}
		} else {
			setnewPet({ ...newPet, id_raza: '' });
		}
	}, [raceValue]);

	useEffect(() => {
		if (hamsterRaceValue?.length !== 0) {
			let data = hamsterRaceData.filter(
				(item) => item.nombre_raza === hamsterRaceValue
			);
			if (data.length !== 0) {
				let objectData = data[0];
				setnewPet({ ...newPet, id_raza: objectData.id_raza });
			}
		}
	}, [hamsterRaceValue]);

	useEffect(() => {
		if (newPet.id_pais.length !== 0) {
			let filterData = countries.filter(
				(item) => item.id_codigo === newPet.id_pais
			);
			setCountryValue(filterData[0]?.descripcion);
		}
	}, []);

	useEffect(() => {
		if (departments.length !== 0) {
			if (newPet.id_departamento !== '') {
				let filterData = departments.filter(
					(item) => item.id_codigo === newPet.id_departamento
				);
				setDepartmentValue(filterData[0]?.descripcion);
			}
		}
	}, []);

	useEffect(() => {
		if (id_codigo !== '') {
			let filterData = cities.filter((item) => item.id_codigo === id_codigo);
			setCityValue(filterData[0]?.descripcion);
		}
	}, []);

	useEffect(() => {
		if (showUserPets) {
			if (petType !== 3) {
				if (id_raza.length !== 0) {
					let filterData = raceData.filter((item) => item.id_raza === id_raza);
					setRaceValue(filterData[0]?.nombre_raza);
				}
			} else {
				if (id_raza.length !== 0) {
					let filterData = hamsterRaceData.filter(
						(item) => item.id_raza === id_raza
					);
					setHamsterRaceValue(filterData[0]?.nombre_raza);
				}
			}
		} else {
			if (petType !== 3) {
				if (id_raza.length !== 0) {
					let filterData = raceData.filter((item) => item.id_raza === id_raza);
					setRaceValue(filterData[0]?.nombre_raza);
				}
			} else {
				if (id_raza.length !== 0) {
					let filterData = hamsterRaceData.filter(
						(item) => item.id_raza === id_raza
					);
					setHamsterRaceValue(filterData[0]?.nombre_raza);
				}
			}
		}
	}, []);

	useEffect(() => {
		if (newPet.id_pais.length !== 0) {
			dispatch(get_department_data_action({ id_codigo: newPet.id_pais }));
			setAllowDepartments(true);
			setAllowCities(false);
		}
	}, [newPet.id_pais]);

	useEffect(() => {
		if (newPet.id_departamento.length !== 0) {
			dispatch(get_city_data_action({ id_codigo: newPet.id_departamento }));
			setAllowCities(true);
		}
	}, [newPet.id_departamento]);

	if (racesContent === true) {
		if (id_tamanio !== 0) {
			// dispatch(get_form_data_action(newPet));
			dispatch(update_form_data_action());
		}
		setRacesContent(false);
	}

	useEffect(() => {
		if (newPet.id_tamanio.length !== 0) {
			dispatch(
				get_pet_size_data({
					id_tipo_mascota: petType,
					id_tamanio: newPet.id_tamanio
				})
			);
		}
	}, [newPet.id_tamanio]);

	if (sendHamsterData === true) {
		if (hamster === true) {
			dispatch(get_hamster_race_action(hamsterData));
			setRacesContent(true);
		}
		setSendHamsterData(false);
	}

	const classes = useStyles();

	const schema = yup.object().shape({
		nombre_mascota: yup
			.string()
			.required(t('complete-validation'))
			.min(3, t('minimum-validation'))
			.max(20, t('maximum-pet-validation'))
			.matches(/^[a-zA-ZÁ-ÿ\s]+$/, t('letters-validation')),

		edad_mascota: yup
			.number()
			.typeError(t('complete-validation'))
			.min(1, t('minimum-pet-age'))
			.max(25, t('maximum-pet-age'))
			.integer(t('only-pet-numbers')),

		descripcion_mascota: yup
			.string()
			.required(t('complete-validation'))
			.min(5, t('minimum-validation-description'))
	});

	const { handleSubmit, register, errors } = useForm({
		defaultValues: {
			nombre_mascota: nombre_mascota,
			edad_mascota: edad_mascota,
			descripcion_mascota: descripcion_mascota
		},
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	useEffect(() => {
		if (newPet.id_tamanio.length !== 0) {
			setAllowRaces(true);
		}
	}, [newPet.id_tamanio]);

	const [checkForm, setCheckForm] = useState(false);

	useEffect(() => {
		switch (petType) {
			case 3:
				setAllowRaces(true);
				if (procedure === 2) {
					// alert('prueba 1')
					if (
						newPet.esterilizado.length &&
						newPet.id_raza &&
						newPet.id_color &&
						newPet.id_departamento &&
						newPet.id_codigo &&
						newPet.descripcion_mascota.length !== 0
					) {
						dispatch(full_pet_description_action());
						dispatch(get_form_data_action(newPet));
						dispatch(update_form_data_action());
					} else {
						dispatch(not_full_pet_description_action());
					}
				} else {
					// alert('prueba 2')
					if (!errors.nombre_mascota && !errors.edad_mascota) {
						if (
							newPet.nombre_mascota.length &&
							newPet.edad_mascota.length &&
							newPet.escala_edad &&
							newPet.esterilizado.length &&
							newPet.id_raza &&
							newPet.id_color &&
							newPet.id_departamento &&
							newPet.id_codigo &&
							newPet.descripcion_mascota.length !== 0
						) {
							setCheckForm(true);
						} else {
							setCheckForm(false);
						}
					}
				}

				break;
			default:
				if (procedure === 2) {
					// form encontrar
					// alert('prueba 3')
					if (
						!errors.edad_mascota &&
						newPet.escala_edad &&
						newPet.esterilizado &&
						newPet.id_tamanio &&
						newPet.id_raza &&
						newPet.genero_mascota &&
						newPet.id_color &&
						newPet.id_departamento &&
						newPet.id_codigo &&
						!errors.descripcion_mascota
					) {
						setCheckForm(true);
					} else {
						setCheckForm(false);
					}
				} else {
					// form adoptar
					// alert('prueba 4')
					if (
						!errors.nombre_mascota &&
						!errors.edad_mascota &&
						!errors.descripcion_mascota &&
						newPet.nombre_mascota &&
						newPet.edad_mascota &&
						newPet.escala_edad &&
						newPet.esterilizado &&
						newPet.id_tamanio &&
						newPet.id_raza &&
						newPet.genero_mascota &&
						newPet.id_color &&
						newPet.id_departamento &&
						newPet.id_codigo &&
						newPet.descripcion_mascota
					) {
						setCheckForm(true);
					} else {
						setCheckForm(false);
					}
				}
		}
	}, [
		newPet.nombre_mascota,
		newPet.descripcion_mascota,
		newPet.id_codigo,
		newPet.id_departamento,
		errors,
		newPet.edad_mascota,
		newPet.escala_edad,
		newPet.id_tamanio,
		newPet.id_raza,
		newPet.id_color,
		newPet.esterilizado
	]);

	useEffect(() => {
		if (checkForm) {
			dispatch(full_pet_description_action());
		} else {
			dispatch(not_full_pet_description_action());
		}
	}, [checkForm]);

	useEffect(() => {
		if (activeStepState === 3) {
			dispatch(
				get_form_data_action({
					...descriptionData,
					id_usuario: `${id}`,
					id_raza: `${newPet.id_raza}`,
					tipo_tramite: `${procedure}`,
					nombre_mascota: `${newPet.nombre_mascota}`,
					edad_mascota: `${newPet.edad_mascota}`,
					escala_edad: `${newPet.escala_edad}`,
					esterilizado: `${newPet.esterilizado}`,
					id_tamanio: `${newPet.id_tamanio}`,
					genero_mascota: `${newPet.genero_mascota}`,
					id_color: `${newPet.id_color}`,
					id_vacuna_Rabia: newPet.id_vacuna_Rabia,
					id_vacuna_Rinotraqueítis: newPet.id_vacuna_Rinotraqueítis,
					id_vacuna_Parvovirus: newPet.id_vacuna_Parvovirus,
					id_vacuna_Moquillo: newPet.id_vacuna_Moquillo,
					id_pais: `${newPet.id_pais}`,
					id_codigo: `${newPet.id_codigo}`,
					id_departamento: `${newPet.id_departamento}`,
					descripcion_mascota: `${newPet.descripcion_mascota}`
				})
			);
			dispatch(update_form_data_action());
		}
	}, [activeStepState]);

	const handleChange3 = (event) => {
		setnewPet({ ...newPet, [event.target.name]: event.target.checked });
		dispatch(
			get_form_data_action({
				...descriptionData,
				[event.target.name]: event.target.checked
			})
		);
	};

	// const handleChange4 = (event, options, checked) => {
	// 	setnewPet({ ...newPet, [event.target.name]: event.target.checked });
	// };

	const [checkUserPetSize, setCheckUserPetSize] = useState(false);

	useEffect(() => {
		if (showUserPets === true) {
			dispatch(get_city_data_action({ id_codigo: id_departamento }));
			switch (id_tamanio) {
				case '2':
					dispatch(medium_size_action());
					setCheckUserPetSize(true);
					break;
				case '1':
					dispatch(big_size_action());
					setCheckUserPetSize(true);
					break;
				case '3':
					dispatch(small_size_action());
					setCheckUserPetSize(true);
					break;
				default:
			}
		}
	}, [showUserPets]);

	useEffect(() => {
		if (checkUserPetSize) {
			dispatch(
				get_pet_size_data({
					id_tipo_mascota: petType,
					id_tamanio: parseInt(id_tamanio)
				})
			);
		}
	}, [checkUserPetSize]);

	// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	// const checkedIcon = <CheckBoxIcon fontSize="small" />;

	const [personName /* , setPersonName */] = React.useState([]);

	// const handleChange5 = (event) => {
	// 	setPersonName(event.target.value);
	// };

	// const MenuProps = {
	// 	PaperProps: {
	// 		style: {
	// 			maxHeight: 48 * 4.5 + 8,
	// 			width: 250
	// 		}
	// 	}
	// };

	// const names = [
	// 	'Oliver Hansen',
	// 	'Van Henry',
	// 	'April Tucker',
	// 	'Ralph Hubbard',
	// 	'Omar Alexander',
	// 	'Carlos Abbott',
	// 	'Miriam Wagner',
	// 	'Bradley Wilkerson',
	// 	'Virginia Andrews',
	// 	'Kelly Snyder'
	// ];

	let { id_pais: departmentLabel, id_codigo: cityLabel } = newPet;

	switch (departmentLabel) {
		// Colombia, Paraguay, Uruguay y Bolivia
		case '1':
		case '1335':
		case '1393':
		case '1203':
			departmentLabel = t('department');
			cityLabel = t('municipality');
			break;

		// E.E U.U
		case '1242':
			departmentLabel = t('state');
			cityLabel = t('county');
			break;

		// Argentina
		case '1190':
			departmentLabel = t('province');
			cityLabel = t('municipality');
			break;

		// Canadá y Ecuador
		case '1214':
		case '1234':
			departmentLabel = t('province');
			cityLabel = t('city');
			break;

		// México, Venezuela y Brasil
		case '1308':
		case '1395':
		case '1205':	
			departmentLabel = t('state');
			cityLabel = t('municipality');
			break;

		// Perú
		case '1336':
			departmentLabel = t('Department');
			cityLabel = t('province');
			break;

		// Chile
		case '1227':
			departmentLabel = t('region');
			cityLabel = t('commune');
			break;

		// Por Defecto
		default:
			departmentLabel = t('Department');
			cityLabel = t('municipality');
			break;
	}

	return (
		<form onSubmit={handleSubmit()} autocomplete="off">
			<Grid container spacing={2} className={classes.formPetDescription}>
				<Grid item xs={12}>
					{procedure === 1 && (
						<Typography variant="h5" gutterBottom>
							{t('describe-pet-adoption')}
						</Typography>
					)}
					{procedure === 2 && (
						<Typography variant="h5" gutterBottom>
							{t('describe-pet-found')}
						</Typography>
					)}
					{procedure === 3 && (
						<Typography variant="h5" gutterBottom>
							{t('describe-pet-lost')}
						</Typography>
					)}
					{/* <Typography variant="h5" gutterBottom>
						Actualiza los datos
					</Typography> */}
				</Grid>

				<Grid item xs={12} sm={5} md={5}>
					<TextField
						label={
							procedure === 2
								? t('pet-name') + ' ' + t('optional')
								: t('pet-name')
						}
						type="text"
						name="nombre_mascota"
						variant="outlined"
						fullWidth
						onChange={handleChange}
						defaultValue={nombre_mascota}
						inputRef={register}
						error={procedure !== 2 && !!errors.nombre_mascota}
						helperText={procedure !== 2 && errors?.nombre_mascota?.message}
					/>
				</Grid>
				<Grid item xs={12} sm={3} md={3}>
					<TextField
						// label="Edad aprox..."
						label={procedure === 2 ? t('aproximated-age') : t('pet-age')}
						type="number"
						name="edad_mascota"
						variant="outlined"
						fullWidth
						onChange={handleChange}
						defaultValue={edad_mascota}
						inputRef={register}
						error={!!errors.edad_mascota}
						helperText={errors?.edad_mascota?.message}
					/>
				</Grid>
				<Grid item xs={12} sm={4} md={4}>
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="demo-simple-select-outlined-label">
							{t('age-scale')}
						</InputLabel>
						<Select
							labelId="demo-simple-select-outlined-label"
							id="demo-simple-select-outlined"
							name="escala_edad"
							label={t('age-scale')}
							onChange={handleChange}
							value={newPet.escala_edad}
							defaultValue={escala_edad}
							MenuProps={MenuPropsCustom}
						>
							<MenuItem value="1" onClick={saveData}>
								{t('weeks')}
							</MenuItem>
							<MenuItem value="2" onClick={saveData}>
								{t('months')}
							</MenuItem>
							<MenuItem value="3" onClick={saveData}>
								{t('years')}
							</MenuItem>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} md={4}>
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="demo-simple-select-outlined-label">
							{t('sterilized-pet')}
						</InputLabel>
						<Select
							labelId="demo-simple-select-outlined-label"
							id="demo-simple-select-outlined"
							name="esterilizado"
							label={t('sterilized-pet')}
							onChange={handleChange}
							value={newPet.esterilizado}
							defaultValue={esterilizado}
							MenuProps={MenuPropsCustom}
						>
							<MenuItem value="2" onClick={saveData}>
								{t('IdontKnow')}
							</MenuItem>
							<MenuItem value="1" onClick={saveData}>
								{t('yes')}
							</MenuItem>
							<MenuItem value="0" onClick={saveData}>
								{t('not')}
							</MenuItem>
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs={12} sm={6} md={4}>
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="demo-simple-select-outlined-label">
							{t('pet-size')}
						</InputLabel>
						<Select
							labelId="demo-simple-select-outlined-label"
							id="demo-simple-select-outlined"
							name="id_tamanio"
							label={t('pet-size')}
							onChange={(e) => {
								handleChange(e);
								setRaceValue('');
							}}
							disabled={hamster === true}
							error={hamster === true}
							value={hamster === true ? null : newPet.id_tamanio}
							defaultValue={id_tamanio}
							MenuProps={MenuPropsCustom}
						>
							<MenuItem value={3} onClick={saveSmallSize}>
								{t('pet-small')}
							</MenuItem>
							<MenuItem value={2} onClick={saveMediumSize}>
								{t('pet-medium')}
							</MenuItem>
							<MenuItem value={1} onClick={saveBigSize}>
								{t('pet-big')}
							</MenuItem>
						</Select>
						{hamster === true && (
							<FormHelperText>{t('Not_Available')}</FormHelperText>
						)}
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					{hamster ? (
						<Autocomplete
							fullWidth
							inputValue={hamsterRaceValue}
							disabled={allowRaces === false}
							onInputChange={(event, newInputValue) => {
								setHamsterRaceValue(newInputValue);
							}}
							ListboxProps={ListboxPropsCustom}
							options={hamsterRaceData}
							getOptionLabel={(option) => option.nombre_raza}
							renderInput={(params) => (
								<TextField
									{...params}
									label={t('pet-breed')}
									variant="outlined"
								/>
							)}
							classes={{
								noOptions: classes.noOptions
							}}
							noOptionsText={t('breed-not-found')}
							closeIcon={false}
						/>
					) : (
						<Autocomplete
							fullWidth
							inputValue={raceValue}
							disabled={allowRaces === false}
							onInputChange={(event, newInputValue) => {
								setRaceValue(newInputValue);
							}}
							ListboxProps={ListboxPropsCustom}
							options={raceData}
							getOptionLabel={(option) => option.nombre_raza}
							renderInput={(params) => (
								<TextField
									{...params}
									label={t('pet-breed')}
									variant="outlined"
								/>
							)}
							classes={{
								noOptions: classes.noOptions
							}}
							noOptionsText={t('breed-not-found')}
							closeIcon={false}
						/>
					)}
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="demo-simple-select-outlined-label">
							{t('pet-gender')}
						</InputLabel>
						<Select
							labelId="demo-simple-select-outlined-label"
							id="demo-simple-select-outlined"
							name="genero_mascota"
							label={t('pet-gender')}
							onChange={handleChange}
							value={newPet.genero_mascota}
							defaultValue={genero_mascota}
							MenuProps={MenuPropsCustom}
						>
							<MenuItem value="1" onClick={saveData}>
								{t('pet-male')}
							</MenuItem>
							<MenuItem value="2" onClick={saveData}>
								{t('pet-female')}
							</MenuItem>
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<FormControl variant="outlined" fullWidth>
						<InputLabel id="demo-simple-select-outlined-label">
							{t('pet-color')}
						</InputLabel>
						<Select
							labelId="demo-simple-select-outlined-label"
							id="demo-simple-select-outlined"
							name="id_color"
							label={t('pet-color')}
							onChange={handleChange}
							value={newPet.id_color}
							defaultValue={id_color}
							MenuProps={MenuPropsCustom}
						>
							{colors.map((i) => (
								<MenuItem
									key={i.id_color}
									value={i.id_color}
									onClick={saveData}
								>
									{i.nombre_color === 'Café' && t('coffee')}
									{i.nombre_color === 'Negro' && t('black')}
									{i.nombre_color === 'Gris' && t('gray')}
									{i.nombre_color === 'Blanco' && t('white')}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs={12} md={4}>
					{/* <Autocomplete
						multiple
						id="checkboxes-tags-demo"
						options={MENU}
						disableCloseOnSelect
						getOptionLabel={(option) => option.item}
						renderOption={(option, { selected }) => (
							<React.Fragment>
								<Checkbox
									icon={icon}
									checkedIcon={checkedIcon}
									style={{ marginRight: 8 }}
									checked={selected}
									name={option.value}
								/>
								{option.item}
							</React.Fragment>
						)}
						style={{ width: 500 }}
						onChange={(event, options, checked) => {
							handleChange4(event, options, checked);
						}}
						// value={value}
						// onChange={(event, newValue) => {
						// 	setValue(newValue);
						// }}
						// inputValue={inputValue}
						// onInputChange={(event, newInputValue) => {
						// 	setInputValue(newInputValue);
						// }}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								label="Menu"
								placeholder="Item"
								fullWidth
							/>
						)}
					/> */}

					{/* <FormControl variant="outlined" className={classes.formControl}>
						<InputLabel id="demo-mutiple-checkbox-label">
							{t('Pet-vaccines')}
						</InputLabel>
						<Select
							labelId="demo-mutiple-checkbox-label"
							label={t('Pet-vaccines')}
							id="demo-mutiple-checkbox"
							multiple
							value={personName}
							onChange={handleChange5}
							// input={<Input />}
							renderValue={(selected) => selected.join(', ')}
							MenuProps={MenuProps}
						>
							{names.map((name) => (
								<MenuItem key={name} value={name}>
									<Checkbox checked={personName.indexOf(name) > -1} />
									<ListItemText primary={name} />
								</MenuItem>
							))}
						</Select>
					</FormControl> */}

					<FormControl variant="outlined" fullWidth disabled={procedure === 2} error={hamster === true}>
						<InputLabel id="demo-mutiple-checkbox-label">
							{t('Pet-vaccines')}
						</InputLabel>
						<Select
							disabled={hamster === true}
							multiple
							value={personName}
							labelId="demo-mutiple-checkbox-label"
							id="demo-mutiple-checkbox"
							label={t('Pet-vaccines')}
							error={hamster === true}
							MenuProps={MenuPropsCustom}
						// renderValue={(selected) => selected.join(', ')}
						>
							<MenuItem>
								<Checkbox
									color="primary"
									name="id_vacuna_Rabia"
									onChange={handleChange3}
									checked={newPet.id_vacuna_Rabia}
								/>
								<ListItemText primary={t('rage')} />
							</MenuItem>

							<MenuItem>
								<Checkbox
									color="primary"
									name="id_vacuna_Rinotraqueítis"
									onChange={handleChange3}
									checked={newPet.id_vacuna_Rinotraqueítis}
								/>
								<ListItemText primary={t('rhinotracheitis')} />
							</MenuItem>

							<MenuItem>
								<Checkbox
									color="primary"
									name="id_vacuna_Parvovirus"
									onChange={handleChange3}
									checked={newPet.id_vacuna_Parvovirus}
								/>
								<ListItemText primary={t('parvovirus')} />
							</MenuItem>

							<MenuItem>
								<Checkbox
									color="primary"
									name="id_vacuna_Moquillo"
									onChange={handleChange3}
									checked={newPet.id_vacuna_Moquillo}
								/>
								<ListItemText primary={t('distemper')} />
							</MenuItem>
						</Select>
						<>
							{hamster === true && (
								<FormHelperText>{t('Not_Available')}</FormHelperText>
							)}
						</>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<Autocomplete
						fullWidth
						defaultValue={countries.find(
							(item) => item.id_codigo === id_country
						)}
						inputValue={countryValue}
						onInputChange={(event, newInputValue) => {
							setCountryValue(newInputValue);
						}}
						options={countries}
						getOptionLabel={(option) => titleCase(option.descripcion)}
						// getOptionSelected={(option, value) => option.descripcion === value.descripcion }
						renderInput={(params) => (
							<TextField {...params} label={t('country')} variant="outlined" />
						)}
						classes={{
							noOptions: classes.noOptions
						}}
						noOptionsText={t('country-not-found')}
						closeIcon={false}
					// disabled
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<Autocomplete
						fullWidth
						inputValue={departmentValue}
						disabled={allowDepartments === false}
						onInputChange={(event, newInputValue) => {
							setDepartmentValue(newInputValue);
						}}
						options={departments}
						getOptionLabel={(option) => titleCase(option.descripcion)}
						// getOptionSelected={(option, value) => option.descripcion === value.descripcion }
						renderInput={(params) => (
							<TextField
								{...params}
								// label={t('department')}
								label={departmentLabel}
								variant="outlined"
							/>
						)}
						classes={{
							noOptions: classes.noOptions
						}}
						noOptionsText={t('department-not-found')}
						closeIcon={false}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={4}>
					<Autocomplete
						fullWidth
						inputValue={cityValue}
						disabled={allowCities === false}
						onInputChange={(event, newInputValue) => {
							setCityValue(newInputValue);
						}}
						options={cities}
						getOptionLabel={(option) => titleCase(option.descripcion)}
						renderInput={(params) => (
							<TextField
								{...params}
								// label={t('municipality')}
								label={cityLabel}
								variant="outlined"
							/>
						)}
						classes={{
							noOptions: classes.noOptions
						}}
						noOptionsText={t('municipality-not-found')}
						closeIcon={false}
					/>
				</Grid>
				<Grid item xs={12} md={12}>
					<TextField
						fullWidth
						variant="outlined"
						name="descripcion_mascota"
						placeholder={
							procedure === 2 ? t('observations-pet') : t('description-pet')
						}
						multiline
						rows={4}
						onChange={handleChange}
						defaultValue={descripcion_mascota}
						inputRef={register}
						error={!!errors.descripcion_mascota}
						helperText={errors?.descripcion_mascota?.message}
					/>
				</Grid>
			</Grid>
		</form>
	);
}

// const MENU = [
// 	{ item: 'Rabia', value: 'id_vacuna_Rabia' },
// 	{ item: 'Rinotraqueítis', value: 'id_vacuna_Parvovirus' },
// 	{ item: 'Parvovirus', value: 'id_vacuna_Parvovirus' },
// 	{ item: 'Moquillo', value: 'id_vacuna_Moquillo' }
// ];
