import {
	SEND_QUESTION_USER,
	SEND_ANSWER_USER,
	GET_QUESTION_ANSWER_USER,
	GET_QUESTION_ANSWER_USER_2,
	CLEAN_QUESTION_ANSWER_USER,
	DIALOG_SHOW_REQUEST,
} from '../types';

const initialState = {
	question: null,
	answer: null,
	dialog: false,
	questionAnswerData: {
		form: null
	},
	questionAnswerData2: {
		form: null
	}
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SEND_QUESTION_USER:
			return {
				...state,
				question: action.payload
			};

		case SEND_ANSWER_USER:
			return {
				...state,
				answer: action.payload
			};

		case GET_QUESTION_ANSWER_USER:
			return {
				...state,
				questionAnswerData: action.payload
			};

		case GET_QUESTION_ANSWER_USER_2:
			return {
				...state,
				questionAnswerData2: action.payload
			}

		case CLEAN_QUESTION_ANSWER_USER:
			return {
				...initialState
			}

		case DIALOG_SHOW_REQUEST: 
		return {
			...state,
			dialog: action.payload
		}

		default:
			return state;
	}
};
