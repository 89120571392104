/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';

import {
	Button,
	Card,
	CardMedia,
	Dialog,
	Grid,
	IconButton,
	TextField,
	Typography,
	useMediaQuery,
	useTheme,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Snackbar,
	Divider,
	Tooltip
} from '@material-ui/core';

import Slide from '@material-ui/core/Slide';
import MuiAlert from '@material-ui/lab/Alert';

import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';

import { useHistory, useParams } from 'react-router-dom';

//icons
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import CloseIcon from '@material-ui/icons/Close';
import userPetsModalStyles from '../assets/css/js/userPetsModalStyles';

import { titleCase } from '../hooks/useTitleCase';

import {
	get_saci_pets_action,
	select_pet_action
} from '../redux/actions/saciPets';
import {
	save_selected_pet_data_action,
	save_user_pet_id_action,
	set_user_pet_modal_data_action
} from '../redux/actions/userPetsAction';

import {
	clean_question_answer_pet_action,
	get_question_answer_pet_action,
	send_answer_pet_action,
	send_question_pet_action
} from '../redux/actions/questionPetsAction';

import CarouselPhotos from '../components/SaciComponents/Adoption/CarouselPhotos';

import { useForm } from 'react-hook-form';

import {
	adoptstepper_dialog_open_action,
	get_session_action
} from '../redux/actions/loginAction';
import Header from '../components/SaciComponents/Header';
import { useLocalStorage } from '../hooks/useLocalStorage';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';

export default function PetInformation() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();

	const { id } = useParams();
	// console.log(id);

	const { userPetsRegistered, userPetData, userPetId, userPetModalData } =
		useSelector((state) => state.userPets);

	const { user, adoptstepperDialog, token } = useSelector(
		(state) => state.login
	);

	const { id_pais } = useSelector((state) => state.login.user);

	// traer preguntas y respuestas
	const { questionAnswerData, answer, question } = useSelector(
		(state) => state.questionPets
	);

	const { mascotas, petSelected, showUserPets } = useSelector(
		(state) => state.saciPets
	);

	const history = useHistory();

	///////////////////////////////////////////////////////////////////////
	// Mantener Sesión
	const [storedToken, setStoredToken] = useLocalStorage('token', '');

	useEffect(() => {
		if (token.length !== 0) {
			setStoredToken(token);
		}
	}, [token]);

	useEffect(() => {
		if (storedToken.length !== 0) {
			dispatch(
				get_session_action({
					tokenlog: storedToken
				})
			);
		}
	}, []);

	///////////////////////////////////////////////////////////////////

	useEffect(() => {
		if (id_pais) {
			dispatch(get_saci_pets_action({ id_pais: id_pais }));
		} else {
			dispatch(get_saci_pets_action({ id_tipo_mascota: false }));
		}
	}, []);

	useEffect(() => {
		if (mascotas.length !== 0) {
			dispatch(select_pet_action(id));
			dispatch(set_user_pet_modal_data_action(true));
		}
	}, [mascotas]);

	useEffect(() => {
		if (petSelected !== null || showUserPets === true) {
			dispatch(save_user_pet_id_action(petSelected));
		}
	}, [petSelected]);

	useEffect(() => {
		if (petSelected !== null) {
			if (showUserPets) {
				const userPet = userPetsRegistered.filter(
					(pet) => pet.id_mascota === petSelected
				);
				// alert('1');
				dispatch(save_selected_pet_data_action(userPet[0]));
			}

			if (mascotas.length !== 0) {
				const userPet = mascotas.filter(
					(pet) => pet.id_mascota === petSelected
				);
				// alert('2');
				dispatch(save_selected_pet_data_action(userPet[0]));
			}
		}
	}, [petSelected, mascotas]);

	// const [state, setstate] = useState([])

	// useEffect(() => {

	// 	if(mascotas.length !== 0){

	// 		let filters = mascotas.filter(pets => pets.id_mascota === id)
	// 		setstate({ ...state, mascotas: filters[0] });
	// 	}

	// }, [mascotas])

	// console.log(state)

	// const filters = mascotas.filter((pets) => pets.id_mascota === id);

	// console.log(filters);

	/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	// useEffect(() => {
	// 	if (petSelected !== null) {
	// 		dispatch(save_user_pet_id_action(petSelected));
	// 	}
	// }, [petSelected]);

	const {
		register,
		errors,
		handleSubmit: handleSubmitQuestion
	} = useForm({
		mode: 'onSubmit'
	});

	// const {
	// 	register: registerAnswer,
	// 	errors: errorsAnswer,
	// 	handleSubmit: handleSubmitAnswer
	// } = useForm({
	// 	mode: 'onChange'
	// });

	//////////////////////////////////////////////////
	//pregunta
	const [petdata, setPetData] = useState({
		primer_usuario: `${userPetData.id_usuario}`,
		segundo_usuario: `${user.id}`,
		mensaje: '',
		id_mascota: `${userPetData.id_mascota}`
	});

	useEffect(() => {
		if (userPetId !== null) {
			setPetData({
				primer_usuario: `${userPetData.id_usuario}`,
				segundo_usuario: `${user.id}`,
				mensaje: `${petdata.mensaje}`,
				id_mascota: `${userPetData.id_mascota}`
			});
		}
	}, [userPetId, user]);

	//////////////////////////////////////////////////
	//ObtenerPreguntaRespuesta

	const [questionData, setQuestionData] = useState({
		primer_usuario: `${userPetData.id_usuario}`,
		id_mascota: `${userPetData.id_mascota}`
	});

	useEffect(() => {
		if (userPetId !== null) {
			setQuestionData({
				primer_usuario: `${userPetData.id_usuario}`,
				id_mascota: `${userPetData.id_mascota}`
			});
		}
	}, [userPetId]);

	//////////////////////////////////////////////////

	const handleChangeMessage = (event) => {
		const { name, value } = event.target;
		setPetData({ ...petdata, [name]: value });
	};

	const onSubmitQuestion = async (data, e) => {
		if (id_pais !== userPetData.id_pais) {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				confirmButtonColor: '#63C132',
				text: 'No puedes hacer preguntas la mascota publicada no corresponde con tu país',
				footer: '<a href="/">¿Deseas volver al inicio?</a>'
			});
		} else if (user.nombres.length !== 0) {
			await dispatch(send_question_pet_action(petdata));
			setTimeout(() => {
				dispatch(get_question_answer_pet_action(questionData));
				e.target.reset();
				setPetData({
					primer_usuario: `${userPetData.id_usuario}`,
					segundo_usuario: `${user.id}`,
					mensaje: '',
					id_mascota: `${userPetData.id_mascota}`
				});
			}, 2000);
		}
	};

	const handleSubmitDialog = () => {
		if (adoptstepperDialog !== true) {
			dispatch(adoptstepper_dialog_open_action());
		}
	};

	useEffect(() => {
		if (userPetModalData) {
			dispatch(get_question_answer_pet_action(questionData));
		}
	}, [questionData]);

	useEffect(() => {
		if (
			questionAnswerData.length > 0 &&
			userPetModalData &&
			questionData.primer_usuario &&
			petdata.mensaje === ''
		) {
			setTimeout(() => {
				dispatch(get_question_answer_pet_action(questionData));
			}, 1000);
		}
	}, [questionAnswerData, userPetModalData, petdata.mensaje]);

	const handleCloseDialog = () => {
		setQuestionData({
			primer_usuario: '',
			id_mascota: ''
		});
		dispatch(save_user_pet_id_action(null));
		dispatch(set_user_pet_modal_data_action(false));
		dispatch(select_pet_action(null));
		dispatch(clean_question_answer_pet_action());
		history.push('/');
	};

	const classes = userPetsModalStyles();

	const theme = useTheme();

	const baseURL = process.env.REACT_APP_BACKEND_URL;

	const fullScreenResponsive = useMediaQuery(theme.breakpoints.down('xl'));

	let {
		nombre_mascota,
		edad_mascota,
		escala_edad,
		raza,
		color,
		tipo,
		esterilizado,
		pais,
		departameto,
		municipio,
		vacunas,
		descripcion_mascota,
		telefono1,
		telefono2,
		correo,
		fotos,
		id_pais: id_pais_pet
	} = userPetData;

	switch (escala_edad) {
		case '1':
			escala_edad = t('weeks');
			break;
		case '2':
			escala_edad = t('months');
			break;
		case '3':
			escala_edad = t('years');
			break;
		default:
	}

	switch (color) {
		case 'Café':
			color = t('coffee');
			break;
		case 'Negro':
			color = t('black');
			break;
		case 'Gris':
			color = t('gray');
			break;
		case 'Blanco':
			color = t('white');
			break;
		default:
	}

	switch (tipo) {
		case 'MACHO':
			tipo = t('pet-male');
			break;
		case 'HEMBRA':
			tipo = t('pet-female');
			break;
		default:
	}

	switch (esterilizado) {
		case '0':
			esterilizado = t('not');
			break;
		case '1':
			esterilizado = t('yes');
			break;
		case '2':
			esterilizado = t('IdontKnow');
			break;
		default:
	}

	switch (telefono1) {
		case null:
			telefono1 = t('main-phone') + ' ' + t('not-available');
			break;
		default:
	}

	switch (telefono2) {
		case null:
			telefono2 = t('secondary-phone') + ' ' + t('not-available');
			break;
		default:
	}

	switch (correo) {
		case null:
			correo = t('email') + ' ' + t('not-available');
			break;
		default:
	}

	let department;
	let municipality;

	switch (id_pais_pet) {
		// Colombia, Paraguay, Uruguay y Bolivia
		case '1':
		case '1335':
		case '1393':
		case '1203':
			department = t('department');
			municipality = t('municipality');
			break;

		// E.E U.U
		case '1242':
			department = t('state');
			municipality = t('county');
			break;

		// Argentina
		case '1190':
			department = t('province');
			municipality = t('municipality');
			break;

		// Canadá y Ecuador
		case '1214':
		case '1234':
			department = t('province');
			municipality = t('city');
			break;

		// México, Venezuela y Brasil
		case '1308':
		case '1395':
		case '1205':
			department = t('state');
			municipality = t('municipality');
			break;

		// Perú
		case '1336':
			department = t('Department');
			municipality = t('province');
			break;

		// Chile
		case '1227':
			department = t('region');
			municipality = t('commune');
			break;

		// Por Defecto
		default:
			department = t('Department');
			municipality = t('municipality');
			break;
	}

	let indicative;

	switch (id_pais_pet) {
		// Argentina
		case '1190':
			indicative = 54;
			break;

		// Bolivia
		case '1203':
			indicative = 591;
			break;

		// Brasil
		case '1205':
			indicative = 55;
			break;

		// Canadá
		case '1214':
			indicative = 1;
			break;

		// Chile
		case '1227':
			indicative = 56;
			break;

		// Colombia
		case '1':
			indicative = 57;
			break;

		// Ecuador
		case '1234':
			indicative = 593;
			break;

		// E.E U.U
		case '1242':
			indicative = 1;
			break;

		// México
		case '1308':
			indicative = 52;
			break;

		// Paraguay
		case '1335':
			indicative = 595;
			break;

		// Perú
		case '1336':
			indicative = 51;
			break;

		// Uruguay
		case '1393':
			indicative = 598;
			break;

		// Venezuela
		case '1395':
			indicative = 58;
			break;

		default:
			indicative = undefined;
			break;
	}

	const vacunasSplit = vacunas.split(',');

	function createData(description, data) {
		return { description, data };
	}

	const rows = [
		createData(t('name'), nombre_mascota),
		createData(t('age'), `${edad_mascota} ${escala_edad}`),
		createData(t('race'), raza),
		createData(t('colour'), color),
		createData(t('gender'), tipo),
		createData(t('sterilized'), esterilizado),
		createData(t('country'), titleCase(pais)),
		createData(department, titleCase(departameto)),
		createData(municipality, titleCase(municipio)),
		createData(
			t('vaccines'),
			<ol className={classes.listVaccines}>
				{vacunasSplit.map((item, index) => (
					<li key={index}>
						{(item === 'Rabia' && t('rage')) ||
							(item === 'Rinotraqueítis' && t('rhinotracheitis')) ||
							(item === 'Parvovirus' && t('parvovirus')) ||
							(item === 'Moquillo' && t('distemper')) ||
							(item === 'Ninguna' && t('Nothing'))}
					</li>
				))}
			</ol>
		),
		createData(t('description'), descripcion_mascota)
	];

	const rows2 = [
		createData(t('main-phone'), '+' + indicative + ' ' + telefono1),
		createData(t('secondary-phone'), telefono2),
		createData(t('email'), correo)
	];

	// let petPhoto = userPetData.fotos;

	let arrayDeCadenas = 0;

	if (fotos.length !== 0) {
		arrayDeCadenas = fotos.split(',');
	}

	var ele1 = parseInt(arrayDeCadenas[0]);
	var ele2 = parseInt(arrayDeCadenas[1]);
	var ele3 = parseInt(arrayDeCadenas[2]);
	var ele4 = parseInt(arrayDeCadenas[3]);
	var ele5 = parseInt(arrayDeCadenas[4]);

	const [items, setItems] = useState([
		{
			imgPath: null
		}
	]);

	useEffect(() => {
		switch (arrayDeCadenas.length) {
			case 1:
				setItems([
					{
						imgPath: `${baseURL}${ele1}.jpg`
					}
				]);

				break;

			case 2:
				setItems([
					{
						imgPath: `${baseURL}${ele1}.jpg`
					},
					{
						imgPath: `${baseURL}${ele2}.jpg`
					}
				]);
				break;

			case 3:
				setItems([
					{
						imgPath: `${baseURL}${ele1}.jpg`
					},
					{
						imgPath: `${baseURL}${ele2}.jpg`
					},
					{
						imgPath: `${baseURL}${ele3}.jpg`
					}
				]);
				break;

			case 4:
				setItems([
					{
						imgPath: `${baseURL}${ele1}.jpg`
					},
					{
						imgPath: `${baseURL}${ele2}.jpg`
					},
					{
						imgPath: `${baseURL}${ele3}.jpg`
					},
					{
						imgPath: `${baseURL}${ele4}.jpg`
					}
				]);
				break;

			case 5:
				setItems([
					{
						imgPath: `${baseURL}${ele1}.jpg`
					},
					{
						imgPath: `${baseURL}${ele2}.jpg`
					},
					{
						imgPath: `${baseURL}${ele3}.jpg`
					},
					{
						imgPath: `${baseURL}${ele4}.jpg`
					},
					{
						imgPath: `${baseURL}${ele5}.jpg`
					}
				]);
				break;

			default:
				break;
		}
	}, [userPetData.fotos]);

	////////////////////////////////////////////////
	// dialog
	// const [open, setOpen] = React.useState(false);

	// const handleClickOpen = () => {
	// 	setOpen(true);
	// };

	// const handleClose = () => {
	// 	setOpen(false);
	// };
	////////////////////////////////////////////////

	///////////////////////////////////////////////////////////
	// Responder Pregunta

	const [answerData1, setAnswerData1] = useState({
		respuesta: ''
	});

	const [answerData, setAnswerData] = useState({
		respuesta: '',
		id: `${answerData1.respuesta}`
	});

	const handleChangeAnswer = (event) => {
		const { name, value } = event.target;
		setAnswerData({ ...answerData, [name]: value });

		const { id } = event.target;
		setAnswerData1({ ...answerData1, [name]: id });
	};

	useEffect(() => {
		setAnswerData({
			respuesta: `${answerData.respuesta}`,
			id: `${answerData1.respuesta}`
		});
	}, [answerData1]);

	const onSubmitAnswer = async (e) => {
		e.preventDefault();
		await dispatch(send_answer_pet_action(answerData));
		dispatch(get_question_answer_pet_action(questionData));
	};

	//////////////////////////////////////////////////////////////////

	const userEqual = user.id !== userPetData.id_usuario;
	const questionAnswer = questionAnswerData.form !== null;

	const responsiveMobile = useMediaQuery(theme.breakpoints.down('md'));

	// Notificaciones

	const Alert = React.forwardRef((props, ref) => (
		<MuiAlert elevation={6} variant="filled" {...props} ref={ref} />
	));

	function TransitionSnackbar(props) {
		return <Slide {...props} direction="up" />;
	}

	const [disableQuestion, setDisableQuestion] = useState();

	// Notificaciones respuesta
	const [state, setState] = useState({
		openSnackbar: false,
		vertical: 'bottom',
		horizontal: 'center'
	});

	const [transition, setTransition] = useState(undefined);

	const { vertical, horizontal, openSnackbar } = state;

	const handleCloseSnackbar = () => {
		setState({ ...state, openSnackbar: false });
	};

	const handleButtonClickSnackbar = (newState, Transition) => {
		setTimeout(() => {
			setTransition(() => Transition);
			setState({ openSnackbar: true, ...newState });
		}, 1000);
	};

	// Notificaciones pregunta
	const [stateQuestion, setStateQuestion] = useState({
		openSnackbarQuestion: false,
		verticalQuestion: 'bottom',
		horizontalQuestion: 'center'
	});

	const [transitionQuestion, setTransitionQuestion] = useState(undefined);

	const { verticalQuestion, horizontalQuestion, openSnackbarQuestion } =
		stateQuestion;

	const handleCloseSnackbarQuestion = () => {
		setStateQuestion({ ...stateQuestion, openSnackbarQuestion: false });
	};

	const handleButtonClickSnackbarQuestion = (newState, Transition) => {
		handleSubmitDialog();
		if (user.id) {
			setDisableQuestion(true);
			setTimeout(() => {
				setTimeout(() => {
					setDisableQuestion(false);
				}, 2000);
				setTransitionQuestion(() => Transition);
				setStateQuestion({ openSnackbarQuestion: true, ...newState });
			}, 1000);
		}
	};

	// Si no existe un id de mascota registrada retornar al path inicial
	const pet = useMemo(
		() => mascotas.find((pet) => pet.id_mascota === id),
		[mascotas]
	);

	useEffect(() => {
		if (mascotas.length > 0 && !pet) {
			Swal.fire({
				position: 'center',
				icon: 'error',
				html: `<h3>${t('pet-id')} <span style="color:#F27474;">${id}</span> ${t(
					'not-registered'
				)}</h3>`,
				showConfirmButton: false,
				timer: 3000
			});
			handleCloseDialog();
		}
	}, [mascotas]);

	// useEffect(() => {
	// 	if (mascotas.length > 0) {
	// 		const response = mascotas?.find((pet) => pet.id_mascota === id);
	// 		if (!response) {
	// 			handleCloseDialog();
	// 		}
	// 	}
	// }, [mascotas]);

	return (
		<>
			<Helmet>
				<meta property="og:url" content="http://www.nytimes.com/2015/02/19/arts/international/when-great-minds-dont-think-alike.html" />
				<meta property="og:type" content="article" />
				<meta property="og:title" content="When Great Minds Don’t Think Alike" />
				<meta property="og:description" content="How much does culture influence creative thinking?" />
				<meta property="og:image" content="http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg" />
			</Helmet>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={1000}
				onClose={handleCloseSnackbar}
				anchorOrigin={{ vertical, horizontal }}
				TransitionComponent={transition}
			>
				<Alert
					onClose={handleCloseSnackbar}
					severity={!!answer ? 'success' : 'error'}
				>
					{!!answer ? t('success-response') : t('error-response')}
				</Alert>
			</Snackbar>

			<Snackbar
				open={openSnackbarQuestion}
				autoHideDuration={1000}
				onClose={handleCloseSnackbarQuestion}
				anchorOrigin={{
					vertical: verticalQuestion,
					horizontal: horizontalQuestion
				}}
				TransitionComponent={transitionQuestion}
			>
				<Alert
					onClose={handleCloseSnackbarQuestion}
					severity={
						question === null || errors?.mensaje?.message ? 'error' : 'success'
					}
				>
					{question === null || errors?.mensaje?.message
						? t('error-question')
						: t('success-question')}
				</Alert>
			</Snackbar>

			{userPetData ? (
				<Dialog
					style={{ zIndex: 1 }}
					fullScreen={fullScreenResponsive}
					open={userPetModalData === true}
					onClose={handleCloseDialog}
					className={classes.dialogContainer}
				>
					<Grid container justify="flex-end">
						<Grid item>
							<Tooltip title={t('close')} >
								<IconButton
									className={classes.closeIconButton}
									edge="end"
									color="primary"
									aria-label="close"
									onClick={handleCloseDialog}
								>
									<CloseIcon className={classes.closeButton} />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>

					<Grid
						container
						className={classes.marginBottomContainer}
						justify="center"
					>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
							<Grid container justify="center">
								<Grid
									item
									xs={12}
									container
									justify="center"
									spacing={responsiveMobile ? 5 : 0}
									className={classes.marginBottomContainer}
								>
									<Grid item xs={12} sm={12} md={8} lg={3} xl={2}>
										<Grid
											container
											spacing={2}
											direction={responsiveMobile ? 'row' : 'column'}
											alignItems="center"
											justify="center"
											style={{
												backgroundSize: 'contain'
											}}
										>
											{items.map((prueba) => (
												<Grid item key={prueba.imgPath}>
													<Card>
														<CardMedia>
															<img
																onClick={() => window.open(`${prueba.imgPath}`)}
																src={prueba.imgPath}
																title={t('click-text')}
																alt=""
																className={classes.cardMedia}
															/>
														</CardMedia>
													</Card>
												</Grid>
											))}
										</Grid>
									</Grid>

									<Grid item xs={12} sm={12} md={10} lg={7} xl={6}>
										<Card>
											<CarouselPhotos
												itemPets={userPetData.fotos}
												itemProcedure={userPetData.tipo_tramite}
											/>
										</Card>
									</Grid>
								</Grid>

								<Grid
									item
									xs={12}
									sm={12}
									md={8}
									lg={10}
									xl={8}
									className={
										responsiveMobile ? classes.marginBottomContainer : null
									}
								>
									<TableContainer component={Paper}>
										<Table className={classes.table} aria-label="simple table">
											<TableHead>
												<TableRow>
													<TableCell>{t('description-pet')}</TableCell>
													<TableCell>{t('information-pet')}</TableCell>
												</TableRow>
											</TableHead>
											<TableBody className={classes.tr}>
												{rows.map((row) => (
													<TableRow key={row.description}>
														<TableCell
															component="th"
															scope="row"
															className={classes.primaryColor}
														>
															{row.description}
														</TableCell>
														<TableCell>{row.data}</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									</TableContainer>
								</Grid>
							</Grid>
						</Grid>

						<Grid item xs={12} sm={12} md={8} lg={5} xl={4}>
							<Grid container className={classes.marginBottomContainer}>
								<TableContainer component={Paper}>
									<Table className={classes.table} aria-label="simple table">
										<TableHead>
											<TableRow>
												<TableCell>{t('description-owner')}</TableCell>
												<TableCell>{t('information-owner')}</TableCell>
											</TableRow>
										</TableHead>
										<TableBody className={classes.tr}>
											{rows2.map((row) => (
												<TableRow key={row.description}>
													<TableCell
														component="th"
														scope="row"
														className={classes.primaryColor}
													>
														{row.description}
													</TableCell>
													<TableCell>{row.data}</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>

							<Paper
								className={classes.paperContainer}
								style={{
									height: questionAnswer
										? userEqual && questionAnswerData.length > 10
											? 995
											: !userEqual && questionAnswerData.length > 10
												? 995
												: 'auto'
										: 'auto',
									overflowY: 'auto'
								}}
							>
								{user.id !== userPetData.id_usuario ? (
									<form
										onSubmit={handleSubmitQuestion(onSubmitQuestion)}
										autoComplete="off"
									>
										<Grid container spacing={3}>
											<Grid item xs={12}>
												<Typography gutterBottom className={classes.textFont}>
													{t('ask-adopter')}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={9} lg={9}>
												<TextField
													id="outlined-helperText"
													label={t('write-question')}
													name="mensaje"
													disabled={disableQuestion}
													variant="outlined"
													fullWidth
													multiline
													rowsMax={4}
													inputRef={register({
														required: {
															value: true,
															message: t('complete-validation')
														},
														minLength: {
															value: 10,
															message: t('question-validation')
														}
													})}
													helperText={
														errors?.mensaje?.message ||
														`${petdata.mensaje.length} / 200`
													}
													error={
														errors?.mensaje?.message && !disableQuestion
															? true
															: false
													}
													onChange={handleChangeMessage}
													inputProps={{ maxLength: 200 }}
													FormHelperTextProps={{
														style: { textAlign: 'end', margin: 0 }
													}}
												/>
											</Grid>
											<Grid item xs={12} sm={3} lg={3}>
												<Button
													fullWidth
													type="submit"
													color="primary"
													variant="contained"
													id="buttonWhite"
													onClick={() => {
														handleButtonClickSnackbarQuestion(
															{
																verticalQuestion: 'bottom',
																horizontalQuestion: 'right'
															},
															TransitionSnackbar
														);
													}}
												>
													{t('ask')}
												</Button>
											</Grid>
										</Grid>
									</form>
								) : null}

								<Grid
									container
									direction="column"
									spacing={3}
									style={{
										marginTop: 12
									}}
								>
									<Grid item>
										<Typography gutterBottom className={classes.textFont}>
											{t('last-question')}
										</Typography>
									</Grid>

									{questionAnswer ? (
										questionAnswerData.map(
											({ id, mensaje, fecha_envio, respuesta }) =>
												userEqual ? (
													<Grid
														item
														key={id}
														style={{
															padding: 12,
															wordBreak: 'break-all'
														}}
													>
														<Typography>{mensaje}</Typography>
														<Typography
															variant="body2"
															style={{
																color: '#8f8f8f'
															}}
														>
															<QuestionAnswerIcon
																color="primary"
																style={{
																	marginRight: 8
																}}
															/>
															{respuesta !== null ? (
																respuesta
															) : (
																<span className={classes.primaryColor}>
																	Respuesta Pendiente
																</span>
															)}
															{''}
															<span
																style={{
																	color: '#63c132',
																	marginLeft: 10
																}}
															>
																{fecha_envio}
															</span>
														</Typography>
														<Divider />
													</Grid>
												) : (
													<>
														<form
															key={id}
															onSubmit={/* handleSubmitAnswer */ onSubmitAnswer}
														>
															<Grid
																item
																style={{
																	padding: 12
																}}
															>
																<Typography
																	style={{
																		wordBreak: 'break-all',
																		marginBottom: 15
																	}}
																	color="primary"
																	variant="body2"
																	gutterBottom
																>
																	{mensaje}
																</Typography>

																<Grid container alignItems="center" spacing={3}>
																	<Grid item xs={12} sm={9} lg={9}>
																		<TextField
																			label={t('write-answer')}
																			name="respuesta"
																			variant="outlined"
																			fullWidth
																			id={`${id}`}
																			onChange={handleChangeAnswer}
																			defaultValue={respuesta}
																		// inputRef={registerAnswer({
																		// 	required: {
																		// 		value: true,
																		// 		message: 'Completa este dato'
																		// 	},
																		// 	minLength: {
																		// 		value: 10,
																		// 		message: 'Mínimo 10 carácteres!'
																		// 	},
																		// 	maxLength: {
																		// 		value: 200,
																		// 		message: 'Máximo 200 carácteres!'
																		// 	}
																		// })}
																		// helperText={
																		// 	errorsAnswer?.respuesta?.message
																		// }
																		// error={
																		// 	errorsAnswer?.respuesta?.message
																		// 		? true
																		// 		: false
																		// }
																		/>
																	</Grid>
																	<Grid item xs={12} sm={3} lg={3}>
																		<Button
																			fullWidth
																			style={{
																				color: '#fff'
																			}}
																			type="submit"
																			color="primary"
																			variant="contained"
																			onClick={() => {
																				handleButtonClickSnackbar(
																					{
																						vertical: 'bottom',
																						horizontal: 'right'
																					},
																					TransitionSnackbar
																				);
																			}}
																		>
																			{respuesta ? t('update') : t('answer')}
																		</Button>
																	</Grid>
																</Grid>
															</Grid>
														</form>
														<Divider />
													</>
												)
										)
									) : (
										<Grid
											xs={12}
											item
											style={{
												padding: 12
											}}
										>
											<Typography>{t('no-question')}</Typography>
										</Grid>
									)}
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Dialog>
			) : null}

			<div style={{ display: 'none' }}>
				<Header />
			</div>
		</>
	);
}
