import React, { useEffect, useState } from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
// Css
import './App.css';
// Redux
import { Provider } from 'react-redux';
import store from './redux/store';
// Pages
import SaciDashboard from './pages/SaciDashboard';
import Profile from './pages/Profile';
//theme
import mainTheme from './assets/css/js/mainTheme';
import MyAdoptRequest from './pages/MyAdoptRequest';
import OutputRequest from './pages/OutputRequest';
import UserPets from './pages/UserPets';
import PetInformation from './pages/PetInformation';
import PwaComponent from './components/pwaComponent/PwaComp'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import AuthGoogle from './pages/AuthGoogle';
import NotFound from './pages/NotFound';
import AuthFB from './pages/AuthFB';

function App() {

	const theme = mainTheme;

	const [isReadyForInstall, setIsReadyForInstall] = useState(false);
	const [deferredPrompt, setDeferredPrompt] = useState(null);

	useEffect(() => {
		window.addEventListener("beforeinstallprompt", (event) => {
			// Prevent the mini-infobar from appearing on mobile.
			event.preventDefault();

			// Stash the event so it can be triggered later.
			window.deferredPrompt = event;
			// Remove the 'hidden' class from the install button container.
			setIsReadyForInstall(true);
		});


		window.addEventListener('appinstalled', () => {
			// Esconder la promoción de instalación de la PWA
			setIsReadyForInstall(false);
			// Limpiar el defferedPrompt para que pueda ser eliminado por el recolector de basura
			setDeferredPrompt(null)
		});
	}, []);

	return (
		<Provider store={store}>
			<ThemeProvider theme={theme}>
				<I18nextProvider i18n={i18n}>
					<Router>
						<>
							<Switch>
								<Route exact path="/" component={SaciDashboard} />
								<Route path="/Profile" component={Profile} />
								<Route path="/entry_requests" component={MyAdoptRequest} />
								<Route path="/exit_requests" component={OutputRequest} />
								<Route path="/my_pets" component={UserPets} />
								<Route path="/authgoogle" component={AuthGoogle} />
								<Route path="/loginFacebook" component={AuthFB} />
											<Route
									exact
									path="/pet-information/:id"
									component={PetInformation}
								/>
								{/* Ruta para manejar páginas no encontradas (404) */}
								<Route component={NotFound} />
								<Redirect to="/" />
							</Switch>
						</>
					</Router>
				</I18nextProvider>
				<PwaComponent isReadyForInstall={isReadyForInstall} deferredPrompt={deferredPrompt} />
			</ThemeProvider>
		</Provider>
	);
}

export default App;
