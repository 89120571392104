import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import { Grid, Typography, useMediaQuery, Hidden } from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';

import { ReactComponent as IconAdopt3 } from '../../../assets/svg/icono3.svg';
import { ReactComponent as IconAdopt2 } from '../../../assets/svg/icono2.svg';
import { ReactComponent as IconAdopt1 } from '../../../assets/svg/icono1.svg';
import wallpaper_large from '../../../assets/svg/wallpaper_large.svg';
import wallpaper_small from '../../../assets/svg/wallpaper_small.svg';
import {
	adoptstepper_dialog_open_action,
	adopt_dialog_open_action
} from '../../../redux/actions/loginAction';

import { useDispatch } from 'react-redux';

import { no_user_pets_action } from '../../../redux/actions/saciPets';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	speedDial: {
		'&.MuiSpeedDial-directionRight': {
			// left: theme.spacing(10),
			justifyContent: 'center'
		}
	},

	textXS: {
		margin: '10px 10px 40px'
	},

	textSM: {
		margin: 10
	},

	textMD: {
		margin: 20
	},

	textLGXL: {
		margin: 20
	}
}));

export default function PostPet() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const classes = useStyles();

	const handleClickOpenModal = () => {
		dispatch(adopt_dialog_open_action({ tipo_tramite: 1 }));
		dispatch(adoptstepper_dialog_open_action());
	};

	const handleClickOpenModalRecover = () => {
		dispatch(no_user_pets_action());
		dispatch(adopt_dialog_open_action({ tipo_tramite: 2 }));
		dispatch(adoptstepper_dialog_open_action());
	};

	const handleClickOpenModalLost = () => {
		dispatch(no_user_pets_action());
		dispatch(adopt_dialog_open_action({ tipo_tramite: 3 }));
		dispatch(adoptstepper_dialog_open_action());
	};

	const CustomSpeedDial = () => {
		const actions = [
			{
				icon: <IconAdopt1 width={60} />,
				name: t('give-in'),
				action: handleClickOpenModal
			},
			{
				icon: <IconAdopt2 width={60} />,
				name: t('found-pet'),
				action: handleClickOpenModalRecover
			},
			{
				icon: <IconAdopt3 width={60} />,
				name: t('lost-pet'),
				action: handleClickOpenModalLost
			}
		];

		const [open, setOpen] = useState(false);

		const handleClose = () => {
			setOpen(false);
		};

		const handleOpen = () => {
			setOpen(true);
		};

		return (
			<SpeedDial
				ariaLabel="SpeedDial example"
				className={classes.speedDial}
				icon={<SpeedDialIcon />}
				onClose={handleClose}
				onOpen={handleOpen}
				open={open}
				direction={'right'}
				FabProps={{
					style: {
						width: '40px', height: '40px'
					}
				}}
				style={{ margin: '0 auto', marginLeft: !responseXS && '300px' }}
			>
				{
					actions.map((action) => (
						<SpeedDialAction
							style={{ margin: '0 auto' }}
							key={action.name}
							icon={action.icon}
							tooltipTitle={action.name}
							onClick={() => {
								action.action();
								handleClose();
							}}
						/>
					))
				}
			</SpeedDial >
		);
	};

	const theme = useTheme();

	const responseXS = useMediaQuery(theme.breakpoints.only('xs'));
	const responseSM = useMediaQuery(theme.breakpoints.only('sm'));
	const responseMD = useMediaQuery(theme.breakpoints.only('md'));
	const responseLG = useMediaQuery(theme.breakpoints.only('lg'));
	const responseXL = useMediaQuery(theme.breakpoints.only('xl'));

	const responseLGXL = useMediaQuery(theme.breakpoints.between('lg', 'xl'));

	const matches = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Grid
			container
			justify="center"
			alignItems="center"
			style={{
				height:
					((responseSM || responseMD) && '40vh') ||
					(responseLG && '30vh') ||
					(responseXL && '50vh')
			}}
		>
			<Hidden
				smDown
			>
				<img
					style={{ position: 'absolute', marginTop: '11.4em', height: '90vh', width: '100%', }}
					src={wallpaper_large}
				/>
			</Hidden>
			<Hidden
				mdUp
			>
				<img
					style={{ position: 'absolute', marginTop: '8em', height: '90vh', width: '100%', }}
					src={wallpaper_small}
				/>
			</Hidden>
			<Hidden
				smDown
			>
				<Grid item xs={12}>
					<Typography
						style={{ margin: '50px auto', position: 'relative', width: '600px' }}
						variant={matches ? 'body1' : 'h4'}
						color="initial"
						align="center"
						className={
							(responseXS && classes.textXS) ||
							(responseSM && classes.textSM) ||
							(responseMD && classes.textMD) ||
							(responseLGXL && classes.textLGXL)
						}
					>
						{t('no-pets-published')}
						<CustomSpeedDial />
					</Typography>
				</Grid>
			</Hidden>
			<Hidden
				mdUp
			>
				<Grid item xs={12}>
					<Typography
						style={{ position: 'relative', width: '280px', margin: '110px auto' }}
						variant={matches ? 'body1' : 'h4'}
						color="initial"
						className={
							(responseXS && classes.textXS) ||
							(responseSM && classes.textSM) ||
							(responseMD && classes.textMD) ||
							(responseLGXL && classes.textLGXL)
						}
					>
						{t('no-pets-published')}
						<CustomSpeedDial />
					</Typography>
				</Grid>
			</Hidden>
		</Grid>
	);
}
