import React from 'react'
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { pwaDataAction } from "../../redux/actions/mainAction";

// Material UI Components

const PwaComp = ({ isReadyForInstall, deferredPrompt }) => {

    //Dispatch stance
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(pwaDataAction({
            isReadyForInstall: isReadyForInstall,
            deferredPrompt: deferredPrompt
        }))
    }, [isReadyForInstall])
    
    return (
        <h1 style={{ display: "none" }}>
        </h1>
    )
}

export default PwaComp;
