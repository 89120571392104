import React from 'react';

import {
	Tabs,
	Tab,
	Box,
	Typography,
	useMediaQuery,
	useTheme,
	makeStyles,
	AppBar
} from '@material-ui/core';

import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { set_publish_pet_state_action } from '../../../redux/actions/userPetsAction';

import { ReactComponent as IconPetsPosted } from '../../../assets/icons/userpets/IconPetsPosted.svg';
import { ReactComponent as IconDisablePets } from '../../../assets/icons/userpets/IconDisablePets.svg';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.only('xs'));

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={matches ? 0 : 3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper
	}
}));

export default function UserPetFilter() {
	const [t] = useTranslation('global');

	const classes = useStyles();

	const theme = useTheme();

	const dispatch = useDispatch();

	const handleClickPublishPets = () => {
		dispatch(set_publish_pet_state_action('1'));
	};

	const handleClickUnpublishPets = () => {
		dispatch(set_publish_pet_state_action('0'));
	};

	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const matches = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<div className={classes.root}>
			<AppBar position="static" color="default">
				<Tabs
					value={value}
					onChange={handleChange}
					orientation={matches ? 'horizontal' : 'vertical'}
					variant={'standard'}
					scrollButtons="on"
					aria-label="Vertical tabs example"
					indicatorColor="primary"
					textColor="primary"
					centered
				>
					<Tab
						label={matches ? t('posted') : t('published-pets')}
						icon={<IconPetsPosted width={40} />}
						{...a11yProps(0)}
						onClick={handleClickPublishPets}
					/>
					<Tab
						label={matches ? t('deactivated') : t('disabled-pets')}
						icon={<IconDisablePets width={40} />}
						{...a11yProps(1)}
						onClick={handleClickUnpublishPets}
					/>
				</Tabs>
			</AppBar>
		</div>
	);
}
