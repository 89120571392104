/* eslint-disable react-hooks/exhaustive-deps */
// Hooks
import React, { useState, useRef, useEffect } from 'react';

// Clsx
import clsx from 'clsx';

// Form Validation
import { Controller, useForm } from 'react-hook-form';

// Dispach Redux
import { useDispatch, useSelector } from 'react-redux';

// redux Actions
import {
	register_dialog_close_action,
	save_register_to_login,
	generate_registry_code_action,
	verify_registry_code_action,
	register_error_clean_action
} from '../redux/actions/registerAction';

import { loginGoogleAction } from '../redux/actions/googleAction';

import { loginFacebookAction } from '../redux/actions/facebookAction';

import Loader from './Loader';

// Material UI
import {
	FormControlLabel,
	TextField,
	Grid,
	Checkbox,
	Button,
	Typography,
	Dialog,
	FormHelperText
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

//Styles
import registerStyles from './../assets/css/js/registerStyles';

// Icons
import iconPhone from '../assets/icons/phone-final.svg';
import iconEmail from '../assets/icons/email-final.svg';
import iconPassword from '../assets/icons/lock-final.svg';
import iconPassword2 from '../assets/icons/lock-2-final.svg';
import logoSend from '../assets/icons/registration.svg';
import iconFacebook from '../assets/icons/facebook-final.svg';
import iconGoogle from '../assets/icons/google-final.svg';
import iconFind from '../assets/icons/drawer/iconFind-final.svg';
// import bb from '../assets'
import petUser from '../assets/icons/drawer/petUser-final.svg';

// Google Button
import GoogleLogin from 'react-google-login';

// Facebook Button
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

// reduc login
import {
	login_dialog_open_action,
	login_error_clean_action,
	set_check_email_modal_action
} from '../redux/actions/loginAction';
import CheckEmailModal from '../components/CheckEmailModal';
import { useTranslation } from 'react-i18next';
import { titleCase } from '../hooks/useTitleCase';
import Swal from 'sweetalert2';
import 'react-phone-input-2/lib/material.css';
import PhoneInput from 'react-phone-input-2';
import ConditionDialog from '../components/SaciComponents/ConditionDialog';
import { useDialog } from '../hooks/useDialog';
import es from 'react-phone-input-2/lang/es.json';
import pt from 'react-phone-input-2/lang/pt.json';
import {
	InputAdornmentFirst,
	InputAdornmentSecond
} from '../components/SaciComponents/FormComponents/CustomInputAdornment';
import { useInputAdornment } from '../hooks/useInputAdornment';
import axiosClient from '../configAxios/axios';
// import i18next from 'i18next';

////////////////////////////////////////////////////////////////////////////////////

export default function Register() {
	const [t, i18n] = useTranslation('global');
	const theme = useTheme();

	const { loader, error, emailErrorMsg } = useSelector(
		(state) => state.register
	);
	const dispatch = useDispatch();

	const { checkEmailModal, errorGoogle } = useSelector((state) => state.login);
	const { countries } = useSelector((state) => state.main);
	const [countryValue, setCountryValue] = useState('');
	const [idCountry, setIdCountry] = useState('');
	const [registrySuccess, setRegistrySuccess] = useState(false);
	const [registerToLogin, setRegisterToLogin] = useState();

	// Check Validation
	const [isChecked, setIsChecked] = useState(false);
	const [checkRed, setCheckRed] = useState(false);
	const [verifyMsg, setVerifyMsg] = useState('');
	const [submitCode, setSubmitCode] = useState(false);
	const fullScreenResponsive = useMediaQuery(theme.breakpoints.down('sm'));
	const matches = useMediaQuery(theme.breakpoints.only('xs'));
	const { open, check, handleClickOpen, handleClose } = useDialog();

	// Styles
	const classes = registerStyles();

	// Field Validation
	const [newUser, setnewUser] = useState({
		primer_nombre: '',
		telefono: '',
		correo: '',
		password: '',
		passwordCheck: '',
		firewall: 'firewall',
		ubicacion: '',
		token_registro: 'token_registro',
		hora_registro: 'hora_registro'
	});

	const [verifyCode, setVerifyCode] = useState('');

	useEffect(() => {
		if (countryValue?.length !== 0) {
			let idCountry = countries.filter(
				(item) => titleCase(item.descripcion) === countryValue
			);
			if (idCountry.length !== 0) {
				setIdCountry(idCountry[0].id_codigo);
			}
		}
	}, [countryValue]);

	useEffect(() => {
		if (idCountry.length !== 0) {
			setnewUser({ ...newUser, ubicacion: idCountry });
		}
	}, [idCountry]);

	// Input Event
	const handleChange = (event) => {
		const { name, value } = event.target;
		setnewUser({ ...newUser, [name]: value });
	};

	const handleChangeNumber = (value, country) => {
		let code = country.dialCode;
		setnewUser({
			...newUser,
			telefono:
				value.slice(
					0,
					code <= 9
						? 1
						: code >= 10 && code <= 99
							? 2
							: code >= 100 && code <= 999
								? 3
								: 4
				) +
				' ' +
				value.slice(
					code <= 9
						? 1
						: code >= 10 && code <= 99
							? 2
							: code >= 100 && code <= 999
								? 3
								: 4
				)
		});
	};

	// Input Submit
	const onSubmit = (data, e) => {
		if (isChecked === true) {
			_handleSubmit({ ...newUser });
			// e.target.reset();
		} else {
			return;
		}
	};

	// Validation Form Register
	const { register, errors, handleSubmit, watch, control, reset } = useForm({
		mode: 'onChange'
	});

	// Password Validation
	const password = useRef({});
	password.current = watch('password', '');

	// verification email
	async function getVerifyCodeResponse() {
		const response = await dispatch(
			verify_registry_code_action({ ...newUser, codigo: verifyCode })
		);
		if (response?.code === '1') {
			setRegistrySuccess(true);
			setVerifyMsg(response.msg.trim() && t('registration-successfully'));
		} else {
			setVerifyMsg(response.msg && t('invalid-code-email'));
		}
	}

	// verification code
	useEffect(() => {
		if (submitCode) {
			setTimeout(() => {
				getVerifyCodeResponse();
			}, 1500);
		} else {
			setVerifyMsg(t('validating'));
		}
		setSubmitCode(false);
	}, [submitCode]);

	// Check Validation to register
	const handleCheckRegister = () => {
		if (isChecked === true) {
			setCheckRed(false);
		} else {
			setCheckRed(true);
		}
	};

	// Check Validation
	useEffect(() => {
		if (check === true) {
			setCheckRed(false);
			setIsChecked(true);
		} else if (check === false) {
			setCheckRed(true);
			setIsChecked(false);
		}
	}, [check]);

	//Redux Actions
	const _handleSubmit = async (data) => {
		generateCode();
	};

	// generate Code
	const generateCode = async () => {
		setVerifyMsg(t('validating'));
		const response = await dispatch(generate_registry_code_action(newUser));
		if (response) {
			setVerifyMsg(response.msg && t('verification-code-sent'));
			dispatch(set_check_email_modal_action(true));
		}
	};

	// Redux Google
	const responseGoogle = (data) => {
		if (data.googleId) {
			dispatch(loginGoogleAction(data));
		}
	};

	// Redux Facebook
	const responseFacebook = (data) => {
		if (data.user) {
			dispatch(loginFacebookAction(data));
		}
	};

	// Redux Open Dialogs
	const openLogin = () => {
		dispatch(register_dialog_close_action());
		dispatch(login_dialog_open_action());
	};

	useEffect(() => {
		if (registerToLogin) {
			dispatch(
				save_register_to_login({
					correo: newUser.correo,
					password: newUser.password,
					primer_nombre: newUser.primer_nombre
				})
			);
			setRegisterToLogin(false);
			dispatch(register_dialog_close_action());
		}
	}, [registerToLogin]);

	const optionsCountries = countries.map((option) => {
		const firstLetter = option.descripcion[0].toUpperCase();
		return {
			firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
			...option
		};
	});

	useEffect(() => {
		if (error) {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				html: `<span style="color:#F27474;">${emailErrorMsg}</span>`,
				confirmButtonColor: '#63C132'
			}).then(() => {
				dispatch(register_error_clean_action());
				setnewUser({ ...newUser, correo: '' });
				reset({ ...newUser, correo: '' });
			});
		}
	}, [error]);

	useEffect(() => {
		if (errorGoogle) {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: t('already-registered-google'),
				confirmButtonColor: '#63C132'
			}).then(() => {
				dispatch(login_error_clean_action());
			});
		}
	}, [errorGoogle]);

	const lang = i18n.language;
	const onlyCountries = [
		'ar',
		'bo',
		'br',
		'ca',
		'cl',
		'co',
		've',
		'ec',
		'us',
		'mx',
		'py',
		'pe',
		'uy',
		've'
	];

	// Custom Hook Password Visibility
	const {
		showPassword1,
		handleClickShowPassword1,
		handleMouseDownPassword1,
		showPassword2,
		handleClickShowPassword2,
		handleMouseDownPassword2
	} = useInputAdornment();

	function navigate (url) {
		window.location.href = url
	}

	async function auth () {
		const response = await axiosClient.get('/generateAuthUrl')
		//const data = await response.json()
		//console.log(response)
		navigate(response.data.url)
	}

	return (
		<>
			{loader && <Loader />}
			<Grid container className={classes.containerForm}>
				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<Typography
						align="center"
						variant="h4"
						gutterBottom
						className={classes.titleForm}
					>
						{t('sign-up')}
					</Typography>
					<Grid container alignItems="center" spacing={2}>
						<Grid item xs={2} className={classes.iconsCenter}>
							<img src={petUser} alt="Usuario" className={classes.icons1} />
						</Grid>
						<Grid item xs={10}>
							<TextField
								label={t('name')}
								type="text"
								name="primer_nombre"
								variant="outlined"
								fullWidth
								inputRef={register({
									required: { value: true, message: t('complete-validation') },
									minLength: { value: 3, message: t('minimum-validation') },
									maxLength: { value: 40, message: t('maximum-validation') },
									pattern: {
										value: /^[a-zA-ZÁ-ÿ\s]{1,40}$/,
										message: t('letters-validation')
									}
								})}
								helperText={errors?.primer_nombre?.message}
								error={errors?.primer_nombre?.message ? true : false}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={2} className={classes.iconsCenter}>
							<img src={iconPhone} alt="Teléfono" className={classes.icons1} />
						</Grid>
						<Grid item xs={10}>
							<Controller
								render={(props) => (
									<PhoneInput
										onlyCountries={onlyCountries}
										localization={
											lang === 'es' || lang === 'es-419'
												? es
												: lang === 'pt'
													? pt
													: false
										}
										country={'co'}
										enableSearch
										name="telefono"
										specialLabel={false}
										placeholder={t('telephone')}
										searchPlaceholder="Buscar..."
										searchNotFound="No ha sido encontrado"
										disableSearchIcon={true}
										inputStyle={{
											width: '100%',
											height: '56px'
										}}
										dropdownStyle={{
											width: matches ? 'auto' : '420px',
											maxHeight: '270px'
										}}
										isValid={(value, country) => {
											if (errors.telefono) {
												return false;
											} else {
												return true;
											}
										}}
										inputRef={register}
										onChange={(value, country) => {
											props.onChange(value);
											handleChangeNumber(value, country);
										}}
										error={!!errors.telefono}
									/>
								)}
								defaultValue=""
								name="telefono"
								control={control}
								rules={{
									required: true,
									minLength: 7
								}}
							/>
							<FormHelperText error={!!errors.telefono}>
								{errors?.telefono?.type === 'minLength' &&
									t('phone-validation')}
								{errors?.telefono?.type === 'required' &&
									t('complete-validation')}
							</FormHelperText>
						</Grid>
						<Grid item xs={2} className={classes.iconsCenter}>
							<img src={iconEmail} alt="Email" className={classes.icons1} />
						</Grid>
						<Grid item xs={10}>
							<TextField
								label={t('email')}
								type="email"
								name="correo"
								variant="outlined"
								fullWidth
								inputRef={register({
									required: { value: true, message: t('complete-validation') },
									pattern: {
										value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
										message: t('email-validation')
									}
								})}
								helperText={errors?.correo?.message}
								error={errors?.correo?.message ? true : false}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={2} className={classes.iconsCenter}>
							<img src={iconFind} alt="País" className={classes.icons1} />
						</Grid>
						<Grid item xs={10}>
							<Controller
								name="country"
								as={
									<Autocomplete
										fullWidth
										inputValue={countryValue}
										onInputChange={(event, newInputValue) => {
											setCountryValue(newInputValue);
										}}
										noOptionsText={t('country-not-found')}
										options={optionsCountries}
										groupBy={(option) => option.firstLetter}
										getOptionLabel={(option) => titleCase(option.descripcion)}
										getOptionSelected={(option, value) =>
											option.descripcion === value.descripcion
										}
										renderInput={(params) => (
											<TextField
												{...params}
												label={t('country')}
												variant="outlined"
												error={!!errors.country}
												helperText={errors.country && t('complete-validation')}
											/>
										)}
									/>
								}
								control={control}
								rules={{
									required: true
								}}
							/>
						</Grid>
						<Grid item xs={2} className={classes.iconsCenter}>
							<img
								src={iconPassword}
								alt="Contraseña"
								className={classes.icons2}
							/>
						</Grid>
						<Grid item xs={10}>
							<TextField
								label={t('password')}
								name="password"
								type={showPassword1 ? 'text' : 'password'}
								variant="outlined"
								fullWidth
								InputProps={{
									autoComplete: 'new-password',
									endAdornment: (
										<InputAdornmentFirst
											handleClickShowPassword1={handleClickShowPassword1}
											handleMouseDownPassword1={handleMouseDownPassword1}
											showPassword1={showPassword1}
										/>
									)
								}}
								inputRef={register({
									required: { value: true, message: t('complete-validation') },
									pattern: {
										value: /^.{5,15}$/,
										message: t('password-validation')
									}
								})}
								helperText={errors?.password?.message}
								error={errors?.password?.message ? true : false}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={2} className={classes.iconsCenter}>
							<img
								src={iconPassword2}
								alt="Contraseña"
								className={classes.icons1}
							/>
						</Grid>
						<Grid item xs={10}>
							<TextField
								label={t('repeat-password')}
								name="passwordCheck"
								type={showPassword2 ? 'text' : 'password'}
								variant="outlined"
								fullWidth
								InputProps={{
									autoComplete: 'new-password',
									endAdornment: (
										<InputAdornmentSecond
											handleClickShowPassword2={handleClickShowPassword2}
											handleMouseDownPassword2={handleMouseDownPassword2}
											showPassword2={showPassword2}
										/>
									)
								}}
								inputRef={register({
									required: { value: true, message: t('complete-validation') },
									validate: (value) =>
										value === password.current || t('same-password')
								})}
								helperText={errors?.passwordCheck?.message}
								error={errors?.passwordCheck?.message ? true : false}
								onChange={handleChange}
							/>
						</Grid>
					</Grid>
					<Grid container justify="center">
						<FormControlLabel
							className={clsx(classes.buttonSecondary2, {
								[classes.checkboxRojo]: checkRed
							})}
							value="start"
							control={
								<Checkbox checked={isChecked} color="primary" name="checkbox" />
							}
							label={t('accepts-terms-conditions')}
							onClick={handleClickOpen}
							labelPlacement="end"
						/>
						<Button
							onClick={handleCheckRegister}
							className={classes.buttonPrimary}
							variant="contained"
							size="large"
							endIcon={
								<img src={logoSend} alt="SignUp" className={classes.icons2} />
							}
							fullWidth
							type="submit"
						>
							{t('register')}
						</Button>
						<span className={classes.containerLine}>
							──────────── O ────────────
						</span>
					{/* 	<FacebookLogin
							appId="398513521394376"
							autoLoad={false}
							callback={responseFacebook}
							fields="name,email,picture"
							render={(renderProps) => (
								<Button
									className={classes.buttonFacebook}
									variant="contained"
									size="large"
									fullWidth
									startIcon={
										<img
											src={iconFacebook}
											alt="Facebook"
											className={classes.icons2}
										/>
									}
									onClick={renderProps.onClick}
								>
									<Grid container justify="center">
										{t('regiter-facebook')}
									</Grid>
								</Button>
							)}
						/> */}
					{/* 	<GoogleLogin
							clientId="494877024211-47b2uf07s91dc0cos4rtec805knm8ls5.apps.googleusercontent.com"
							buttonText="Login"
							onSuccess={responseGoogle}
							onFailure={responseGoogle}
							cookiePolicy={'single_host_origin'}
							render={(renderProps) => ( */}
								<Button
									className={classes.buttonGmail}
									variant="contained"
									size="large"
									fullWidth
									startIcon={
										<img
											src={iconGoogle}
											alt="Google"
											className={classes.icons1}
										/>
									}
									onClick={auth}
								>
									<Grid container justify="center">
										{t('register-google')}
									</Grid>
								</Button>
						{/* 	)}
						/> */}
						<Button
							variant="text"
							size="small"
							className={classes.buttonSecondary3}
							onClick={openLogin}
						>
							{t('have-account')}
						</Button>
					</Grid>
				</form>
			</Grid>
			<Dialog
				open={checkEmailModal}
				onClose={() => set_check_email_modal_action(false)}
				fullScreen={fullScreenResponsive}
			>
				<Grid
					container
					alignItems="center"
					className={classes.checkEmailContainer}
				>
					<Grid item xs={12}>
						<CheckEmailModal
							submitCode={submitCode}
							setSubmitCode={setSubmitCode}
							setVerifyCode={setVerifyCode}
							verifyMsg={verifyMsg}
							registrySuccess={registrySuccess}
							setRegisterToLogin={setRegisterToLogin}
							generateCode={generateCode}
						/>
					</Grid>
				</Grid>
			</Dialog>
			<ConditionDialog open={open} onClose={handleClose} />
		</>
	);
}
