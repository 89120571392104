/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import Swal from 'sweetalert2';

import { Avatar, Grid, Paper, Typography } from '@material-ui/core';

import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@material-ui/lab';

import { makeStyles } from '@material-ui/core/styles';

import UpdateIcon from '@material-ui/icons/Update';

import CancelIcon from '@material-ui/icons/Cancel';

import ChatIcon from '@material-ui/icons/Chat';

import ShowRequest from '../components/SaciComponents/UserComponents/AdoptRequest/ShowRequest';

import {
	adopt_me_dialog_action,
	adopt_me_dialog_procedure_action,
	select_pet_action,
	select_pet_form_action
} from '../redux/actions/saciPets';
import {
	cancel_pet_request_action,
	clean_user_pets_data_action,
	save_user_pet_id_action
} from '../redux/actions/userPetsAction';

import { get_output_request_pets_action } from '../redux/actions/outputRequestAction';

import { dialog_show_request_action } from '../redux/actions/questionUsersAction';
import { useTranslation } from 'react-i18next';
import { get_department_data_action } from '../redux/actions/adoptFormAction';

const useStyles = makeStyles((theme) => ({
	CustomTooltip: {
		backgroundColor: '#1E3F20',
		color: 'white',
		opacity: 0.7
	},
	SpeedDial: {
		'&& .MuiSpeedDial-fab': {
			width: 50,
			height: 50
		}
	},

	root: {
		// [theme.breakpoints.up('md')]: {
		// 	padding: theme.spacing(0, 2, 2, 2)
		// },
		[theme.breakpoints.only('xs')]: {
			padding: theme.spacing(2, 2, 2, 2)
		}
	},

	tabs: {
		borderRight: `1px solid ${theme.palette.divider}`
	},

	paper: {
		padding: theme.spacing(2),
		'&:hover': {
			boxShadow: '0 0 10px rgba(99, 193, 50), 0px 0px 20px rgba(99, 193, 50)',
			backgroundColor: '#F0F2F5'
		}
	}
}));

export const PetRequestSent = ({ AdoptionRequestSent }) => {
	const [t] = useTranslation('global');

	const dispatch = useDispatch();

	const classes = useStyles();

	const { id, id_pais } = useSelector((state) => state.login.user);

	const { petSelected, petFormSelected } = useSelector(
		(state) => state.saciPets
	);

	const { outputRequestData } = useSelector((state) => state.outputRequest);

	const [cancelRequest, setCancelRequest] = useState(false);

	const baseURL = process.env.REACT_APP_BACKEND_URL;

	/////////////////////////////////////////////////////////////////////

	const handleClickUpdateRequest = async () => {
		await dispatch(get_department_data_action({ id_codigo: id_pais }));
		await dispatch(adopt_me_dialog_action(true));
		await dispatch(adopt_me_dialog_procedure_action(1));
	};

	const handleSelectPet = (id_mascota, id_formulario) => {
		dispatch(save_user_pet_id_action(id_mascota));
		dispatch(select_pet_action(id_mascota));
		dispatch(select_pet_form_action(id_formulario));
	};

	useEffect(() => {
		if (cancelRequest) {
			dispatch(
				cancel_pet_request_action({
					id_mascota: petSelected,
					id_formulario: petFormSelected
				})
			);
			dispatch(get_output_request_pets_action({ id: id }));
		}
		setCancelRequest(false);
	}, [cancelRequest]);

	const handleClickDeleteRequest = () => {
		Swal.fire({
			title: '¿Deseas cancelar la solicitud?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#63C132',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, cancelar!',
			cancelButtonText: 'No, salir!'
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire('Solicitud Cancelada', '', 'success');
				if (outputRequestData) {
					setCancelRequest(true);
					dispatch(clean_user_pets_data_action());
				}
			}
		});
	};

	useEffect(() => {
		if (cancelRequest) {
			dispatch(
				cancel_pet_request_action({
					id_mascota: petSelected,
					id_formulario: petFormSelected
				})
			);
			dispatch(get_output_request_pets_action({ id: id }));
		}
		setCancelRequest(false);
	}, [cancelRequest]);

	const handleClickShowRequest = () => {
		setTimeout(() => {
			dispatch(dialog_show_request_action(true));
		}, 1000);
	};

	const CustomSpeedDial = ({ prop1, prop2, prop3 }) => {
		const actions = [
			{
				icon: <UpdateIcon />,
				name: t('update'),
				action: handleClickUpdateRequest
			},
			{
				icon: <CancelIcon />,
				name: t('cancel'),
				action: handleClickDeleteRequest
			},
			{
				icon: <ChatIcon />,
				name: t('chat'),
				action: handleClickShowRequest
			}
		];

		const [open, setOpen] = useState(false);

		const handleClose = () => {
			setOpen(false);
		};

		const handleOpen = () => {
			setOpen(true);
		};

		return (
			<SpeedDial
				ariaLabel="SpeedDial example"
				icon={<SpeedDialIcon />}
				onClose={handleClose}
				onOpen={handleOpen}
				open={open}
				direction="right"
				className={classes.SpeedDial}
				hidden={prop3 === false}
			>
				{actions.map((action) => (
					<SpeedDialAction
						key={action.name}
						icon={action.icon}
						tooltipTitle={action.name}
						onClick={() => {
							action.action(prop1, prop2);
							handleSelectPet(prop1, prop2);
						}}
					/>
				))}
			</SpeedDial>
		);
	};

	let outputRequestSent = outputRequestData?.filter(function (item) {
		if (AdoptionRequestSent !== undefined) {
			return item.solicitud_adopcion === AdoptionRequestSent;
		} else {
			return outputRequestData;
		}
	});

	return (
		<Grid container className={classes.root} justify="center" spacing={3}>
			{outputRequestSent?.length > 0 ? (
				outputRequestSent.map((item) => (
					<Grid
						item
						key={item.id_formulario}
						xs={12}
						sm={10}
						md={6}
						lg={6}
						xl={4}
					>
						<Paper className={classes.paper}>
							<Grid container spacing={2}>
								<Grid item container alignItems="center">
									<Grid item xs container direction="column" spacing={2}>
										<Grid item>
											<Typography gutterBottom variant="subtitle1">
												{t('information-pet')}
											</Typography>
											<Typography
												variant="body2"
												color="textSecondary"
												gutterBottom
											>
												<span>{t('name')}: </span>
												<span style={{ color: '#63C132' }}>
													{item.nombre_mascota}
												</span>
											</Typography>
											<Typography
												variant="body2"
												color="textSecondary"
												gutterBottom
											>
												<span>{t('owner')}: </span>
												<span style={{ color: '#63C132' }}>{item.nombres}</span>
											</Typography>

											<Typography variant="body2" color="textSecondary">
												<span>{t('state')}: </span>
												{item.solicitud_adopcion === true ? (
													<span style={{ color: '#63C132' }}>
														{t('status-accepted')}
													</span>
												) : item.solicitud_adopcion === false ? (
													<span style={{ color: '#63C132' }}>
														{t('status-rejected')}
													</span>
												) : item.solicitud_adopcion === null ? (
													<span style={{ color: '#63C132' }}>
														{t('status-pending')}
													</span>
												) : null}
											</Typography>
										</Grid>
									</Grid>

									<Grid
										container
										item
										xs={4}
										justify="center"
										alignItems="center"
									>
										<Grid item>
											<Avatar
												src={`${baseURL}${item.id_foto}.jpg`}
												style={{ width: 100, height: 100 }}
												title="Pet Image"
											/>
										</Grid>
									</Grid>
								</Grid>
								<Grid item>
									<CustomSpeedDial
										prop1={item.id_mascota}
										prop2={item.id_formulario}
										prop3={item.solicitud_adopcion}
									/>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				))
			) : (
				<Grid container alignItems="center">
					<Grid item xs={12}>
						<Typography align="center">
							{/* No tienes solicitudes{' '}
							{(AdoptionRequestSent === true && 'aceptadas') ||
								(AdoptionRequestSent === false && 'rechazadas') ||
								(AdoptionRequestSent === null && 'pendientes')}{' '}
							actualmente. */}
							{(AdoptionRequestSent === true && t('no-currently-accepted')) ||
								(AdoptionRequestSent === false && t('no-currently-rejected')) ||
								(AdoptionRequestSent === null && t('no-currently-pending')) ||
								(AdoptionRequestSent === undefined && t('no-currently-sent'))}
						</Typography>
					</Grid>
				</Grid>
			)}
			<ShowRequest />
		</Grid>
	);
};
