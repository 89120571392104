import { Slide } from '@material-ui/core';
import React, { useState } from 'react';

export const useSnackbar = () => {

	function TransitionSnackbar(props) {
		return <Slide {...props} direction="left" />;
	}

	const [state, setState] = useState({
		openSnackbar: false,
		vertical: 'bottom',
		horizontal: 'right'
	});

	const [transition, setTransition] = useState(undefined);

	const { vertical, horizontal, openSnackbar } = state;

	const handleCloseSnackbar = () => {
		setState({ ...state, openSnackbar: false });
	};

	const handleOpenSnackbar = (newState, Transition) => {
		setState({ openSnackbar: true, ...newState });
		setTransition(() => Transition);
	};

	return {
		TransitionSnackbar,
		transition,
		vertical,
		horizontal,
		openSnackbar,
		handleCloseSnackbar,
		handleOpenSnackbar
	};
};