import {
	INICIAR_SESION_ERROR,
	INICIAR_SESION_EXITO,
	INICIAR_SESION_EXITO_PHOTO,
	INICIAR_SESION_START,
	LOGIN_DIALOG_OPEN,
	LOGIN_DIALOG_CLOSE,
	ADOPT_DIALOG_OPEN,
	ADOPT_DIALOG_CLOSE,
	ADOPTSTEPPER_DIALOG_OPEN,
	ADOPTSTEPPER_DIALOG_CLOSE,
	SACI_USER_PROFILE,
	SACI_PHONE_PROFILE,
	SACI_EMAIL_PROFILE_VALIDATE,
	SACI_EMAIL_PROFILE,
	SACI_PASSWORD_PROFILE,
	SACI_PASSWORD_VALIDATE_PROFILE,
	SACI_PHOTO_PROFILE,
	SACI_PHOTO_DELETE_PROFILE,
	CHECK_LOGIN,
	CHECK_LOGOUT,
	SET_PROCEDURE,
	LOAD_GOOGLE_DATA,
	SAVE_USER_TOKEN,
	GET_SESSION,
	SET_CHECK_EMAIL_MODAL,
	SET_FORGOT_PASSWORD_MODAL,
	LOGIN_ERROR_CLEAN,
	INICIAR_SESION_ERROR_GOOGLE,
	UPDATE_COUNTRY_USER,
	LOGIN_CLEAN_PHOTO,
	ALERT_PASSWORD_CODE,
	ALERT_CLEAN_CODE
} from '../types';

const initialState = {
	// user: 'developer',
	user: {
		nombres: '',
		correo: '',
		telefono: '',
		id: ''
	},
	loader: false,
	error: false,
	errorGoogle: false,
	loginDialog: null,
	adoptDialog: null,
	adoptstepperDialog: null,
	procedure: null,
	photoProfile: '',
	passwordValidate: '',
	emailValidate: '',
	userPhoto: '',
	checkLogin: false,
	token: '',
	checkEmailModal: false,
	registryCodeMsg: '',
	forgotPasswordModal: false,
	tipoCode: '',
	reloadImage: false,
	alertPassword: ''
};

export default (state = initialState, action) => {
	switch (action.type) {
		case INICIAR_SESION_START:
			return {
				...state,
				loader: true
			};
		case LOAD_GOOGLE_DATA:
			return {
				...state,
				user: action.payload
			};
		case GET_SESSION:
			return {
				...state,
				user: action.payload,
				checkLogin: true
			};
		case INICIAR_SESION_EXITO:
			return {
				...state,
				loader: false,
				error: false,
				errorGoogle: false,
				user: action.payload
			};
		case SAVE_USER_TOKEN:
			return {
				...state,
				token: action.payload
			};
		case INICIAR_SESION_EXITO_PHOTO:
			return {
				...state,
				userPhoto: action.payload
			};
		case INICIAR_SESION_ERROR:
			return {
				...state,
				loader: false,
				error: true,
				errorMessage: action.payload.error,
				user: {
					nombres: '',
					correo: '',
					telefono: ''
				},
				ok: false
			};
		case INICIAR_SESION_ERROR_GOOGLE:
			return {
				...state,
				loader: false,
				errorGoogle: true,
				errorMessage: action.payload.error,
				user: {
					nombres: '',
					correo: '',
					telefono: ''
				},
				ok: false
			};
		case LOGIN_DIALOG_OPEN:
			return {
				...state,
				loginDialog: true
			};
		case LOGIN_DIALOG_CLOSE:
			return {
				...state,
				loginDialog: false
			};
		case ADOPT_DIALOG_OPEN:
			return {
				...state,
				adoptDialog: true,
				procedure: action.payload
			};
		case SET_PROCEDURE:
			return {
				...state,
				procedure: action.payload
			};
		case ADOPT_DIALOG_CLOSE:
			return {
				...state,
				adoptDialog: false
			};
		case ADOPTSTEPPER_DIALOG_OPEN:
			return {
				...state,
				adoptstepperDialog: true
			};
		case ADOPTSTEPPER_DIALOG_CLOSE:
			return {
				...state,
				adoptstepperDialog: false
			};
		case SACI_USER_PROFILE:
			return {
				...state,
				user: action.payload
			};
		case SACI_PHONE_PROFILE:
			return {
				...state,
				user: action.payload
			};
		case SACI_EMAIL_PROFILE:
			return {
				...state,
				user: action.payload
			};
		case SACI_EMAIL_PROFILE_VALIDATE:
			return {
				...state,
				emailValidate: action.payload
			};
		case SACI_PASSWORD_VALIDATE_PROFILE:
			return {
				...state,
				passwordValidate: action.payload
			};
		case SACI_PASSWORD_PROFILE:
			return {
				...state,
				user: action.payload
			};
		case SACI_PHOTO_PROFILE:
			return {
				...state,
				userPhoto: [{
					foto: action.payload
				}],
				reloadImage: !state.reloadImage
			};
		case SACI_PHOTO_DELETE_PROFILE:
			return {
				...state,
				photoProfile: action.payload,
				userPhoto: null
			};
		case LOGIN_CLEAN_PHOTO:
			return {
				...state,
				userPhoto: null
			};
		case CHECK_LOGIN:
			return {
				...state,
				checkLogin: true
			};
		case CHECK_LOGOUT:
			return {
				...initialState,
				token: ''
			};
		case SET_CHECK_EMAIL_MODAL:
			return {
				...state,
				checkEmailModal: action.payload
			};
		case SET_FORGOT_PASSWORD_MODAL:
			return {
				...state,
				forgotPasswordModal: action.payload.state,
				tipoCode: action.payload.tipo,
				loginDialog: null
			};
		case ALERT_PASSWORD_CODE:
			return {
				...state,
				alertPassword: action.payload
			};
		case ALERT_CLEAN_CODE:
			return {
				...state,
				alertPassword: ''
			};
		case LOGIN_ERROR_CLEAN:
			return {
				...state,
				error: false,
				errorGoogle: false
			};
		case UPDATE_COUNTRY_USER:
			return {
				...state,
				user: {
					...state.user,
					id_pais: action.payload
				}
			};
		default:
			return state;
	}
};
