import React from 'react';

import { IconButton, Slide, Typography, withStyles } from '@material-ui/core';

import MuiDialogTitle from '@material-ui/core/DialogTitle';

import CloseIcon from '@material-ui/icons/Close';

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1)
	}
});

export const Transition = React.forwardRef(function Transition(props, ref) {
	return (
		<Slide
			direction="down"
			ref={ref}
			{...props}
			timeout={{ enter: 500, exit: 500 }}
		/>
	);
});

export const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
					color="primary"
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});
