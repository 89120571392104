/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Header from '../components/SaciComponents/Header';
import CardsPets from '../components/SaciComponents/Adoption/CardsPets';
import FiltersPets from '../components/SaciComponents/Adoption/FiltersPets';
import FiltersPetsResponsive from '../components/SaciComponents/Adoption/FiltersPetsResponsive';
import { Grid, Hidden } from '@material-ui/core';
import Pagination from '../components/SaciComponents/Pagination';
import AdoptMeForm from '../components/SaciComponents/Adoption/AdoptMeForm';
import UnLoggedModal from '../components/SaciComponents/UnLoggedModal';
import { useDispatch, useSelector } from 'react-redux';
import {
	get_saci_pets_action,
	select_pet_action,
	set_on_request_page_state_action,
	set_user_pets_state_action
} from '../redux/actions/saciPets';
import {
	get_user_pets_request,
	set_on_entry_request_page,
	set_on_send_request_page_action
} from '../redux/actions/userPetsAction';
import {
	get_countries_action,
	hide_loading_page_action
} from '../redux/actions/mainAction';
import { reset_adopt_me_form_action } from '../redux/actions/adoptMeFormAction';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { get_session_action, loginNormalAction, saveUserDefaultLogin } from '../redux/actions/loginAction';
import '../assets/css/loading.css';

const useStyles = makeStyles((theme) => ({
	pageContainer: {
		marginTop: '1%',
		marginBottom: '1%'
	}
}));

export default function SaciDashboard() {
	const classes = useStyles();
	const { id, id_pais, nombres } = useSelector((state) => state.login.user);
	const { token } = useSelector((state) => state.login);
	const dispatch = useDispatch();
	const { allowOrDenyRequest } = useSelector((state) => state.userPets);
	const { petFiltersValidate, petEmpty } = useSelector(
		(state) => state.saciPets
	);
	const { countries, hideLoading } = useSelector((state) => state.main);
	useEffect(() => {
		dispatch(set_user_pets_state_action(false));
		dispatch(set_on_request_page_state_action(false));
		dispatch(set_on_entry_request_page(false));
		dispatch(set_on_send_request_page_action(false));
		dispatch(select_pet_action(null));
		dispatch(reset_adopt_me_form_action());
	}, []);
	// const [googleData, setGoogleData] = useLocalStorage('google-session', '');
	const [storedToken, setStoredToken] = useLocalStorage('token', '');
	// dispatch(get_google_data_action(googleData));

	// useEffect(() => {
	// 	if (googleData.error === false) {
	// 		if (googleData.length !== 0) {
	// 			dispatch(check_login_action());
	// 			dispatch(
	// 				load_google_data_action({
	// 					nombres: googleData.profileObj.name,
	// 					email: googleData.profileObj.email
	// 				})
	// 			);
	// 		}
	// 	}
	// }, [googleData]);

	// acitvación de usuario por defecto
	useEffect(() => {
		if (!storedToken) {
			localStorage.setItem('token', process.env.REACT_APP_USER_DEFAULT);
		}
	}, [storedToken, nombres]);

	// peticion cuando no hay sesión guardada
	useEffect(() => {
		if (!storedToken && !id_pais) {
			dispatch(get_saci_pets_action({ id_tipo_mascota: false }));
		}
	}, [storedToken, id_pais]);

	// peticion cuando hay sesión guardada
	useEffect(() => {
		if (id_pais) {
			dispatch(get_saci_pets_action({ id_pais: id_pais }));
		}
	}, [id_pais]);

	useEffect(() => {
		if (token.length !== 0) {
			setStoredToken(token);
		}
	}, [token]);

	useEffect(() => {
		if (storedToken.length !== 0) {
			dispatch(
				get_session_action({
					tokenlog: storedToken
				})
			);
		}
	}, [storedToken]);

	useEffect(() => {
		if (countries.length === 0) {
			dispatch(get_countries_action());
		}
	}, []);

	useEffect(() => {
		if (id) {
			dispatch(get_user_pets_request({ id_usuario: id }));
		}
	}, [id, allowOrDenyRequest]);

	const [time, setTime] = useState(true);

	const [timeValidate, setTimeValidate] = useState(true);

	setTimeout(() => {
		setTimeValidate(false);
	}, 2900);

	setTimeout(() => {
		setTime(false);
	}, 3000);

	useEffect(() => {
		if (!time) {
			dispatch(hide_loading_page_action(true));
		}
	}, [time]);

	return (
		<>
			{!hideLoading && time ? (
				<div
					className={
						timeValidate
							? 'lds-main animate__animated animate__fadeInDown'
							: 'lds-main animate__animated animate__fadeOutDown'
					}
				>
					<h1 className="lds-tittle">PET SACI</h1>

					<div className="lds-roller">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div>
			) : (
				<>
					<Header />
					<Grid container justify="center">
						{!petEmpty && petFiltersValidate.length !== 0 && (
							<Hidden smDown>
								<Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
									<FiltersPets />
								</Grid>
							</Hidden>
						)}

						{!petEmpty && petFiltersValidate.length !== 0 && (
							<Hidden mdUp>
								<Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
									<FiltersPetsResponsive />
								</Grid>
							</Hidden>
						)}

						<Grid item xs={12} sm={12} md={9} lg={10} xl={10}>
							<CardsPets />
						</Grid>
						<AdoptMeForm />
						<UnLoggedModal />
						{/* <ResponsiveMenu /> */}
					</Grid>
					<Grid container justify="center" className={classes.pageContainer}>
						<Pagination />
					</Grid>
				</>
			)}
		</>
	);
}
