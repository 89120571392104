import {  saveUserGoogle } from '../../configAxios/Google';
import {
	INICIAR_SESION_ERROR_GOOGLE,
	INICIAR_SESION_EXITO,
	INICIAR_SESION_START,
	SAVE_USER_TOKEN
} from '../types';
import { loginNormalSuccessPhoto } from './loginAction';

// Get Data to localStorage
export function loginGoogleAction(data) {
	return async (dispatch) => {
		dispatch(loginGoogleStart());
		try {
			const response = await saveUserGoogle(data);
			if (response.data.user.telefono === null) {
				dispatch(loginGoogleSuccess({
					...response.data.user,
					telefono: ''
				}));
				dispatch(save_user_token_action(response.data.tokenGenerado));
				dispatch(loginNormalSuccessPhoto(response.data.foto));
			} else {
				dispatch(loginNormalSuccessPhoto(response.data.foto));
				dispatch(loginGoogleSuccess(response.data.user));
				dispatch(save_user_token_action(response.data.tokenGenerado));
			}
		} catch (error) {
			dispatch(loginGoogleError(error));
		}
	};
}

const loginGoogleStart = () => ({
	type: INICIAR_SESION_START
});

const loginGoogleSuccess = (username) => ({
	type: INICIAR_SESION_EXITO,
	payload: username
});

const loginGoogleError = (error) => ({
	type: INICIAR_SESION_ERROR_GOOGLE,
	payload: error
});

const save_user_token_action = (token) => ({
	type: SAVE_USER_TOKEN,
	payload: token
});
