import { SET_RESPONSIVE_MENU, HIDE_MENU_BUTTONS, HIDE_LOADING_PAGE, GET_COUNTRIES, PWA_DATA } from '../types';

const initialState = {
	responsiveMenu: false,
	hideMenuButtons: false,
	countries: [],
	hideLoading: false,
	isReadyForInstall: false,
	deferredPrompt: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_RESPONSIVE_MENU:
			return {
				...state,
				responsiveMenu: action.payload
			};

		case HIDE_MENU_BUTTONS:
			return {
				...state,
				hideMenuButtons: action.payload
			};
		case GET_COUNTRIES:
			return {
				...state,
				countries: action.payload
			}

		case HIDE_LOADING_PAGE:
			return {
				...state,
				hideLoading: action.payload
			}
		case PWA_DATA:
			return {
				...state,
				isReadyForInstall: action.payload.isReadyForInstall,
				deferredPrompt: action.payload.deferredPrompt
			}
		default:
			return state;
	}
};
