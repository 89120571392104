/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import Header from '../components/SaciComponents/Header';

// Clsx
import clsx from 'clsx';

// Sweet Alert
import Swal from 'sweetalert2';

// Material UI
import {
	TextField,
	Grid,
	Typography,
	Button,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Fab,
	CircularProgress,
	Paper,
	Avatar,
	Snackbar
} from '@material-ui/core';

import Slide from '@material-ui/core/Slide';

import MuiAlert from '@material-ui/lab/Alert';

//Icons Material UI
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

// Image Profile
import userImage from '../assets/icons/drawer/iconProfile.svg';

// import userImage from '../assets/icons/drawer/petUser-final.svg';

// Dispach Redux
import { useDispatch, useSelector } from 'react-redux';

import {
	saci_user_profile_action,
	saci_phone_profile_action,
	saci_email_profile_action,
	saci_email_validate_profile_action,
	saci_password_validate_profile_action,
	saci_password_profile_action,
	saci_photo_profile_action,
	saci_photo_delete_profile_action,
	set_forgot_password_modal_action,
	gen_password_code_action,
	login_photo_clean_action
} from '../redux/actions/loginAction';

// Form Validation
import { useForm } from 'react-hook-form';

// Notification
import { SnackbarProvider, useSnackbar } from 'notistack';
import { show_user_pets_action } from '../redux/actions/saciPets';
import { useHistory } from 'react-router-dom';
import { hide_menu_buttons_action } from '../redux/actions/mainAction';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.only('xl')]: {
			width: '35%'
		},
		[theme.breakpoints.only('lg')]: {
			width: '45%'
		},
		[theme.breakpoints.only('md')]: {
			width: '60%'
		},
		[theme.breakpoints.only('sm')]: {
			width: '80%'
		},
		[theme.breakpoints.only('xs')]: {
			width: '100%'
		},
		margin: 'auto'
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		flexBasis: '33.33%',
		flexShrink: 0
	},
	secondaryHeading: {
		fontSize: theme.typography.pxToRem(15),
		color: theme.palette.text.secondary
	},
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative'
	},
	buttonSuccess: {
		backgroundColor: '#63C132',
		color: '#FFFFFF',
		'&:hover': {
			// backgroundColor: '#1E3F20',
		}
	},
	buttonFailed: {
		backgroundColor: 'red',
		color: '#FFFFFF',
		'&:hover': {
			backgroundColor: '#C13232'
		}
	},
	fabProgress: {
		color: '#1E3F20',
		position: 'absolute',
		top: -6,
		left: -6,
		zIndex: 1
	},
	buttonProgress: {
		color: '#63C132',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12
	},
	buttonSave: {
		color: '#FFFFFF'
	},

	rootProfile: {
		'&:hover': {
			border: '3px dashed  #63C132',
			transition: 'border 0.8s linear 0.2s',

			transform: 'scale(1.1)',
			'-webkit-transition': 'all 500ms ease-in-out',
			objectFit: 'cover'
		},
		// maxWidth: 245,
		border: '3px solid  #fff',
		marginLeft: 'auto',
		marginRight: 'auto',

		width: theme.spacing(15),
		height: theme.spacing(15),

		objectFit: 'cover',
		'-webkit-transition': 'all 500ms ease-in-out',
		transform: 'scale(1)'
	},

	containerPetimages: {
		textAlign: 'center'
	},

	paper: {
		padding: theme.spacing(2),
		margin: 'auto',
		maxWidth: 500,
		marginBottom: '20px'
	},

	image: {
		width: 128,
		height: 128
	},

	// img: {
	//   margin: 'auto',
	//   display: 'block',
	//   maxWidth: '100%',
	//   maxHeight: '100%',
	// },

	formContainer: {
		display: 'flex'
	}
}));

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const Alert = React.forwardRef((props, ref) => (
	<MuiAlert elevation={6} variant="filled" {...props} ref={ref} />
));

function TransitionRight(props) {
	return <Slide {...props} direction="right" />;
}

function Profile() {
	const [t] = useTranslation('global');
	const classes = useStyles();
	const dispatch = useDispatch();

	const [expanded, setExpanded] = useState(false);

	const handleChangeAccordion = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	const { id, nombres, telefono, correo } = useSelector(
		(state) => state.login.user
	);

	const { user, emailValidate, passwordValidate, userPhoto, tipoCode } = useSelector(
		(state) => state.login
	);

	const { publishPet, userPetsRegistered } = useSelector(
		(state) => state.userPets
	);

	useEffect(() => {
		dispatch(hide_menu_buttons_action(true));
	}, []);

	// Mostrar Mascotas Publicadas
	useEffect(() => {
		if (publishPet !== null) {
			const userPet = userPetsRegistered?.filter(
				(pet) => pet.publicado === publishPet
			);
			dispatch(show_user_pets_action(userPet));
		}
	}, [publishPet]);

	let history = useHistory();

	useEffect(() => {
		if (!nombres) {
			history.push('/');
		}
	}, []);

	// Notificaciones
	const [state, setState] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'left'
	});

	const [transition, setTransition] = useState(undefined);

	const { vertical, horizontal, open } = state;

	const handleClose = () => {
		setState({ ...state, open: false });
	};

	////////////////////////////////////////

	//Editar Nombre
	const [editName, seteditName] = useState({
		nombres: `${nombres}`,
		id: `${id}`
	});

	const handleChangeName = (event) => {
		const { name, value } = event.target;
		seteditName({ ...editName, ...user, [name]: value });
	};

	const onSubmitName = () => {
		if (nombres !== editName.nombres) {
			dispatch(saci_user_profile_action(editName));
		}
	};

	// Editar Telefono
	const [editPhone, seteditPhone] = useState({
		telefono: `${telefono}`,
		id: `${id}`
	});

	// let code;

	// switch (id_pais) {
	// 	case '1':
	// 		code = '57';
	// 		break;

	// 	default:
	// 		break;
	// }

	let code = telefono.split(' ');

	const handleChangePhone = (event) => {
		const { name, value } = event.target;
		seteditPhone({ ...editPhone, ...user, [name]: code[0] + ' ' + value });
	};

	const onSubmitPhone = () => {
		if (telefono !== editPhone.telefono) {
			dispatch(saci_phone_profile_action(editPhone));
		}
	};

	// Editar Correo
	const [editEmail, setEditEmail] = useState({
		correo: `${correo}`,
		id: `${id}`
	});

	const handleChangeEmail = (event) => {
		const { name, value } = event.target;
		setEditEmail({ ...editEmail, ...user, [name]: value });
	};

	const onSubmitEmail = () => {
		dispatch(saci_email_validate_profile_action(editEmail));
	};

	// const [errorEmail, setErrorEmail] = useState(null)

	useEffect(() => {
		if (emailValidate.ok === true) {
			dispatch(saci_email_profile_action(editEmail));
			// setErrorEmail(false)
		}

		if (emailValidate.ok === false) {
			// setErrorEmail(true)
		}
	}, [emailValidate, emailValidate.ok]);

	// Editar Contraseña Nueva
	const [editPassword, setEditPassword] = useState({
		password: '',
		id: `${id}`
	});


	const handleChangePassword = (event) => {
		const { name, value } = event.target;
		setEditPassword({ ...editPassword, ...user, [name]: value });
	};

	// Cambio Contraseña 
	const onSubmitPassword = () => {
		dispatch(set_forgot_password_modal_action({
			state: true,
			tipo: 'Cambiar'
		}))
		// dispatch(gen_password_code_action({ correo: correo, tipoCode: tipoCode }))
		// dispatch(saci_password_profile_action(editPassword));
	};

	// Editar Contraseña Actual
	const [editPasswordCurrent, setEditPasswordCurrent] = useState({
		passwordCurrent: '',
		id: `${id}`
	});


	const handleChangePasswordCurrent = (event) => {
		const { name, value } = event.target;
		setEditPasswordCurrent({
			...editPasswordCurrent,
			...user,
			[name]: value
		});
	};

	useEffect(() => {
		dispatch(saci_password_validate_profile_action(editPasswordCurrent));
	}, [editPasswordCurrent]);

	// Validacion Nombre
	const {
		register: registerName,
		errors: errorsName,
		handleSubmit: handleSubmitName
	} = useForm({
		mode: 'onChange'
	});

	// Validacion Teléfono
	const {
		register: registerPhone,
		errors: errorsPhone,
		handleSubmit: handleSubmitPhone
	} = useForm({
		mode: 'onChange'
	});

	// Validacion Correo
	const {
		register: registerEmail,
		errors: errorsEmail,
		handleSubmit: handleSubmitEmail
	} = useForm({
		mode: 'onChange'
	});

	// // Validacion Contraseña Actual
	const { register: registerPasswordCurrent, errors: errorsPasswordCurrent } =
		useForm({
			mode: 'onChange'
		});


	// Validacion Contraseña Nueva
	const {
		register: registerPassword,
		errors: errorsPassword,
		handleSubmit: handleSubmitPassword
	} = useForm({
		mode: 'onChange'
	});

	// Button Save Nombre
	const [loadingName, setLoadingName] = useState(false);
	const [successName, setSuccessName] = useState(false);
	const [failedName, setFailedName] = useState(false);

	// Button Save Telefono
	const [loadingPhone, setLoadingPhone] = useState(false);
	const [successPhone, setSuccessPhone] = useState(false);
	const [failedPhone, setFailedPhone] = useState(false);

	// Button Save Correo
	const [loadingEmail, setLoadingEmail] = useState(false);
	const [successEmail, setSuccessEmail] = useState(false);
	const [failedEmail, setFailedEmail] = useState(false);

	// Button Save Contrañesa Actual
	const [successPasswordCurrent] = useState(false);
	const [failedPasswordCurrent] = useState(false);

	// Button Save Contrañesa Nueva
	const [loadingPassword, setLoadingPassword] = useState(false);
	const [successPassword, setSuccessPassword] = useState(false);
	const [failedPassword, setFailedPassword] = useState(false);

	const timer = useRef();

	const buttonClassname = clsx({
		[classes.buttonSuccess]:
			successName ||
			successPhone ||
			successEmail ||
			successPasswordCurrent ||
			successPassword,
		[classes.buttonFailed]:
			failedName ||
			failedPhone ||
			failedEmail ||
			failedPasswordCurrent ||
			failedPassword
	});

	useEffect(() => {
		return () => {
			clearTimeout(timer.current);
		};
	}, []);

	const { enqueueSnackbar } = useSnackbar();

	// boton carga nombre
	const handleButtonClickName = (variant) => () => {
		if (!loadingName) {
			setSuccessName(false);
			setFailedName(false);
			setLoadingName(true);
			timer.current = window.setTimeout(() => {
				if (errorsName.nombres) {
					setFailedName(true);
				} else {
					setSuccessName(true);
				}
				setLoadingName(false);
				enqueueSnackbar(
					errorsName.nombres
						? t('error-information')
						: t('updated-information'),
					{ variant }
				);
				setTimeout(() => {
					setSuccessName(false);
					setFailedName(false);
				}, 1000);
			}, 2000);
		}
	};

	// boton carga telefono
	const handleButtonClickPhone = (variant) => () => {
		if (!loadingPhone) {
			setSuccessPhone(false);
			setFailedPhone(false);

			setLoadingPhone(true);
			timer.current = window.setTimeout(() => {
				if (errorsPhone.telefono) {
					setFailedPhone(true);
				} else {
					setSuccessPhone(true);
				}
				setLoadingPhone(false);
				enqueueSnackbar(
					errorsPhone.telefono
						? t('error-information')
						: t('updated-information'),
					{ variant }
				);
				setTimeout(() => {
					setSuccessPhone(false);
					setFailedPhone(false);
				}, 1000);
			}, 2000);
		}
	};

	// boton carga correo
	const handleButtonClickEmail = (newState, Transition) => () => {
		if (!loadingEmail) {
			setSuccessEmail(false);
			setFailedEmail(false);

			setLoadingEmail(true);
			timer.current = window.setTimeout(() => {
				if (errorsEmail.correo) {
					setFailedEmail(true);
				} else {
					setSuccessEmail(true);
				}
				setLoadingEmail(false);
				setTimeout(() => {
					setSuccessEmail(false);
					setFailedEmail(false);
				}, 1000);
				setTransition(() => Transition);
				setState({ open: true, ...newState });
			}, 2000);
		}
	};

	// boton carga contraseña Nueva
	const handleButtonClickPassword = (variant) => () => {
		if (!loadingPassword) {
			setSuccessPassword(false);
			setFailedPassword(false);

			setLoadingPassword(true);
			timer.current = window.setTimeout(() => {
				if (errorsPassword.password) {
					setFailedPassword(true);
				} else {
					setSuccessPassword(true);
				}
				setLoadingPassword(false);
				// enqueueSnackbar(
				// 	errorsPassword.password
				// 		? t('error-information')
				// 		: t('updated-information'),
				// 	{
				// 		variant
				// 	}
				// );
				setTimeout(() => {
					setSuccessPassword(false);
					setFailedPassword(false);
				}, 1000);
			}, 2000);
		}
	};

	const [userimage, setUserImage] = useState();
	const [preview, setPreview] = useState();

	const fileInputRef1 = useRef();

	const [checked1, setChecked1] = useState(false);

	useEffect(() => {
		if (userimage) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setPreview(reader.result);
			};
			reader.readAsDataURL(userimage);
			setChecked1(true);
			setPhotoSave(true);
		} else {
			setPreview(null);
			setPhotoDelete(false);
			setPhotoSave(false);
		}
	}, [userimage]);

	const handleSubmitImageProfile = () => {
		if (checked1 === true) {
			Swal.fire({
				title: '¿Quieres guardar tu foto de perfil?',
				showDenyButton: true,
				confirmButtonText: `Si`,
				confirmButtonColor: '#63C132',
				denyButtonText: `No`
			}).then((result) => {
				if (result.isConfirmed) {
					dispatch(login_photo_clean_action());
					Swal.fire('Foto Guardada', '', 'success');
					dispatch(saci_photo_profile_action(userimage, id, preview));
					// dispatch(loginNormalAction({correo}));
					setChecked1(false);
					setPhotoSave(false);
				} else if (result.isDenied) {
					Swal.fire('Foto No Guardada', '', 'error');
					// setUserImage(null);
				}
			});
		}
	};

	const handleDeleteImageProfile = () => {
		// setChecked1(true);
		// if (checked1 === true) {
		Swal.fire({
			title: '¿Estás seguro de eliminar la foto?',
			showDenyButton: true,
			confirmButtonText: `Si`,
			confirmButtonColor: '#63C132',
			denyButtonText: `No`
		}).then((result) => {
			if (result.isConfirmed) {
				setPreview(null);
				Swal.fire('Foto Eliminada', '', 'error');
				dispatch(saci_photo_delete_profile_action(id));
				setUserImage(null);
			} else if (result.isDenied) {
			}
		});
		// }
	};
	///////////////////////////////////////
	const baseURL = process.env.REACT_APP_BACKEND_URL;

	// const userPhotos = itemUsers;

	// const [photo_1, setPhoto_1] = useState();

	// const [items, setItems] = useState({
	//   imgPath: `${baseURL}${userPhoto[0].id}.jpg`,
	// });

	const [photoDelete, setPhotoDelete] = useState(false);

	const [photoSave, setPhotoSave] = useState(false);

	const [items, setItems] = useState({
		imgPath: userImage
	});

	useEffect(() => {
		if (userPhoto !== null) {
			if (userPhoto.length === 1) {
				setItems({
					imgPath: `${baseURL}${userPhoto[0].id}.jpg`
				});
				setPhotoDelete(true);
			}
		}

		if (userPhoto === null) {
			setItems({
				imgPath: userImage
			});
		}
	}, [userPhoto]);

	return (
		<div>
			<Header />
			<div className={classes.root}>
				<Snackbar
					open={open}
					autoHideDuration={6000}
					onClose={handleClose}
					anchorOrigin={{ vertical, horizontal }}
					TransitionComponent={transition}
				>
					<Alert
						onClose={handleClose}
						severity={emailValidate.ok ? 'success' : 'error'}
					>
						{/* {emailValidate.ok ? emailValidate.message : emailValidate.message} */}

						{emailValidate.ok
							? t('email') + ' ' + editEmail.correo + ' ' + t('updated-email')
							: t('error-email') + ': ' + editEmail.correo}
					</Alert>
				</Snackbar>
				<Paper className={classes.paper}>
					<Grid container spacing={2} justify="center">
						<Grid item xs={4}>
							<div className={classes.containerPetimages}>
								{preview ? (
									<Grid item>
										<Avatar
											onClick={(event) => {
												event.preventDefault();
												fileInputRef1.current.click();
											}}
											src={preview}
											className={classes.rootProfile}
											title={t('my-picture')}
										/>
									</Grid>
								) : (
									<Grid item>
										<Avatar
											onClick={(event) => {
												event.preventDefault();
												fileInputRef1.current.click();
											}}
											src={items.imgPath}
											className={classes.rootProfile}
											title={t('update-picture')}
										/>
									</Grid>
								)}

								<input
									type="file"
									style={{ display: 'none' }}
									ref={fileInputRef1}
									accept="image/*"
									onChange={(event) => {
										const file = event.target.files[0];
										if (file && file.type.substr(0, 5) === 'image') {
											setUserImage(file);
										} else {
											setUserImage(null);
										}
									}}
								/>
							</div>
						</Grid>
						<Grid item xs={12} sm container>
							<Grid item xs container direction="column" spacing={2}>
								<Grid item xs>
									<Typography gutterBottom variant="subtitle1">
										{t('my-profile-information')}
									</Typography>
									<Typography
										variant="body2"
										color="textSecondary"
										gutterBottom
									>
										<span>{t('account')}: </span>
										<span style={{ color: '#63C132' }}>
											{user.origen_cuenta}
										</span>
									</Typography>
									{userPetsRegistered && (
										<Typography variant="body2" color="textSecondary">
											<span>{t('published-pets')}: </span>
											<span style={{ color: '#63C132' }}>
												{userPetsRegistered && userPetsRegistered.length}
											</span>
										</Typography>
									)}
								</Grid>
								<Grid container spacing={1}>
									<Grid item xs={6} md={5}>
										<Button
											variant="contained"
											size="small"
											color="primary"
											onClick={handleSubmitImageProfile}
											disabled={photoSave ? false : true}
											startIcon={<SaveIcon />}
											id={photoSave ? 'buttonWhite' : undefined}
											fullWidth
										>
											{t('save')}
										</Button>
									</Grid>
									<Grid item xs={6} md={5}>
										<Button
											variant="contained"
											size="small"
											color="primary"
											onClick={handleDeleteImageProfile}
											disabled={preview || photoDelete ? false : true}
											startIcon={<DeleteForeverIcon />}
											id={preview || photoDelete ? 'buttonWhite' : undefined}
											fullWidth
										>
											{t('delete')}
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Paper>

				<Accordion
					expanded={expanded === 'panel1'}
					onChange={handleChangeAccordion('panel1')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon color="primary" />}
						aria-controls="panel1bh-content"
						id="panel1bh-header"
					>
						<Typography className={classes.heading}>{t('username')}</Typography>
						<Typography className={classes.secondaryHeading}>
							{nombres}
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid container>
							<Grid item xs={12} md={10}>
								<form
									onSubmit={handleSubmitName(onSubmitName)}
									className={classes.formContainer}
								>
									<TextField
										type="text"
										name="nombres"
										variant="outlined"
										defaultValue={nombres}
										disabled={loadingName ? true : false}
										fullWidth
										inputRef={registerName({
											required: {
												value: true,
												message: t('complete-validation')
											},
											minLength: {
												value: 3,
												message: t('minimum-validation')
											},
											maxLength: {
												value: 40,
												message: t('maximum-validation')
											},
											pattern: {
												value: /^[a-zA-ZÁ-ÿ\s]{1,40}$/,
												message: t('letters-validation')
											}
										})}
										helperText={errorsName?.nombres?.message}
										error={errorsName?.nombres?.message ? true : false}
										onChange={handleChangeName}
									/>
									<div className={classes.wrapper}>
										<Fab
											type="submit"
											size="small"
											aria-label="save"
											color="primary"
											className={buttonClassname}
											onClick={handleButtonClickName(
												errorsName.nombres ? 'error' : 'success'
											)}
										>
											{successName ? (
												<CheckIcon />
											) : failedName ? (
												<CloseIcon />
											) : (
												<SaveIcon className={classes.buttonSave} />
											)}
										</Fab>
										{loadingName && (
											<CircularProgress
												size={52}
												className={classes.fabProgress}
											/>
										)}
									</div>
								</form>
							</Grid>
						</Grid>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expanded === 'panel2'}
					onChange={handleChangeAccordion('panel2')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon color="primary" />}
						aria-controls="panel2bh-content"
						id="panel2bh-header"
					>
						<Typography className={classes.heading}>
							{t('telephone')}
						</Typography>
						<Typography className={classes.secondaryHeading}>
							{'+'}
							{telefono}
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid item xs={12} md={6}>
							<form
								onSubmit={handleSubmitPhone(onSubmitPhone)}
								className={classes.formContainer}
							>
								<TextField
									type="tel"
									name="telefono"
									variant="outlined"
									defaultValue={telefono.split(' ')[1]}
									disabled={loadingPhone ? true : false}
									fullWidth
									inputRef={registerPhone({
										required: {
											value: true,
											message: t('complete-validation')
										},
										pattern: {
											value: /^\d{7,10}$/,
											message: t('phone-validation')
										}
									})}
									helperText={errorsPhone?.telefono?.message}
									error={errorsPhone?.telefono?.message ? true : false}
									onChange={handleChangePhone}
								/>

								<div className={classes.wrapper}>
									<Fab
										type="submit"
										size="small"
										aria-label="save"
										color="primary"
										className={buttonClassname}
										onClick={handleButtonClickPhone(
											errorsPhone.telefono ? 'error' : 'success'
										)}
									>
										{successPhone ? (
											<CheckIcon />
										) : failedPhone ? (
											<CloseIcon />
										) : (
											<SaveIcon className={classes.buttonSave} />
										)}
									</Fab>
									{loadingPhone && (
										<CircularProgress
											size={52}
											className={classes.fabProgress}
										/>
									)}
								</div>
							</form>
						</Grid>
					</AccordionDetails>
				</Accordion>
				<Accordion
					expanded={expanded === 'panel3'}
					onChange={handleChangeAccordion('panel3')}
				>
					<AccordionSummary
						expandIcon={<ExpandMoreIcon color="primary" />}
						aria-controls="panel3bh-content"
						id="panel3bh-header"
					>
						<Typography className={classes.heading}>{t('email')}</Typography>
						<Typography className={classes.secondaryHeading}>
							{correo}
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Grid item xs={12} md={10}>
							<form
								onSubmit={handleSubmitEmail(onSubmitEmail)}
								className={classes.formContainer}
							>
								<TextField
									// label="Email"
									type="email"
									name="correo"
									variant="outlined"
									defaultValue={correo}
									disabled={loadingEmail ? true : false}
									fullWidth
									inputRef={registerEmail({
										required: {
											value: true,
											message: t('complete-validation')
										},
										pattern: {
											value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
											message: t('email-validation')
										}
									})}
									helperText={errorsEmail?.correo?.message}
									error={errorsEmail?.correo?.message ? true : false}
									onChange={handleChangeEmail}
								/>
								<div className={classes.wrapper}>
									<Fab
										type="submit"
										size="small"
										aria-label="save"
										color="primary"
										disabled={errorsEmail?.correo?.message ? true : false}
										className={buttonClassname}
										onClick={handleButtonClickEmail(
											{
												vertical: 'bottom',
												horizontal: 'left'
											},
											TransitionRight
										)}
									>
										{successEmail ? (
											<CheckIcon />
										) : failedEmail ? (
											<CloseIcon />
										) : (
											<SaveIcon className={classes.buttonSave} />
										)}
									</Fab>
									{loadingEmail && (
										<CircularProgress
											size={52}
											className={classes.fabProgress}
										/>
									)}
								</div>
							</form>
						</Grid>
					</AccordionDetails>
				</Accordion>
				{user.origen_cuenta === "Registro Normal" && (
					<>
						<Accordion
							expanded={expanded === 'panel4'}
							onChange={handleChangeAccordion('panel4')}
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon color="primary" />}
								aria-controls="panel4bh-content"
								id="panel4bh-header"
							>
								<Typography className={classes.heading}>{t('change-password')}</Typography>
								<Typography className={classes.secondaryHeading}></Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid item xs={10} md={5}>
									<form
										onSubmit={handleSubmitPassword}
										className={classes.formContainer}
									>
										<TextField
											type="password"
											name="passwordCurrent"
											variant="outlined"
											disabled={passwordValidate.ok !== true ? false : true}
											fullWidth
											inputRef={registerPasswordCurrent({
												required: {
													value: true,
													message: t('complete-validation')
												},
												validate: () => {
													if (passwordValidate.ok !== true) {
														return t('password-current-validation');
													} else {
														return null;
													}
												}
											})}
											helperText={
												passwordValidate.ok !== true
													? errorsPasswordCurrent?.passwordCurrent?.message
													: null
											}
											error={
												passwordValidate.ok !== true
													? errorsPasswordCurrent?.passwordCurrent?.message
														? true
														: false
													: false
											}
											onChange={handleChangePasswordCurrent}
										/>
									</form>
								</Grid>
								<Grid item xs={2} md={2}>
									{/* <Typography className={classes.heading} gutterBottom>
								{t('new') + ' ' + t('password')}
							</Typography> */}
									<form
										onSubmit={handleSubmitPassword(onSubmitPassword)}
										className={classes.formContainer}
									>
										{/* <TextField
									type="password"
									name="password"
									variant="outlined"
									disabled={passwordValidate.ok !== true ? true : false}
									fullWidth
									inputRef={registerPassword({
										required: {
											value: true,
											message: t('complete-validation')
										},
										pattern: {
											value: /^.{5,12}$/,
											message: t('password-new-validation')
										}
									})}
									helperText={errorsPassword?.password?.message}
									error={errorsPassword?.password?.message ? true : false}
									onChange={handleChangePassword}
								/> */}
										<div className={classes.wrapper}>
											<Fab
												disabled={passwordValidate.ok !== true ? true : false}
												type="submit"
												size="small"
												aria-label="save"
												color="primary"
												className={buttonClassname}
												onClick={handleButtonClickPassword(
													errorsPassword?.password?.message ? 'error' : 'success'
												)}
											>
												{successPassword ? (
													<CheckIcon />
												) : failedPassword ? (
													<CloseIcon />
												) : (
													<SaveIcon className={classes.buttonSave} />
												)}
											</Fab>
											{loadingPassword && (
												<CircularProgress
													size={52}
													className={classes.fabProgress}
												/>
											)}
										</div>
									</form>
								</Grid>

							</AccordionDetails>
						</Accordion>
					</>
				)}
			</div>
		</div>
	);
}

export default function IntegrationNotistack() {
	return (
		<SnackbarProvider maxSnack={1}>
			<Profile />
		</SnackbarProvider>
	);
}
