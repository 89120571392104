import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { ReactComponent as IconAllRequests } from '../../../../assets/icons/userpets/IconAllRequests.svg';
import { ReactComponent as IconPetsAccepted } from '../../../../assets/icons/userpets/IconPetsAccepted.svg';
import { ReactComponent as IconPetsRejected } from '../../../../assets/icons/userpets/IconPetsRejected.svg';
import { ReactComponent as IconPetsPending } from '../../../../assets/icons/userpets/IconPetsPending.svg';
import { PetRequest } from './PetRequest';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { set_selected_request_action } from '../../../../redux/actions/userPetsAction';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-force-tabpanel-${index}`}
			aria-labelledby={`scrollable-force-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `scrollable-force-tab-${index}`,
		'aria-controls': `scrollable-force-tabpanel-${index}`
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper
	}
}));

export default function TabsRequest() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const classes = useStyles();
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const theme = useTheme();

	const matches = useMediaQuery(theme.breakpoints.down('xs'));

	const handlePutNull = () => {
		dispatch(set_selected_request_action(null));
	};

	return (
		<div className={classes.root}>
			<AppBar position="static" color="default">
				<Tabs
					value={value}
					onChange={handleChange}
					variant={matches ? 'scrollable' : 'standard'}
					scrollButtons="on"
					indicatorColor="primary"
					textColor="primary"
					aria-label="scrollable force tabs example"
					centered
				>
					<Tab
						onClick={handlePutNull}
						label={t("requests-received")}
						icon={<IconAllRequests width={50} />}
						{...a11yProps(0)}
					/>
					<Tab
						onClick={handlePutNull}
						label={t("accepted")}
						icon={<IconPetsAccepted width={50} />}
						{...a11yProps(1)}
					/>
					<Tab
						onClick={handlePutNull}
						label={t("rejected")}
						icon={<IconPetsRejected width={50} />}
						{...a11yProps(2)}
					/>
					<Tab
						onClick={handlePutNull}
						label={t("pending")}
						icon={<IconPetsPending width={50} />}
						{...a11yProps(3)}
					/>
				</Tabs>
			</AppBar>
			<TabPanel value={value} index={0}>
				<PetRequest />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<PetRequest AdoptionRequest={true} />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<PetRequest AdoptionRequest={false} />
			</TabPanel>
			<TabPanel value={value} index={3}>
				<PetRequest AdoptionRequest={null} />
			</TabPanel>
		</div>
	);
}
