import axiosClient from './axios';
import { setEncryptPassword } from '../components/utils';

export async function saveUserLogin(userData) {
    try {
        console.log('userData', userData);
        const formData = new FormData();
        formData.append('password', setEncryptPassword(userData.password))
        formData.append('correo', userData.correo)
        formData.append('origen_cuenta', userData.origen_cuenta)
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
        const response = await axiosClient.post('/login', formData);
        return response.data
    } catch (e) {
        console.log(e.response, 'e')
        return e.response.data
    }

}

// export const use_alert_action = (state) => async (dispatch) => {
// 	console.log(state, 'staaaa')
// 	dispatch({
// 		type: ALERT_PASSWORD_CODE,
// 		payload: state
// 	});
// };

export async function LoginUserRegistered(userLog) {
    try {
        const formData = new FormData();

        formData.append('password', setEncryptPassword(userLog.password))
        formData.append('correo', userLog.correo)
        formData.append('origen_cuenta', 'Registro_normal')
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
        const response = await axiosClient.post('/login', formData);
        return response
    } catch (e) {
        console.log(e)
    }

}

// export async function saveGoogleUser(userData) {
//     try {
//         console.log(userData)
//         const formData = new FormData();

//         formData.append('googleId', userData.googleId)
//         formData.append('imageUrl', userData.imageUrl)
//         formData.append('name', userData.name)
//         formData.append('email', userData.email)
//         formData.append('givenName', userData.email)
        

//         const response = await axios({
//             url: `${baseURL}/login`,
//             method: 'POST',
//             data: formData,
//         })
//         return response
//     } catch (e) {
//         console.log(e)
//     }

// }