import {
	addFavourite,
	getFavourite,
	showFavorite
} from '../../configAxios/favouritePets';
import {
	ADD_FAVOURITE_PET,
	CLEAN_FAVOURITE_PET,
	GET_FAVOURITE_PET,
	SHOW_FAVOURITE_PET
} from '../types';

export const add_favourite_pet_action = (favouriteData) => async (dispatch) => {
	try {
		const response = await addFavourite(favouriteData);
		console.log(response);
		dispatch({
			type: ADD_FAVOURITE_PET,
			payload: response.data
		});
	} catch (error) {
		console.log(error);
	}
};

export const get_favourite_pet_action = (favouriteData) => async (dispatch) => {
	try {
		const response = await getFavourite(favouriteData);
		console.log(response);
		dispatch({
			type: GET_FAVOURITE_PET,
			payload: response.data.fav
		});
	} catch (error) {
		console.log(error);
	}
};

export const show_favourite_pet_action = (favouriteData) => async (dispatch) => {
	try {
		const response = await showFavorite(favouriteData);
		console.log(response);
		dispatch({
			type: SHOW_FAVOURITE_PET,
			payload: response.data.fav
		});
	} catch (error) {
		console.log(error);
	}
};

export const clean_favourite_pet_action = () => async (dispatch) => {
		dispatch({
			type: CLEAN_FAVOURITE_PET,
		});
}
