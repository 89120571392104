import React, { useState } from 'react';
import { Button, makeStyles, withStyles, Hidden, ListItemIcon, ListItemText, MenuItem, Menu } from '@material-ui/core';

//icons
import iconFind from '../../../assets/icons/drawer/iconFind-final.svg';
import iconLost from '../../../assets/icons/drawer/iconLost-final.svg';
import iconRecover from '../../../assets/icons/drawer/iconRecover-final.svg';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


//redux
import { useDispatch, useSelector } from 'react-redux';

import {
	// login_dialog_open_action,
	adoptstepper_dialog_open_action,
	// adoptstepper_dialog_close_action,
	adopt_dialog_open_action
} from '../../../redux/actions/loginAction';
import { no_user_pets_action } from '../../../redux/actions/saciPets';
import { useTranslation } from 'react-i18next';
import ButtonPwa from './ButtonPwa';

const useStyles = makeStyles((theme) => ({
	findPetButtonDesktop: {
		color: '#fff',
		textTransform: 'none',
		fontSize: '15px',
		[theme.breakpoints.between('xs', 'sm')]: {
			color: '#000'
		}
	},
	menuIcons: {
		width: '40px',
		[theme.breakpoints.only('xs')]: {
			width: '30px'
		}
	}
}));

const StyledMenu = withStyles({
	paper: {
		border: '1px solid #d3d4d5',
		marginTop: '7px'
	}
})((props) => (
	<Menu
		// elevation={2}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center'
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center'
		}}
		{...props}
	/>
));

const StyledMenuItem = withStyles((theme) => ({
	root: {
		'&:focus': {
			backgroundColor: theme.palette.primary.main,
			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
				color: theme.palette.common.white
			}
		}
	}
}))(MenuItem);


export default function FindPetButton({ handleMenuClose }) {
	const [t] = useTranslation("global");
	const { hideMenuButtons } = useSelector((state) => state.main);
	///////////////Constantes Botón Encuentra tu mascota////////////////
	const [anchorEl, setAnchorEl] = useState(null);
	const { showUserPets } = useSelector((state) => state.saciPets);
	const { showOutReqPets } = useSelector((state) => state.saciPets);
	const { onEntryRequestPage } = useSelector((state) => state.userPets);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	////////////////////////////////////////////////////////////////////
	const dispatch = useDispatch();

	const [tramite2] = useState({
		tipo_tramite: 2
	});

	const handleClickOpenModalRecover = () => {
		handleMenuClose();
		setAnchorEl(null);
		dispatch(no_user_pets_action());
		dispatch(adopt_dialog_open_action(tramite2));
		dispatch(adoptstepper_dialog_open_action());
	};

	const [tramite3] = useState({
		tipo_tramite: 3
	});

	const handleClickOpenModalLost = () => {
		handleMenuClose();
		setAnchorEl(null);
		dispatch(no_user_pets_action());
		dispatch(adopt_dialog_open_action(tramite3));
		dispatch(adoptstepper_dialog_open_action());
	};

	const classes = useStyles();

	const showButton =
		showUserPets || showOutReqPets || onEntryRequestPage || hideMenuButtons;

	return (
		<>
			<Hidden smDown>
				<ButtonPwa
					color={'#FFFFFF'}
				/>
				{showButton ? null : (
					<Button
						color="secondary"
						className={classes.findPetButtonDesktop}
						onClick={handleClick}
						startIcon={
							<img src={iconFind} alt="LogIn" className={classes.menuIcons} />
						}
					>
						<ArrowDropDownIcon />
						<Hidden only="md">{t("find-pet")}</Hidden>
					</Button>
				)}
			</Hidden>
			{!showButton && (
				<Hidden mdUp>
					<MenuItem
						color="secondary"
						className={classes.findPetButtonDesktop}
						onClick={handleClick}
					>
						<img src={iconFind} alt="LogIn" className={classes.menuIcons} />
						<ArrowDropDownIcon />
						{t("find-pet")}
					</MenuItem>
				</Hidden>

			)}


			<StyledMenu
				autoFocus={false}
				id="customized-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<StyledMenuItem onClick={handleClickOpenModalRecover}>
					<ListItemIcon>
						<img
							src={iconRecover}
							alt="Lost Pets"
							className={classes.menuIcons}
						/>
					</ListItemIcon>
					<ListItemText primary={t("found-pet")} />
				</StyledMenuItem>
				<StyledMenuItem onClick={handleClickOpenModalLost}>
					<ListItemIcon>
						<img src={iconLost} alt="Lost Pets" className={classes.menuIcons} />
					</ListItemIcon>
					<ListItemText primary={t("lost-pet")} />
				</StyledMenuItem>
			</StyledMenu>
		</>
	);
}
