import axiosClient from './axios';
import { setEncryptPassword } from '../components/utils';

export async function saveUserEdit(editName) {
  try {
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
    const response = await axiosClient.post('/CambiarNombre', editName);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function savePhoneEdit(editPhone) {
  try {
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
    const response = await axiosClient.post('/CambiarTelefono', editPhone);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function saveEmailEdit(editEmail) {
  try {
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
    const response = await axiosClient.post('/CambiarEmail', editEmail);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function savePasswordEdit(editPassword) {
  console.log('editPassword', editPassword);

  try {
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
    const response = await axiosClient.post('/CambiarContrasena', { ...editPassword, password: setEncryptPassword(editPassword) });
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function validatePasswordEdit(editPasswordCurrent) {
  const formData = new FormData();

  formData.append('password', setEncryptPassword(editPasswordCurrent.passwordCurrent));
  formData.append('id', editPasswordCurrent.id);

  try {
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
    const response = await axiosClient.post('/CompararContra', formData);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function uploadPhotoEdit(userimage, id) {
  const formData = new FormData();

  formData.append('consecutivo', 1);
  formData.append('photo', userimage);
  formData.append('id_usuario', id);

  try {
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
    const response = await axiosClient.post('/upload2', formData);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export async function deletePhotoEdit(id) {
  const formData = new FormData();

  formData.append('id_usuario', id);

  try {
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
    const response = await axiosClient.post('/EliminarFotoUser', formData);
  } catch (error) {
    console.log(error);
  }
}
