/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { get_saci_pets_action } from '../../../redux/actions/saciPets';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

const useStyles = makeStyles((theme) => ({
	inputRoot: {
		color: 'inherit'
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create('width'),
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			width: '12ch',
			'&:focus': {
				width: '20ch'
			}
		}
	}
}));

export const FiltersSearchPets = () => {
	const [t] = useTranslation('global');

	const classes = useStyles();

	const dispatch = useDispatch();
	
	const { id_pais } = useSelector((state) => state.login.user);

	const [storedToken] = useLocalStorage('token', '');

	const [searchTerm, setSearchTerm] = useState('');

	const handleChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const [stateSearch, setStateSearch] = useState(false);

	useEffect(() => {
		if (!id_pais && stateSearch && !storedToken) {
			dispatch(get_saci_pets_action({ searchTerm }));
		}
		if(id_pais && stateSearch) {
			dispatch(get_saci_pets_action({ searchTerm, id_pais }));
		}
	}, [searchTerm]);

	return (
		<div>
			<InputBase
				placeholder={t('search')}
				classes={{
					root: classes.inputRoot,
					input: classes.inputInput
				}}
				inputProps={{ 'aria-label': 'search' }}
				value={searchTerm}
				onClick={()=>setStateSearch(true)}
				onChange={handleChange}
				name="search"
				autoComplete="off"
			/>
		</div>
	);
};
