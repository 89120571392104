import axios from 'axios';
import axiosClient from './axios';


export async function getDataUserGoogle(userData) {
    try {
        let response;
        await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${userData.access_token}`, {
            headers: {
                Authorization: `Bearer ${userData.access_token}`,
                Accept: 'application/json'
            }
        })
            .then((res) => {
                return saveUserGoogle({
                    ...res.data,
                    token: userData.access_token
                })
            })
        return null
    } catch (e) {
        /* console.log(e) */
    }

}

export async function saveUserGoogle(userData) {
    console.log(userData, 'userData');
    try {
        const formData = new FormData();

        formData.append('id_rol', '1')
        formData.append('imageUrl', userData.picture)
        formData.append('name', userData.name)
        formData.append('correo', userData.email)
        formData.append('origen_cuenta', 'google')
        
     //   axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
        const response = await axiosClient.post(`/login?code=${userData.code}&id_rol=1`, formData);
        console.log(response, 'responseresponse');
        return response
    } catch (e) {
        console.log(e)
    }

}