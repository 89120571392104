import {
	questionUser,
	answerUser,
	getQuestionAnswerUser,
	getQuestionAnswerUser2
} from '../../configAxios/questionUsers';

import {
	SEND_QUESTION_USER,
	SEND_ANSWER_USER,
	GET_QUESTION_ANSWER_USER,
	GET_QUESTION_ANSWER_USER_2,
	CLEAN_QUESTION_ANSWER_USER,
	DIALOG_SHOW_REQUEST
} from '../types';

export const send_question_user_action = (petdata) => async (dispatch) => {
	try {
		const response = await questionUser(petdata);
		// console.log(response);

		dispatch({
			type: SEND_QUESTION_USER,
			payload: response.data
		});
	} catch (error) {
		console.log(error);
	}
};

export const send_answer_user_action = (petdataAnswer) => async (dispatch) => {
	try {
		const response = await answerUser(petdataAnswer);
		// console.log(response);

		dispatch({
			type: SEND_ANSWER_USER,
			payload: response.data
		});
	} catch (error) {
		console.log(error);
	}
};

export const get_question_answer_user_action =
	(questionData) => async (dispatch) => {
		try {
			const response = await getQuestionAnswerUser(questionData);
			// console.log(response);

			let question_answer;

			if (!response.data.form) {
				question_answer = response.data;
			} else {
				question_answer = response.data.form;
			}

			dispatch({
				type: GET_QUESTION_ANSWER_USER,
				payload: question_answer
			});
		} catch (error) {
			console.log(error);
		}
	};

export const get_question_answer_user_2_action =
	(requestData) => async (dispatch) => {
		try {
			const response = await getQuestionAnswerUser2(requestData);
			// console.log(response);

			let question_answer_2;

			if (!response.data.form) {
				question_answer_2 = response.data;
			} else {
				question_answer_2 = response.data.form;
			}

			dispatch({
				type: GET_QUESTION_ANSWER_USER_2,
				payload: question_answer_2
			});
		} catch (error) {
			console.log(error);
		}
	};

export const clean_question_answer_user_action = () => async (dispatch) => {
	dispatch({
		type: CLEAN_QUESTION_ANSWER_USER
	});
};

export const dialog_show_request_action = (dialog) => async (dispatch) => {
	dispatch({
		type: DIALOG_SHOW_REQUEST,
		payload: dialog
	});
};
