/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import {
	makeStyles,
	withStyles,
	Button,
	Grid,
	Dialog,
	Hidden,
	Toolbar,
	IconButton,
	Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	useMediaQuery,
	Avatar,
	Tooltip
} from '@material-ui/core';

import { useTheme } from '@material-ui/core/styles';

import IconProfile from '../../assets/icons/drawer/iconProfile.svg';
import IconPetsPosted from '../../assets/icons/drawer/iconPetsPosted.svg';
import IconAdoptionRequest from '../../assets/icons/drawer/iconAdoptionRequest.svg';
import IconLogout from '../../assets/icons/drawer/iconLogout.svg';

import petUser from '../../assets/icons/drawer/petUser-final.svg';
import CloseIcon from '@material-ui/icons/Close';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
//Redux
import { useSelector, useDispatch } from 'react-redux';

// Swal Alerts
import Swal from 'sweetalert2';

import Login from '../../pages/Login';

import Register from '../../pages/Register';

import {
	LoginRegisteredAction,
	login_dialog_close_action,
	login_dialog_open_action,
	adopt_dialog_close_action,
	adoptstepper_dialog_open_action,
	check_login_action,
	check_logout_action
} from '../../redux/actions/loginAction';

import {
	register_dialog_close_action,
	save_register_to_login
} from '../../redux/actions/registerAction';

import {
	clean_user_pets_data_action,
	save_user_pet_id_action,
	set_on_my_request_page_action,
	set_on_send_request_page_action
} from '../../redux/actions/userPetsAction';

import {
	get_saci_pets_action,
	get_saci_pets_validate_clean_Action,
	select_pet_action,
	set_user_pets_state_action,
	show_user_pets_action
} from '../../redux/actions/saciPets';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import ForgotPassword from './ForgotPassword';
import { useTranslation } from 'react-i18next';
import { get_output_request_pets_clean_action } from '../../redux/actions/outputRequestAction';
import { clean_favourite_pet_action } from '../../redux/actions/favouritePetsAction';
import { useDialog } from '../../hooks/useDialog';
import CountryDialog from './CountryDialog';

const useStyles = makeStyles((theme) => ({
	accountCircle: {
		color: '#fff',
		textTransform: 'none',
		fontSize: '15px',
		[theme.breakpoints.between('xs', 'sm')]: {
			color: '#000'
		}
	},
	loginButton: {
		color: '#fff',
		textTransform: 'none',
		fontSize: '15px',
		[theme.breakpoints.between('xs', 'sm')]: {
			color: '#000'
		}
	},
	accountIcon: {
		width: '40px'
	},

	menuIcons: {
		width: '40px',
		[theme.breakpoints.only('xs')]: {
			width: '30px'
		}
	}
}));

const StyledMenu = withStyles({
	paper: {
		border: '1px solid #d3d4d5',
		marginTop: '5px'
	}
})((props) => (
	<Menu
		// elevation={2}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: 'bottom',
			horizontal: 'center'
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'center'
		}}
		{...props}
	/>
));

const StyledMenuItem = withStyles((theme) => ({
	root: {
		'&:focus': {
			backgroundColor: theme.palette.primary.main,
			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
				color: theme.palette.common.white
			}
		}
	}
}))(MenuItem);

export default function SectionDesktop({ handleMenuClose }) {
	const [t, i18n] = useTranslation('global');
	const theme = useTheme();
	// const [googleData, setGoogleData] = useLocalStorage('google-session', '')
	const [storedToken, setStoredToken] = useLocalStorage('token', '');

	const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

	const dispatch = useDispatch();

	const { checkLogin } = useSelector((state) => state.login);

	const { nombres, id_pais, origen_cuenta } = useSelector(
		(state) => state.login.user
	);

	// const { registerData } = useSelector((state) => state.register);

	const { loginDialog, loader: loaderLogin } = useSelector(
		(state) => state.login
	);

	const { registerDialog, loader: loaderRegister } = useSelector(
		(state) => state.register
	);

	const { userPetsRegistered, petRequestArray } = useSelector(
		(state) => state.userPets
	);

	const { outputRequestData } = useSelector((state) => state.outputRequest);

	const { registerLoginData } = useSelector((state) => state.register);

	const { userPhoto, photoProfile, reloadImage } = useSelector((state) => state.login);

	///////////////Constantes Botón Encuentra tu mascota////////////////
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		dispatch(clean_user_pets_data_action());
		dispatch(save_user_pet_id_action(null));
		dispatch(select_pet_action(null));
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const [anchorEl2, setAnchorEl2] = React.useState(null);

	const handleClick2 = (event) => {
		setAnchorEl2(event.currentTarget);
	};

	const handleClose2 = () => {
		setAnchorEl2(null);
	};
	////////////////////////////////////////////////////////////////////

	const classes = useStyles();

	const handleClickOpenLogin = () => {
		dispatch(adopt_dialog_close_action());
		dispatch(login_dialog_open_action());
	};

	const handleClickCloseLogin = () => {
		dispatch(login_dialog_close_action());
	};

	const handleClickCloseRegister = () => {
		dispatch(register_dialog_close_action());
	};

	const { open, handleClickOpen, handleClose: handleCloseDialog } = useDialog();

	useEffect(() => {
		if (nombres.length !== 0) {
			if (checkLogin === false) {
				i18n.changeLanguage(
					id_pais === '1214' || id_pais === '1242' || id_pais === '1343'
						? 'en'
						: id_pais === '1205'
							? 'pt'
							: 'es'
				);
				Swal.fire({
					icon: 'success',
					title: `${t('welcome')} ${nombres}`,
					text: t('session-initiated'),
					confirmButtonColor: '#63C132'
				}).then((result) => {
					dispatch(login_dialog_close_action());
					dispatch(register_dialog_close_action());
					dispatch(adoptstepper_dialog_open_action());
					if (result.isConfirmed) {
						Swal.close();
						dispatch(check_login_action());
						if (origen_cuenta === 'facebook' || origen_cuenta === 'google' && id_pais === null) {
							handleClickOpen();
						}
					}
				});
			}
		}
	}, [nombres, checkLogin, registerLoginData]);

	useEffect(() => {
		if (registerLoginData.length !== 0) {
			dispatch(LoginRegisteredAction(registerLoginData));
			dispatch(register_dialog_close_action());
		}
		dispatch(save_register_to_login(''));
	}, [registerLoginData]);

	const handleclickMyPets = () => {
		if (userPetsRegistered?.length) {
			history.push('my_pets');
			dispatch(show_user_pets_action(userPetsRegistered));
		} else {
			setAnchorEl(null);
			Swal.fire({
				icon: 'error',
				title: t('dont-pets-published'),
				confirmButtonColor: '#63C132'
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.close();
				}
			});
		}
	};

	const handleClickOutputRequest = () => {
		setAnchorEl2(null);
		setAnchorEl(null);
		if (petRequestArray?.length) {
			dispatch(set_on_my_request_page_action(true));
			dispatch(set_on_send_request_page_action(false));
			dispatch(set_user_pets_state_action(false));
			history.push('/entry_requests');
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: t('no-currently-received'),
				confirmButtonColor: '#63C132'
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.close();
				}
			});
		}
	};

	// Rediccionamiento a perfil de usuario con history push
	let history = useHistory();
	function handleclickMyProfile() {
		setAnchorEl(null);
		history.push('/Profile');
	}

	const baseURL = process.env.REACT_APP_BACKEND_URL;

	const handleClickSendRequest = () => {
		setAnchorEl2(null);
		setAnchorEl(null);
		if (outputRequestData) {
			dispatch(set_on_send_request_page_action(true));
			dispatch(set_on_my_request_page_action(false));
			history.push('/exit_requests');
		} else {
			Swal.fire({
				icon: 'error',
				title: 'Oops...',
				text: t('no-currently-sent'),
				confirmButtonColor: '#63C132'
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.close();
				}
			});
		}
	};

	const handleClickLogout = () => {
		setAnchorEl(null);
		handleMenuClose && handleMenuClose();
		Swal.fire({
			title: t('want-log-out'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#d33',
			cancelButtonColor: '#63C132',
			confirmButtonText: t('yes-exit'),
			cancelButtonText: t('no-cancel')
		}).then((result) => {
			if (result.isConfirmed) {
				Swal.fire(t('session-end'), '', 'success');
				// setGoogleData('');
				setStoredToken('');
				// setIngresar('Ingresar')
				dispatch(check_logout_action());
				dispatch(get_output_request_pets_clean_action());
				dispatch(get_saci_pets_action({ id_tipo_mascota: false }));
				dispatch(get_saci_pets_validate_clean_Action());
				dispatch(clean_favourite_pet_action());
				localStorage.removeItem('token')
				window.location.reload();
				history.push('/');
			}
		});
	};

	return (
		<>
			{nombres ? (
				<>
					<Hidden smDown>
						<Button
							className={classes.accountCircle}
							edge="end"
							aria-label="account of current user"
							aria-haspopup="true"
							color="inherit"
							onClick={handleClick}
							startIcon={
								!photoProfile && !userPhoto ? (
									<img
										src={petUser}
										alt="Login"
										className={classes.menuIcons}
									/>
								) : (
									<Avatar
										src={userPhoto[0].id ? `${baseURL}${userPhoto[0].id}.jpg` : userPhoto[0].foto}
										// src={state.imgPath}
										className={classes.menuIcons}
										alt="Icon Profile"
									/>
								)
							}
						>
							<ArrowDropDownIcon />
							{`${nombres}`}
						</Button>
					</Hidden>

					<Hidden mdUp>
						<MenuItem
							className={classes.accountCircle}
							edge="end"
							aria-label="account of current user"
							aria-haspopup="true"
							color="inherit"
							onClick={handleClick}
						>
							<img src={petUser} alt="Login" className={classes.menuIcons} />
							<ArrowDropDownIcon />
							{`${nombres}`}
						</MenuItem>
					</Hidden>

					<StyledMenu
						autoFocus={false}
						id="customized-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center'
						}}
					>
						<StyledMenuItem onClick={handleclickMyProfile}>
							<ListItemIcon>
								<img
									src={IconProfile}
									className={classes.menuIcons}
									alt="Icon Profile"
								/>
								{/* {!userPhoto ? (
									<img
										src={IconProfile}
										className={classes.menuIcons}
										alt="Icon Profile"
									/>
								) : (
									<Avatar
										src={`${baseURL}${userPhoto[0].id}.jpg`}
										className={classes.menuIcons}
										alt="Icon Profile"
									/>
								)} */}
							</ListItemIcon>

							<ListItemText primary={t('profile')} />
						</StyledMenuItem>
						<StyledMenuItem onClick={handleclickMyPets}>
							<ListItemIcon>
								<img
									src={IconPetsPosted}
									className={classes.menuIcons}
									alt="Icon Pets Posted"
								/>
							</ListItemIcon>
							<ListItemText primary={t('my-pets')} />
						</StyledMenuItem>

						<StyledMenuItem onClick={handleClick2}>
							<ListItemIcon>
								<img
									src={IconAdoptionRequest}
									className={classes.menuIcons}
									alt="Icon Adoption Request"
								/>
							</ListItemIcon>
							<ListItemText primary={t('requests')} />
						</StyledMenuItem>

						<StyledMenu
							autoFocus={false}
							id="customized-menu"
							anchorEl={anchorEl2}
							keepMounted
							open={Boolean(anchorEl2)}
							onClose={handleClose2}
							anchorOrigin={{
								vertical: 'center',
								horizontal: 'left'
							}}
							transformOrigin={{
								vertical: 'center',
								horizontal: 'right'
							}}
						>
							<StyledMenuItem onClick={handleClickOutputRequest}>
								<ListItemIcon>
									<img
										src={IconAdoptionRequest}
										className={classes.menuIcons}
										alt="Icon Output"
									/>
								</ListItemIcon>
								<ListItemText primary={t('received')} />
							</StyledMenuItem>
							<StyledMenuItem onClick={handleClickSendRequest}>
								<ListItemIcon>
									<img
										src={IconAdoptionRequest}
										className={classes.menuIcons}
										alt="Icon Adoption Request"
									/>
								</ListItemIcon>
								<ListItemText primary={t('sent')} />
							</StyledMenuItem>
						</StyledMenu>

						<StyledMenuItem onClick={handleClickLogout}>
							<ListItemIcon>
								<img
									src={IconLogout}
									className={classes.menuIcons}
									alt="Icon Logout"
								/>
							</ListItemIcon>
							<ListItemText primary={t('sign-off')} />
						</StyledMenuItem>
					</StyledMenu>
				</>
			) : (
				<>
					<Hidden smDown>
						<Button
							// fullWidth
							color="secondary"
							className={classes.loginButton}
							onClick={handleClickOpenLogin}
							startIcon={
								<img src={petUser} alt="Login" className={classes.menuIcons} />
							}
						>
							<ArrowDropDownIcon />
							{t('login')}
						</Button>
					</Hidden>

					<Hidden mdUp>
						<MenuItem
							color="secondary"
							className={classes.loginButton}
							onClick={handleClickOpenLogin}
						>
							<img src={petUser} alt="Login" className={classes.menuIcons} />
							<ArrowDropDownIcon />
							{t('login')}
						</MenuItem>
					</Hidden>
				</>
			)}

			<Dialog
				open={loginDialog === true}
				onClose={!loaderLogin ? handleClickCloseLogin : null}
				style={{ zIndex: 1 }}
				fullScreen={fullScreen}
			>
				<Grid container justify="flex-end">
					<Tooltip title={t('close')} >
						<Toolbar>
							<IconButton
								edge="end"
								color="primary"
								aria-label="close"
								onClick={handleClickCloseLogin}
							>
								<CloseIcon />
							</IconButton>
						</Toolbar>
					</Tooltip>
				</Grid>
				<Login />
			</Dialog>
			<Dialog
				open={registerDialog === true}
				onClose={!loaderRegister ? handleClickCloseRegister : null}
				style={{ zIndex: 1 }}
				fullScreen={fullScreen}
				maxWidth="sm"
			>
				<Grid container justify="flex-end">
					<Tooltip title={t('close')} >
						<Toolbar>
							<IconButton
								edge="end"
								color="primary"
								aria-label="close"
								onClick={handleClickCloseRegister}
							>
								<CloseIcon />
							</IconButton>
						</Toolbar>
					</Tooltip>

				</Grid>
				<Register />
			</Dialog>
			<ForgotPassword />
			{/* <Button onClick={handleClickOpen}>Open Dialog</Button> */}
			<CountryDialog open={open} onClose={handleCloseDialog} />
		</>
	);
}
