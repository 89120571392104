import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import {
	Button,
	createMuiTheme,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Grid,
	Hidden,
	makeStyles,
	useMediaQuery,
	useTheme
} from '@material-ui/core';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import CancelIcon from '@material-ui/icons/Cancel';

import { ThemeProvider } from '@material-ui/styles';

import { DialogTitle, Transition } from './DialogComponents/CustomDialog';

import { useTranslation } from 'react-i18next';


import Terminos from '../../assets/svg/terminos_condiciones.svg';

const useStyles = makeStyles((theme) => ({
	dialog: {
		'&& .MuiDialogActions-root': {
			justifyContent: 'flex-start'
		}
	},
	button: {
		marginRight: theme.spacing(10)
	}
}));

export default function ConditionDialog(props) {
	const classes = useStyles();

	const { open, onClose } = props;

	const theme = useTheme();

	const [t] = useTranslation('global');

	const themeButton = createMuiTheme({
		palette: {
			primary: {
				main: '#DB4A39'
			}
		}
	});

	const matches = useMediaQuery(theme.breakpoints.only('xs'));

	const descriptionElementRef = useRef(null);

	useEffect(() => {
		if (open) {
			const { current: descriptionElement } = descriptionElementRef;
			if (descriptionElement !== null) {
				descriptionElement.focus();
			}
		}
	}, [open]);

	const handleCheck = (checked) => {
		onClose(checked);
	};

	return (
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth={'sm'}
			fullScreen={matches}
			className={classes.dialog}
			scroll={matches ? 'paper' : 'body'}
			TransitionComponent={Transition}
			fullWidth
			PaperProps={{
				style: {
					borderRadius: '15px',
					'&::WebkitScrollbar': {
						display: 'none'
					},
				}
			}}
		>
			<img
				style={{ position: 'absolute', padding: '10px' }}
				src={Terminos} />

			<DialogContent dividers>
				<Hidden
					mdUp
				>
					<DialogContentText
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
						style={{
							maxHeight: '162px',
							width: '250px',
							overflow: 'auto',
							textAlign: 'justify',
							marginTop: '25px',
							position: 'relative',
							whiteSpace: 'pre-line'
						}}
					>
						{t('terms-and-conditions')}
					</DialogContentText>
				</Hidden>
				<Hidden
					smDown
				>
					<DialogContentText
						id="scroll-dialog-description"
						ref={descriptionElementRef}
						tabIndex={-1}
						style={{
							maxHeight: '270px',
							width: '400px',
							overflow: 'auto',
							textAlign: 'justify',
							marginTop: '50px',
							position: 'relative',
							whiteSpace: 'pre-line'
						}}
					>
						{t('terms-and-conditions')}
					</DialogContentText>
				</Hidden>
			</DialogContent>
			<DialogActions >
				<Grid item xs={12} style={{ textAlign: 'center' }}>
					<Button
						variant="contained"
						color="primary"
						id="buttonWhite"
						startIcon={<CheckCircleIcon />}
						onClick={() => handleCheck(true)}
						style={{ marginRight: '5px' }}
					>
						Aceptar
					</Button>
					<ThemeProvider theme={themeButton}>
						<Button
							variant="contained"
							color="primary"
							startIcon={<CancelIcon />}
							onClick={() => handleCheck(false)}
						>
							Cancelar
						</Button>
					</ThemeProvider>
				</Grid>
			</DialogActions>
		</Dialog >
	);
}

ConditionDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired
};
