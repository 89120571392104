import { makeStyles } from '@material-ui/core';

export const userPetStyles = makeStyles((theme) => ({
	pageContainer: {
		marginTop: '1%',
		marginBottom: '1%'
	},
	userPetFilter: {
		paddingTop: 52,
		width: '250px',
		position: 'fixed',
	}
}));
