/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react';

import { makeStyles } from '@material-ui/core/styles';

// Clsx
import clsx from 'clsx';

import {
	Card,
	Avatar,
	CardHeader,
	CardActions,
	IconButton,
	Button,
	Grid,
	MenuItem,
	Menu,
	Zoom,
	Tooltip,
	ListItemIcon,
	ListItemText,
	Badge
} from '@material-ui/core';

import ShowRequest from '../../SaciComponents/UserComponents/AdoptRequest/ShowRequest';
import petLocation from '../../../assets/icons/drawer/iconFind-final.svg';
// Icons
import CancelIcon from '@material-ui/icons/Cancel';
import UpdateIcon from '@material-ui/icons/Update';
import Skeleton from '@material-ui/lab/Skeleton';
import FavoriteIconPet1 from '../../../assets/icons/cards/favorite-icon-pet.svg';
import FavoriteIconPet2 from '../../../assets/icons/cards/favorite-icon-pet-2.svg';

import ShareIconPet from '../../../assets/icons/cards/share-icon-pet.svg';
import FootprintIconPet from '../../../assets/icons/cards/footprint-icon-pet.svg';
import PetsIcon from '@material-ui/icons/Pets';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// Card Design
import frame1 from '../../../assets/icons/cards/marcoVerde.svg';
import frame2 from '../../../assets/icons/cards/marcoAmarillo.svg';
import frame3 from '../../../assets/icons/cards/marcoRojo.svg';

// Logo 404
import { ReactComponent as NotFound } from '../../../assets/icons/cards/404-spanish.svg';

// Accordion
import Typography from '@material-ui/core/Typography';

import Swal from 'sweetalert2';

//Redux
import { useDispatch, useSelector } from 'react-redux';

// useMediaQuery
import { useTheme } from '@material-ui/styles';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
	adopt_me_dialog_action,
	adopt_me_dialog_procedure_action,
	select_pet_action,
	select_pet_form_action,
	unlogged_modal_action
} from '../../../redux/actions/saciPets';

import {
	get_city_data_action,
	get_department_data_action,
	get_form_data_action,
	get_pet_colors_action,
	set_edit_user_pet_dialog,
	update_form_data_action
} from '../../../redux/actions/adoptFormAction';

import CarouselPhotos from './CarouselPhotos';

import {
	cancel_pet_request_action,
	get_pets_by_user_action,
	publish_request,
	save_selected_pet_data_action,
	save_user_pet_id_action,
	set_published_pet_action
} from '../../../redux/actions/userPetsAction';

import {
	cat_action,
	dog_action,
	hamster_action
} from '../../../redux/actions/petTypeAction';

import { get_pet_size_data } from '../../../redux/actions/petSizeAction';

import { get_output_request_pets_action } from '../../../redux/actions/outputRequestAction';
import { set_procedure_action } from '../../../redux/actions/loginAction';
import { hide_menu_buttons_action } from '../../../redux/actions/mainAction';
import { get_hamster_race_action } from '../../../redux/actions/getHamsterRaceAction';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ShareDialog } from '../CardComponents/ShareDialog';
import { clean_question_answer_pet_action } from '../../../redux/actions/questionPetsAction';

import '../../../assets/css/bone.css';
import PostPet from '../MessageComponents/PostPet';
import {
	add_favourite_pet_action,
	show_favourite_pet_action
} from '../../../redux/actions/favouritePetsAction';
import CustomSnackbar from '../CardComponents/CustomSnackbar';
import { useSnackbar } from '../../../hooks/useSnackbar';
import { useDialog } from '../../../hooks/useDialog';
import zIndex from '@material-ui/core/styles/zIndex';

const useStyles = makeStyles((theme) => ({
	avatar1: {
		backgroundColor: '#63C132'
	},

	avatar2: {
		backgroundColor: '#D8D841'
	},

	avatar3: {
		backgroundColor: '#BC3535'
	},

	iconSize: {
		width: '20px',
	},

	cardsPetsContainer: {
		width: '99%',
		marginLeft: 5,
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(0)
		}
	},

	cardsPets: {
		borderRadius: 10,
		boxShadow: ' 0px 0px 10px  #989898,  -5px -5px 10px #ffffff',
		[theme.breakpoints.down('xs')]: {
			margin: theme.spacing(0, 0, 3, 0)
		}
	},

	cardsPets1: {
		'&:hover': {
			boxShadow: '0 0 10px rgba(99, 193, 50), 0px 0px 20px rgba(99, 193, 50)'
		}
	},

	cardsPets2: {
		'&:hover': {
			boxShadow: '0 0 10px rgba(216, 216, 65), 0px 0px 20px rgba(216, 216, 65)'
		}
	},

	cardsPets3: {
		'&:hover': {
			boxShadow: '0 0 10px rgba(188, 53, 53), 0px 0px 20px rgba(188, 53, 53)'
		}
	},

	buttonPrimary: {
		color: '#ffffff',
		fontWeight: 'bold',
		textTransform: 'none',
		marginRight: '12px'
	},

	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular
	},

	paperContainer: {
		padding: '50px'
	},

	iconsCards: {
		width: '25px'
	},

	cardDesign: {
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100%',
		[theme.breakpoints.only('sm')]: {
			backgroundSize: '115%'
		}
	},

	cardDesign1: {
		backgroundImage: `url(${frame1})`
	},

	cardDesign2: {
		backgroundImage: `url(${frame2})`
	},

	cardDesign3: {
		backgroundImage: `url(${frame3})`
	},

	userPetTittleContainer: {
		margin: theme.spacing(2, 0, 5, 0)
	},

	RequestIcon: {
		width: '50px',
		[theme.breakpoints.only('xs')]: {
			width: '30px'
		}
	},

	CustomTooltip: {
		backgroundColor: '#1E3F20',
		color: 'white',
		opacity: 0.7
	},

	listItem: {
		minWidth: 40
	},

	badge: {
		' && .MuiBadge-badge': {
			border: `2px solid ${theme.palette.background.paper}`,
			padding: '0 4px',
			color: 'white'
		}
	},

	badge1: {
		'&& .MuiBadge-badge': {
			backgroundColor: '#BC3535'
		}
	},

	badge2: {
		'&& .MuiBadge-badge': {
			backgroundColor: '#1E3F20'
		}
	}
}));

////////////////////////////////////////////////////////////
export default function CardsPets({ UserPets }) {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const {
		pageMascotas,
		mascotas,
		showUserPets,
		petSelected,
		petFormSelected,
		title,
		showOutReqPets,
		petEmpty,
		petFiltersValidate,
		petFilters
	} = useSelector((state) => state.saciPets);
	const { nombres, id } = useSelector((state) => state.login.user);
	const classes = useStyles();

	const [checkLogin, setCheckLogin] = useState(false);

	const { userPetsRegistered } = useSelector((state) => state.userPets);
	const { userPetData } = useSelector((state) => state.userPets);
	const { editPetDialog } = useSelector((state) => state.adoptFormData);
	const { id_tipo_mascota, id_mascota } = useSelector(
		(state) => state.userPets.userPetData
	);
	const { descriptionData } = useSelector((state) => state.adoptFormData);
	const { vacunas } = useSelector((state) => state.userPets.userPetData);
	const [activePets, setActivePets] = useState(true);
	const { publishPet, publishReq } = useSelector((state) => state.userPets);
	const [loading, setLoading] = useState(true);
	const { outputRequestData } = useSelector((state) => state.outputRequest);

	useEffect(() => {
		dispatch(hide_menu_buttons_action(false));
	}, []);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1500);
		setLoading(true);
	}, [mascotas, pageMascotas]);

	useEffect(() => {
		switch (publishPet) {
			case '0':
				setActivePets(false);
				break;
			case '1':
				setActivePets(true);
				break;
			default:
		}
	}, [publishPet]);

	const [anchorEl, setAnchorEl] = useState(null);

	const [anchorEl_outReq, setAnchorEl_outReq] = useState(null);

	// Al dar clic botón Adóptame
	const handleClickAdoptMe = () => {
		if (checkLogin) {
			dispatch(adopt_me_dialog_action(true));
			dispatch(adopt_me_dialog_procedure_action(1));
			dispatch(set_procedure_action(1));
		} else {
			dispatch(unlogged_modal_action(true));
		}
	};

	// Al dar clic botón Encuentrame
	const handleClickFindMe = () => {
		if (checkLogin) {
			dispatch(adopt_me_dialog_action(true));
			dispatch(adopt_me_dialog_procedure_action(2));
			dispatch(set_procedure_action(2));
		} else {
			dispatch(unlogged_modal_action(true));
		}
	};

	// Al dar clic botón
	const handleClickHelpMe = () => {
		if (checkLogin) {
			dispatch(adopt_me_dialog_action(true));
			dispatch(adopt_me_dialog_procedure_action(3));
			dispatch(set_procedure_action(3));
		} else {
			dispatch(unlogged_modal_action(true));
		}
	};

	useEffect(() => {
		if (nombres) {
			setCheckLogin(true);
		} else {
			setCheckLogin(false);
		}
	}, [nombres]);

	useEffect(() => {
		if (id?.length !== 0 || publishReq === true) {
			dispatch(get_pets_by_user_action({ id_usuario: id }));
			dispatch(publish_request(false));
		}
	}, [id, publishReq]);

	const handleOpenMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClickOpenOutReqMenu = (event) => {
		setAnchorEl_outReq(event.currentTarget);
	};

	const handlePetMenuClose = () => {
		setAnchorEl(null);
	};

	const handleClickOutReqMenuClose = (event) => {
		setAnchorEl_outReq(null);
	};

	const theme = useTheme();

	const isMobile = useMediaQuery(theme.breakpoints.down('xs'), {
		defaultMatches: true
	});

	const skeletonResponsiveXL = useMediaQuery(theme.breakpoints.only('xl'), {
		defaultMatches: true
	});

	const skeletonResponsiveMDLG = useMediaQuery(
		theme.breakpoints.between('md', 'lg'),
		{
			defaultMatches: true
		}
	);

	const skeletonResponsiveSM = useMediaQuery(theme.breakpoints.down('sm'), {
		defaultMatches: true
	});

	const [cards, setCards] = useState(false);

	useEffect(() => {
		if (pageMascotas.length !== 0) {
			setCards(true);
		}
	}, [pageMascotas]);

	useEffect(() => {
		if (petFiltersValidate.length === 0) {
			setCards(false);
		}
	}, [petFiltersValidate]);

	useEffect(() => {
		if (petSelected !== null || showUserPets === true) {
			dispatch(save_user_pet_id_action(petSelected));
		}
	}, [petSelected]);

	useEffect(() => {
		if (petSelected !== null) {
			if (showUserPets) {
				const userPet = userPetsRegistered.filter(
					(pet) => pet.id_mascota === petSelected
				);
				dispatch(save_selected_pet_data_action(userPet[0]));
			} else {
				const userPet = pageMascotas.filter(
					(pet) => pet.id_mascota === petSelected
				);
				dispatch(save_selected_pet_data_action(userPet[0]));
			}
		}
	}, [petSelected]);

	const handleClickEditMe = () => {
		setAnchorEl(null);
		// dispatch(adopt_dialog_open_action(tramite1));
		dispatch(set_edit_user_pet_dialog(true));
		dispatch(set_procedure_action(1));
	};

	useEffect(() => {
		switch (id_tipo_mascota) {
			case '1':
				dispatch(cat_action());
				break;
			case '2':
				dispatch(dog_action());
				break;
			case '3':
				dispatch(hamster_action());
				break;
			default:
		}
	}, [id_tipo_mascota]);

	const depData = {
		id_codigo: userPetData.id_departamento
	};

	const countryData = {
		id_codigo: userPetData.id_pais
	};

	const petData = {
		id_tipo_mascota: userPetData.id_tipo_mascota,
		id_tamanio: userPetData.id_tamanio
	};

	const [userPetVaccines, setUserPetVaccines] = useState([]);
	const [checkVaccines, setCheckVaccines] = useState(false);
	const [rabia, setRabia] = useState(false);
	const [moquillo, setMoquillo] = useState(false);
	const [rino, setRino] = useState(false);
	const [parvo, setParvo] = useState(false);

	useEffect(() => {
		if (showUserPets) {
			let vaccines = vacunas.split(',');
			setUserPetVaccines(vaccines);
			if (userPetData.id_tipo_mascota === '3') {
				dispatch(get_hamster_race_action({ id_tipo_mascota: 3 }));
			}
		}
	}, [userPetData]);

	useEffect(() => {
		dispatch(get_pet_colors_action());
	}, []);

	useEffect(() => {
		if (userPetVaccines.length !== 0) {
			let rabia = userPetVaccines.indexOf('Rabia');
			let moquillo = userPetVaccines.indexOf('Moquillo');
			let rinotraqueitis = userPetVaccines.indexOf('Rinotraqueítis');
			let parvovirus = userPetVaccines.indexOf('Parvovirus');
			if (rabia > -1) {
				setRabia(true);
			}
			if (moquillo > -1) {
				setMoquillo(true);
			}
			if (rinotraqueitis > -1) {
				setRino(true);
			}
			if (parvovirus > -1) {
				setParvo(true);
			}
			setCheckVaccines(true);
		}
	}, [userPetVaccines]);

	useEffect(() => {
		// alert('user pet data')
		if (showUserPets === true && userPetData.id_mascota.length !== 0) {
			dispatch(get_form_data_action(userPetData));
			dispatch(update_form_data_action());
			dispatch(get_city_data_action(depData));
			dispatch(get_pet_size_data(petData));
			dispatch(get_department_data_action(countryData));
		}
	}, [showUserPets, userPetData]);

	useEffect(() => {
		if (checkVaccines) {
			dispatch(
				get_form_data_action({
					...descriptionData,
					id_vacuna_Rabia: rabia,
					id_vacuna_Moquillo: moquillo,
					id_vacuna_Parvovirus: parvo,
					id_vacuna_Rinotraqueítis: rino
				})
			);
			dispatch(update_form_data_action());
		}
		setCheckVaccines(false);
	}, [checkVaccines]);

	// useEffect(() => {
	// 	dispatch(get_department_data_action());
	// }, []);

	useEffect(() => {
		if (showUserPets) {
			dispatch(update_form_data_action());
		}
	}, [descriptionData]);

	useEffect(() => {
		if (!editPetDialog) {
			setCheckVaccines(false);
			setRabia(false);
			setParvo(false);
			setMoquillo(false);
			setRino(false);
		}
	}, [editPetDialog]);

	const handleUnpublishPet = () => {
		setAnchorEl(null);
		return Swal.fire({
			title: t('want-deactivate-pet'),
			icon: 'question',
			showDenyButton: true,
			confirmButtonColor: '#63C132',
			denyButtonColor: '#D33',
			confirmButtonText: t('accept'),
			denyButtonText: t('cancel')
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(
					set_published_pet_action({
						id_usuario: id,
						id_mascota: id_mascota,
						publicado: '0'
					})
				);
				Swal.fire({
					title: t('disabled-pet'),
					icon: 'error',
					confirmButtonColor: '#63C132'
				}).then((result) => {
					if (result.isConfirmed) {
						dispatch(publish_request(true));
						Swal.close();
					}
				});
			} else if (result.isDenied) {
				Swal.fire({
					title: t('changes-not-saved'),
					icon: 'info',
					confirmButtonColor: '#63C132'
				});
			}
		});
	};

	const handlePublishPet = () => {
		setAnchorEl(null);
		return Swal.fire({
			title: t('want-activate-pet'),
			icon: 'question',
			showDenyButton: true,
			confirmButtonColor: '#63C132',
			denyButtonColor: '#D33',
			confirmButtonText: t('accept'),
			denyButtonText: t('cancel')
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(
					set_published_pet_action({
						id_usuario: id,
						id_mascota: id_mascota,
						publicado: '1'
					})
				);
				Swal.fire({
					title: t('activated-pet'),
					icon: 'success',
					confirmButtonColor: '#63C132'
				}).then((result) => {
					if (result.isConfirmed) {
						dispatch(publish_request(true));
						// dispatch(set_active_pets_action(false));
						Swal.close();
					}
				});
			} else if (result.isDenied) {
				Swal.fire({
					title: t('changes-not-saved'),
					icon: 'info',
					confirmButtonColor: '#63C132'
				});
			}
		});
	};

	useEffect(() => {
		if (id > 0 /* !== null */) {
			dispatch(get_output_request_pets_action({ id: id }));
		}
	}, [id]);

	//output request pets
	const handleClickUpdateRequest = () => {
		setAnchorEl_outReq(null);
		dispatch(adopt_me_dialog_action(true));
		dispatch(adopt_me_dialog_procedure_action(1));
	};

	const handleSelectPet = (id_mascota, id_formulario) => {
		dispatch(select_pet_action(id_mascota));
		dispatch(select_pet_form_action(id_formulario));
		dispatch(clean_question_answer_pet_action());
	};

	const handleClickDeleteRequest = () => {
		setAnchorEl_outReq(null);
		Swal.fire({
			title: '¿Deseas cancelar la solicitud?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#63C132',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Si, cancelar!',
			cancelButtonText: 'No, salir!'
		}).then((result) => {
			if (result.isConfirmed) {
				if (outputRequestData) {
					dispatch(
						cancel_pet_request_action({
							id_mascota: petSelected,
							id_formulario: petFormSelected
						})
					);
					dispatch(get_output_request_pets_action({ id: id }));
				}
				Swal.fire('Solicitud Cancelada', '', 'success');
			}
		});
	};

	const CustomTooltip = (props) => (
		<Tooltip
			{...props}
			arrow
			TransitionComponent={Zoom}
			placement={'top'}
			classes={{ tooltip: classes.CustomTooltip }}
		/>
	);

	const { open, handleClickOpen, handleClose } = useDialog();

	let id_data = pageMascotas.map(({ id_mascota }) => id_mascota);

	let request = [];

	if (outputRequestData?.length > 0) {
		request = outputRequestData.filter(
			(item) => !(item.id_mascota === id_data)
		);
	}

	const [favorite, setFavorite] = useState(false);

	const handleClickAddFavourite = async (id_pet) => {
		if (checkLogin) {
			setFavorite(true);
			await dispatch(
				add_favourite_pet_action({ id_mascota: id_pet, id_usuario: id })
			);
			await dispatch(show_favourite_pet_action({ id_usuario: id }));
			handleOpenSnackbar(
				{
					vertical: 'bottom',
					horizontal: 'right'
				},
				TransitionSnackbar
			);
			setTimeout(() => {
				setFavorite(false);
			}, 3000);
		} else {
			dispatch(unlogged_modal_action(true));
		}
	};

	const { /* getFavourite, */ showFavourite } = useSelector(
		(state) => state.favouritePets
	);

	// console.log(getFavourite);

	useEffect(() => {
		// dispatch(get_favourite_pet_action({ id_mascota: '1181' }));
		if (id?.length !== 0) {
			dispatch(show_favourite_pet_action({ id_usuario: id }));
		}
	}, [id]);

	let filters = [];

	if (showFavourite?.length > 0) {
		let id_pet = showFavourite.map(({ id_mascota }) => id_mascota);
		filters = petFilters.filter((pets) => id_pet.includes(pets.id_mascota));
		// console.log(filters);
	}

	const {
		TransitionSnackbar,
		transition,
		vertical,
		horizontal,
		openSnackbar,
		handleCloseSnackbar,
		handleOpenSnackbar
	} = useSnackbar();

	return (
		<>
			{showUserPets || showOutReqPets ? (
				<Grid
					container
					className={classes.userPetTittleContainer}
					justify="center"
				>
					<Typography variant="h6">{title}</Typography>
				</Grid>
			) : null}
			<Grid
				container
				spacing={isMobile ? 1 : 3}
				justify="center"
				className={classes.cardsPetsContainer}
			>
				{pageMascotas?.map((item) => {
					return (
						<Grid
							key={item.id_mascota}
							item
							xs={12}
							sm={6}
							md={6}
							lg={4}
							xl={3}
						>
							<Card
								className={clsx(
									classes.cardsPets,
									!loading && {
										[classes.cardsPets1]: item.tipo_tramite === '1',
										[classes.cardsPets2]: item.tipo_tramite === '2',
										[classes.cardsPets3]: item.tipo_tramite === '3'
									}
								)}
								onClick={() =>
									handleSelectPet(item.id_mascota, item.id_formulario)
								}
							>
								<CardHeader
									avatar={
										loading ? (
											<Skeleton
												animation="wave"
												variant="circle"
												width={40}
												height={40}
											/>
										) : (
											<Avatar
												aria-label="recipe"
												className={clsx({
													[classes.avatar1]: item.tipo_tramite === '1',
													[classes.avatar2]: item.tipo_tramite === '2',
													[classes.avatar3]: item.tipo_tramite === '3'
												})}
											>
												<PetsIcon />
											</Avatar>
										)
									}
									title={
										loading ? (
											<Skeleton
												animation="wave"
												variant="text"
												height={20}
												width="50%"
											/>
										) : (
											(item.tipo_tramite === '1' && `${t('pet-adoption')}`) ||
											(item.tipo_tramite === '2' && `${t('pet-recovered')}`) ||
											(item.tipo_tramite === '3' && `${t('pet-lost')}`)
										)
									}
								// subheader="September 14, 2016"
								/>

								{loading ? (
									<Skeleton
										animation="wave"
										variant="rect"
										height={
											(skeletonResponsiveXL && 340) ||
											(skeletonResponsiveMDLG && 330) ||
											(skeletonResponsiveSM && 300)
										}
									/>
								) : (
									<CarouselPhotos
										itemPets={item.fotos}
										itemProcedure={item.tipo_tramite}
										itemId={item.id_mascota}
									/>
								)}

								<div
									className={clsx(
										classes.cardDesign,
										!loading && {
											[classes.cardDesign1]: item.tipo_tramite === '1',
											[classes.cardDesign2]: item.tipo_tramite === '2',
											[classes.cardDesign3]: item.tipo_tramite === '3'
										}
									)}
								>
									<CardHeader
										title={
											loading ? (
												<Skeleton
													animation="wave"
													variant="text"
													height={20}
													width="30%"
												/>
											) : (
												<Fragment>
													<Typography
														style={{
															color: '#FFFFFF',
															fontWeight: 'semi-bold',
															fontSize: '10px'
														}}
													>
														<img src={petLocation} alt="" className={classes.iconSize} />	{item.pais} - {item.departameto} - {item.municipio}
													</Typography>
													<Typography
														style={{
															color: '#FFFFFF',
															fontWeight: 'bolder',
															fontSize: '20px'
														}}
													>
														{item.nombre_mascota}
													</Typography>
												</Fragment>
											)
										}
										subheader={
											loading ? (
												<Skeleton
													animation="wave"
													variant="text"
													height={20}
													width="50%"
												/>
											) : (
												item.raza
											)
										}
									/>
									<CardActions disableSpacing>
										<div style={{ display: 'flex', flexGrow: 1 }}>
											{showUserPets ? null : (
												<>
													{loading ? (
														<Skeleton
															animation="wave"
															variant="circle"
															width={40}
															height={40}
															style={{ marginRight: 6 }}
														/>
													) : (
														<CustomTooltip
															title={
																filters.find(
																	(x) => x.id_mascota === item.id_mascota
																)
																	? t('delete-favorites')
																	: t('add-favorites')
															}
														>
															<IconButton
																style={{ zIndex: 0 }}
																aria-label="add to favorites"
																disabled={favorite}
																onClick={() => {
																	handleClickAddFavourite(item.id_mascota);
																}}
															>
																<Badge
																	badgeContent={
																		id?.length === 0
																			? 0
																			: filters.find(
																				(x) =>
																					x.id_mascota === item.id_mascota
																			)
																				? '-'
																				: '+'
																	}
																	anchorOrigin={{
																		vertical: 'top',
																		horizontal: 'left'
																	}}
																	className={clsx(
																		classes.badge,
																		filters.find(
																			(x) => x.id_mascota === item.id_mascota
																		)
																			? [classes.badge1]
																			: [classes.badge2]
																	)}
																>
																	<img
																		src={
																			filters.find(
																				(x) => x.id_mascota === item.id_mascota
																			)
																				? FavoriteIconPet2
																				: FavoriteIconPet1
																		}
																		alt="Favorite Icon Pet"
																		className={classes.iconsCards}
																	/>
																</Badge>
															</IconButton>
														</CustomTooltip>
													)}

													{loading ? (
														<Skeleton
															animation="wave"
															variant="circle"
															width={40}
															height={40}
															style={{ marginRight: 6 }}
														/>
													) : (
														<CustomTooltip title={t('share-pet')}>
															<IconButton
																aria-label="share"
																onClick={handleClickOpen}
															>
																<img
																	src={ShareIconPet}
																	alt="Share Icon Pet"
																	className={classes.iconsCards}
																/>
															</IconButton>
														</CustomTooltip>
													)}
												</>
											)}
											{loading ? (
												<Skeleton
													animation="wave"
													variant="circle"
													width={40}
													height={40}
												/>
											) : (
												<CustomTooltip title={t('information-pet')}>
													<Link
														to={{
															pathname: `/pet-information/${item.id_mascota}`,
															state: { pageMascotas: item }
														}}
													>
														<IconButton
														// onClick={() =>
														// 	dispatch(set_user_pet_modal_data_action(true))
														// }
														// value={item.id_mascota}
														>
															<img
																src={FootprintIconPet}
																alt="Footprint Icon Pet"
																className={classes.iconsCards}
															/>
														</IconButton>
													</Link>
												</CustomTooltip>
											)}
										</div>
										{loading ? (
											<Skeleton
												animation="wave"
												variant="text"
												width={100}
												height={50}
												style={{ marginRight: 12 }}
											/>
										) : item.id_usuario === id &&
											!showUserPets ? null : showUserPets ? (
												<Button
													className={classes.buttonPrimary}
													variant="contained"
													size="small"
													color="secondary"
													onClick={handleOpenMenu}
												>
													{t('options')}
												</Button>
											) : showOutReqPets ? (
												<Button
													className={classes.buttonPrimary}
													variant="contained"
													size="small"
													color="secondary"
													onClick={handleClickOpenOutReqMenu}
												>
													{t('options')}
												</Button>
											) : item.tipo_tramite === '1' ? (
												<Button
													className={classes.buttonPrimary}
													variant="contained"
													size="small"
													color="secondary"
													onClick={handleClickAdoptMe}
													disabled={
														request.find(
															(pet) => pet.id_mascota === item.id_mascota
														) && true
													}
												>
													{request.find(
														(pet) => pet.id_mascota === item.id_mascota
													)
														? 'Enviada'
														: t('adopt-me')}
												</Button>
											) : item.tipo_tramite === '2' ? (
												<Button
													className={classes.buttonPrimary}
													variant="contained"
													size="small"
													color="secondary"
													onClick={handleClickFindMe}
													disabled={
														request.find(
															(pet) => pet.id_mascota === item.id_mascota
														) && true
													}
												>
													{request.find(
														(pet) => pet.id_mascota === item.id_mascota
													)
														? 'Enviada'
														: t('find-me')}

													{/* {t('find-me')} */}
												</Button>
											) : item.tipo_tramite === '3' ? (
												<Button
													className={classes.buttonPrimary}
													variant="contained"
													size="small"
													color="secondary"
													onClick={handleClickHelpMe}
													disabled={
														request.find(
															(pet) => pet.id_mascota === item.id_mascota
														) && true
													}
												>
													{request.find(
														(pet) => pet.id_mascota === item.id_mascota
													)
														? 'Enviada'
														: t('inform-me')}
												</Button>
											) : null}
									</CardActions>
								</div>
							</Card>
						</Grid>
					);
				})}
				{
					pageMascotas.length === 0 &&
						petFiltersValidate.length !== 0 &&
						UserPets === undefined &&
						cards ? (
						<div
							className="animate__animated animate__zoomIn animate__slower 2s"
							style={{ display: 'flex', alignItems: 'center', height: '70vh' }}
						>
							<NotFound
								style={{
									maxWidth: '100%',
									height: '70%'
								}}
							/>
						</div>
					) : null
				}

				{
					(petEmpty ||
						(pageMascotas.length === 0 && petFiltersValidate.length === 0)) &&
					UserPets === undefined && /* cards && */ <PostPet />
				}

				{/* Cuando no tengo mascotas desactivadas */}
				{
					mascotas.length === 0 && UserPets === true && publishPet === '0' && (
						<div
							className="animate__animated animate__zoomIn animate__slower 2s"
							style={{ display: 'flex', alignItems: 'center', height: '70vh' }}
						>
							<h1>{t('you-have-no') + ' ' + t('disabled-pets')}</h1>
						</div>
					)
				}

				{/* cuando desactivo todas mis mascotas publicadas */}
				{
					mascotas.length === 0 && UserPets === true && publishPet === '1' && (
						<div
							className="animate__animated animate__zoomIn animate__slower 2s"
							style={{ display: 'flex', alignItems: 'center', height: '70vh' }}
						>
							<h1>{t('you-have-no') + ' ' + t('published-pets')}</h1>
						</div>
					)
				}
			</Grid >
			<Menu
				id="simple-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handlePetMenuClose}
			>
				<MenuItem onClick={handleClickEditMe}>
					<ListItemIcon className={classes.listItem}>
						<EditIcon color="primary" />
					</ListItemIcon>
					{t('edit')}
				</MenuItem>
				{activePets ? (
					<MenuItem onClick={handleUnpublishPet}>
						<ListItemIcon className={classes.listItem}>
							<CancelIcon color="primary" />
						</ListItemIcon>
						{t('deactivate')}
					</MenuItem>
				) : (
					<MenuItem onClick={handlePublishPet}>
						<ListItemIcon className={classes.listItem}>
							<CheckCircleIcon color="primary" />
						</ListItemIcon>
						{t('activate')}
					</MenuItem>
				)}
			</Menu>

			<Menu
				id="request_menu"
				anchorEl={anchorEl_outReq}
				keepMounted
				open={Boolean(anchorEl_outReq)}
				onClose={handleClickOutReqMenuClose}
			>
				<MenuItem onClick={handleClickUpdateRequest}>
					<ListItemIcon>
						<UpdateIcon color="primary" />
					</ListItemIcon>
					<ListItemText primary="Actualizar preguntas" />
				</MenuItem>
				<MenuItem onClick={handleClickDeleteRequest}>
					<ListItemIcon>
						<CancelIcon color="primary" />
					</ListItemIcon>
					<ListItemText primary="Cancelar Solicitud" />
				</MenuItem>

				<div onClick={handleClickOutReqMenuClose}>
					<ShowRequest request={true} />
				</div>
			</Menu>

			<ShareDialog open={open} onClose={handleClose} />

			<CustomSnackbar
				openSnackbar={openSnackbar}
				handleCloseSnackbar={handleCloseSnackbar}
				vertical={vertical}
				horizontal={horizontal}
				transition={transition}
			/>
		</>
	);
}
