/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Typography, Chip, Tooltip, Zoom, Box } from '@material-ui/core';

// import filters from '../../../assets/icons/filters/046-pet-shelter.svg'
// import dog from '../../../assets/icons/petype/dog.svg';
// import cat from '../../../assets/icons/petype/cat.svg';
// import hamster from '../../../assets/icons/petype/hamster.svg';

import petFilter from '../../../assets/icons/filters/filters-final.svg';

import petType from '../../../assets/icons/filters/pet-type-final.svg';
import petDog from '../../../assets/icons/filters/dog-final.svg';
import petCat from '../../../assets/icons/filters/cat-final.svg';
import petHamster from '../../../assets/icons/filters/hamster-final.svg';

import petRace from '../../../assets/icons/filters/pet-race-final.svg';

import petRaceCat from '../../../assets/icons/filters/pet-race-cat.svg';
import petRaceDog from '../../../assets/icons/filters/pet-race-dog.svg';
import petRaceHamster from '../../../assets/icons/filters/pet-race-hamster.svg';

// import petRaceDog1 from '../../../assets/icons/filters/pet-race-dog01.svg';
// import petRaceDog3 from '../../../assets/icons/filters/pet-race-dog03.svg';
// import petRaceDog4 from '../../../assets/icons/filters/pet-race-dog04.svg';
// import petRaceDog5 from '../../../assets/icons/filters/pet-race-dog05.svg';

import petSize from '../../../assets/icons/filters/pet-size-final.svg';
import petSmall from '../../../assets/icons/filters/small-final.svg';
import petMedium from '../../../assets/icons/filters/medium-final.svg';
import petBig from '../../../assets/icons/filters/big-final.svg';

import petGender from '../../../assets/icons/filters/pet-gender-final.svg';
import petMale from '../../../assets/icons/filters/male-final.svg';
import petFemale from '../../../assets/icons/filters/female-final.svg';

import petYear from '../../../assets/icons/filters/pet-year-final.svg';
import petPuppie from '../../../assets/icons/filters/puppie-final.svg';
import petYoung from '../../../assets/icons/filters/young-final.svg';
import petAdult from '../../../assets/icons/filters/adult-final.svg';
import petOld from '../../../assets/icons/filters/old-final.svg';

import petState from '../../../assets/icons/filters/pet-state-final.svg';
import petAdopt from '../../../assets/icons/filters/pet-state-final.svg';
import petRecover from '../../../assets/icons/filters/recover-final.svg';
import petLost from '../../../assets/icons/filters/lost-final.svg';

import petLocation from '../../../assets/icons/drawer/iconFind-final.svg';
import petCountryCol from '../../../assets/icons/IconsFlags/colombia.svg';
import petCountryEu from '../../../assets/icons/IconsFlags/eeuu.svg';
import petCountryBr from '../../../assets/icons/IconsFlags/brazil.svg';
import petCountryAr from '../../../assets/icons/IconsFlags/argentina.svg';
import petCountryMe from '../../../assets/icons/IconsFlags/mexico.svg';
import petCountryCh from '../../../assets/icons/IconsFlags/chile.svg';
import petCountryVe from '../../../assets/icons/IconsFlags/venezuela.svg';
import petCountryPe from '../../../assets/icons/IconsFlags/peru.svg';
import petCountryEc from '../../../assets/icons/IconsFlags/ecuador.svg';
import petCountryCa from '../../../assets/icons/IconsFlags/canada.svg';
import petCountryUr from '../../../assets/icons/IconsFlags/uruguay.svg';
import petCountryPa from '../../../assets/icons/IconsFlags/paraguay.svg';
import petCountryBo from '../../../assets/icons/IconsFlags/bolivia.svg';
import petCountryBal from '../../../assets/icons/IconsFlags/belice.png';
import petCountryCos from '../../../assets/icons/IconsFlags/costa-rica.png';
import petCountrySalv from '../../../assets/icons/IconsFlags/el-salvador.png';
import petCountryGua from '../../../assets/icons/IconsFlags/guatemala.svg';
import petCountryHo from '../../../assets/icons/IconsFlags/honduras.svg';
import petCountryNi from '../../../assets/icons/IconsFlags/nicaragua.png';
import petCountryPan from '../../../assets/icons/IconsFlags/panama.png';
import petCountryCu from '../../../assets/icons/IconsFlags/cuba.svg';
import petCountryEs from '../../../assets/icons/IconsFlags/espana.png';
import petCountrySu from '../../../assets/icons/IconsFlags/surinam.png';
import petCountryTri from '../../../assets/icons/IconsFlags/trinidad-y-tobago.png';

import FavoriteIcon from '@material-ui/icons/Favorite';
import petFavourite from '../../../assets/icons/filters/favorite-icon.svg'

// Dispatch Redux
import { useDispatch, useSelector } from 'react-redux';

// Redux Actions
import {
	get_saci_pets_action,
	get_saci_pets_filters_races_action
} from '../../../redux/actions/saciPets';

import { titleCase } from '../../../hooks/useTitleCase';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { show_favourite_pet_action } from '../../../redux/actions/favouritePetsAction';

const useTreeItemStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.text.secondary,
		'&:hover > $content': {
			backgroundColor: '#c9caca'
		},
		'&:focus > $content, &$selected > $content': {
			backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
			color: 'var(--tree-view-color)'
		},
		'&:focus > $content $label, &:hover > $content $label, &$selected > $content $label':
		{
			backgroundColor: 'transparent'
		},
		userSelect: 'none'
	},
	content: {
		color: theme.palette.text.secondary,
		paddingRight: theme.spacing(1),
		fontWeight: theme.typography.fontWeightMedium,
		'$expanded > &': {
			fontWeight: theme.typography.fontWeightRegular
		}
	},
	group: {
		marginLeft: 0,
		'& $content': {
			paddingLeft: theme.spacing(2)
		}
	},
	expanded: {},
	selected: {},
	label: {
		fontWeight: 'inherit',
		color: 'inherit'
	},
	labelRoot: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0.5, 0)
	},
	labelIcon: {
		marginRight: theme.spacing(1)
	},
	labelText: {
		fontWeight: 'inherit',
		flexGrow: 1
	}
}));

function StyledTreeItem(props) {
	const classes = useTreeItemStyles();
	const {
		labelText,
		labelIcon: LabelIcon,
		labelInfo,
		color,
		bgColor,
		...other
	} = props;

	return (
		<TreeItem
			label={
				<div className={classes.labelRoot}>
					<LabelIcon color="inherit" className={classes.labelIcon} />
					<Typography variant="body2" className={classes.labelText}>
						{labelText}
					</Typography>
					<Typography variant="caption" color="inherit">
						{labelInfo}
					</Typography>
				</div>
			}
			style={{
				'--tree-view-color': color,
				'--tree-view-bg-color': bgColor
			}}
			classes={{
				root: classes.root,
				content: classes.content,
				expanded: classes.expanded,
				selected: classes.selected,
				group: classes.group,
				label: classes.label
			}}
			{...other}
		/>
	);
}

StyledTreeItem.propTypes = {
	bgColor: PropTypes.string,
	color: PropTypes.string,
	labelIcon: PropTypes.elementType.isRequired,
	labelInfo: PropTypes.string,
	labelText: PropTypes.string.isRequired
};

const useStyles = makeStyles((theme) => ({
	root: {
		height: 264,
		flexGrow: 1,
		maxWidth: 400
	},

	iconSize: {
		width: '40px',
		marginRight: '10px'
	},

	CustomTooltip: {
		backgroundColor: '#1E3F20',
		color: 'white',
		opacity: 0.7,
		fontSize: '12px'
	},

	filtersChips: {
		margin: theme.spacing(3),

		'& .MuiChip-deleteIconColorPrimary': {
			color: '#FFF'
		},

		'& .MuiChip-label': {
			color: '#FFF'
		},

		'& .MuiChip-root:hover': {
			backgroundColor: '#BC3535'
		},

		'& .MuiChip-root': {
			marginRight: '5px',
			marginBottom: '5px'
		}
	}
}));

export default function FiltersPets() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();

	const classes = useStyles();

	const { id_pais, id } = useSelector((state) => state.login.user);

	const { showFavourite } = useSelector((state) => state.favouritePets);

	const [storedToken] = useLocalStorage('token', '');

	// const { countries } = useSelector((state) => state.main);

	const lottieFilter = () => {
		return (
			<img src={petFilter} alt="Icon Pet Filter" className={classes.iconSize} />
		);
	};

	const lottiePetType = () => {
		return (
			<img src={petType} alt="Icon Pet Type" className={classes.iconSize} />
		);
	};

	const lottieDog = () => {
		return <img src={petDog} alt="Icon Pet Dog" className={classes.iconSize} />;
	};

	const lottieCat = () => {
		return <img src={petCat} alt="Icon Pet Dog" className={classes.iconSize} />;
	};

	const lottieHamster = () => {
		return (
			<img src={petHamster} alt="Icon Pet Dog" className={classes.iconSize} />
		);
	};

	const lottiePetRace = () => {
		return (
			<img src={petRace} alt="Icon Pet Race" className={classes.iconSize} />
		);
	};

	const raceCat = () => {
		return (
			<img
				src={petRaceCat}
				alt="Icon Pet Race Cat"
				className={classes.iconSize}
			/>
		);
	};

	const raceDog2 = () => {
		return (
			<img
				src={petRaceDog}
				alt="Icon Pet Race Dog"
				className={classes.iconSize}
			/>
		);
	};

	const raceHamster = () => {
		return (
			<img
				src={petRaceHamster}
				alt="Icon Pet Race Hamster"
				className={classes.iconSize}
			/>
		);
	};
	///////////////////////////////////////////////////////
	const lottiePetSize = () => {
		return (
			<img src={petSize} alt="Icon Pet Size" className={classes.iconSize} />
		);
	};

	const lottieSmall = () => {
		return (
			<img src={petSmall} alt="Icon Pet Small" className={classes.iconSize} />
		);
	};

	const lottieMedium = () => {
		return (
			<img src={petMedium} alt="Icon Pet Medium" className={classes.iconSize} />
		);
	};

	const lottieBig = () => {
		return <img src={petBig} alt="Icon Pet Big" className={classes.iconSize} />;
	};

	const lottiePetGender = () => {
		return (
			<img src={petGender} alt="Icon Pet Gender" className={classes.iconSize} />
		);
	};

	const lottieMale = () => {
		return (
			<img
				src={petMale}
				alt="Icon Pet Gender Male"
				className={classes.iconSize}
			/>
		);
	};

	const lottieFemale = () => {
		return (
			<img
				src={petFemale}
				alt="Icon Pet Gender Female"
				className={classes.iconSize}
			/>
		);
	};

	const lottiePetYear = () => {
		return (
			<img src={petYear} alt="Icon Pet Year" className={classes.iconSize} />
		);
	};

	const lottiePuppie = () => {
		return (
			<img src={petPuppie} alt="Icon Pet Puppie" className={classes.iconSize} />
		);
	};

	const lottieYoung = () => {
		return (
			<img src={petYoung} alt="Icon Pet Young" className={classes.iconSize} />
		);
	};

	const lottieAdult = () => {
		return (
			<img src={petAdult} alt="Icon Pet Adult" className={classes.iconSize} />
		);
	};

	const lottieOld = () => {
		return <img src={petOld} alt="Icon Pet Old" className={classes.iconSize} />;
	};

	const lottieState = () => {
		return (
			<img src={petState} alt="Icon Pet State" className={classes.iconSize} />
		);
	};

	const lottieAdopt = () => {
		return (
			<img src={petAdopt} alt="Icon Pet Adopt" className={classes.iconSize} />
		);
	};

	const lottieRecover = () => {
		return (
			<img
				src={petRecover}
				alt="Icon Pet Recover"
				className={classes.iconSize}
			/>
		);
	};

	const lottieLost = () => {
		return (
			<img src={petLost} alt="Icon Pet Lost" className={classes.iconSize} />
		);
	};

	const lottieLocation = () => {
		return <img src={petLocation} alt="" className={classes.iconSize} />;
	};
	const lottieCountryCol = () => {
		return <img src={petCountryCol} alt="" className={classes.iconSize} />;
	};
	const lottieCountryEu = () => {
		return <img src={petCountryEu} alt="" className={classes.iconSize} />;
	};
	const lottieCountryBr = () => {
		return <img src={petCountryBr} alt="" className={classes.iconSize} />;
	};
	const lottieCountryCh = () => {
		return <img src={petCountryCh} alt="" className={classes.iconSize} />;
	};
	const lottieCountryVe = () => {
		return <img src={petCountryVe} alt="" className={classes.iconSize} />;
	};
	const lottieCountryPe = () => {
		return <img src={petCountryPe} alt="" className={classes.iconSize} />;
	};
	const lottieCountryAr = () => {
		return <img src={petCountryAr} alt="" className={classes.iconSize} />;
	};
	const lottieCountryMe = () => {
		return <img src={petCountryMe} alt="" className={classes.iconSize} />;
	};
	const lottieFavourite = () => {
		return <img src={petFavourite} alt="" className={classes.iconSize} />;
	}
	const lottieCountryBo = () => {
		return <img src={petCountryBo} alt="" className={classes.iconSize} />;
	};
	const lottieCountryPa = () => {
		return <img src={petCountryPa} alt="" className={classes.iconSize} />;
	};
	const lottieCountryUr = () => {
		return <img src={petCountryUr} alt="" className={classes.iconSize} />;
	};
	const lottieCountryCa = () => {
		return <img src={petCountryCa} alt="" className={classes.iconSize} />;
	};
	const lottieCountryEc = () => {
		return <img src={petCountryEc} alt="" className={classes.iconSize} />;
	};

	const lottieCountryBal = () => {
		return <img src={petCountryBal} alt="" className={classes.iconSize} />;
	};
	const lottieCountryCos = () => {
		return <img src={petCountryCos} alt="" className={classes.iconSize} />;
	};
	const lottieCountrySalv = () => {
		return <img src={petCountrySalv} alt="" className={classes.iconSize} />;
	};
	const lottieCountryGua = () => {
		return <img src={petCountryGua} alt="" className={classes.iconSize} />;
	};
	const lottieCountryHo = () => {
		return <img src={petCountryHo} alt="" className={classes.iconSize} />;
	};
	const lottieCountryNi = () => {
		return <img src={petCountryNi} alt="" className={classes.iconSize} />;
	};
	const lottieCountryPan = () => {
		return <img src={petCountryPan} alt="" className={classes.iconSize} />;
	};
	const lottieCountryCu = () => {
		return <img src={petCountryCu} alt="" className={classes.iconSize} />;
	};
	const lottieCountryEs = () => {
		return <img src={petCountryEs} alt="" className={classes.iconSize} />;
	};
	const lottieCountrySu = () => {
		return <img src={petCountrySu} alt="" className={classes.iconSize} />;
	};
	const lottieCountryTri = () => {
		return <img src={petCountryTri} alt="" className={classes.iconSize} />;
	};

	/////////////////////////////////////////////////////////////////////////
	const [expanded, setExpanded] = useState([]);

	useEffect(() => {
		setExpanded(['1']);
	}, []);

	const handleToggle = (event, nodeIds) => {
		setExpanded(nodeIds);
	};

	// tipos de mascota//

	// gatos
	const [filtersCats, setFiltersCats] = useState(false);
	const [filtersCatsValidate, setFiltersCatsValidate] = useState(false);

	const handleCatsAdd = () => {
		setFiltersCats(true);
	};

	const handleCatsDelete = () => {
		setFiltersCatsValidate(true);
		setTimeout(() => {
			setFiltersCats(false);
			setFiltersCatsValidate(false);
		}, 1000);
	};

	// perros

	const [filtersDogs, setFiltersDogs] = useState(false);
	const [filtersDogsValidate, setFiltersDogsValidate] = useState(false);

	const handleDogsAdd = () => {
		setFiltersDogs(true);
	};

	const handleDogsDelete = () => {
		setFiltersDogsValidate(true);
		setTimeout(() => {
			setFiltersDogs(false);
			setFiltersDogsValidate(false);
		}, 1000);
	};

	// hamsters
	const [filtersHamsters, setFiltersHamsters] = useState(false);
	const [filtersHamstersValidate, setFiltersHamsterValidate] = useState(false);

	const handleHamstersAdd = () => {
		setFiltersHamsters(true);
	};

	const handleHamstersDelete = () => {
		setFiltersHamsterValidate(true);
		setTimeout(() => {
			setFiltersHamsterValidate(false);
			setFiltersHamsters(false);
		}, 1000);
	};

	/////////////////////////////////////////////////////////////////////////
	// tamaños //

	// mequeño
	const [filtersSmalls, setFiltersSmalls] = useState(false);
	const [filtersSmallsValidate, setFiltersSmallsValidate] = useState(false);

	const handleSmallsAdd = () => {
		setFiltersSmalls(true);
	};

	const handleSmallsDelete = () => {
		setFiltersSmallsValidate(true);
		setTimeout(() => {
			setFiltersSmallsValidate(false);
			setFiltersSmalls(false);
		}, 1000);
	};

	// mediano
	const [filtersMediums, setFiltersMediums] = useState(false);
	const [filtersMediumsValidate, setFiltersMediumsValidate] = useState(false);

	const handleMediumsAdd = () => {
		setFiltersMediums(true);
	};

	const handleMediumsDelete = () => {
		setFiltersMediumsValidate(true);
		setTimeout(() => {
			setFiltersMediumsValidate(false);
			setFiltersMediums(false);
		}, 1000);
	};

	// grande
	const [filtersBigs, setFiltersBigs] = useState(false);
	const [filtersBigsValidate, setFiltersBigsValidate] = useState(false);

	const handleBigsAdd = () => {
		setFiltersBigs(true);
	};

	const handleBigsDelete = () => {
		setFiltersBigsValidate(true);
		setTimeout(() => {
			setFiltersBigsValidate(false);
			setFiltersBigs(false);
		}, 1000);
	};

	/////////////////////////////////////////////////////////////////////////
	//genero

	//machos
	const [filtersMales, setFiltersMales] = useState(false);
	const [filtersMalesValidate, setFiltersMalesValidate] = useState(false);

	const handleMalesAdd = () => {
		setFiltersMales(true);
	};

	const handleMalesDelete = () => {
		setFiltersMalesValidate(true);
		setTimeout(() => {
			setFiltersMalesValidate(false);
			setFiltersMales(false);
		}, 1000);
	};

	//hembras
	const [filtersFemales, setFiltersFemales] = useState(false);
	const [filtersFemalesValidate, setFiltersFemalesValidate] = useState(false);

	const handleFemalesAdd = () => {
		setFiltersFemales(true);
	};

	const handleFemalesDelete = () => {
		setFiltersFemalesValidate(true);
		setTimeout(() => {
			setFiltersFemalesValidate(false);
			setFiltersFemales(false);
		}, 1000);
	};

	/////////////////////////////////////////////////////////////////////////

	//genero

	// cachorros
	const [filtersPuppies, setFiltersPuppies] = useState(false);
	const [filtersPuppiesValidate, setFiltersPuppiesValidate] = useState(false);

	const handlePuppiesAdd = () => {
		setFiltersPuppies(true);
	};

	const handlePuppiesDelete = () => {
		setFiltersPuppiesValidate(true);
		setTimeout(() => {
			setFiltersPuppiesValidate(false);
			setFiltersPuppies(false);
		}, 1000);
	};

	// jovenes
	const [filtersYoungs, setFiltersYoungs] = useState(false);
	const [filtersYoungsValidate, setFiltersYoungsValidate] = useState(false);

	const handleYoungsAdd = () => {
		setFiltersYoungs(true);
	};

	const handleYoungsDelete = () => {
		setFiltersYoungsValidate(true);
		setTimeout(() => {
			setFiltersYoungsValidate(false);
			setFiltersYoungs(false);
		}, 1000);
	};

	// adultos
	const [filtersAdults, setFiltersAdults] = useState(false);
	const [filtersAdultsValidate, setFiltersAdultsValidate] = useState(false);

	const handleAdultsAdd = () => {
		setFiltersAdults(true);
	};

	const handleAdultsDelete = () => {
		setFiltersAdultsValidate(true);
		setTimeout(() => {
			setFiltersAdultsValidate(false);
			setFiltersAdults(false);
		}, 1000);
	};

	//viejos
	const [filtersOlds, setFiltersOlds] = useState(false);
	const [filtersOldsValidate, setFiltersOldsValidate] = useState(false);

	const handleOldsAdd = () => {
		setFiltersOlds(true);
	};

	const handleOldsDelete = () => {
		setFiltersOldsValidate(true);
		setTimeout(() => {
			setFiltersOldsValidate(false);
			setFiltersOlds(false);
		}, 1000);
	};

	/////////////////////////////////////////////////////////////////////////
	// Estado

	// Adoptados
	const [filtersAdopt, setFiltersAdopt] = useState(false);
	const [filtersAdoptValidate, setFiltersAdoptValidate] = useState(false);

	const handleAdoptAdd = () => {
		setFiltersAdopt(true);
	};

	const handleAdoptDelete = () => {
		setFiltersAdoptValidate(true);
		setTimeout(() => {
			setFiltersAdoptValidate(false);
			setFiltersAdopt(false);
		}, 1000);
	};

	// Recuperados
	const [filtersRecovered, setFiltersRecovered] = useState(false);
	const [filtersRecoveredValidate, setFiltersRecoveredValidate] =
		useState(false);

	const handleRecoveredAdd = () => {
		setFiltersRecovered(true);
	};

	const handleRecoveredDelete = () => {
		setFiltersRecoveredValidate(true);
		setTimeout(() => {
			setFiltersRecoveredValidate(false);
			setFiltersRecovered(false);
		}, 1000);
	};

	// Perdidos
	const [filtersLost, setFiltersLost] = useState(false);
	const [filtersLostValidate, setFiltersLostValidate] = useState(false);

	const handleLostAdd = () => {
		setFiltersLost(true);
	};

	const handleLostDelete = () => {
		setFiltersLostValidate(true);
		setTimeout(() => {
			setFiltersLostValidate(false);
			setFiltersLost(false);
		}, 1000);
	};

	/////////////////////////////////////////////////////////////////////////
	//razas
	const { petFilters, mascotas } = useSelector((state) => state.saciPets);

	// crear array de un array
	let dataArr = mascotas.map((item) => {
		return [item.id_raza, item];
	});

	// crear valor clave  de un array de array
	let maparr = new Map(dataArr);

	// convertir de nuevo a array desde mapobject
	let mascotasDuplicate = [...maparr.values()];

	// mostrar raza gatos no duplicados
	let filtersIconsCats = mascotasDuplicate.filter(
		(pets) => pets.id_tipo_mascota === '1'
	);

	// mostrar raza perros no duplicados
	let filtersIconsDogs = mascotasDuplicate.filter(
		(pets) => pets.id_tipo_mascota === '2'
	);

	// mostrar raza hamsters no duplicados
	let filtersIconsHamsters = mascotasDuplicate.filter(
		(pets) => pets.id_tipo_mascota === '3'
	);

	const [filtersRaces, setFiltersRaces] = useState(false);
	const [filtersRacesData, setFiltersRacesData] = useState(false);
	const [filtersRacesValidate, setChipFiltersRaces] = useState(false);

	const handleChipsAdd = (item) => {
		setFiltersRaces(true);
		setFiltersRacesData(item);
	};

	const handleChipsDelete = () => {
		setChipFiltersRaces(true);
		setTimeout(() => {
			setChipFiltersRaces(false);
			setFiltersRaces(false);
			setFiltersRacesData(false);
		}, 1000);
	};

	let filtersRacesPets = mascotas;
	let filtersRacesCards;

	let heightRaces;
	if (mascotasDuplicate.length >= 4) {
		heightRaces = 200;
	} else {
		heightRaces = 'auto';
	}

	let countPets = mascotas.length;

	if (filtersRaces === true) {
		filtersRacesPets = mascotasDuplicate.filter(function (pets) {
			return pets.id_raza === filtersRacesData;
		});

		filtersRacesCards = mascotas.filter(function (pets) {
			return pets.id_raza === filtersRacesData;
		});
	}

	useEffect(() => {
		if (filtersRacesData !== false) {
			dispatch(get_saci_pets_filters_races_action(filtersRacesCards));
		}
	}, [filtersRacesData]);

	const chipRaces = filtersRacesPets.map((data) => {
		if (filtersRaces === true) {
			return (
				<Chip
					className={
						filtersRacesValidate
							? 'animate__animated animate__backOutDown'
							: 'animate__animated animate__fadeInUpBig'
					}
					key={data.id_mascota}
					label={data.raza}
					color="primary"
					onDelete={handleChipsDelete}
				/>
			);
		} else {
			return null;
		}
	});

	/////////////////////////////////////////////////////////////////////////
	// paises generales
	// Ciudad - Colombia

	// crear array de un array
	let dataArr3 = mascotas.map((item) => {
		return [item.id_pais, item];
	});

	// // crear valor clave  de un array de array
	let maparr3 = new Map(dataArr3);

	// // convertir de nuevo a array desde mapobject
	let mascotasDuplicate3 = [...maparr3.values()];

	// // mostrar paises no duplicados
	let filterIconsCountrys = mascotasDuplicate3.filter(
		(pets) => pets.id_pais !== null
	);

	const [filtersCountrys, setFiltersCountrys] = useState(false);
	const [filtersCountrysData, setFiltersCountrysData] = useState(false);
	const [filtersCountrysValidate, setFiltersCountrysValidate] = useState(false);

	const handleCountryAdds = (id_pais) => {
		setFiltersCountrys(true);
		setFiltersCountrysData(id_pais);
		// setExpanded(['1', '8', '9']);
	};

	const handleCountrysDelete = () => {
		setFiltersCountrysValidate(true);
		setTimeout(() => {
			setFiltersCountrysValidate(false);
			setFiltersCountrys(false);
			setFiltersCountrysData(false);
		}, 1000);
	};

	const [stateCountry, setStateCountry] = useState([]);

	useEffect(() => {
		if (filtersCountrys === true) {
			let filtersCountrysPets = mascotasDuplicate3.filter(
				(pets) => pets.id_pais === filtersCountrysData
			);
			if (filtersCountrysPets.length !== 0) {
				setStateCountry(filtersCountrysPets);
			}
		}
	}, [filtersCountrys]);

	let chipCountrys = stateCountry.map((data) => {
		if (filtersCountrys === true) {
			return (
				<Chip
					className={
						filtersCountrysValidate
							? 'animate__animated animate__backOutDown'
							: 'animate__animated animate__fadeInUpBig'
					}
					key={data.id_mascota}
					label={titleCase(data.pais)}
					color="primary"
					onDelete={handleCountrysDelete}
				/>
			);
		} else {
			return null;
		}
	});

	///////////////////////////////////////////////////////////
	// Ciudades

	// crear array de un array
	let dataArr2 = mascotas.map((item) => {
		return [item.id_departamento, item];
	});
	
	// crear valor clave  de un array de array
	let maparr2 = new Map(dataArr2);
	

	// convertir de nuevo a array desde mapobject
	let mascotasDuplicate2 = [...maparr2.values()];

	// mostrar departamento no duplicados
	let filterIconsDepartments = mascotasDuplicate2.filter(
		(pets) => pets.id_pais === filtersCountrysData
	);

	const [filtersCitys, setFiltersCitys] = useState(false);
	const [filtersCitysData, setFiltersCitysData] = useState(false);
	const [filtersCitysValidate, setFiltersCitysValidate] = useState(false);

	const handleChipsAddCity = (item) => {
		setFiltersCitys(true);
		setFiltersCitysData(item);
	};

	const handleChipsDeleteCity = () => {
		setFiltersCitysValidate(true);
		setTimeout(() => {
			setFiltersCitysValidate(false);
			setFiltersCitys(false);
			setFiltersCitysData(false);
		}, 1000);
	};

	let heightCity;

	if (mascotasDuplicate2.length >= 4) {
		heightCity = 340;
	} else {
		heightCity = 'auto';
	}

	const [stateCity, setStateCity] = useState([]);

	useEffect(() => {
		if (filtersCitys === true) {
			let filtersCitysPets = mascotasDuplicate2.filter(
				(pets) => pets.id_departamento === filtersCitysData
			);
			if (filtersCitysPets.length !== 0) {
				setStateCity(filtersCitysPets);
			}
		}
	}, [filtersCitys]);

	const chipCity = stateCity.map((data) => {
		if (filtersCitys === true) {
			return (
				<Chip
					className={
						filtersCitysValidate
							? 'animate__animated animate__backOutDown'
							: 'animate__animated animate__fadeInUpBig'
					}
					key={data.id_mascota}
					label={titleCase(data.departameto)}
					color="primary"
					onDelete={handleChipsDeleteCity}
				/>
			);
		} else {
			return null;
		}
	});


	///////////////////////////////////////////////////////////
	// Municipios

	// crear array de un array
	let dataArrMunis = mascotas.map((item) => {
		return [item.id_municipio, item];
	});

	// crear valor clave  de un array de array
	let maparrMunis = new Map(dataArrMunis);
	
	// convertir de nuevo a array desde mapobject
	let mascotasDuplicateMunis = [...maparrMunis.values()];
	
	// mostrar municipios no duplicados
	let filterIconsMuni = mascotasDuplicateMunis.filter(
		(pets) => pets.id_departamento === filtersCitysData
	);
	
	
	const [filtersMunis, setFiltersMunis] = useState(false);
	const [filtersMunisData, setFiltersMunisData] = useState(false);
	const [filtersMunisValidate, setFiltersMunisValidate] = useState(false);

	const handleChipsAddMunis = (item) => {
		setFiltersMunis(true);
		setFiltersMunisData(item);
	};

	const handleChipsDeleteMunis = () => {
		setFiltersMunisValidate(true);
		setTimeout(() => {
			setFiltersMunisValidate(false);
			setFiltersMunis(false);
			setFiltersMunisData(false);
		}, 1000);
	};

	let heightMunis;

	if (mascotasDuplicateMunis.length >= 4) {
		heightMunis = 340;
	} else {
		heightMunis = 'auto';
	}

	const [stateMunis, setStateMunis] = useState([]);

	useEffect(() => {
		if (filtersMunis === true) {
			let filtersMunisPets = mascotasDuplicateMunis.filter(
				(pets) => pets.id_municipio === filtersMunisData
			);
			if (filtersMunisPets.length !== 0) {
				setStateMunis(filtersMunisPets);
			}
		}
	}, [filtersMunis]);

	const chipMunis = stateMunis.map((data) => {
		if (filtersMunis === true) {
			return (
				<Chip
					className={
						filtersMunisValidate
							? 'animate__animated animate__backOutDown'
							: 'animate__animated animate__fadeInUpBig'
					}
					key={data.id_mascota}
					label={titleCase(data.municipio)}
					color="primary"
					onDelete={handleChipsDeleteMunis}
				/>
			);
		} else {
			return null;
		}
	});

	///////////////////////////////////////////////////////////
	// favoritos
	const [filtersFavourites, setFiltersFavourites] = useState(false);
	const [filtersFavouritesValidate, setFiltersFavouritesValidate] =
		useState(false);

	const handleFavouritesAdd = () => {
		dispatch(show_favourite_pet_action({ id_usuario: id }));
		setFiltersFavourites(true);
	};

	const handleFavouritesDelete = () => {
		setFiltersFavouritesValidate(true);
		setTimeout(() => {
			setFiltersFavourites(false);
			setFiltersFavouritesValidate(false);
		}, 1000);
		dispatch(get_saci_pets_action({ id_pais: id_pais }));
	};

	/////////////////////////////////////////////////////////////////////////
	useEffect(() => {
		// filtro vacio
		if (
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds &&
			!filtersRaces &&
			!filtersAdopt &&
			!filtersRecovered &&
			!filtersLost &&
			!filtersCountrys &&
			!filtersCitys &&
			!filtersMunis &&
			!filtersFavourites &&
			!storedToken &&
			!id_pais
		) {
			dispatch(get_saci_pets_action({ id_tipo_mascota: false }));
		}

		// filtro vacio
		if (
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds &&
			!filtersRaces &&
			!filtersAdopt &&
			!filtersRecovered &&
			!filtersLost &&
			!filtersCountrys &&
			!filtersCitys &&
			!filtersMunis &&
			!filtersFavourites &&
			showFavourite?.length === 0 &&
			id_pais
		) {
			dispatch(get_saci_pets_action({ id_pais: id_pais }));
		}

		// gatos
		if (
			filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds &&
			!filtersRaces &&
			!filtersCountrys &&
			!filtersCitys &&
			!filtersMunis
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
			setExpanded(['1', '2', '3']);
		}

		// perros
		if (
			filtersDogs &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds &&
			!filtersRaces &&
			!filtersCountrys &&
			!filtersCitys &&
			!filtersMunis
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
			setExpanded(['1', '2', '3']);
		}

		// hamsters
		if (
			filtersHamsters &&
			!filtersCats &&
			!filtersDogs &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds &&
			!filtersRaces &&
			!filtersCountrys &&
			!filtersCitys &&
			!filtersMunis
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '3' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
			setExpanded(['1', '2', '3']);
		}

		// gatos y perros
		if (
			filtersCats &&
			filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos y hamsters
		if (
			filtersCats &&
			filtersHamsters &&
			!filtersDogs &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '3') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros y hamsters
		if (
			filtersDogs &&
			filtersHamsters &&
			!filtersCats &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '2' || pets.id_tipo_mascota === '3') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros y hamsters
		if (
			filtersCats &&
			filtersDogs &&
			filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' ||
						pets.id_tipo_mascota === '2' ||
						pets.id_tipo_mascota === '3') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		///////////// tamaños /////////////

		// pequeño
		if (
			filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tamanio === '3' && (id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// mediano
		if (
			filtersMediums &&
			!filtersSmalls &&
			!filtersBigs &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tamanio === '2' && (id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// grande
		if (
			filtersBigs &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tamanio === '1' && (id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// pequeño y mediano
		if (
			filtersSmalls &&
			filtersMediums &&
			!filtersBigs &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tamanio === '3' || pets.id_tamanio === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);

			dispatch(get_saci_pets_action(filters));
		}

		// pequeño y grande
		if (
			filtersSmalls &&
			filtersBigs &&
			!filtersMediums &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tamanio === '3' || pets.id_tamanio === '1') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// grande y mediano
		if (
			filtersBigs &&
			filtersMediums &&
			!filtersSmalls &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tamanio === '1' || pets.id_tamanio === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// pequeño, mediano y grande
		if (
			filtersSmalls &&
			filtersMediums &&
			filtersBigs &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tamanio === '3' ||
						pets.id_tamanio === '2' ||
						pets.id_tamanio === '1') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		/////////////////////// tamaños por mascota ///////////////////////

		// gatos, pequeños
		if (
			filtersCats &&
			filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					pets.id_tamanio === '3' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, medianos
		if (
			filtersCats &&
			filtersMediums &&
			!filtersSmalls &&
			!filtersBigs &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					pets.id_tamanio === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		//gatos, grandes
		if (
			filtersCats &&
			filtersBigs &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					pets.id_tamanio === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, pequeños
		if (
			filtersDogs &&
			filtersSmalls &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					pets.id_tamanio === '3' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros medianos
		if (
			filtersDogs &&
			filtersMediums &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					pets.id_tamanio === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros grandes
		if (
			filtersDogs &&
			filtersBigs &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					pets.id_tamanio === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// hamsters no tienen tamaño

		// gatos, pequeños, medianos
		if (
			filtersCats &&
			filtersSmalls &&
			filtersMediums &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, pequeños, grandes
		if (
			filtersCats &&
			filtersSmalls &&
			filtersBigs &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '1') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, medianos, grandes
		if (
			filtersCats &&
			filtersMediums &&
			filtersBigs &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					(pets.id_tamanio === '2' || pets.id_tamanio === '1') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, pequeños, medianos
		if (
			filtersDogs &&
			filtersSmalls &&
			filtersMediums &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, pequeños, grandes
		if (
			filtersDogs &&
			filtersSmalls &&
			filtersBigs &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '1') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);

			dispatch(get_saci_pets_action(filters));
		}

		// perros, medianos, grandes
		if (
			filtersDogs &&
			filtersMediums &&
			filtersBigs &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					(pets.id_tamanio === '2' || pets.id_tamanio === '1') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos y perros, pequeños
		if (
			filtersCats &&
			filtersDogs &&
			filtersSmalls &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					pets.id_tamanio === '3' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos y perros, medianos
		if (
			filtersCats &&
			filtersDogs &&
			filtersMediums &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					pets.id_tamanio === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos y perros, grandes
		if (
			filtersCats &&
			filtersDogs &&
			filtersBigs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					pets.id_tamanio === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, pequeños, medianos
		if (
			filtersCats &&
			filtersDogs &&
			filtersSmalls &&
			filtersMediums &&
			!filtersHamsters &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos y perros, pequeños, grandes
		if (
			filtersCats &&
			filtersDogs &&
			filtersSmalls &&
			filtersBigs &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '1') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos y perros, medianos, grandes
		if (
			filtersCats &&
			filtersDogs &&
			filtersMediums &&
			filtersBigs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					(pets.id_tamanio === '2' || pets.id_tamanio === '1') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, pequeños, medianos, grandes
		if (
			filtersCats &&
			filtersSmalls &&
			filtersMediums &&
			filtersBigs &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					(pets.id_tamanio === '3' ||
						pets.id_tamanio === '2' ||
						pets.id_tamanio === '1') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, pequeños, medianos, grandes
		if (
			filtersDogs &&
			filtersSmalls &&
			filtersMediums &&
			filtersBigs &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			// filtros perros, pequeños, medianos, grandes
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					(pets.id_tamanio === '3' ||
						pets.id_tamanio === '2' ||
						pets.id_tamanio === '1') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, pequeños, medianos, grandes
		if (
			filtersCats &&
			filtersDogs &&
			filtersSmalls &&
			filtersMediums &&
			filtersBigs &&
			!filtersHamsters &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					(pets.id_tamanio === '3' ||
						pets.id_tamanio === '2' ||
						pets.id_tamanio === '1') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// machos
		if (
			filtersMales &&
			!filtersFemales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// hembras
		if (
			filtersFemales &&
			!filtersMales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			// filtros hembras
			let filters = petFilters.filter(
				(pets) =>
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// machos, hembras
		if (
			filtersMales &&
			filtersFemales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, machos
		if (
			filtersCats &&
			filtersMales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, hembras
		if (
			filtersCats &&
			filtersFemales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, machos
		if (
			filtersDogs &&
			filtersMales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, hembras
		if (
			filtersDogs &&
			filtersFemales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// hamsters, machos
		if (
			filtersHamsters &&
			filtersMales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '3' &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// hamsters, hembras
		if (
			filtersHamsters &&
			filtersFemales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '3' &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, machos, hembras
		if (
			filtersCats &&
			filtersMales &&
			filtersFemales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, machos, hembras
		if (
			filtersDogs &&
			filtersMales &&
			filtersFemales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// hamsters, machos, hembras
		if (
			filtersHamsters &&
			filtersMales &&
			filtersFemales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '3' &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, machos
		if (
			filtersCats &&
			filtersDogs &&
			filtersMales &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, hembras
		if (
			filtersCats &&
			filtersDogs &&
			filtersFemales &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, hamsters, machos

		if (
			filtersCats &&
			filtersHamsters &&
			filtersMales &&
			!filtersDogs &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '3') &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, hamsters, hembras
		if (
			filtersCats &&
			filtersHamsters &&
			filtersFemales &&
			!filtersDogs &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '3') &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, hamsters, machos
		if (
			filtersDogs &&
			filtersHamsters &&
			filtersMales &&
			!filtersCats &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '2' || pets.id_tipo_mascota === '3') &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, hamsters, hembras
		if (
			filtersDogs &&
			filtersHamsters &&
			filtersFemales &&
			!filtersCats &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '2' || pets.id_tipo_mascota === '3') &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, machos, hembras
		if (
			filtersCats &&
			filtersDogs &&
			filtersMales &&
			filtersFemales &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, hamsters, machos, hembras
		if (
			filtersCats &&
			filtersHamsters &&
			filtersMales &&
			filtersFemales &&
			!filtersDogs &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '3') &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, hamsters, machos, hembras
		if (
			filtersDogs &&
			filtersHamsters &&
			filtersMales &&
			filtersFemales &&
			!filtersCats &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '2' || pets.id_tipo_mascota === '3') &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, hamsters, machos
		if (
			filtersCats &&
			filtersDogs &&
			filtersHamsters &&
			filtersMales &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' ||
						pets.id_tipo_mascota === '2' ||
						pets.id_tipo_mascota === '3') &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, hamsters, hembras
		if (
			filtersCats &&
			filtersDogs &&
			filtersHamsters &&
			filtersFemales &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' ||
						pets.id_tipo_mascota === '2' ||
						pets.id_tipo_mascota === '3') &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, hamsters, machos, hembras
		if (
			filtersCats &&
			filtersDogs &&
			filtersHamsters &&
			filtersMales &&
			filtersFemales &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' ||
						pets.id_tipo_mascota === '2' ||
						pets.id_tipo_mascota === '3') &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, pequeños, machos
		if (
			filtersCats &&
			filtersSmalls &&
			filtersMales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					pets.id_tamanio === '3' &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, pequeños, hembras
		if (
			filtersCats &&
			filtersSmalls &&
			filtersFemales & !filtersDogs &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					pets.id_tamanio === '3' &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, pequeños, machos
		if (
			filtersDogs &&
			filtersSmalls &&
			filtersMales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					pets.id_tamanio === '3' &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, pequeños, hembras
		if (
			filtersDogs &&
			filtersSmalls &&
			filtersFemales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					pets.id_tamanio === '3' &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// hamsters no tienen tamaño
		// hamsters no tienen tamaño

		// gatos, medianos, machos
		if (
			filtersCats &&
			filtersMediums &&
			filtersMales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					pets.id_tamanio === '2' &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, medianos, hembras
		if (
			filtersCats &&
			filtersMediums &&
			filtersFemales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					pets.id_tamanio === '2' &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, medianos, machos
		if (
			filtersDogs &&
			filtersMediums &&
			filtersMales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					pets.id_tamanio === '2' &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, medianos, hembras
		if (
			filtersDogs &&
			filtersMediums &&
			filtersFemales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					pets.id_tamanio === '2' &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// hamsters no tienen tamaño

		// gatos, grandes, machos
		if (
			filtersCats &&
			filtersBigs &&
			filtersMales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					pets.id_tamanio === '1' &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, grandes, hembras
		if (
			filtersCats &&
			filtersBigs &&
			filtersFemales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					pets.id_tamanio === '1' &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, grandes, machos
		if (
			filtersDogs &&
			filtersBigs &&
			filtersMales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					pets.id_tamanio === '1' &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, grandes, hembras
		if (
			filtersDogs &&
			filtersBigs &&
			filtersFemales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					pets.id_tamanio === '1' &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// hamsters no tienen tamaño
		// hamsters no tienen tamaño

		// gatos, pequeños, machos, hembras
		if (
			filtersCats &&
			filtersSmalls &&
			filtersMales &&
			filtersFemales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					pets.id_tamanio === '3' &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, medianos, machos, hembras
		if (
			filtersCats &&
			filtersMediums &&
			filtersMales &&
			filtersFemales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					pets.id_tamanio === '2' &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, grandes, machos, hembras
		if (
			filtersCats &&
			filtersBigs &&
			filtersMales &&
			filtersFemales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					pets.id_tamanio === '1' &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, pequeños, machos, hembras
		if (
			filtersDogs &&
			filtersSmalls &&
			filtersMales &&
			filtersFemales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					pets.id_tamanio === '3' &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, medianos, machos, hembras
		if (
			filtersDogs &&
			filtersMediums &&
			filtersMales &&
			filtersFemales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					pets.id_tamanio === '2' &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, grandes, machos, hembras
		if (
			filtersDogs &&
			filtersBigs &&
			filtersMales &&
			filtersFemales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					pets.id_tamanio === '1' &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, pequeños, machos
		if (
			filtersCats &&
			filtersDogs &&
			filtersSmalls &&
			filtersMales &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					pets.id_tamanio === '3' &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, pequeños, hembras
		if (
			filtersCats &&
			filtersDogs &&
			filtersSmalls &&
			filtersFemales &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			// filtros gatos, perros, pequeños, hembras
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					pets.id_tamanio === '3' &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, medianos, machos
		if (
			filtersCats &&
			filtersDogs &&
			filtersMediums &&
			filtersMales &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					pets.id_tamanio === '2' &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, medianos, hembras
		if (
			filtersCats &&
			filtersDogs &&
			filtersMediums &&
			filtersFemales &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					pets.id_tamanio === '2' &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, grandes, machos
		if (
			filtersCats &&
			filtersDogs &&
			filtersBigs &&
			filtersMales &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					pets.id_tamanio === '1' &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, grandes, hembras
		if (
			filtersCats &&
			filtersDogs &&
			filtersBigs &&
			filtersFemales &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					pets.id_tamanio === '1' &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, pequeños, machos, hembras
		if (
			filtersCats &&
			filtersDogs &&
			filtersSmalls &&
			filtersMales &&
			filtersFemales &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					pets.id_tamanio === '3' &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, medianos, machos, hembras
		if (
			filtersCats &&
			filtersDogs &&
			filtersMediums &&
			filtersMales &&
			filtersFemales &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					pets.id_tamanio === '2' &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, grandes, machos, hembras
		if (
			filtersCats &&
			filtersDogs &&
			filtersBigs &&
			filtersMales &&
			filtersFemales &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					pets.id_tamanio === '1' &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// pequeños, machos
		if (
			filtersSmalls &&
			filtersMales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tamanio === '3' &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// pequeños, hembras
		if (
			filtersSmalls &&
			filtersFemales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tamanio === '3' &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// pequeños, machos, hembras
		if (
			filtersSmalls &&
			filtersMales &&
			filtersFemales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tamanio === '3' &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// medianos, machos
		if (
			filtersMediums &&
			filtersMales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tamanio === '2' &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// medianos, hembras
		if (
			filtersMediums &&
			filtersFemales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tamanio === '2' &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// medianos, machos, hembras
		if (
			filtersMediums &&
			filtersMales &&
			filtersFemales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tamanio === '2' &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// grandes, machos
		if (
			filtersBigs &&
			filtersMales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tamanio === '1' &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// grandes, hembras
		if (
			filtersBigs &&
			filtersFemales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tamanio === '1' &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// grandes, machos, hembras
		if (
			filtersBigs &&
			filtersMales &&
			filtersFemales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tamanio === '1' &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// pequeños, medianos, machos
		if (
			filtersSmalls &&
			filtersMediums &&
			filtersMales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tamanio === '3' || pets.id_tamanio === '2') &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// pequeños, medianos, hembras
		if (
			filtersSmalls &&
			filtersMediums &&
			filtersFemales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tamanio === '3' || pets.id_tamanio === '2') &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// pequeños, medianos, machos, hembras
		if (
			filtersSmalls &&
			filtersMediums &&
			filtersMales &&
			filtersFemales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tamanio === '3' || pets.id_tamanio === '2') &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// pequeños, grandes, machos
		if (
			filtersSmalls &&
			filtersBigs &&
			filtersMales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tamanio === '3' || pets.id_tamanio === '1') &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// pequeños, grandes, hembras
		if (
			filtersSmalls &&
			filtersBigs &&
			filtersFemales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tamanio === '3' || pets.id_tamanio === '1') &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// pequeños, grandes, machos, hembras
		if (
			filtersSmalls &&
			filtersBigs &&
			filtersMales &&
			filtersFemales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tamanio === '3' || pets.id_tamanio === '1') &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// medianos, gandes, machos
		if (
			filtersMediums &&
			filtersBigs &&
			filtersMales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tamanio === '2' || pets.id_tamanio === '1') &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// medianos, gandes, hembras
		if (
			filtersMediums &&
			filtersBigs &&
			filtersFemales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tamanio === '2' || pets.id_tamanio === '1') &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// medianos, gandes, machos, hembras
		if (
			filtersMediums &&
			filtersBigs &&
			filtersMales &&
			filtersFemales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tamanio === '2' || pets.id_tamanio === '1') &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// pequeños, medianos, grandes, machos
		if (
			filtersSmalls &&
			filtersMediums &&
			filtersBigs &&
			filtersMales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tamanio === '3' ||
						pets.id_tamanio === '2' ||
						pets.id_tamanio === '1') &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// pequeños, medianos, grandes, hembras
		if (
			filtersSmalls &&
			filtersMediums &&
			filtersBigs &&
			filtersFemales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tamanio === '3' ||
						pets.id_tamanio === '2' ||
						pets.id_tamanio === '1') &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// pequeños, medianos, grandes, machos, hembras
		if (
			filtersSmalls &&
			filtersMediums &&
			filtersBigs &&
			filtersMales &&
			filtersFemales &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tamanio === '3' ||
						pets.id_tamanio === '2' ||
						pets.id_tamanio === '1') &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);

			dispatch(get_saci_pets_action(filters));
		}
		// gatos, pequeños, medianos, machos
		if (
			filtersCats &&
			filtersSmalls &&
			filtersMediums &&
			filtersMales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '2') &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);

			dispatch(get_saci_pets_action(filters));
		}

		// gatos, pequeños, medianos, hembras
		if (
			filtersCats &&
			filtersSmalls &&
			filtersMediums &&
			filtersFemales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '2') &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, pequeños, medianos, machos, hembras
		if (
			filtersCats &&
			filtersSmalls &&
			filtersMediums &&
			filtersMales &&
			filtersFemales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '2') &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, pequeños, grandes, machos
		if (
			filtersCats &&
			filtersSmalls &&
			filtersBigs &&
			filtersMales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '1') &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, pequeños, grandes, hembras
		if (
			filtersCats &&
			filtersSmalls &&
			filtersBigs &&
			filtersFemales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '1') &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, pequeños, grandes, machos, hembras
		if (
			filtersCats &&
			filtersSmalls &&
			filtersBigs &&
			filtersMales &&
			filtersFemales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '1') &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, medianos, grandes, machos
		if (
			filtersCats &&
			filtersMediums &&
			filtersBigs &&
			filtersMales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					(pets.id_tamanio === '2' || pets.id_tamanio === '1') &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, medianos, grandes, hembras
		if (
			filtersCats &&
			filtersMediums &&
			filtersBigs &&
			filtersFemales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					(pets.id_tamanio === '2' || pets.id_tamanio === '1') &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, medianos, grandes, machos, hembras
		if (
			filtersCats &&
			filtersMediums &&
			filtersBigs &&
			filtersMales &&
			filtersFemales &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '1' &&
					(pets.id_tamanio === '2' || pets.id_tamanio === '1') &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, pequeños, medianos, machos
		if (
			filtersDogs &&
			filtersSmalls &&
			filtersMediums &&
			filtersMales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '2') &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, pequeños, medianos, hembras
		if (
			filtersDogs &&
			filtersSmalls &&
			filtersMediums &&
			filtersFemales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '2') &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);

			dispatch(get_saci_pets_action(filters));
		}

		// perros, pequeños, medianos, machos, hembras
		if (
			filtersDogs &&
			filtersSmalls &&
			filtersMediums &&
			filtersMales &&
			filtersFemales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '2') &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, pequeños, grandes, machos
		if (
			filtersDogs &&
			filtersSmalls &&
			filtersBigs &&
			filtersMales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '1') &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, pequeños, grandes, hembras
		if (
			filtersDogs &&
			filtersSmalls &&
			filtersBigs &&
			filtersFemales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '1') &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, pequeños, gandes, machos, hembras
		if (
			filtersDogs &&
			filtersSmalls &&
			filtersBigs &&
			filtersMales &&
			filtersFemales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '1') &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, medianos, grandes, machos
		if (
			filtersDogs &&
			filtersMediums &&
			filtersBigs &&
			filtersMales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					(pets.id_tamanio === '2' || pets.id_tamanio === '1') &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, medianos, grandes, hembras
		if (
			filtersDogs &&
			filtersMediums &&
			filtersBigs &&
			filtersFemales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					(pets.id_tamanio === '2' || pets.id_tamanio === '1') &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perros, medianos, grandes, machos, hembras
		if (
			filtersDogs &&
			filtersMediums &&
			filtersBigs &&
			filtersMales &&
			filtersFemales &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_tipo_mascota === '2' &&
					(pets.id_tamanio === '2' || pets.id_tamanio === '1') &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, pequeños, medianos, machos
		if (
			filtersCats &&
			filtersDogs &&
			filtersSmalls &&
			filtersMediums &&
			filtersMales &&
			!filtersHamsters &&
			!filtersBigs &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '2') &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, pequeños, medianos, hembras
		if (
			filtersCats &&
			filtersDogs &&
			filtersSmalls &&
			filtersMediums &&
			filtersFemales &&
			!filtersHamsters &&
			!filtersBigs &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '2') &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, pequeños, medianos, machos, hembras
		if (
			filtersCats &&
			filtersDogs &&
			filtersSmalls &&
			filtersMediums &&
			filtersMales &&
			filtersFemales &&
			!filtersHamsters &&
			!filtersBigs &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '2') &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, pequeños, grandes, machos
		if (
			filtersCats &&
			filtersDogs &&
			filtersSmalls &&
			filtersBigs &&
			filtersMales &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '1') &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, pequeños, grandes, hembras
		if (
			filtersCats &&
			filtersDogs &&
			filtersSmalls &&
			filtersBigs &&
			filtersFemales &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '1') &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, pequeños, grandes, machos, hembras
		if (
			filtersCats &&
			filtersDogs &&
			filtersSmalls &&
			filtersBigs &&
			filtersMales &&
			filtersFemales &&
			!filtersHamsters &&
			!filtersMediums &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					(pets.id_tamanio === '3' || pets.id_tamanio === '1') &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, medianos, grandes, machos
		if (
			filtersCats &&
			filtersDogs &&
			filtersMediums &&
			filtersBigs &&
			filtersMales &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					(pets.id_tamanio === '2' || pets.id_tamanio === '1') &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, medianos, grandes, hembras
		if (
			filtersCats &&
			filtersDogs &&
			filtersMediums &&
			filtersBigs &&
			filtersFemales &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					(pets.id_tamanio === '2' || pets.id_tamanio === '1') &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, medianos, grandes, machos, hembras
		if (
			filtersCats &&
			filtersDogs &&
			filtersMediums &&
			filtersBigs &&
			filtersMales &&
			filtersFemales &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					(pets.id_tamanio === '2' || pets.id_tamanio === '1') &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, pequeños, medianos, grandes, machos
		if (
			filtersCats &&
			filtersDogs &&
			filtersSmalls &&
			filtersMediums &&
			filtersBigs &&
			filtersMales &&
			!filtersHamsters &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					(pets.id_tamanio === '3' ||
						pets.id_tamanio === '2' ||
						pets.id_tamanio === '1') &&
					pets.genero_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, pequeños, medianos, grandes, hembras
		if (
			filtersCats &&
			filtersDogs &&
			filtersSmalls &&
			filtersMediums &&
			filtersBigs &&
			filtersFemales &&
			!filtersHamsters &&
			!filtersMales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					(pets.id_tamanio === '3' ||
						pets.id_tamanio === '2' ||
						pets.id_tamanio === '1') &&
					pets.genero_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// gatos, perros, pequeños, medianos, grandes, machos, hembras
		if (
			filtersCats &&
			filtersDogs &&
			filtersSmalls &&
			filtersMediums &&
			filtersBigs &&
			filtersMales &&
			filtersFemales &&
			!filtersHamsters &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.id_tipo_mascota === '1' || pets.id_tipo_mascota === '2') &&
					(pets.id_tamanio === '3' ||
						pets.id_tamanio === '2' ||
						pets.id_tamanio === '1') &&
					(pets.genero_mascota === '1' || pets.genero_mascota === '2') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}
		// cachorros,
		if (
			filtersPuppies &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					((pets.edad_mascota >= 1 &&
						pets.edad_mascota <= 25 &&
						pets.escala_edad === '1') ||
						(pets.edad_mascota <= 6 && pets.escala_edad === '2')) &&
					(id_pais ? pets.id_pais === id_pais : true)
			);

			dispatch(get_saci_pets_action(filters));
		}

		// jovenes,
		if (
			filtersYoungs &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					((pets.edad_mascota >= 7 &&
						pets.edad_mascota <= 24 &&
						pets.escala_edad === '2') ||
						(pets.edad_mascota <= 2 && pets.escala_edad === '3')) &&
					(id_pais ? pets.id_pais === id_pais : true)
			);

			dispatch(get_saci_pets_action(filters));
		}

		// adultos,
		if (
			filtersAdults &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.edad_mascota >= 3 &&
					pets.edad_mascota <= 10 &&
					pets.escala_edad === '3' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// viejos
		if (
			filtersOlds &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.edad_mascota >= 11 &&
					pets.edad_mascota <= 25 &&
					pets.escala_edad === '3' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// cachorros, jovenes, adultos, viejos
		if (
			filtersPuppies &&
			filtersYoungs &&
			filtersAdults &&
			filtersOlds &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales
		) {
			let filters = petFilters.filter(
				(pets) =>
					((pets.edad_mascota >= 1 &&
						pets.edad_mascota <= 25 &&
						pets.escala_edad === '1') ||
						(pets.edad_mascota <= 6 && pets.escala_edad === '2') ||
						(pets.edad_mascota >= 7 &&
							pets.edad_mascota <= 24 &&
							pets.escala_edad === '2') ||
						(pets.edad_mascota <= 2 && pets.escala_edad === '3') ||
						(pets.edad_mascota >= 3 &&
							pets.edad_mascota <= 10 &&
							pets.escala_edad === '3') ||
						(pets.edad_mascota >= 11 &&
							pets.edad_mascota <= 25 &&
							pets.escala_edad === '3')) &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// cachorros, gatos
		if (
			filtersPuppies &&
			filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					((pets.edad_mascota >= 1 &&
						pets.edad_mascota <= 25 &&
						pets.escala_edad === '1') ||
						(pets.edad_mascota <= 6 && pets.escala_edad === '2')) &&
					pets.id_tipo_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// cachorros, perros
		if (
			filtersPuppies &&
			filtersDogs &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					((pets.edad_mascota >= 1 &&
						pets.edad_mascota <= 25 &&
						pets.escala_edad === '1') ||
						(pets.edad_mascota <= 6 && pets.escala_edad === '2')) &&
					pets.id_tipo_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// cachorros, hamsters
		if (
			filtersPuppies &&
			filtersHamsters &&
			!filtersCats &&
			!filtersDogs &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					((pets.edad_mascota >= 1 &&
						pets.edad_mascota <= 25 &&
						pets.escala_edad === '1') ||
						(pets.edad_mascota <= 6 && pets.escala_edad === '2')) &&
					pets.id_tipo_mascota === '3' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// jovenes, gatos
		if (
			filtersYoungs &&
			filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					((pets.edad_mascota >= 7 &&
						pets.edad_mascota <= 24 &&
						pets.escala_edad === '2') ||
						(pets.edad_mascota <= 2 && pets.escala_edad === '3')) &&
					pets.id_tipo_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// jovenes, perros
		if (
			filtersYoungs &&
			filtersDogs &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					((pets.edad_mascota >= 7 &&
						pets.edad_mascota <= 24 &&
						pets.escala_edad === '2') ||
						(pets.edad_mascota <= 2 && pets.escala_edad === '3')) &&
					pets.id_tipo_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// jovenes, hamsters
		if (
			filtersYoungs &&
			filtersHamsters &&
			!filtersCats &&
			!filtersDogs &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					((pets.edad_mascota >= 7 &&
						pets.edad_mascota <= 24 &&
						pets.escala_edad === '2') ||
						(pets.edad_mascota <= 2 && pets.escala_edad === '3')) &&
					pets.id_tipo_mascota === '3' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// adultos, gatos
		if (
			filtersAdults &&
			filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.edad_mascota >= 3 &&
					pets.edad_mascota <= 10 &&
					pets.escala_edad === '3' &&
					pets.id_tipo_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// adultos, perros
		if (
			filtersAdults &&
			filtersDogs &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.edad_mascota >= 3 &&
					pets.edad_mascota <= 10 &&
					pets.escala_edad === '3' &&
					pets.id_tipo_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// adultos, hamsters
		if (
			filtersAdults &&
			filtersHamsters &&
			!filtersCats &&
			!filtersDogs &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.edad_mascota >= 3 &&
					pets.edad_mascota <= 10 &&
					pets.escala_edad === '3' &&
					pets.id_tipo_mascota === '3' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// viejos, gatos
		if (
			filtersOlds &&
			filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.edad_mascota >= 11 &&
					pets.edad_mascota <= 25 &&
					pets.escala_edad === '3' &&
					pets.id_tipo_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// viejos, perros
		if (
			filtersOlds &&
			filtersDogs &&
			!filtersCats &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.edad_mascota >= 11 &&
					pets.edad_mascota <= 25 &&
					pets.escala_edad === '3' &&
					pets.id_tipo_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// viejos, hamsters
		if (
			filtersOlds &&
			filtersHamsters &&
			!filtersCats &&
			!filtersDogs &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.edad_mascota >= 11 &&
					pets.edad_mascota <= 25 &&
					pets.escala_edad === '3' &&
					pets.id_tipo_mascota === '3' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// adoptados
		if (
			filtersAdopt &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds &&
			!filtersLost &&
			!filtersRecovered
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.tipo_tramite === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// recuperados
		if (
			filtersRecovered &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds &&
			!filtersAdopt &&
			!filtersLost
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.tipo_tramite === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// perdidos
		if (
			filtersLost &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds &&
			!filtersAdopt &&
			!filtersRecovered
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.tipo_tramite === '3' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// recuperados, perdidos
		if (
			filtersRecovered &&
			filtersLost &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds &&
			!filtersAdopt
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.tipo_tramite === '2' || pets.tipo_tramite === '3') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// recuperados, adoptados
		if (
			filtersRecovered &&
			filtersAdopt &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds &&
			!filtersLost
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.tipo_tramite === '2' || pets.tipo_tramite === '1') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// adoptados, perdidos
		if (
			filtersAdopt &&
			filtersLost &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds &&
			!filtersRecovered
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.tipo_tramite === '1' || pets.tipo_tramite === '3') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// adoptados, recuperados, perdidos
		if (
			filtersAdopt &&
			filtersRecovered &&
			filtersLost &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds
		) {
			let filters = petFilters.filter(
				(pets) =>
					(pets.tipo_tramite === '1' ||
						pets.tipo_tramite === '2' ||
						pets.tipo_tramite === '3') &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// Paises
		if (
			!filtersCitys &&
			filtersCountrys &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds &&
			!filtersRaces &&
			!filtersCitys &&
			!filtersMunis
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_pais === filtersCountrysData &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			// alert('filtro pais')
			dispatch(get_saci_pets_action(filters));
			setExpanded(['1', '8', '9']);
		}

		// ciudades
		if (
			!filtersMunis &&
			filtersCitys &&
			filtersCountrys &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds &&
			!filtersRaces
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_departamento === filtersCitysData &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			// alert('filtro departamento')
			dispatch(get_saci_pets_action(filters));
			setExpanded(['1', '8', '9', '10']);
		}

		// pais, ciudad, gatos
		if (filtersCountrys && filtersCitys && !filtersMunis && filtersCats) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_pais === filtersCountrysData &&
					pets.id_departamento === filtersCitysData &&
					pets.id_tipo_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// pais, ciudad, perros
		if (filtersCountrys && filtersCitys && !filtersMunis && filtersDogs) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_pais === filtersCountrysData &&
					pets.id_departamento === filtersCitysData &&
					pets.id_tipo_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// pais, ciudad,  hamsters
		if (filtersCountrys && filtersCitys && !filtersMunis && filtersHamsters) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_pais === filtersCountrysData &&
					pets.id_departamento === filtersCitysData &&
					pets.id_tipo_mascota === '3' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// municipios
		if (
			filtersMunis &&
			filtersCitys &&
			filtersCountrys &&
			!filtersCats &&
			!filtersDogs &&
			!filtersHamsters &&
			!filtersSmalls &&
			!filtersMediums &&
			!filtersBigs &&
			!filtersMales &&
			!filtersFemales &&
			!filtersPuppies &&
			!filtersYoungs &&
			!filtersAdults &&
			!filtersOlds &&
			!filtersRaces
		) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_municipio === filtersMunisData &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			// alert('filtro departamento')
			dispatch(get_saci_pets_action(filters));
		}

		// pais, ciudad, municipios, gatos
		if (filtersCountrys && filtersCitys && filtersMunis && filtersCats) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_pais === filtersCountrysData &&
					pets.id_departamento === filtersCitysData &&
					pets.id_municipio === filtersMunisData &&
					pets.id_tipo_mascota === '1' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// pais, ciudad, municipios, perros
		if (filtersCountrys && filtersCitys && filtersMunis && filtersDogs) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_pais === filtersCountrysData &&
					pets.id_departamento === filtersCitysData &&
					pets.id_municipio === filtersMunisData &&
					pets.id_tipo_mascota === '2' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// pais, ciudad, municipios, hamsters
		if (filtersCountrys && filtersCitys && filtersMunis && filtersHamsters) {
			let filters = petFilters.filter(
				(pets) =>
					pets.id_pais === filtersCountrysData &&
					pets.id_departamento === filtersCitysData &&
					pets.id_municipio === filtersMunisData &&
					pets.id_tipo_mascota === '3' &&
					(id_pais ? pets.id_pais === id_pais : true)
			);
			dispatch(get_saci_pets_action(filters));
		}

		// favoritos
		if (filtersFavourites && showFavourite?.length > 0) {
			let id_pet = showFavourite.map(({ id_mascota }) => id_mascota);
			let filters = petFilters.filter((pets) =>
				id_pet.includes(pets.id_mascota)
			);

			dispatch(get_saci_pets_action(filters));
		} else if (filtersFavourites && showFavourite === null) {
			dispatch(get_saci_pets_action([]));
		}

		// if(filtersFavourites && showFavourite === null) { }
	}, [
		filtersCats,
		filtersDogs,
		filtersHamsters,
		filtersSmalls,
		filtersMediums,
		filtersBigs,
		filtersMales,
		filtersFemales,
		filtersPuppies,
		filtersYoungs,
		filtersAdults,
		filtersOlds,
		filtersRaces,
		filtersAdopt,
		filtersRecovered,
		filtersLost,
		filtersCountrys,
		filtersCitys,
		filtersMunis,
		filtersFavourites,
		storedToken,
		id_pais,
		showFavourite
	]);

	const CustomTooltip = (props) => (
		<Tooltip
			{...props}
			arrow
			TransitionComponent={Zoom}
			placement={'right'}
			classes={{ tooltip: classes.CustomTooltip }}
		/>
	);

	return (
		<div>
			<div className={classes.filtersChips}>
				{filtersCats ? (
					<Chip
						className={
							filtersCatsValidate
								? 'animate__animated animate__backOutDown'
								: 'animate__animated animate__fadeInUpBig'
						}
						color="primary"
						label={t('cats')}
						onDelete={handleCatsDelete}
					/>
				) : null}

				{filtersDogs ? (
					<Chip
						className={
							filtersDogsValidate
								? 'animate__animated animate__backOutDown'
								: 'animate__animated animate__fadeInUpBig'
						}
						color="primary"
						label={t('dogs')}
						onDelete={handleDogsDelete}
					/>
				) : null}

				{filtersHamsters ? (
					<Chip
						className={
							filtersHamstersValidate
								? 'animate__animated animate__backOutDown'
								: 'animate__animated animate__fadeInUpBig'
						}
						color="primary"
						label={t('hamsters')}
						onDelete={handleHamstersDelete}
					/>
				) : null}
			</div>

			<div className={classes.filtersChips}>{chipRaces}</div>

			<div className={classes.filtersChips}>
				{filtersSmalls ? (
					<Chip
						className={
							filtersSmallsValidate
								? 'animate__animated animate__backOutDown'
								: 'animate__animated animate__fadeInUpBig'
						}
						color="primary"
						label={t('small')}
						onDelete={handleSmallsDelete}
					/>
				) : null}

				{filtersMediums ? (
					<Chip
						className={
							filtersMediumsValidate
								? 'animate__animated animate__backOutDown'
								: 'animate__animated animate__fadeInUpBig'
						}
						color="primary"
						label={t('medium')}
						onDelete={handleMediumsDelete}
					/>
				) : null}

				{filtersBigs ? (
					<Chip
						className={
							filtersBigsValidate
								? 'animate__animated animate__backOutDown'
								: 'animate__animated animate__fadeInUpBig'
						}
						color="primary"
						label={t('large')}
						onDelete={handleBigsDelete}
					/>
				) : null}
			</div>

			<div className={classes.filtersChips}>
				{filtersMales ? (
					<Chip
						className={
							filtersMalesValidate
								? 'animate__animated animate__backOutDown'
								: 'animate__animated animate__fadeInUpBig'
						}
						color="primary"
						label={t('males')}
						onDelete={handleMalesDelete}
					/>
				) : null}
				{filtersFemales ? (
					<Chip
						className={
							filtersFemalesValidate
								? 'animate__animated animate__backOutDown'
								: 'animate__animated animate__fadeInUpBig'
						}
						color="primary"
						label={t('females')}
						onDelete={handleFemalesDelete}
					/>
				) : null}
			</div>
			<div className={classes.filtersChips}>
				{filtersPuppies ? (
					<Chip
						className={
							filtersPuppiesValidate
								? 'animate__animated animate__backOutDown'
								: 'animate__animated animate__fadeInUpBig'
						}
						color="primary"
						label={t('puppies')}
						onDelete={handlePuppiesDelete}
					/>
				) : null}
				{filtersYoungs ? (
					<Chip
						className={
							filtersYoungsValidate
								? 'animate__animated animate__backOutDown'
								: 'animate__animated animate__fadeInUpBig'
						}
						color="primary"
						label={t('young')}
						onDelete={handleYoungsDelete}
					/>
				) : null}
				{filtersAdults ? (
					<Chip
						className={
							filtersAdultsValidate
								? 'animate__animated animate__backOutDown'
								: 'animate__animated animate__fadeInUpBig'
						}
						color="primary"
						label={t('adults')}
						onDelete={handleAdultsDelete}
					/>
				) : null}
				{filtersOlds ? (
					<Chip
						className={
							filtersOldsValidate
								? 'animate__animated animate__backOutDown'
								: 'animate__animated animate__fadeInUpBig'
						}
						color="primary"
						label={t('old')}
						onDelete={handleOldsDelete}
					/>
				) : null}
			</div>
			<div className={classes.filtersChips}>
				{filtersAdopt ? (
					<Chip
						className={
							filtersAdoptValidate
								? 'animate__animated animate__backOutDown'
								: 'animate__animated animate__fadeInUpBig'
						}
						color="primary"
						label={t('adoptive')}
						onDelete={handleAdoptDelete}
					/>
				) : null}

				{filtersRecovered ? (
					<Chip
						className={
							filtersRecoveredValidate
								? 'animate__animated animate__backOutDown'
								: 'animate__animated animate__fadeInUpBig'
						}
						color="primary"
						label={t('recovered')}
						onDelete={handleRecoveredDelete}
					/>
				) : null}
				{filtersLost ? (
					<Chip
						className={
							filtersLostValidate
								? 'animate__animated animate__backOutDown'
								: 'animate__animated animate__fadeInUpBig'
						}
						color="primary"
						label={t('lost')}
						onDelete={handleLostDelete}
					/>
				) : null}
			</div>

			<div className={classes.filtersChips}>{chipCountrys}</div>

			<div className={classes.filtersChips}>{chipCity}</div>

			<div className={classes.filtersChips}>{chipMunis}</div>

			<div className={classes.filtersChips}>
				{filtersFavourites ? (
					<Chip
						className={
							filtersFavouritesValidate
								? 'animate__animated animate__backOutDown'
								: 'animate__animated animate__fadeInUpBig'
						}
						color="primary"
						label={'Favoritos'}
						onDelete={handleFavouritesDelete}
					/>
				) : null}
			</div>

			<TreeView
				className={classes.root}
				defaultCollapseIcon={<ArrowDropDownIcon />}
				defaultExpandIcon={<ArrowRightIcon />}
				defaultEndIcon={<div style={{ width: 24 }} />}
				expanded={expanded}
				onNodeToggle={handleToggle}
			>
				<StyledTreeItem
					nodeId="1"
					labelText={t('filters')}
					labelIcon={lottieFilter}
					bgColor="#63C132"
					color="#FFFFFF"
					labelInfo={`${countPets}`}
				>
					<StyledTreeItem
						nodeId="2"
						labelText={t('pet-type')}
						labelIcon={lottiePetType}
					// labelInfo="90"
					>
						<StyledTreeItem
							nodeId="10"
							labelText={t('cats')}
							labelIcon={lottieCat}
							onClick={handleCatsAdd}
						// labelInfo="90"
						/>
						<StyledTreeItem
							nodeId="11"
							labelText={t('dogs')}
							labelIcon={lottieDog}
							onClick={handleDogsAdd}
						// labelInfo="90"
						/>

						<StyledTreeItem
							nodeId="12"
							labelText={t('hamsters')}
							labelIcon={lottieHamster}
							onClick={handleHamstersAdd}
						// labelInfo="90"
						/>
					</StyledTreeItem>
					{filtersCats || filtersDogs || filtersHamsters ? (
						<StyledTreeItem
							nodeId="3"
							labelText={t('race')}
							labelIcon={lottiePetRace}
						>
							<Box
								style={{
									height: heightRaces,
									overflow: 'auto'
								}}
							>
								{filtersCats
									? filtersIconsCats.map((item) => (
										<StyledTreeItem
											key={item.id_mascota}
											value={item.id_raza}
											defaultValue={item.raza}
											nodeId={item.id_mascota}
											labelIcon={raceCat}
											labelText={item.raza}
											onClick={() => handleChipsAdd(item.id_raza)}
										></StyledTreeItem>
									))
									: null}

								{filtersDogs
									? filtersIconsDogs.map((item) => (
										<StyledTreeItem
											key={item.id_mascota}
											value={item.id_raza}
											defaultValue={item.raza}
											nodeId={item.id_mascota}
											labelIcon={raceDog2}
											labelText={item.raza}
											onClick={() => handleChipsAdd(item.id_raza)}
										></StyledTreeItem>
									))
									: null}

								{filtersHamsters
									? filtersIconsHamsters.map((item) => (
										<StyledTreeItem
											key={item.id_mascota}
											value={item.id_raza}
											defaultValue={item.raza}
											nodeId={item.id_mascota}
											labelIcon={raceHamster}
											labelText={item.raza}
											onClick={() => handleChipsAdd(item.id_raza)}
										></StyledTreeItem>
									))
									: null}
							</Box>
						</StyledTreeItem>
					) : null}

					<StyledTreeItem
						nodeId="4"
						labelText={t('size')}
						labelIcon={lottiePetSize}
					>
						<StyledTreeItem
							nodeId="13"
							labelText={t('small')}
							labelIcon={lottieSmall}
							onClick={handleSmallsAdd}
						// labelInfo="90"
						/>
						<StyledTreeItem
							nodeId="14"
							labelText={t('medium')}
							labelIcon={lottieMedium}
							onClick={handleMediumsAdd}
						// labelInfo="90"
						/>
						<StyledTreeItem
							nodeId="15"
							labelText={t('large')}
							labelIcon={lottieBig}
							onClick={handleBigsAdd}
						// labelInfo="90"
						/>
					</StyledTreeItem>
					<StyledTreeItem
						nodeId="5"
						labelText={t('gender')}
						labelIcon={lottiePetGender}
					>
						<StyledTreeItem
							nodeId="16"
							labelText={t('males')}
							labelIcon={lottieMale}
							onClick={handleMalesAdd}
						// labelInfo="90"
						/>
						<StyledTreeItem
							nodeId="17"
							labelText={t('females')}
							labelIcon={lottieFemale}
							onClick={handleFemalesAdd}
						// labelInfo="90"
						/>
					</StyledTreeItem>
					<StyledTreeItem
						nodeId="6"
						labelText={t('age')}
						labelIcon={lottiePetYear}
					>
						<CustomTooltip title={t('tooltip-puppies')}>
							<Box>
								<StyledTreeItem
									nodeId="18"
									labelText={t('puppies')}
									labelIcon={lottiePuppie}
									onClick={handlePuppiesAdd}
								// labelInfo="90"
								/>
							</Box>
						</CustomTooltip>
						<CustomTooltip title={t('tooltip-young')}>
							<Box>
								<StyledTreeItem
									nodeId="19"
									labelText={t('young')}
									labelIcon={lottieYoung}
									onClick={handleYoungsAdd}
								// labelInfo="90"
								/>
							</Box>
						</CustomTooltip>
						<CustomTooltip title={t('tooltip-adults')}>
							<Box>
								<StyledTreeItem
									nodeId="20"
									labelText={t('adults')}
									labelIcon={lottieAdult}
									onClick={handleAdultsAdd}
								// labelInfo="90"
								/>
							</Box>
						</CustomTooltip>
						<CustomTooltip title={t('tooltip-old')}>
							<Box>
								<StyledTreeItem
									nodeId="21"
									labelText={t('old')}
									labelIcon={lottieOld}
									onClick={handleOldsAdd}
								// labelInfo="90"
								/>
							</Box>
						</CustomTooltip>
					</StyledTreeItem>
					<StyledTreeItem
						nodeId="7"
						labelText={t('publication-type')}
						labelIcon={lottieState}
					>
						<StyledTreeItem
							nodeId="22"
							labelText={t('adoptive')}
							labelIcon={lottieAdopt}
							onClick={handleAdoptAdd}
						// labelInfo="90"
						/>
						<StyledTreeItem
							nodeId="23"
							labelText={t('recovered')}
							labelIcon={lottieRecover}
							onClick={handleRecoveredAdd}
						// labelInfo="90"
						/>
						<StyledTreeItem
							nodeId="24"
							labelText={t('lost')}
							labelIcon={lottieLost}
							onClick={handleLostAdd}
						// labelInfo="90"
						/>
					</StyledTreeItem>
					<StyledTreeItem
						nodeId="8"
						labelText={t('location')}
						labelIcon={lottieLocation}
					>
						{filterIconsCountrys.map((item) => (
							<StyledTreeItem
								key={item.id_mascota}
								nodeId={item.id_mascota}
								labelText={titleCase(item.pais)}
								labelIcon={
									/* COLOMBIA */		(item.id_pais === '1' && lottieCountryCol) ||
									/* ESTADOS UNIDOS */(item.id_pais === '1242' && lottieCountryEu) ||
									/* BRASIL */	 	(item.id_pais === '1205' && lottieCountryBr) ||
									/* CHILE */		 	(item.id_pais === '1227' && lottieCountryCh) ||
									/* ECUADOR */	 	(item.id_pais === '1234' && lottieCountryEc) ||
									/* ARGENTINA */	 	(item.id_pais === '1190' && lottieCountryAr) ||
									/* MEXICO */		(item.id_pais === '1308' && lottieCountryMe) ||
									/* BOLIVIA */		(item.id_pais === '1203' && lottieCountryBo) ||
									/* CANADA */		(item.id_pais === '1214' && lottieCountryCa) ||
									/* PARAGUAY */		(item.id_pais === '1335' && lottieCountryPa) ||
									/* URUGUAY */		(item.id_pais === '1393' && lottieCountryUr) ||
									/* VENEZUELA */		(item.id_pais === '1395' && lottieCountryVe) ||
									/* PERU */			(item.id_pais === '1336' && lottieCountryPe) ||
									/* BELICE */		(item.id_pais === '1238' && lottieCountryBal) ||
									/* COSTA */			(item.id_pais === '1223' && lottieCountryCos) ||
									/* ELSALVADOR*/		(item.id_pais === '1236' && lottieCountrySalv) ||
									/* GUATEMALA */		(item.id_pais === '1259' && lottieCountryGua) ||
									/* HONDURAS */		(item.id_pais === '1266' && lottieCountryHo) ||
									/* NICARAGUA */		(item.id_pais === '1319' && lottieCountryNi) ||
									/* PANAMA */		(item.id_pais === '1334' && lottieCountryPan) ||
									/* CUBA */			(item.id_pais === '1225' && lottieCountryCu) ||
									/* ESPAÑA */		(item.id_pais === '1239' && lottieCountryEs) ||
									/* SURINAM */		(item.id_pais === '1374' && lottieCountrySu) ||
									/* TRINIDAD&TOBAGO*/(item.id_pais === '1385' && lottieCountryTri)
								}
								onClick={() => handleCountryAdds(item.id_pais)}
							// labelInfo="90"
							></StyledTreeItem>
						))}
					</StyledTreeItem>

					{/* id_codigo  	País

					1238		Belice
					1223		Costa Rica
					1236		El Salvador
					1259		Guatemala
					1266		Honduras
					1319		Nicaragua
					1334		Panama
					1225		Cuba
					1239		España
					1374		Surinam
					1385		Trinidad y Tobago */}
					{filtersCountrys && (
						<StyledTreeItem
							nodeId="9"
							labelText={
								filtersCountrysData === '1' ? t('department') : t('state')
							}
							labelIcon={lottieLocation}
						>
							<Box
								style={{
									height: heightCity,
									overflow: 'auto'
								}}
							>
								{filterIconsDepartments.map((item) => (
									<StyledTreeItem
										key={item.id_mascota}
										nodeId={item.id_departamento}
										labelIcon={lottieLocation}
										labelText={titleCase(item.departameto)}
										onClick={() => handleChipsAddCity(item.id_departamento)}
									></StyledTreeItem>
								))}
							</Box>
						</StyledTreeItem>
					)}
					{filtersCitys && (
						<StyledTreeItem
							nodeId="10"
							labelText={t('municipality')}
							labelIcon={lottieLocation}
						>
							<Box
								style={{
									height: heightMunis,
									overflow: 'auto'
								}}
							>
								{filterIconsMuni.map((item) => (
									<StyledTreeItem
										key={item.id_mascota}
										nodeId={item.id_municipio}
										labelIcon={lottieLocation}
										labelText={titleCase(item.municipio)}
										onClick={() => handleChipsAddMunis(item.id_municipio)}
									></StyledTreeItem>
								))}
							</Box>
						</StyledTreeItem>
					)}

					{showFavourite?.length !== 0 && (
						<StyledTreeItem
							nodeId="30"
							labelText={t('favorites')}
							labelIcon={lottieFavourite}
						>
							<StyledTreeItem
								nodeId="31"
								labelText={t('view-favorites')}
								labelIcon={FavoriteIcon}
								onClick={handleFavouritesAdd}
							// labelInfo="90"
							/>
						</StyledTreeItem>
					)}
				</StyledTreeItem>
			</TreeView>
		</div>
	);
}
