import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AdoptStepper from './AdoptForm/AdoptStepper';
import FindPetButton from './Adoption/FindPetButton';
import SectionDesktop from './SectionDesktop';
import TraductionButton from './TraductionButton';
import { fade, makeStyles } from '@material-ui/core/styles';
import {
	AppBar,
	Toolbar,
	IconButton,
	Typography,
	MenuItem,
	Menu,
	Hidden,
	Drawer,
	Box
} from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreVert';
import servicesIcon from '../../assets/icons/drawer/services.svg';
import storeIcon from '../../assets/icons/drawer/store.svg';
import logoIcon from './../../assets/svg/logo-pinina.svg';
import menuIcon from '../../assets/icons/menu-final.svg';
import searchIcon from '../../assets/icons/drawer/searchIcon-final.svg';
import { useHistory } from 'react-router-dom';
import { FiltersSearchPets } from './Adoption/FiltersSearchPets';
import clsx from 'clsx';
import { get_session_action, save_user_token_action } from '../../redux/actions/loginAction';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},

	search: {
		color: '#fff',
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': {
			backgroundColor: fade(theme.palette.common.white, 0.25)
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: theme.spacing(3),
			width: 'auto'
		}
	},

	searchIcon: {
		color: '#fff',
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},

	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex'
		}
	},

	sectionMobile: {
		display: 'flex',
		[theme.breakpoints.up('md')]: {
			display: 'none'
		}
	},

	logoIcon: {
		width: '40px'
	},

	title: {
		color: '#ffff',
		fontSize: '1.2rem',
		marginRight: '5px',
		[theme.breakpoints.up('xs')]: {
			display: 'block'
		}
	},

	title2: {
		color: '#000',
		fontSize: '1.2rem',
		[theme.breakpoints.up('xs')]: {
			display: 'block'
		}
	},

	traductionButton: {
		marginLeft: theme.spacing(3)
	},

	mainPhrase: {
		width: '100%',
		textAlign: 'center',
		color: '#fff',
		fontWeight: 'bold',

	}
}));

export default function PrimarySearchAppBar() {
	const [t] = useTranslation("global");
	const classes = useStyles();

	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
	// const [storedToken, setStoredToken] = useLocalStorage('token', '');

	const history = useHistory();

	const dispatch = useDispatch();

	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const { user, token } = useSelector((state) => state.login);

	const { showUserPets, showOutReqPets } = useSelector(
		(state) => state.saciPets
	);

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMenuClose = () => {
		handleMobileMenuClose();
	};

	const handleMobileMenuOpen = (event) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	const [open, setOpen] = useState();

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenu = (
		<Hidden mdUp>
			<Menu
				anchorEl={mobileMoreAnchorEl}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				id={mobileMenuId}
				keepMounted
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={isMobileMenuOpen}
				onClose={handleMobileMenuClose}
			>
				<div onClick={user.nombres.length === 0 ? handleMenuClose : undefined}>
					<SectionDesktop handleMenuClose={handleMenuClose} />
				</div>
				<div onClick={handleMenuClose}>
					<AdoptStepper />
				</div>
				<div>
					<FindPetButton handleMenuClose={handleMenuClose} />
				</div>
				<div>
					<TraductionButton handleMenuClose={handleMenuClose} />
				</div>
			</Menu>
		</Hidden>
	);

	return (
		<div className={classes.grow} style={{ marginTop: 80, zIndex: '9000' }}>
			<AppBar position="fixed" style={{ zIndex: 1 }}>
				<Toolbar>
					<IconButton
						edge="start"
						className={classes.menuButton}
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
					>
						<img src={menuIcon} alt="Menu" style={{ width: '25px' }} />
					</IconButton>
					<IconButton
						edge="start"
						className={classes.menuButton}
						color="inherit"
						aria-label="open drawer"
						onClick={() => history.push('/')}
					>
						<img className={classes.logoIcon} src={logoIcon} alt="logo"></img>
					</IconButton>
					{showUserPets ? (
						<Hidden smUp>
							<Typography className={classes.title} variant="h5">
								PET
							</Typography>
							<Typography className={classes.title2} variant="h5">
								SACI
							</Typography>
						</Hidden>
					) : null}
					<Hidden only="xs">
						<Typography className={classes.title} variant="h5">
							PET
						</Typography>
						<Typography className={classes.title2} variant="h5">
							SACI
						</Typography>
					</Hidden>

					<div
						className={clsx(classes.traductionButton, [classes.sectionDesktop])}
					>
						<TraductionButton />
					</div>

					{!showUserPets && !showOutReqPets ? (
						<div className={classes.search}>
							<div className={classes.searchIcon}>
								<img src={searchIcon} alt="" style={{ width: '30px' }} />
							</div>
							<FiltersSearchPets />
						</div>
					) : null}

					<div className={classes.grow}>
						<Hidden smDown>
							<p className={classes.mainPhrase} style={{ marginTop: '10px' }}>
								{t("main-phrase")}
							</p>
						</Hidden>

					</div>
					<div className={classes.sectionDesktop}>
						<FindPetButton handleMenuClose={handleMenuClose} />
						<AdoptStepper />
						<SectionDesktop />
					</div>
					<div className={classes.sectionMobile}>
						<IconButton
							aria-label="show more"
							aria-controls={mobileMenuId}
							aria-haspopup="true"
							onClick={handleMobileMenuOpen}
							// onClick={() => dispatch(set_responsive_menu_action(true))}
							color="inherit"
						>
							<MoreIcon />
						</IconButton>
					</div>
				</Toolbar>
				<Hidden smUp>
					<div className={classes.mainPhrase}>
						{t("main-phrase")}
					</div>
				</Hidden>

			</AppBar>
			{renderMobileMenu}

			<Drawer
				docked={false}
				width={500}
				open={open}
				onClose={() => setOpen(false)}
			>
				<AppBar position="static">
					<Toolbar>
						<IconButton
							edge="start"
							className={classes.menuButton}
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerClose}
						>
							<img src={menuIcon} alt="Menu" style={{ width: '25px' }} />
						</IconButton>
						<IconButton className={classes.upper}>
							<img className={classes.logoIcon} src={logoIcon} alt="logo"></img>
						</IconButton>
						<Typography className={classes.title} variant="h5" noWrap>
							PET
						</Typography>
						<Typography className={classes.title2} variant="h5" noWrap>
							SACI
						</Typography>
					</Toolbar>
				</AppBar>
				<div className={classes.draweritem}>
					<Box m={2}>
						<MenuItem>
							<img
								src={servicesIcon}
								style={{ width: '30px', marginRight: '15px' }}
								alt="servicios"
							/>
							<h6>Servicios</h6>
						</MenuItem>
						<MenuItem>
							<img
								src={storeIcon}
								style={{ width: '30px', marginRight: '15px' }}
								alt="Tienda"
							/>
							<h6>Tienda</h6>
						</MenuItem>
					</Box>
				</div>
			</Drawer>
		</div>
	);
}
