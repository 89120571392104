/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';

import MuiDialogTitle from '@material-ui/core/DialogTitle';

import MuiDialogContent from '@material-ui/core/DialogContent';

import MuiDialogActions from '@material-ui/core/DialogActions';

import CloseIcon from '@material-ui/icons/Close';

import PersonIcon from '@material-ui/icons/Person';

import {
	Dialog,
	IconButton,
	Typography,
	Avatar,
	Grid,
	TextField,
	Fab,
	useMediaQuery,
	useTheme,
	Slide,
	Tooltip
} from '@material-ui/core';

import SendIcon from '@material-ui/icons/Send';

import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
	clean_question_answer_user_action,
	dialog_show_request_action,
	get_question_answer_user_2_action,
	get_question_answer_user_action,
	send_answer_user_action,
	send_question_user_action
} from '../../../../redux/actions/questionUsersAction';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const styles = (theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	titleChat: {
		fontSize: '1rem'
	}
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	const [t] = useTranslation('global');
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography className={classes.titleChat} variant="h6">
				{children}
			</Typography>
			{onClose ? (
				<Tooltip title={t('close')} >
					<IconButton
						aria-label="close"
						className={classes.closeButton}
						onClick={onClose}
					>
						<CloseIcon />
					</IconButton>
				</Tooltip>

			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(2)
	}
}))(MuiDialogActions);

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
	messageContainer1: {
		wordWrap: 'break-word'
	},

	messageContainer2: {
		display: 'flex',
		justifyContent: 'flex-end',
		wordBreak: 'break-word'
	},

	chipContainer: {
		borderRadius: 16,
		padding: '5px 12px',
		display: 'block',
		maxWidth: 'fit-content'
	},

	chipColorPrimary: {
		backgroundColor: '#63C132'
	},

	chipColorSecondary: {
		backgroundColor: '#E4E6EB'
	}
});

export default function CustomizedDialogs() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const classes = useStyles();

	const { userPetData, selectRequestData } = useSelector(
		(state) => state.userPets
	);

	const { dialog, questionAnswerData, questionAnswerData2 } = useSelector(
		(state) => state.questionUsers
	);

	// const { showUserPets, petSelected, pageMascotas } = useSelector(
	// 	(state) => state.saciPets
	// );

	// const { open, handleClickOpen, handleClose } = useDialogShowRequest();

	// const [open, setOpen] = useState(false);

	// const handleOpen = () => {
	// 	dispatch(dialog_show_request_action(true))
	// }

	// const baseURL = process.env.REACT_APP_BACKEND_URL;

	// const { userPhoto } = useSelector((state) => state.login);

	const handleClose = () => {
		dispatch(dialog_show_request_action(false));
		dispatch(clean_question_answer_user_action());
	};

	const {
		register,
		errors,
		handleSubmit: handleSubmitQuestion
	} = useForm({
		mode: 'onChange'
	});

	const { id_usuario, id, id_mascota } = userPetData;

	const [petdata, setPetData] = useState({
		primer_usuario: `${id_usuario}`,
		segundo_usuario: `${id}`,
		mensaje: '',
		id_mascota: `${id_mascota}`
	});

	useEffect(() => {
		setPetData({
			primer_usuario: `${id_usuario}`,
			segundo_usuario: `${id}`,
			mensaje: '',
			id_mascota: `${id_mascota}`
		});
	}, [userPetData]);

	// console.log(petdata);

	const handleChangeMessage = (event) => {
		const { name, value } = event.target;
		setPetData({ ...petdata, [name]: value });
	};

	//Obtener Pregunta
	const [questionData, setQuestionData] = useState({
		primer_usuario: `${id_usuario}`,
		primer_usuario: `${id_usuario}`,
		segundo_usuario: `${id}`
	});

	//Obtener Pregunta
	useEffect(() => {
		setQuestionData({
			primer_usuario: `${id_usuario}`,
			id_mascota: `${id_mascota}`,
			segundo_usuario: `${id}`
		});
	}, [userPetData]);

	// Enviar preguntas
	const onSubmitQuestion = async (data, e) => {
		e.preventDefault();
		e.target.reset();
		await dispatch(send_question_user_action(petdata));
		dispatch(get_question_answer_user_action(questionData));
	};

	// obtener Obtener Preguntas y Respuestas al abrir el dialog
	useEffect(() => {
		if (dialog && selectRequestData === null) {
			dispatch(get_question_answer_user_action(questionData));
		}
	}, [dialog]);

	// obtener Obtener Preguntas y Respuestas en vivo
	useEffect(() => {
		if (
			(questionAnswerData.length > 0 && dialog) ||
			(questionAnswerData.ok && dialog)
		) {
			setTimeout(() => {
				dispatch(get_question_answer_user_action(questionData));
			}, 1000);
		} else {
			dispatch(clean_question_answer_user_action());
		}
	}, [questionAnswerData]);

	////////////////////////////////////////////////////////////////////////////////

	let last_position;

	if (questionAnswerData2.form !== null) {
		last_position = questionAnswerData2[questionAnswerData2.length - 1];
	}

	const {
		register: registerAnswer,
		errors: errorsAnswer,
		handleSubmit: handleSubmitAnswer
	} = useForm({
		mode: 'onChange'
	});

	const [petdataAnswer, setPetDataAnswer] = useState({
		respuesta: '',
		id: `${last_position?.id}`
	});

	useEffect(() => {
		setPetDataAnswer({
			respuesta: `${petdataAnswer.respuesta}`,
			id: `${last_position?.id}`
		});
	}, [last_position]);

	// console.log(petdataAnswer);

	const handleChangeAnswer = (event) => {
		const { name, value } = event.target;
		setPetDataAnswer({ ...petdataAnswer, [name]: value });
	};

	const onSubmitAnswer = async (data, e) => {
		e.preventDefault();
		e.target.reset();
		dispatch(send_answer_user_action(petdataAnswer));
	};

	//ObtenerPreguntaRespuesta
	const [requestData, setRequestData] = useState({
		segundo_usuario: `${selectRequestData?.id}`,
		id_mascota: `${selectRequestData?.id_mascota}`
	});

	useEffect(() => {
		setRequestData({
			segundo_usuario: `${selectRequestData?.id}`,
			id_mascota: `${selectRequestData?.id_mascota}`
		});
	}, [selectRequestData]);

	// console.log(requestData);

	useEffect(() => {
		if (selectRequestData !== null && dialog) {
			// alert('1 useEffect')
			dispatch(get_question_answer_user_2_action(requestData));
		}
	}, [requestData, dialog]);

	useEffect(() => {
		// switch (questionAnswerData2.form && dialog) {
		// 	case null:
		// 		setTimeout(() => {
		// 			dispatch(get_question_answer_user_2_action(requestData));
		// 		}, 1000);
		// 		break;
		// 	default:
		// }
		if (
			(questionAnswerData2.length > 0 && dialog) ||
			(questionAnswerData2.ok && dialog)
		) {
			setTimeout(() => {
				// alert('2 useEffect')
				dispatch(get_question_answer_user_2_action(requestData));
			}, 1000);
		} else {
			dispatch(clean_question_answer_user_action());
		}
	}, [questionAnswerData2]);
	//////////////////////////

	const questionAnswer = questionAnswerData.form !== null;

	const questionAnswer2 = questionAnswerData2.form !== null;

	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.only('xs'));

	return (
		<>
			<Dialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={dialog}
				maxWidth={'xs'}
				fullWidth
				fullScreen={fullScreen}
				TransitionComponent={Transition}
			// transitionDuration={1000}
			>
				<DialogTitle id="customized-dialog-title" onClose={handleClose}>
					{t('request-status')}:{' '}
					<span style={{ color: '#63C132', fontSize: 15 }}>
						{(selectRequestData?.solicitud_adopcion === null &&
							t('status-pending')) ||
							(userPetData.solicitud_adopcion === null &&
								t('status-pending')) ||
							(userPetData.solicitud_adopcion === true &&
								t('status-accepted')) ||
							(userPetData.solicitud_adopcion === false &&
								t('status-rejected'))}
					</span>
				</DialogTitle>
				<DialogContent dividers>
					{questionAnswer ? (
						questionAnswerData.map(
							({ id, fecha_envio, mensaje, respuesta }) => (
								<div key={id}>
									{mensaje && (
										<Grid
											container
											justify="flex-end"
											alignItems="flex-end"
											spacing={2}
										>
											<Grid item xs={8} className={classes.messageContainer2}>
												<Typography
													variant="body2"
													className={clsx(classes.chipContainer, [
														classes.chipColorPrimary
													])}
												>
													{mensaje}
												</Typography>
											</Grid>
										</Grid>
									)}

									{respuesta && (
										<Grid container spacing={2} alignItems="center">
											<Grid container>
												<Typography
													gutterBottom
													variant="caption"
													style={{
														color: '#8f8f8f'
													}}
												>
													{fecha_envio}
												</Typography>
											</Grid>
											<Grid item>
												<Avatar className={classes.chipColorSecondary}>
													{/* src={ `${baseURL}${userPhoto[0]?.id}.jpg` } */}
													<PersonIcon color="primary" />
												</Avatar>
											</Grid>
											<Grid item xs={8} className={classes.messageContainer1}>
												<Typography
													variant="body2"
													className={clsx(classes.chipContainer, [
														classes.chipColorSecondary
													])}
												>
													{respuesta}
												</Typography>
											</Grid>
										</Grid>
									)}
								</div>
							)
						)
					) : questionAnswer2 ? (
						questionAnswerData2.map(
							({ id, fecha_envio, mensaje, respuesta }) => (
								<div key={id}>
									{mensaje && (
										<Grid container spacing={2} alignItems="center">
											<Grid container>
												<Typography
													variant="caption"
													style={{
														color: '#8f8f8f'
													}}
												>
													{fecha_envio}
												</Typography>
											</Grid>
											<Grid item>
												<Avatar className={classes.chipColorSecondary}>
													{/* src={ `${baseURL}${userPhoto[0]?.id}.jpg` } */}
													<PersonIcon color="primary" />
												</Avatar>
											</Grid>
											<Grid item xs={8} className={classes.messageContainer1}>
												<Typography
													variant="body2"
													className={clsx(classes.chipContainer, [
														classes.chipColorSecondary
													])}
												>
													{mensaje}
												</Typography>
											</Grid>
										</Grid>
									)}
									{respuesta && (
										<Grid
											container
											spacing={2}
											justify="flex-end"
											alignItems="flex-end"
										>
											<Grid item xs={8} className={classes.messageContainer2}>
												<Typography
													variant="body2"
													className={clsx(classes.chipContainer, [
														classes.chipColorPrimary
													])}
												>
													{respuesta}
												</Typography>
											</Grid>
										</Grid>
									)}
								</div>
							)
						)
					) : (
						<Grid
							xs={12}
							item
							style={{
								padding: 12
							}}
						>
							<Typography>
								{selectRequestData === null
									? t("send-message")
									: t("no-message")}
							</Typography>
						</Grid>
					)}
				</DialogContent>

				{selectRequestData === null && (
					<form onSubmit={handleSubmitQuestion(onSubmitQuestion)}>
						<DialogActions>
							<Grid
								container
								justify="flex-end"
								alignItems="flex-end"
								spacing={2}
							>
								<Grid item xs={10}>
									<TextField
										label={t("write-message")}
										name="mensaje"
										variant="outlined"
										size="small"
										fullWidth
										inputRef={register({
											required: {
												value: true,
												message: t("complete-validation")
											}
										})}
										helperText={errors?.mensaje?.message}
										error={errors?.mensaje?.message ? true : false}
										onChange={handleChangeMessage}
									/>
								</Grid>
								<Grid item xs={2}>
									<Fab
										id="buttonWhite"
										aria-label="save"
										color="primary"
										type="submit"
										size="small"
									>
										<SendIcon />
									</Fab>
								</Grid>
							</Grid>
						</DialogActions>
					</form>
				)}

				{selectRequestData !== null && questionAnswer2 && (
					<form onSubmit={handleSubmitAnswer(onSubmitAnswer)}>
						<DialogActions>
							<Grid
								container
								justify="flex-end"
								alignItems="flex-end"
								spacing={2}
							>
								<Grid item xs={10}>
									<TextField
										label={t("write-message")}
										name="respuesta"
										variant="outlined"
										size="small"
										fullWidth
										inputRef={registerAnswer({
											required: {
												value: true,
												message: t("complete-validation")
											}
										})}
										helperText={errorsAnswer?.respuesta?.message}
										error={errorsAnswer?.respuesta?.message ? true : false}
										onChange={handleChangeAnswer}
										disabled={last_position.respuesta !== null && true}
									/>
								</Grid>
								<Grid item xs={2}>
									<Fab
										disabled={last_position.respuesta !== null && true}
										id="buttonWhite"
										aria-label="save"
										color="primary"
										type="submit"
										size="small"
									>
										<SendIcon />
									</Fab>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="caption" style={{ color: 'red' }}>
										{last_position.respuesta !== null &&
											t("wait-message")}
									</Typography>
								</Grid>
							</Grid>
						</DialogActions>
					</form>
				)}
			</Dialog>
		</>
	);
}
