import axiosClient from './axios';

//redux selector


export async function getPetSize(petData) {

    try {
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
        const response = await axiosClient.post('/razasTipoTamano', petData)
        return response
    } catch (e) {
        console.log(e)
    }
}

export async function getHamsterRace(hamsterData) {

    try {
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
        const response = await axiosClient.post('/razas', hamsterData)
        return response
    } catch (e) {
        console.log(e)
    }
}

export async function getDepartmentData(departmentData) {
    try {
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
        const response = await axiosClient.post('/mostrardepartamentos', departmentData)
        return response
    } catch (e) {
        console.log(e)
    }
}

export async function registryPetAdoption(newPet) {
    try {
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
        const response = await axiosClient.post('/mascotas', newPet);
        return response
    } catch (e) {
        console.log(e)
    }

}

export async function getCityData(depData) {

    try {
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
        const response = await axiosClient.post('/mostrarmunicipios', depData)
        return response
    } catch (e) {
        console.log(e)
    }
}

export async function uploadImagesAdoption(petimage1, id_mascota) {
    console.log('consecutivo1');
    const formData = new FormData();
    formData.append('consecutivo', 1)
    formData.append('photo', petimage1)
    formData.append('id_mascota', id_mascota)
    try {
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
        const response = await axiosClient.post('/upload', formData)
        return response
    } catch (e) {
        console.log(e)
    }
}

export async function uploadPetImage2(petimage2, id_mascota) {
    console.log('consecutivo2');
    const formData = new FormData();
    formData.append('consecutivo', 2)
    formData.append('photo', petimage2)
    formData.append('id_mascota', id_mascota)

    try {
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
        const response = await axiosClient.post('/upload', formData)
        return response
    } catch (e) {
        console.log(e)
    }
}

export async function uploadPetImage3(petimage3, id_mascota) {
    console.log('consecutivo3');
    const formData = new FormData();
    formData.append('consecutivo', 3)
    formData.append('photo', petimage3)
    formData.append('id_mascota', id_mascota)

    try {
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
        const response = await axiosClient.post('/upload', formData)
        return response
    } catch (e) {
        console.log(e)
    }
}

export async function uploadPetImage4(petimage4, id_mascota) {
    console.log('consecutivo4');
    const formData = new FormData();
    formData.append('consecutivo', 4)
    formData.append('photo', petimage4)
    formData.append('id_mascota', id_mascota)

    try {
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
        const response = await axiosClient.post('/upload', formData)
        return response
    } catch (e) {
        console.log(e)
    }
}

export async function uploadPetImage5(petimage5, id_mascota) {
    console.log('consecutivo5');
    const formData = new FormData();
    formData.append('consecutivo', 5)
    formData.append('photo', petimage5)
    formData.append('id_mascota', id_mascota)

    try {
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
        const response = await axiosClient.post('/upload', formData)
        return response
    } catch (e) {
        console.log(e)
    }
}

export async function uploadPetImage6(petimage5, id_mascota, consecutivo) {
    console.log('consecutivo6', petimage5, consecutivo);
    const formData = new FormData();
    formData.append('consecutivo', consecutivo)
    formData.append('photo', petimage5)
    formData.append('id_mascota', id_mascota)

    try {
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
        const response = await axiosClient.post('/upload', formData)
        return response
    } catch (e) {
        console.log(e)
    }
}

export async function updateFormData(descriptionData) {
    try {
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
        const response = await axiosClient.post('/ActualizarMascota', descriptionData)
        return response.data
    }
    catch (error) {
        console.log(error)
    }
}


