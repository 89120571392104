import { makeStyles } from '@material-ui/core';
import { mainColor } from './mainTheme';

export const forgotPasswordStyles = makeStyles((theme) => ({
	codeInputs: {
		width: '50px',
		height: '50px',
		borderRadius: '10px',
		borderColor: '#adb5bd',
		border: '2px solid',
		textAlign: 'center',
		fontSize: '20px',
		'&:focus': {
			borderColor: mainColor,
			outline: 'none'
		}
	},

	button: {
		color: '#fff'
	},
	emailTitle: {
		padding: theme.spacing(0, 0, 8, 0)
	},
	buttonEmail: {
		padding: theme.spacing(8, 0, 0, 0)
	},
	errorMsg: {
		color: '#BC3535'
	},
	dialogContainer: {
		flex: 'none'
	},
	dialog: {
		'&& .MuiDialog-paperFullScreen': {
			justifyContent: 'center'
		}
	}
}));
