/* eslint-disable react-hooks/exhaustive-deps */
import {
	Box,
	Button,
	Dialog,
	Divider,
	Grid,
	Hidden,
	IconButton,
	Tooltip,
	Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
//icons
import adoptRequestStyles from '../../../../assets/css/js/adoptRequestStyles';
import { useDispatch, useSelector } from 'react-redux';
import {
	allow_or_deny_request_pet_action,
	clean_requestData_action,
	get_user_pets_request,
	set_selected_request_action
} from '../../../../redux/actions/userPetsAction';
//Sweet Alerts
import Swal from 'sweetalert2';
//icons
import CloseIcon from '@material-ui/icons/Close';

import CancelIcon from '@material-ui/icons/Cancel';
import ChatIcon from '@material-ui/icons/Chat';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import ShowRequest from '../../../SaciComponents/UserComponents/AdoptRequest/ShowRequest';
import { get_city_data_action } from '../../../../redux/actions/adoptFormAction';
import { dialog_show_request_action } from '../../../../redux/actions/questionUsersAction';
import { useTranslation } from 'react-i18next';

export default function RequestForm() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const { selectRequestData } = useSelector((state) => state.userPets);
	const { departments, cities } = useSelector((state) => state.adoptFormData);
	const classes = adoptRequestStyles();
	const [requestState, setRequestState] = useState(null);
	const { id } = useSelector((state) => state.login.user);
	const [modal, setModal] = useState(false);
	const [questions, setQuestions] = useState({
		pregunta_1: '',
		pregunta_2: '',
		pregunta_3: '',
		pregunta_4: '',
		pregunta_5: '',
		pregunta_6: ''
	});

	const {
		pregunta_1,
		pregunta_2,
		pregunta_3,
		pregunta_4,
		pregunta_5,
		pregunta_6
	} = questions;

	const [departmentData, setDepartmentData] = useState(null);

	const [cityData, setCityData] = useState(null);

	useEffect(() => {
		if (selectRequestData) {
			const departmentData = departments.filter(
				(item) => item.id_codigo === selectRequestData.id_codigo
			);
			console.log(departmentData);

			switch (selectRequestData.tipo_tramite) {
				case '1':
					setQuestions({
						pregunta_1: t('adopted-question-1'),
						pregunta_2: t('adopted-question-2'),
						pregunta_3: t('adopted-question-3'),
						pregunta_4: t('adopted-question-4'),
						pregunta_5: t('adopted-question-5'),
						pregunta_6: t('adopted-question-6')
					});
					break;
				case '2':
					setQuestions({
						pregunta_1: t('recovered-question-1'),
						pregunta_2: t('adopted-question-2'),
						pregunta_3: t('recovered-question-3'),
						pregunta_4: t('recovered-question-4')
					});
					break;
				case '3':
					setQuestions({
						pregunta_1: t('lost-question-1'),
						pregunta_2: t('lost-question-2'),
						pregunta_3: t('lost-question-3')
					});
					break;
				default:
			}
			// getLocationData();
			setModal(true);
		}
	}, [selectRequestData, t]);

	useEffect(() => {
		if (selectRequestData?.solicitud_adopcion) {
			setRequestState(t('status-accepted'));
		} else if (!selectRequestData?.solicitud_adopcion) {
			setRequestState(t('status-rejected'));
		}
	}, [selectRequestData, t]);

	const handleClickAcceptRequest = () => {
		Swal.fire({
			title: t('accept-request'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: t('accept'),
			cancelButtonText: t('cancel'),
			confirmButtonColor: '#63C132',
			cancelButtonColor: '#BC3535'
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(
					allow_or_deny_request_pet_action({
						solicitud_adopcion: 'true',
						id_mascota: selectRequestData.id_mascota,
						id_formulario: selectRequestData.id_formulario
					})
				);
				dispatch(get_user_pets_request({ id_usuario: id }));
				dispatch(set_selected_request_action(null));

				Swal.fire(t('request-accepted'), '', 'success');
				// Swal.close();
			}
		});
	};

	const handleClickDenyRequest = () => {
		Swal.fire({
			title: t('reject-request'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: t('reject'),
			cancelButtonText: t('cancel'),
			confirmButtonColor: '#63C132',
			cancelButtonColor: '#BC3535'
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(
					allow_or_deny_request_pet_action({
						solicitud_adopcion: 'false',
						id_mascota: selectRequestData.id_mascota,
						id_formulario: selectRequestData.id_formulario
					})
				);
				dispatch(get_user_pets_request({ id_usuario: id }));
				dispatch(set_selected_request_action(null));
				Swal.fire(t('request-rejected'), '', 'error');
			}
		});
	};

	const handleClickCloseModal = () => {
		setModal(false);
		dispatch(clean_requestData_action());
	};

	const handleClickShowRequest = () => {
		dispatch(dialog_show_request_action(true));
	};

	console.log(selectRequestData?.solicitud_adopcion);

	// function getLocationData() {
	// 	let departmentLocation = departments.filter(
	// 		(item) => item.id_codigo === selectRequestData.ciudad
	// 	);
	// 	let depStr = departmentLocation[0].descripcion;
	// 	// if (depStr = 'BOGOTA D.C') {
	// 	// 	setDepartmentData('Bogotá D.C');
	// 	// } else {
	// 	let depStrFix = depStr.charAt(0) + depStr.slice(1).toLowerCase();
	// 	setDepartmentData(depStrFix);
	// 	// }
	// 	dispatch(
	// 		get_city_data_action({ id_unde: departmentLocation[0].id_codigo })
	// 	);
	// }

	useEffect(() => {
		if (selectRequestData !== null) {
			if (cities?.length !== 0) {
				let cityLocation = cities.filter(
					(item) => item.id_codigo === selectRequestData.localidad
				);
				if (cityLocation?.length !== 0) {
					let cityStr = cityLocation[0].descripcion;
					let cityStrFix = cityStr.charAt(0) + cityStr.slice(1).toLowerCase();
					setCityData(cityStrFix);
				}
			}
		}
	}, [cities]);

	return (
		<>
			<Hidden smDown>
				<div>
					{selectRequestData ? (
						<div className={classes.root}>
							<Grid
								container
								spacing={2}
								alignContent="center"
								justify="center"
								alignItems="center"
							>
								<Grid
									item
									xl={6}
									lg={6}
									md={6}
									sm={6}
									xs={6}
									className={classes.textFields}
								>
									<Typography color="primary">{t('applicant-name')}</Typography>
									<Typography>{selectRequestData.nombres}</Typography>
								</Grid>
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<Divider />
								</Grid>
								<Grid item xl={4} lg={4} md={6} sm={6} xs={4}>
									<Typography color="primary">{t('pet-request')}</Typography>
									<Typography>{selectRequestData.nombre_mascota}</Typography>
								</Grid>
								<Grid item xl={4} lg={4} md={6} sm={6} xs={8}>
									<Typography color="primary">{t('email')}</Typography>
									<Typography>{selectRequestData.correo_user}</Typography>
								</Grid>
								<Hidden lgUp>
									<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
										<Divider />
									</Grid>
								</Hidden>
								<Grid item xl={4} lg={4} md={6} sm={4} xs={6}>
									<Typography color="primary">{t('telephone')}</Typography>
									<Typography>+{selectRequestData.telefono}</Typography>
								</Grid>
								<Hidden mdDown>
									<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
										<Divider />
									</Grid>
								</Hidden>
								<Grid item xl={4} lg={4} md={6} sm={4} xs={6}>
									<Typography color="primary">{t('civil-status')}</Typography>
									<Typography>{selectRequestData.estado_civil}</Typography>
								</Grid>
								<Hidden lgUp>
									<Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
										<Divider />
									</Grid>
								</Hidden>
								<Grid item xl={4} lg={4} md={6} sm={6} xs={6}>
									<Typography color="primary">{t('occupation')}</Typography>
									<Typography>{selectRequestData.ocupacion}</Typography>
								</Grid>
								<Grid item xl={4} lg={4} md={6} sm={6} xs={6}>
									<Typography color="primary">Ubicación</Typography>
									<Typography>
										{selectRequestData?.ubicacion}
									</Typography>
								</Grid>
								<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
									<Divider />
								</Grid>
								{selectRequestData.pregunta_1 ? (
									<>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<Typography color="primary">{pregunta_1}</Typography>
											<Typography>{selectRequestData.pregunta_1}</Typography>
										</Grid>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<Divider />
										</Grid>
									</>
								) : null}
								{selectRequestData.pregunta_2 ? (
									<>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<Typography color="primary">{pregunta_2}</Typography>
											<Typography>{selectRequestData.pregunta_2}</Typography>
										</Grid>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<Divider />
										</Grid>
									</>
								) : null}
								{selectRequestData.pregunta_3 ? (
									<>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<Typography color="primary">{pregunta_3}</Typography>
											<Typography>{selectRequestData.pregunta_3}</Typography>
										</Grid>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<Divider />
										</Grid>
									</>
								) : null}
								{selectRequestData.pregunta_4 ? (
									<>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<Typography color="primary">{pregunta_4}</Typography>
											<Typography>{selectRequestData.pregunta_4}</Typography>
										</Grid>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<Divider />
										</Grid>
									</>
								) : null}
								{selectRequestData.pregunta_5 ? (
									<>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<Typography color="primary">{pregunta_5}</Typography>
											<Typography>{selectRequestData.pregunta_5}</Typography>
										</Grid>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<Divider />
										</Grid>
									</>
								) : null}
								{selectRequestData.pregunta_6 ? (
									<>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<Typography color="primary">{pregunta_6}</Typography>
											<Typography>{selectRequestData.pregunta_6}</Typography>
										</Grid>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<Divider />
										</Grid>
									</>
								) : null}
								{selectRequestData?.solicitud_adopcion !== null ? (
									<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
										<Grid
											container
											spacing={2}
											justify="center"
											alignItems="center"
										>
											<Grid item md={6} lg={4}>
												<Typography align="center">
													{t('request-status')}: {requestState}
												</Typography>
											</Grid>

											{selectRequestData?.solicitud_adopcion !== false && (
												<Grid item xl={2} md={3}>
													<Button
														variant="contained"
														color="primary"
														onClick={handleClickShowRequest}
														fullWidth
														style={{
															color: '#fff',
															textTransform: 'none',
															display: 'flex'
														}}
														startIcon={<ChatIcon />}
													>
														{t('chat')}
													</Button>
													<ShowRequest />
												</Grid>
											)}
										</Grid>
									</Grid>
								) : (
									<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
										<Grid container spacing={2} justify="center">
											<Grid item xl={2} md={3} style={{ display: 'flex' }}>
												<Button
													variant="contained"
													color="primary"
													onClick={handleClickAcceptRequest}
													fullWidth
													style={{
														color: '#fff',
														textTransform: 'none'
													}}
													startIcon={<CheckCircleIcon />}
												>
													{t('accept')}
												</Button>
											</Grid>
											<Grid item xl={2} md={3}>
												<Button
													variant="contained"
													color="primary"
													onClick={handleClickDenyRequest}
													fullWidth
													style={{
														color: '#fff',
														textTransform: 'none',
														display: 'flex'
													}}
													startIcon={<CancelIcon />}
												>
													{t('reject')}
												</Button>
											</Grid>
											<Grid item xl={2} xs={3}>
												<Button
													variant="contained"
													color="primary"
													onClick={handleClickShowRequest}
													fullWidth
													style={{
														color: '#fff',
														textTransform: 'none',
														display: 'flex'
													}}
													startIcon={<ChatIcon />}
												>
													{t('chat')}
												</Button>

												<ShowRequest />
											</Grid>
										</Grid>
									</Grid>
								)}
							</Grid>

							{/* <Grid container alignContent='center' justify='center' alignItems='center'>
                                
                            </Grid> */}
						</div>
					) : (
						<Grid
							container
							justify="center"
							alignItems="center"
							style={{ height: '400px' }}
						>
							<Grid item xs={4} lg={4} md={4} sm={4}>
								<Typography
									style={{ marginLeft: 'auto', marginRight: 'auto' }}
									variant="h6"
									align="center"
								>
									{t('select-request')}
								</Typography>
							</Grid>
						</Grid>
					)}
				</div>
			</Hidden>
			<Hidden mdUp>
				<Dialog open={modal} fullScreen style={{ zIndex: 1 }}>
					<div>
						<Grid container>
							<Grid item xs={10}>
								<Box />
							</Grid>
							<Grid item xs={2}>
								<Tooltip title={t('close')} >
									<IconButton onClick={handleClickCloseModal}>
										<CloseIcon />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>
						{selectRequestData ? (
							<div className={classes.root}>
								<Grid
									container
									spacing={2}
									alignContent="center"
									justify="center"
									alignItems="center"
								>
									<Grid
										item
										xl={6}
										lg={6}
										md={6}
										sm={6}
										xs={6}
										className={classes.textFields}
									>
										<Typography color="primary">
											{t('applicant-name')}
										</Typography>
										<Typography>{selectRequestData.nombres}</Typography>
									</Grid>
									<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
										<Divider />
									</Grid>
									<Grid item xl={4} lg={4} md={6} sm={6} xs={4}>
										<Typography color="primary">{t('pet-request')}</Typography>
										<Typography>{selectRequestData.nombre_mascota}</Typography>
									</Grid>
									<Grid item xl={4} lg={4} md={6} sm={6} xs={8}>
										<Typography color="primary">{t('email')}</Typography>
										<Typography>{selectRequestData.correo_user}</Typography>
									</Grid>
									<Hidden lgUp>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<Divider />
										</Grid>
									</Hidden>
									<Grid item xl={4} lg={4} md={6} sm={4} xs={6}>
										<Typography color="primary">{t('telephone')}</Typography>
										<Typography>{selectRequestData.telefono}</Typography>
									</Grid>
									<Hidden mdDown>
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<Divider />
										</Grid>
									</Hidden>
									<Grid item xl={4} lg={4} md={6} sm={4} xs={6}>
										<Typography color="primary">{t('civil-status')}</Typography>
										<Typography>{selectRequestData.estado_civil}</Typography>
									</Grid>
									<Hidden lgUp>
										<Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
											<Divider />
										</Grid>
									</Hidden>
									<Grid item xl={4} lg={4} md={6} sm={6} xs={6}>
										<Typography color="primary">{t('occupation')}</Typography>
										<Typography>{selectRequestData.ocupacion}</Typography>
									</Grid>
									<Grid item xl={4} lg={4} md={6} sm={6} xs={6}>
										<Typography color="primary">{t('location')}</Typography>
										<Typography>{selectRequestData.localidad}</Typography>
									</Grid>
									<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
										<Divider />
									</Grid>
									{selectRequestData.pregunta_1 ? (
										<>
											<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
												<Typography color="primary">{pregunta_1}</Typography>
												<Typography>{selectRequestData.pregunta_1}</Typography>
											</Grid>
											<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
												<Divider />
											</Grid>
										</>
									) : null}
									{selectRequestData.pregunta_2 ? (
										<>
											<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
												<Typography color="primary">{pregunta_2}</Typography>
												<Typography>{selectRequestData.pregunta_2}</Typography>
											</Grid>
											<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
												<Divider />
											</Grid>
										</>
									) : null}
									{selectRequestData.pregunta_3 ? (
										<>
											<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
												<Typography color="primary">{pregunta_3}</Typography>
												<Typography>{selectRequestData.pregunta_3}</Typography>
											</Grid>
											<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
												<Divider />
											</Grid>
										</>
									) : null}
									{selectRequestData.pregunta_4 ? (
										<>
											<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
												<Typography color="primary">{pregunta_4}</Typography>
												<Typography>{selectRequestData.pregunta_4}</Typography>
											</Grid>
											<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
												<Divider />
											</Grid>
										</>
									) : null}
									{selectRequestData.pregunta_5 ? (
										<>
											<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
												<Typography color="primary">{pregunta_5}</Typography>
												<Typography>{selectRequestData.pregunta_5}</Typography>
											</Grid>
											<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
												<Divider />
											</Grid>
										</>
									) : null}
									{selectRequestData.pregunta_6 ? (
										<>
											<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
												<Typography color="primary">{pregunta_6}</Typography>
												<Typography>{selectRequestData.pregunta_6}</Typography>
											</Grid>
											<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
												<Divider />
											</Grid>
										</>
									) : null}
									{selectRequestData?.solicitud_adopcion !== null ? (
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<Grid
												container
												spacing={2}
												justify="center"
												alignItems="center"
											>
												<Grid
													item
													xl={6}
													lg={6}
													md={6}
													sm={6}
													xs={12}
													style={{ textAlign: 'center' }}
												>
													<Typography>
														{t('request-status')}: {requestState}
													</Typography>
												</Grid>
												{selectRequestData?.solicitud_adopcion !== false && (
													<Grid item xs={6} sm={3}>
														<Button
															variant="contained"
															color="primary"
															onClick={handleClickShowRequest}
															fullWidth
															style={{
																color: '#fff',
																textTransform: 'none',
																display: 'flex'
															}}
															startIcon={<ChatIcon />}
														>
															{t('chat')}
														</Button>
														<ShowRequest />
													</Grid>
												)}
											</Grid>
										</Grid>
									) : (
										<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
											<Grid container spacing={2} justify="center">
												<Grid
													item
													xs={6}
													sm={4}
													md={3}
													style={{ display: 'flex' }}
												>
													<Button
														variant="contained"
														color="primary"
														onClick={handleClickAcceptRequest}
														fullWidth
														style={{
															color: '#fff',
															textTransform: 'none'
														}}
														startIcon={<CheckCircleIcon />}
													>
														{t('accept')}
													</Button>
												</Grid>
												<Grid item xs={6} sm={4} md={3}>
													<Button
														variant="contained"
														color="primary"
														onClick={handleClickDenyRequest}
														fullWidth
														style={{
															color: '#fff',
															textTransform: 'none',
															display: 'flex'
														}}
														startIcon={<CancelIcon />}
													>
														{t('reject')}
													</Button>
												</Grid>

												<Grid item xs={12} sm={4} md={3}>
													<Button
														variant="contained"
														color="primary"
														onClick={handleClickShowRequest}
														fullWidth
														style={{
															color: '#fff',
															textTransform: 'none',
															display: 'flex'
														}}
														startIcon={<ChatIcon />}
													>
														{t('chat')}
													</Button>

													<ShowRequest />
												</Grid>

												<Grid item xs={3}>
													<ShowRequest request={false} />
												</Grid>
											</Grid>
										</Grid>
									)}
								</Grid>
							</div>
						) : (
							<Grid
								container
								justify="center"
								alignItems="center"
								style={{ height: '400px' }}
							>
								<Grid item xs={4} lg={4} md={4} sm={4}>
									<Typography
										style={{ marginLeft: 'auto', marginRight: 'auto' }}
										variant="h6"
									>
										Selecciona alguna solicitud para ver los detalles.
									</Typography>
								</Grid>
							</Grid>
						)}
					</div>
				</Dialog>
			</Hidden>
		</>
	);
}
