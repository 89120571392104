import axiosClient from './axios';

export async function saveAdoptMeForm(formData) {
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post('/formulario', formData);

		const formDataPets = new FormData();

		formDataPets.append('id_mascota', formData.id_mascota);
		formDataPets.append('id_formulario', response.data.form[0].id_formulario);

		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		await axiosClient.post('/formulariomascota', formDataPets);

		return response;
	} catch (e) {
		console.log(e);
	}
}

export async function updateAdoptMeForm(formData) {
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post('/actualizarformulario', formData);
		return response;
	} catch (e) {
		console.log(e);
	}
}

export default saveAdoptMeForm;
