import axiosClient from './axios';

export async function updateCountry (countryData) {
    try {
        axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
        const response = await axiosClient.post('/actualizarpais', countryData);
        return response
    } catch (error) {
        console.log(error)
    }
}