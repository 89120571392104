import getUserPets, {
	allowOrDenyRequest,
	cancelPetRequest,
	publishPet,
	userPetRequest
} from '../../configAxios/userPets';
import {
	SET_USER_PETS_MODAL,
	GET_PETS_BY_USER,
	SAVE_USER_PET_ID,
	SET_USER_PET_MODAL_DATA,
	SAVE_USER_PET_DATA,
	RESET_PETS,
	ACTIVE_PET_STATE,
	SET_PUBLISHED_PET,
	REQUEST_PET,
	SELECTED_REQUEST,
	CLEAN_USER_PETS_DATA,
	MY_REQUEST_PAGE,
	SEND_REQUEST_PAGE,
	PUBLISH_USER_PET_STATE,
	SET_CARD_PETS_TITLE,
	SET_ENTRY_REQUEST_PAGE_STATE,
	ACCEPT_REQUEST_PET,
	DENY_REQUEST_PET,
	NEW_REQUEST_PET,
	CLEAN_REQUEST_DATA_ACTION,
	ALLOW_OR_DENY_USER_PET_REQUEST,
	CANCEL_PET_REQUEST,
	PUBLISH_REQUEST,
	CLEAN_PETS_BY_USER
} from '../types';

export const user_pets_modal_action = (state) => async (dispatch) => {
	dispatch({
		type: SET_USER_PETS_MODAL,
		payload: state
	});
};

export const get_pets_by_user_action = (userId) => async (dispatch) => {
	const response = await getUserPets(userId);
	dispatch({
		type: GET_PETS_BY_USER,
		payload: response
	});
};

export const save_user_pet_id_action = (petId) => async (dispatch) => {
	dispatch({
		type: SAVE_USER_PET_ID,
		payload: petId
	});
};

export const set_user_pet_modal_data_action = (state) => async (dispatch) => {
	dispatch({
		type: SET_USER_PET_MODAL_DATA,
		payload: state
	});
};

export const save_selected_pet_data_action = (petData) => async (dispatch) => {
	dispatch({
		type: SAVE_USER_PET_DATA,
		payload: petData
	});
};

export const reset_pets_action = () => async (dispatch) => {
	dispatch({
		type: RESET_PETS
	});
};

export const set_active_pets_action = (state) => async (dispatch) => {
	dispatch({
		type: ACTIVE_PET_STATE,
		payload: state
	});
};

export const set_published_pet_action = (data) => async (dispatch) => {
	try {
		const response = await publishPet(data);
		dispatch({
			type: SET_PUBLISHED_PET,
			payload: response
		});
		return response;
	} catch (error) {
		console.log(error);
	}
};

export const publish_request = (state) => async (dispatch) => {
	dispatch({
		type: PUBLISH_REQUEST,
		payload: state
	});
};

export const get_user_pets_request = (data) => async (dispatch) => {
	try {
		const response = await userPetRequest(data);
		dispatch({
			type: REQUEST_PET,
			payload: response
		});
	} catch (error) {
		console.log(error);
	}
};

export const save_accept_request_action =
	(acceptRequest) => async (dispatch) => {
		dispatch({
			type: ACCEPT_REQUEST_PET,
			payload: acceptRequest
		});
	};

export const save_deny_request_action = (denyRequest) => async (dispatch) => {
	try {
		dispatch({
			type: DENY_REQUEST_PET,
			payload: denyRequest
		});
	} catch (error) {
		console.log(error);
	}
};

export const save_new_request_action = (newRequest) => async (dispatch) => {
	dispatch({
		type: NEW_REQUEST_PET,
		payload: newRequest
	});
};

export const set_selected_request_action =
	(requestData) => async (dispatch) => {
		dispatch({
			type: SELECTED_REQUEST,
			payload: requestData
		});
	};

export const clean_user_pets_data_action = () => async (dispatch) => {
	dispatch({
		type: CLEAN_USER_PETS_DATA
	});
};

export const set_on_my_request_page_action = (state) => async (dispatch) => {
	dispatch({
		type: MY_REQUEST_PAGE,
		payload: state
	});
};

export const set_on_send_request_page_action = (state) => async (dispatch) => {
	dispatch({
		type: SEND_REQUEST_PAGE,
		payload: state
	});
};

export const set_publish_pet_state_action = (state) => async (dispatch) => {
	dispatch({
		type: PUBLISH_USER_PET_STATE,
		payload: state
	});
};

export const set_card_pets_title_action = (titleData) => async (dispatch) => {
	dispatch({
		type: SET_CARD_PETS_TITLE,
		payload: titleData
	});
};

export const set_on_entry_request_page = (state) => async (dispatch) => {
	dispatch({
		type: SET_ENTRY_REQUEST_PAGE_STATE,
		payload: state
	});
};

export const clean_requestData_action = () => async (dispatch) => {
	dispatch({
		type: CLEAN_REQUEST_DATA_ACTION
	});
};

export const clean_pets_by_user_action = () => async (dispatch) => {
	dispatch({
		type: CLEAN_PETS_BY_USER
	});
};

export const allow_or_deny_request_pet_action =
	(requestData) => async (dispatch) => {
		try {
			await allowOrDenyRequest(requestData);
			dispatch({
				type: ALLOW_OR_DENY_USER_PET_REQUEST,
				payload: 1
			});
		} catch (error) {
			console.error();
		}
	};

export const cancel_pet_request_action =
	(cancelRequestData) => async (dispatch) => {
		// console.log(cancelRequestData);
		try {
			const response = await cancelPetRequest(cancelRequestData);
			dispatch({
				type: CANCEL_PET_REQUEST,
				payload: response.data
			});
		} catch (error) {
			console.log(error);
		}
	};
