import { useState } from 'react';

export const useDialog = () => {
	const [open, setOpen] = useState(false);

	const [check, setCheck] = useState();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (checked) => {
		setOpen(false);
		if (checked === true) {
			setCheck(true);
		} else if (checked === false) {
			setCheck(false);
		}
	};

	return {
		open,
		check,
		handleClickOpen,
		handleClose
	};
};
