import {
	GET_SACI_PETS,
	GET_SACI_PETS_FILTERS,
	GET_SACI_EMPTY_PETS,
	PAGE_SACI_PETS,
	SELECT_PET_DATA,
	SELECT_PET_FORM_DATA,
	SET_ADOPT_ME_DIALOG,
	SET_ADOPT_ME_DIALOG_PROCEDURE,
	SET_PET_DATA_DIALOG,
	SET_UNLOGGED_MODAL,
	SHOW_USER_PETS,
	RESET_PETS,
	GET_PETS_NO_FILTER,
	NO_USER_PETS,
	SHOW_OUTPUT_REQ_PETS,
	SET_USER_PETS_STATE,
	SET_CARD_PETS_TITLE,
	SET_OUTPUT_REQ_PETS_STATE,
	CLEAN_PETS,
	GET_SACI_PETS_VALIDATE,
	GET_SACI_PETS_VALIDATE_CLEAN
} from '../types';

const initialState = {
	mascotas: [],
	pageMascotas: [],
	petFilters: [],
	petFiltersValidate: false,
	petEmpty: false,
	petSelected: null,
	petFormSelected: null,
	adoptMeDialog: false,
	dataDialog: false,
	unloggedModal: false,
	showUserPets: false,
	showOutReqPets: false,
	procedure: null,
	title: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_SACI_PETS:
			return {
				...state,
				mascotas: action.payload,
				petEmpty: false
			};
		
		case GET_SACI_PETS_FILTERS:
			return {
				...state,
				petFilters: action.payload
			}

		case GET_SACI_PETS_VALIDATE:
			return {
				...state,
				petFiltersValidate: action.payload
			}
		
		case GET_SACI_PETS_VALIDATE_CLEAN: 
			return {
				...state,
				petFiltersValidate: action.payload
			}

		case GET_SACI_EMPTY_PETS:
			return {
				...state,
				petEmpty: true
			}

		case PAGE_SACI_PETS:
			return {
				...state,
				pageMascotas: action.payload
			};

		case SELECT_PET_DATA:
			return {
				...state,
				petSelected: action.payload
			};

		case SELECT_PET_FORM_DATA:
			return {
				...state,
				petFormSelected: action.payload
			};

		case SET_ADOPT_ME_DIALOG:
			return {
				...state,
				adoptMeDialog: action.payload
			};

		case SET_ADOPT_ME_DIALOG_PROCEDURE:
			return {
				...state,
				procedure: action.payload
			};
		case SET_PET_DATA_DIALOG:
			return {
				...state,
				dataDialog: action.payload
			};
		case SET_UNLOGGED_MODAL:
			return {
				...state,
				unloggedModal: action.payload
			};
		case SHOW_USER_PETS:
			return {
				...state,
				mascotas: action.payload,
				showUserPets: true
			};
		case SET_USER_PETS_STATE:
			return {
				...state,
				showUserPets: action.payload
			};
		case RESET_PETS:
			return {
				...state,
				pageMascotas: []
			};
		case GET_PETS_NO_FILTER:
			return {
				...state,
				mascotas: action.payload,
				showUserPets: false
			};
		case NO_USER_PETS:
			return {
				...state,
				showUserPets: false
			};
		case SHOW_OUTPUT_REQ_PETS:
			return {
				...state,
				mascotas: action.payload,
				showOutReqPets: true
			};
		case CLEAN_PETS:
			return {
				...state,
				mascotas: [],
				pageMascotas: []
			};
		case SET_OUTPUT_REQ_PETS_STATE:
			return {
				...state,
				showOutReqPets: action.payload
			};
		case SET_CARD_PETS_TITLE:
			return {
				...state,
				title: action.payload
			};
		default:
			return state;
	}
};
