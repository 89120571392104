import { LoginUserRegistered, saveUserLogin } from '../../configAxios/Login';

import {
	saveUserEdit,
	savePhoneEdit,
	saveEmailEdit,
	savePasswordEdit,
	validatePasswordEdit,
	uploadPhotoEdit,
	deletePhotoEdit
} from '../../configAxios/userProfile';
import {
	INICIAR_SESION_ERROR,
	INICIAR_SESION_EXITO,
	INICIAR_SESION_EXITO_PHOTO,
	INICIAR_SESION_START,
	LOGIN_DIALOG_CLOSE,
	LOGIN_DIALOG_OPEN,
	ADOPT_DIALOG_OPEN,
	ADOPT_DIALOG_CLOSE,
	ADOPTSTEPPER_DIALOG_OPEN,
	ADOPTSTEPPER_DIALOG_CLOSE,
	SACI_USER_PROFILE,
	SACI_PHONE_PROFILE,
	SACI_EMAIL_PROFILE_VALIDATE,
	SACI_EMAIL_PROFILE,
	SACI_PASSWORD_VALIDATE_PROFILE,
	SACI_PASSWORD_PROFILE,
	SACI_PHOTO_PROFILE,
	SACI_PHOTO_DELETE_PROFILE,
	CHECK_LOGIN,
	CHECK_LOGOUT,
	SET_PROCEDURE,
	LOAD_GOOGLE_DATA,
	SAVE_USER_TOKEN,
	GET_SESSION,
	SET_CHECK_EMAIL_MODAL,
	SET_FORGOT_PASSWORD_MODAL,
	LOGIN_ERROR_CLEAN,
	UPDATE_COUNTRY_USER,
	LOGIN_CLEAN_PHOTO,
	ALERT_PASSWORD_CODE,
	ALERT_CLEAN_CODE
} from '../types';

import axiosClient from '../../configAxios/axios';
import { updateCountry } from '../../configAxios/updateCountry';
import { setEncryptPassword } from '../../components/utils';


// Get Data to localStorage
export function loginNormalAction(data) {
	console.log('data', data);

	return async (dispatch) => {
		dispatch(loginNormalStart());
		try {
			const response = await saveUserLogin(data);
			if (response.code === 1) {
				dispatch(loginNormalSuccess(response.user));
				dispatch(loginNormalSuccessPhoto(response.foto));
				dispatch(save_user_token_action(response.tokenGenerado));
			} else {
				dispatch(alert_action(response.msg));
				dispatch(loginNormalError(response.msg));
			}
		} catch (error) {
			dispatch(loginNormalError(error));
		}
	};
}

// User Default
export function saveUserDefaultLogin(data) {
	console.log('data', data);
	return async (dispatch) => {
		try {
			console.log('data', data);
			const formData = new FormData();
			formData.append('password', setEncryptPassword(data.password))
			formData.append('correo', data.correo)
			formData.append('origen_cuenta', data.origen_cuenta)
			axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
			const response = await axiosClient.post('/login', formData);
			console.log('response', response);
			dispatch(save_user_token_action(response.data.tokenGenerado));
		} catch (error) {
			dispatch(loginNormalError(error));
		}
	};
}

export function LoginRegisteredAction(userLog) {
	return async (dispatch) => {
		dispatch(loginNormalStart());

		try {
			const response = await LoginUserRegistered(userLog);
			dispatch(loginNormalSuccess(response.data.user));
		} catch (error) {
			dispatch(loginNormalError(error));
			console.log(error);
		}
	};
}

const loginNormalStart = () => ({
	type: INICIAR_SESION_START
});

const loginNormalSuccess = (username) => ({
	type: INICIAR_SESION_EXITO,
	payload: username
});

export const loginNormalSuccessPhoto = (userPhoto) => ({
	type: INICIAR_SESION_EXITO_PHOTO,
	payload: userPhoto
});

export const save_user_token_action = (token) => ({
	type: SAVE_USER_TOKEN,
	payload: token
});

const loginNormalError = (error) => ({
	type: INICIAR_SESION_ERROR,
	payload: error
});

export const login_dialog_open_action = (loginDialog) => {
	return {
		type: LOGIN_DIALOG_OPEN,
		payload: loginDialog
	};
};

export const login_dialog_close_action = (loginDialog) => {
	return {
		type: LOGIN_DIALOG_CLOSE,
		payload: loginDialog
	};
};

export const adopt_dialog_open_action = (adoptDialog) => {
	console.log(adoptDialog);
	let procedure_type = adoptDialog.tipo_tramite;
	console.log(procedure_type);

	return {
		type: ADOPT_DIALOG_OPEN,
		payload: procedure_type
	};
};

export const adopt_dialog_close_action = (adoptDialog) => {
	return {
		type: ADOPT_DIALOG_CLOSE,
		payload: adoptDialog
	};
};

export const adoptstepper_dialog_open_action = (adoptstepperDialog) => {
	return {
		type: ADOPTSTEPPER_DIALOG_OPEN,
		payload: adoptstepperDialog
	};
};

export const adoptstepper_dialog_close_action = (adoptstepperDialog) => {
	return {
		type: ADOPTSTEPPER_DIALOG_CLOSE,
		payload: adoptstepperDialog
	};
};

export const saci_user_profile_action = (editName) => async (dispatch) => {
	try {
		await saveUserEdit(editName);
		console.log(editName);
		dispatch({
			type: SACI_USER_PROFILE,
			payload: editName
		});
	} catch (error) {
		console.log(error);
	}
};

export const saci_phone_profile_action = (editPhone) => async (dispatch) => {
	try {
		await savePhoneEdit(editPhone);
		console.log(editPhone);
		dispatch({
			type: SACI_PHONE_PROFILE,
			payload: editPhone
		});
	} catch (error) {
		console.log(error);
	}
};

export const saci_email_validate_profile_action =
	(editEmail) => async (dispatch) => {
		try {
			const response = await saveEmailEdit(editEmail);
			console.log(response);
			dispatch({
				type: SACI_EMAIL_PROFILE_VALIDATE,
				payload: response.data
			});
		} catch (error) {
			console.log(error);
		}
	};

export const saci_email_profile_action = (editEmail) => async (dispatch) => {
	try {
		// const response = await saveEmailEdit(editEmail);
		// console.log(response);

		// await saveEmailEdit(editEmail);
		console.log(editEmail);

		dispatch({
			type: SACI_EMAIL_PROFILE,
			payload: editEmail
		});
		// dispatch({
		//   type: SACI_EMAIL_PROFILE_VALIDATE,
		//   payload: response.data,
		// });
	} catch (error) {
		console.log(error);
	}
};

export const saci_password_validate_profile_action =
	(editPasswordCurrent) => async (dispatch) => {
		try {
			const response = await validatePasswordEdit(editPasswordCurrent);

			console.log(editPasswordCurrent);
			dispatch({
				type: SACI_PASSWORD_VALIDATE_PROFILE,
				payload: response.data
			});
		} catch (error) {
			console.log(error);
		}
	};

export const saci_password_profile_action =
	(editPassword) => async (dispatch) => {
		try {
			await savePasswordEdit(editPassword);
			console.log(editPassword);
			dispatch({
				type: SACI_PASSWORD_PROFILE,
				payload: editPassword
			});
		} catch (error) {
			console.log(error);
		}
	};

export const saci_photo_profile_action =
	(userimage, id, preview) => async (dispatch) => {
		try {
			const responsePhotoProfile = await uploadPhotoEdit(userimage, id);
			console.log(responsePhotoProfile, 'responsePhotoProfile');
			dispatch({
				type: SACI_PHOTO_PROFILE,
				payload: preview
			});
		} catch (error) {
			console.log(error);
		}
	};

export const saci_photo_delete_profile_action = (id) => async (dispatch) => {
	try {
		const responseDeletePhotoProfile = await deletePhotoEdit(id);
		console.log(responseDeletePhotoProfile);
		dispatch({
			type: SACI_PHOTO_DELETE_PROFILE,
			payload: responseDeletePhotoProfile
		});
	} catch (error) {
		console.log(error);
	}
};

export const check_login_action = () => async (dispatch) => {
	dispatch({
		type: CHECK_LOGIN
	});
};

export const check_logout_action = () => async (dispatch) => {
	dispatch({
		type: CHECK_LOGOUT
	});
};

export const set_procedure_action = (procedure) => async (dispatch) => {
	dispatch({
		type: SET_PROCEDURE,
		payload: procedure
	});
};

export const load_google_data_action = (data) => async (dispatch) => {
	dispatch({
		type: LOAD_GOOGLE_DATA,
		payload: data
	});
};

export const get_session_action = (token) => async (dispatch) => {
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post('/acsseso', token);
		dispatch({
			type: GET_SESSION,
			payload: response.data.form[0]
		});
		dispatch(loginNormalSuccessPhoto(response.data.foto));
	} catch (error) {
		console.log(error);
	}
};

export const set_check_email_modal_action = (state) => async (dispatch) => {
	console.log(state, 'state')
	dispatch({
		type: SET_CHECK_EMAIL_MODAL,
		payload: state
	});
};

export const set_forgot_password_modal_action = (state) => async (dispatch) => {
	dispatch({
		type: SET_FORGOT_PASSWORD_MODAL,
		payload: state
	});
};

export const gen_password_code_action = (data) => async (dispatch) => {
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post('/generate-code-password', data);
		return response.data;
	} catch (error) {
		dispatch(alert_action(error.response.data.msg));
		console.log(error);
		return error.response.data;
	}
};

export const verify_password_code_action = (code) => async () => {
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post('/verify-code-password', code);
		return response.data;
	} catch (error) {
		console.log(error);
	}
};

export const update_password_action = (password) => async () => {
	try {
		const response = await axiosClient.patch('/update-password', {...password, password: setEncryptPassword(password.password)
	});
		return response;
	} catch (error) {
		console.log(error);
	}
};

export const login_error_clean_action = () => async (dispatch) => {
	dispatch({
		type: LOGIN_ERROR_CLEAN
	});
};

export const update_country_user_action = (countryData, storedToken) => async (dispatch) => {
	console.log(countryData, storedToken, 'yees')
	try {
		const response = await updateCountry(countryData);
		console.log(response);
		dispatch({
			type: UPDATE_COUNTRY_USER,
			payload: response.data.pais[0].id
		});
		// dispatch(
		// 	get_session_action({
		// 		tokenlog: storedToken
		// 	})
		// );
		window.location.reload();
	} catch (error) {
		console.log(error)
	}
};

export const login_photo_clean_action = () => async (dispatch) => {
	dispatch({
		type: LOGIN_CLEAN_PHOTO
	});
};

export const alert_action = (state) => async (dispatch) => {
	console.log(state, 'staaaa')
	dispatch({
		type: ALERT_PASSWORD_CODE,
		payload: state
	});
};

export const alert_clean_action = () => async (dispatch) => {
	dispatch({
		type: ALERT_CLEAN_CODE
	});
};
