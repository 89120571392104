import getOutputReqPetsData from '../../configAxios/outputRequest';
import { GET_OUTPUT_REQ_PETS, GET_OUTPUT_REQ_PETS_CLEAN } from '../types';

export const get_output_request_pets_action = (id) => async (dispatch) => {
	try {
		const response = await getOutputReqPetsData(id);
		dispatch({
			type: GET_OUTPUT_REQ_PETS,
			payload: response
		});
	} catch (error) {
		console.log(error);
	}
};

export const get_output_request_pets_clean_action = () => async (dispatch) => {
	try {
		dispatch({
			type: GET_OUTPUT_REQ_PETS_CLEAN
		});
	} catch (error) {
		console.log(error);
	}
};
