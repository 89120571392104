import { makeStyles } from '@material-ui/core';

export const userDescriptionStyles = makeStyles((theme) => ({
	root: {
		[theme.breakpoints.only('xs')]: {
			padding: theme.spacing(0, 1, 0, 1)
		},
		[theme.breakpoints.only('sm')]: {
			padding: theme.spacing(0, 8, 0, 8)
		},
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(0, 30, 0, 30)
		}
	},
	itemContainer: {
		// display: 'flex'
	},
	numberActive: {
		color: '#63C132'
	},

	numberDesactive: {
		color: '#BDBDBD'
	}
}));
