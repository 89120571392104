import { makeStyles } from '@material-ui/core/styles';

const adoptMeFormStyles = makeStyles((theme) => ({
	closeIconButton: {
		marginRight: 5,
		marginTop: 5
	},

	closeButton: {
		color: '#808080',
		width: 30,
		height: 30
	},

	button: {
		marginRight: theme.spacing(1),
		alignItems: 'center'
	},

	formPetDescription: {
		marginTop: 20,
		marginBottom: 20
	},

	userDataContainer: {
		[theme.breakpoints.up('sm')]: {
			padding: theme.spacing(2, 10, 2, 10)
		},
		padding: theme.spacing(2, 2, 2, 2)
	},
	inputs: {
		padding: theme.spacing(0, 4, 0, 0)
	},
	allreadyContainer: {
		[theme.breakpoints.down('sm')]: {
			minHeigth: '80vh'
		}
	},
	petsGifs: {
		[theme.breakpoints.up('md')]: {
			width: '150px',
			marginLeft: 'auto',
			marginRight: 'auto'
		},
		[theme.breakpoints.only('xl')]: {
			width: '200px',
			marginLeft: 'auto',
			marginRight: 'auto'
		},
		[theme.breakpoints.down('md')]: {
			width: '150px',
			marginLeft: 'auto',
			marginRight: 'auto'
		}
	},
	noOptions: {
		color: 'red'
	}
}));

export default adoptMeFormStyles;
