import { GET_OUTPUT_REQ_PETS, GET_OUTPUT_REQ_PETS_CLEAN } from "../types";

const initialState = {
  outputRequestData: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_OUTPUT_REQ_PETS:
      return {
        ...state,
        outputRequestData: action.payload
      };

    case GET_OUTPUT_REQ_PETS_CLEAN:
      return {
        ...initialState
      }
    default:
      return state;
  }
};
