import {
	ADD_FAVOURITE_PET,
	CLEAN_FAVOURITE_PET,
	GET_FAVOURITE_PET,
	SHOW_FAVOURITE_PET
} from '../types';

const initialState = {
	addFavourite: false,
	getFavourite: [],
	showFavourite: []
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ADD_FAVOURITE_PET:
			return {
				...state,
				addFavourite: action.payload
			};

		case GET_FAVOURITE_PET:
			return {
				...state,
				getFavourite: action.payload
			};

		case SHOW_FAVOURITE_PET:
			return {
				...state,
				showFavourite: action.payload
			};

		case CLEAN_FAVOURITE_PET:
			return {
				...initialState
			};

		default:
			return state;
	}
};
