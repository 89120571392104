import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// traduction
import global_es from './translations/es/global.json';
import global_en from './translations/en/global.json';
import global_pt from './translations/pt/global.json';
// import i18next from 'i18next';

const options = {
	// order and from where user language should be detected
	order: [
		'querystring',
		'cookie',
		'localStorage',
		'sessionStorage',
		'navigator',
		'htmlTag',
		'path',
		'subdomain'
	],

	// keys or params to lookup language from
	lookupQuerystring: 'lng',
	lookupCookie: 'i18next',
	lookupLocalStorage: 'i18nextLng',
	lookupSessionStorage: 'i18nextLng',
	lookupFromPathIndex: 0,
	lookupFromSubdomainIndex: 0,

	// cache user language on
	caches: ['localStorage', 'cookie'],
	excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

	// optional expire and domain for set cookie
	cookieMinutes: 10,
	cookieDomain: 'myDomain',

	// optional htmlTag with lang attribute, the default is:
	htmlTag: document.documentElement,

	// optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
	cookieOptions: { path: '/', sameSite: 'strict' }
};

i18n
	// .use(Backend) // load translation using xhr -> see /public/locales. We will add locales in the next step
	.use(LanguageDetector) // detect user language
	.use(initReactI18next) // pass the i18n instance to react-i18next.

	.init({
		// debug: true,
		resources: {
			en: {
				global: global_en
			},
			pt: {
				global: global_pt
			},
			es: {
				global: global_es
			}
		},
		fallbackLng: 'en',
		// supportedLngs: ['en', 'pt', 'es'],
		detection: options,
		nonExplicitSupportedLngs: true,
		interpolation: {
			escapeValue: false
		}
	});

export default i18n;
