import { makeStyles } from '@material-ui/core/styles';
import { mainColor } from './mainTheme';

const uploadImagesStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(1, 1, 1, 1)
        },
        [theme.breakpoints.only('lg')]: {
            padding: theme.spacing(4, 2, 4, 2)
        },
        padding: theme.spacing(4, 4, 4, 4)
    },
    mainContainer: {
        borderColor: mainColor,
        borderStyle: 'dotted',
        borderWidth: 'thick',
        borderRadius: '10px',
    },
    uploadIcon: {
        width: 80,
        height: 80
    },
    uploadContainer: {
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(2, 1, 2, 1)
        },
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(4, 2, 4, 2)
        },
        padding: theme.spacing(4, 4, 4, 4)
    },
    itemPhoto: {
        [theme.breakpoints.only('xs')]: {
            padding: theme.spacing(2, 1, 2, 1)
        },
        [theme.breakpoints.down('lg')]: {
            padding: theme.spacing(4, 2, 4, 2)
        },
        padding: theme.spacing(4, 4, 4, 4)

    },
    photoContainer: {
        position: 'relative'

    },
    quitPhotoIcon: {
        position: 'absolute'
    },
    photos: {
        [theme.breakpoints.only('xs')]: {
            height: 150,
            width: 112.5
        },
        height: 200,
        width: 150,
        objectFit: 'cover',
        '-webkit-transition': 'all 500ms ease-in-out',
        transform: 'scale(1)',
        borderStyle: 'solid',
        borderWidth: 'medium',
        borderColor: mainColor,
        borderRadius: '5px'
    },
    messageContainer: {
        padding: theme.spacing(2, 0, 2, 0)
    }

}));

export default uploadImagesStyles;
