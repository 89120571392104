import axiosClient from './axios';

async function getUserPets(userId) {
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post('/mascotasDesactivadas', userId);
		return response.data.mascotas.reverse();
	} catch (e) {
		console.log(e);
		return []
	}
};

export async function publishPet(data) {
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post('/PublicacionMascota', data);
		return response.data;
	} catch (e) {
		console.log(e);
	}
}

export async function userPetRequest(userId) {
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post('/MacotasRegistradas', userId);
		return response.data.form.reverse();
	} catch (e) {
		console.log(e);
	}
}

export async function allowOrDenyRequest(requestData) {
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post('/formulariopeticion', requestData);
		return response.data;
	} catch (e) {
		console.log(e);
	}
}

export async function cancelPetRequest(cancelRequestData) {
	try {
		axiosClient.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
		const response = await axiosClient.post(
			'/cancelarformulario',
			cancelRequestData
		);
		return response;
	} catch (error) {
		console.log(error);
	}
}

export default getUserPets;
