import { makeStyles } from '@material-ui/core/styles';

const userPetsModalStyles = makeStyles((theme) => ({
	closeIconButton: {
		marginRight: 5,
		marginTop: 5
	},

	closeButton: {
		color: '#808080',
		width: 30,
		height: 30
	},

	table: {
		'& .MuiTableHead-root': {
			backgroundColor: '#63c132'
		},
		'& .MuiTableCell-head': {
			color: '#fff'
		},
		'& .MuiTableCell-root': {
			border: '1px solid #ddd'
		}
	},

	tr: {
		'& :hover': {
			backgroundColor: '#F0F2F5'
		}
	},

	dialogContainer: {
		'& .MuiDialog-paperFullScreen': {
			backgroundColor: '#F0F2F5'
		}
	},

	paperContainer: {
		padding: theme.spacing(3),
	},

	marginBottomContainer: {
		marginBottom: 40
	},

	listVaccines: {
		margin: 0,
		padding: '0px 0px 0px 16px'
	},

	cardMedia: {
		width: '100px',
		height: '100px',
		objectFit: 'contain',
		cursor: 'pointer'
	},

	primaryColor: {
		color: '#63c132'
	},

	textFont: {
		fontWeight: 600
	}
}));

export default userPetsModalStyles;
