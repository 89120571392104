import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import FiltersPets from '../../SaciComponents/Adoption/FiltersPets';
import SaveIcon from '@material-ui/icons/Save';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: 'relative'
	}
}));

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function FiltersPetsResponsive() {
	const [t] = useTranslation('global');
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			<Button
				variant="outlined"
				color="primary"
				onClick={handleClickOpen}
				startIcon={<FilterListIcon />}
				fullWidth
			>
				{t('filters')}
			</Button>

			<Dialog
				fullScreen
				open={open}
				onClose={handleClose}
				TransitionComponent={Transition}
			>
				<AppBar className={classes.appBar}>
					<Toolbar>
						<Tooltip title={t('close')} >
							<IconButton
								edge="start"
								color="inherit"
								onClick={handleClose}
								aria-label="close"
							>
								<CloseIcon />
							</IconButton>
						</Tooltip>
						<Button
							variant="outlined"
							autoFocus
							// color="primary"
							id="buttonWhite"
							onClick={handleClose}
							startIcon={<SaveIcon />}
						>
							Guardar Cambios
						</Button>
					</Toolbar>
				</AppBar>
				<FiltersPets />
			</Dialog>
		</div>
	);
}
