/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { PetRequestSent } from './PetRequestSent';

import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import { AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core';

import { ReactComponent as IconAllRequests } from '../assets/icons/userpets/IconAllRequests.svg';
import { ReactComponent as IconPetsAccepted } from '../assets/icons/userpets/IconPetsAccepted.svg';
import { ReactComponent as IconPetsRejected } from '../assets/icons/userpets/IconPetsRejected.svg';
import { ReactComponent as IconPetsPending } from '../assets/icons/userpets/IconPetsPending.svg';

import Header from '../components/SaciComponents/Header';

import { useMediaQuery, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import AdoptMeForm from '../components/SaciComponents/Adoption/AdoptMeForm';

import UnLoggedModal from '../components/SaciComponents/UnLoggedModal';

import {
	set_user_pets_state_action,
	show_out_request_pets_action
} from '../redux/actions/saciPets';

import { useHistory } from 'react-router-dom';

import { save_selected_pet_data_action } from '../redux/actions/userPetsAction';

import { set_procedure_action } from '../redux/actions/loginAction';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
	const dispatch = useDispatch();
	const { children, value, index, ...other } = props;

	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.only('xs'));
	const { userPetId } = useSelector((state) => state.userPets);
	const { outputRequestData } = useSelector((state) => state.outputRequest);

	useEffect(() => {
		if (userPetId) {
			let filteredData = outputRequestData.filter(
				(item) => item.id_mascota === userPetId
			);
			dispatch(save_selected_pet_data_action(filteredData[0]));
			dispatch(set_procedure_action(parseInt(filteredData[0].tipo_tramite)));
		}
	}, [userPetId]);

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-force-tabpanel-${index}`}
			aria-labelledby={`scrollable-force-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={mobile ? 1 : 3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired
};

function a11yProps(index) {
	return {
		id: `scrollable-force-tab-${index}`,
		'aria-controls': `scrollable-force-tabpanel-${index}`
	};
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: '100%',
		backgroundColor: theme.palette.background.paper
	}
}));

export default function OutputRequest() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const classes = useStyles();
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const theme = useTheme();

	const matches = useMediaQuery(theme.breakpoints.down('xs'));

	//////////////////////////////////////////////

	const history = useHistory();
	const { nombres } = useSelector((state) => state.login.user);
	const { outputRequestData } = useSelector((state) => state.outputRequest);

	useEffect(() => {
		if (!nombres) {
			history.push('/');
		}

		if (outputRequestData === null) {
			history.push('/');
		} else {
			dispatch(set_user_pets_state_action(false));
			dispatch(show_out_request_pets_action(outputRequestData));
		}
	}, [outputRequestData]);

	useEffect(() => {
		dispatch(set_user_pets_state_action(false));
		// dispatch(set_card_pets_title_action('Solicitudes Enviadas'));
	}, []);

	return (
		<>
			<Header />
			<Grid container alignItems="center" justify="center">
				<Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
					<div className={classes.root}>
						<AppBar position="static" color="default">
							<Tabs
								value={value}
								onChange={handleChange}
								variant={matches ? 'scrollable' : 'standard'}
								scrollButtons="on"
								indicatorColor="primary"
								textColor="primary"
								aria-label="scrollable force tabs example"
								centered
							>
								<Tab
									label={t("Requests-submitted")}
									icon={<IconAllRequests width={50} />}
									{...a11yProps(0)}
								/>
								<Tab
									label={t("accepted")}
									icon={<IconPetsAccepted width={50} />}
									{...a11yProps(1)}
								/>
								<Tab
									label={t("rejected")}
									icon={<IconPetsRejected width={50} />}
									{...a11yProps(2)}
								/>
								<Tab
									label={t("pending")}
									icon={<IconPetsPending width={50} />}
									{...a11yProps(3)}
								/>
							</Tabs>
						</AppBar>
						<TabPanel value={value} index={0}>
							<PetRequestSent />
						</TabPanel>
						<TabPanel value={value} index={1}>
							<PetRequestSent AdoptionRequestSent={true} />
						</TabPanel>
						<TabPanel value={value} index={2}>
							<PetRequestSent AdoptionRequestSent={false} />
						</TabPanel>
						<TabPanel value={value} index={3}>
							<PetRequestSent AdoptionRequestSent={null} />
						</TabPanel>
					</div>
				</Grid>
			</Grid>

			<Grid container justify="center">
				<Grid item xs={12} sm={12} md={10} lg={10} xl={10}></Grid>
				<AdoptMeForm />
				<UnLoggedModal />
			</Grid>
		</>
	);
}
