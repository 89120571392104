/* eslint-disable react-hooks/exhaustive-deps */
import {
	withStyles,
	Switch,
	Button,
	Dialog,
	Grid,
	Hidden,
	IconButton,
	Step,
	StepLabel,
	Stepper,
	TextField,
	Typography,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	StepConnector,
	useMediaQuery,
	Tooltip
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTheme } from '@material-ui/core/styles';

import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
	adopt_me_dialog_action,
	select_pet_action
} from '../../../redux/actions/saciPets';

import { makeStyles } from '@material-ui/core/styles';

import Swal from 'sweetalert2';

//styles
import adoptMeFormStyles from '../../../assets/css/js/adoptMeFormStyles';

//icons
import CloseIcon from '@material-ui/icons/Close';
import PetUserDateIcon from '../../../assets/icons/dialogs/pet-user-date.svg';
import PetDateIcon from '../../../assets/icons/dialogs/pet-date.svg';

import {
	get_city_data_action,
	get_department_data_action
} from '../../../redux/actions/adoptFormAction';

import {
	enable_step_two_action,
	get_adopt_me_form_action,
	reset_adopt_me_form_action,
	save_adopt_me_form_action,
	update_adopt_me_form_action
} from '../../../redux/actions/adoptMeFormAction';

import clsx from 'clsx';
import { get_output_request_pets_action } from '../../../redux/actions/outputRequestAction';
import { set_procedure_action } from '../../../redux/actions/loginAction';
import { save_user_pet_id_action } from '../../../redux/actions/userPetsAction';

import gato from '../../../assets/lotties/Gato.gif';
import perro from '../../../assets/lotties/Perro.gif';
import hamster from '../../../assets/lotties/Hamster.gif';
import { titleCase } from '../../../hooks/useTitleCase';
import { useTranslation } from 'react-i18next';

const IOSSwitch = withStyles((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1)
	},

	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: '#63c132',
				opacity: 1,
				border: 'none'
			}
		},
		'&$focusVisible $thumb': {
			color: '#63c132',
			border: '6px solid #fff'
		}
	},

	thumb: {
		width: 24,
		height: 24
	},

	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border'])
	},

	checked: {},

	focusVisible: {}
}))(({ classes, ...props }) => {
	return (
		<Switch
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked
			}}
			{...props}
		/>
	);
});

export function CustomizedSwitch({ stateTerm, setStateTerm }) {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();

	const { formData } = useSelector((state) => state.adoptMeForm);

	const handleChange = (event) => {
		setStateTerm({ ...stateTerm, [event.target.name]: event.target.checked });
	};

	const { terminos } = stateTerm;

	useEffect(() => {
		dispatch(
			update_adopt_me_form_action({
				...formData,
				terminos
			})
		);
	}, [stateTerm]);

	return (
		<Typography component="div">
			<Grid
				component="label"
				container
				alignItems="center"
				justify="center"
				spacing={1}
			>
				<Grid item>{t('not')}</Grid>
				<Grid item>
					<IOSSwitch
						checked={stateTerm.terminos}
						onChange={handleChange}
						name="terminos"
					/>
				</Grid>
				<Grid item>{t('yes')}</Grid>
			</Grid>
		</Typography>
	);
}

const ColorlibConnector = withStyles({
	alternativeLabel: {
		top: 22
	},
	active: {
		'& $line': {
			backgroundImage:
				'linear-gradient( 20deg,rgb(99, 193, 50) 100%,rgb(99, 193, 50) 50%,rgb(197, 232, 183) 100%)'
		}
	},
	completed: {
		'& $line': {
			backgroundImage:
				'linear-gradient( 20deg,rgb(99, 193, 50) 100%,rgb(99, 193, 50) 50%,rgb(197, 232, 183) 100%)'
		}
	},
	line: {
		height: 3,
		border: 0,
		backgroundColor: '#eaeaf0',
		borderRadius: 1
	}
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
	root: {
		backgroundColor: '#ccc',
		zIndex: 1,
		color: '#fff',
		width: 50,
		height: 50,
		display: 'flex',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center'
	},
	active: {
		backgroundImage:
			'linear-gradient( 136deg, rgb(197, 232, 183) 0%, rgb(99, 193, 50) 50%, rgb(99, 193, 50) 100%)',
		boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)'
	},
	completed: {
		backgroundImage:
			'linear-gradient( 136deg, rgb(197, 232, 183) 0%, rgb(99, 193, 50) 50%, rgb(99, 193, 50) 100%)'
	}
});

function ColorlibStepIcon(props) {
	const classes = useColorlibStepIconStyles();

	const { active, completed } = props;

	const icons = {
		1: <img src={PetUserDateIcon} alt="Pets User Dates" />,
		2: <img src={PetDateIcon} alt="Pets Dates" />
	};

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
				[classes.completed]: completed
			})}
		>
			{icons[String(props.icon)]}
		</div>
	);
}

export function getSteps(t) {
	return [t('personal-information'), t('adoption-data')];
}

export function getStepContent(step) {
	switch (step) {
		case 0:
			return <UserData />;
		case 1:
			return <AdoptQuestions />;
		default:
	}
}

export default function AdoptMeForm() {
	const [t] = useTranslation('global');
	const [stateTerm, setStateTerm] = useState({
		terminos: false
	});
	const { nombres, correo } = useSelector((state) => state.login.user);
	const { id_tipo_mascota } = useSelector(
		(state) => state.userPets.userPetData
	);
	const telefonoUser = useSelector((state) => state.login.user.telefono);
	const { id } = useSelector((state) => state.login.user);
	const { userPetId } = useSelector((state) => state.userPets);
	const { onOutputRequestPage } = useSelector((state) => state.userPets);
	const { formData } = useSelector((state) => state.adoptMeForm);
	const { stepTwo } = useSelector((state) => state.adoptMeForm);
	const id_formulario = useSelector(
		(state) => state.userPets.userPetData.id_formulario
	);
	const {
		nombre_adoptante,
		direccion_adoptante,
		ciudad,
		localidad,
		telefono,
		email,
		ocupacion,
		estado_civil,
		pregunta_1,
		pregunta_2,
		pregunta_3,
		pregunta_4,
		pregunta_5,
		pregunta_6
	} = useSelector((state) => state.userPets.userPetData);
	const userPetData = useSelector((state) => state.userPets);
	const [activeStep, setActiveStep] = useState(0);
	const steps = getSteps(t);
	const classes = adoptMeFormStyles();
	// const fullScreenResponsive = useMediaQuery(theme.breakpoints.down('sm'));
	const dispatch = useDispatch();
	const { adoptMeDialog } = useSelector((state) => state.saciPets);

	// useEffect(() => {
	// 	if (nombres?.length) {
	// 		dispatch(
	// 			get_adopt_me_form_action({
	// 				...formData,
	// 				nombre_adoptante: `${nombres}`,
	// 				telefono: `${telefonoUser}`,
	// 				correo: `${correo}`
	// 			})
	// 		);
	// 	}
	// }, [nombres]);

	useEffect(() => {
		if (onOutputRequestPage) {
			dispatch(
				get_adopt_me_form_action({
					...formData,
					nombre_adoptante: nombre_adoptante,
					direccion_adoptante: direccion_adoptante,
					ciudad: ciudad,
					localidad: localidad,
					telefono: telefono,
					email: email,
					ocupacion: ocupacion,
					estado_civil: estado_civil,
					pregunta_1: pregunta_1,
					pregunta_2: pregunta_2,
					pregunta_3: pregunta_3,
					pregunta_4: pregunta_4,
					pregunta_5: pregunta_5,
					pregunta_6: pregunta_6
				})
			);
		} else {
			dispatch(
				get_adopt_me_form_action({
					...formData,
					nombre_adoptante: `${nombres}`,
					telefono: `${telefonoUser}`,
					correo: `${correo}`
				})
			);
		}
	}, [adoptMeDialog, userPetData]);

	const handleClickClose = () => {
		return Swal.fire({
			title: '¿Estás seguro?',
			text: 'No podrás revertir los cambios!',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#D33',
			cancelButtonColor: '#63C132',
			confirmButtonText: 'Sí, salir!',
			cancelButtonText: 'No, cancelar!',
			customClass: 'swal-wide'
		}).then((result) => {
			if (result.isConfirmed) {
				dispatch(adopt_me_dialog_action(false));
				dispatch(reset_adopt_me_form_action());
				setActiveStep(0);
				dispatch(select_pet_action(null));
				dispatch(set_procedure_action(null));
				dispatch(save_user_pet_id_action(null));
				Swal.close();
			}
		});
	};

	const handleClickRegister = () => {
		return Swal.fire({
			icon: 'warning',
			title: t('record-data'),
			showDenyButton: true,
			confirmButtonColor: '#63C132',
			denyButtonColor: '#D33',
			confirmButtonText: t('accept'),
			denyButtonText: t('cancel')
		}).then((result) => {
			if (result.isConfirmed) {
				if (onOutputRequestPage) {
					Swal.fire(t('form-updated'), '', 'success');
					dispatch(
						update_adopt_me_form_action({
							...formData,
							terminos: true,
							id: id,
							id_mascota: userPetId,
							id_formulario: id_formulario
						})
					);
					dispatch(adopt_me_dialog_action(false));
					dispatch(select_pet_action(null));
					setTimeout(() => {
						dispatch(get_output_request_pets_action({ id: id }));
					}, 500);
					setActiveStep(0);
					dispatch(enable_step_two_action(false));
					dispatch(reset_adopt_me_form_action());
					dispatch(save_user_pet_id_action(null));
				} else {
					Swal.fire(t('form-sent'), '', 'success');
					dispatch(
						save_adopt_me_form_action({
							...formData,
							terminos: true,
							id: id,
							id_mascota: userPetId
						})
					);
					dispatch(adopt_me_dialog_action(false));
					dispatch(select_pet_action(null));
					setTimeout(() => {
						dispatch(get_output_request_pets_action({ id: id }));
					}, 500);
					setActiveStep(0);
					dispatch(enable_step_two_action(false));
					dispatch(reset_adopt_me_form_action());
					dispatch(save_user_pet_id_action(null));
				}
			} else if (result.isDenied) {
				Swal.fire(t('not-recorded-changes'), '', 'info');
			}
		});
	};

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};
	const theme = useTheme();
	const fullScreenResponsive = useMediaQuery(theme.breakpoints.down('sm'));
	return (
		<>
			<Dialog
				style={{ zIndex: 2 }}
				open={adoptMeDialog}
				onClose={handleClickClose}
				fullWidth
				maxWidth="md"
				fullScreen={fullScreenResponsive}
			>
				<Grid container justify="flex-end" alignItems="flex-start">
					<Tooltip title={t('close')} >
						<IconButton
							className={classes.closeIconButton}
							edge="end"
							color="inherit"
							aria-label="close"
							onClick={handleClickClose}
						>
							<CloseIcon className={classes.closeButton} />
						</IconButton>
					</Tooltip>

				</Grid>
				<div className={classes.root}>
					<Hidden only={'xs'}>
						<Stepper
							alternativeLabel
							activeStep={activeStep}
							connector={<ColorlibConnector />}
						>
							{steps.map((label) => (
								<Step key={label}>
									<StepLabel StepIconComponent={ColorlibStepIcon}>
										{label}
									</StepLabel>
								</Step>
							))}
						</Stepper>
					</Hidden>
					<div>
						{activeStep === steps.length ? (
							<div>
								<Grid
									style={{
										margin: '2%',
										marginLeft: '10%',
										marginRight: '10%'
									}}
								>
									<Grid
										container
										justify="center"
										alignItems="center"
										className={classes.allreadyContainer}
									>
										<Grid item xs={12} style={{ textAlign: 'center' }}>
											<Typography variant="h5">
												{t('step-making-request')}
											</Typography>
										</Grid>
									</Grid>
									<Grid item xs={12} md={12} style={{ display: 'flex' }}>
										{id_tipo_mascota === '1' ? (
											<img src={gato} alt="" className={classes.petsGifs} />
										) : id_tipo_mascota === '2' ? (
											<img src={perro} alt="" className={classes.petsGifs} />
										) : (
											<img src={hamster} alt="" className={classes.petsGifs} />
										)}
									</Grid>
									<Grid container style={{ marginTop: '5%' }} justify="center">
										<Typography>{t('accepts-terms-conditions')}</Typography>
									</Grid>
									<Grid
										container
										style={{ marginTop: '2%', display: 'flex' }}
										justify="center"
										alignItems="center"
									>
										<CustomizedSwitch
											stateTerm={stateTerm}
											setStateTerm={setStateTerm}
										/>
									</Grid>
								</Grid>
								<Grid container style={{ marginBottom: '2%' }} justify="center">
									<Button className={classes.button} onClick={handleBack}>
										{t('back')}
									</Button>
									<Button
										variant="contained"
										color="primary"
										className={classes.button}
										onClick={handleClickRegister}
										disabled={stateTerm.terminos ? false : true}
									>
										{t('send')}
									</Button>
								</Grid>
							</div>
						) : (
							<div>
								<Typography className={classes.instructions}>
									{getStepContent(activeStep)}
								</Typography>
								<div>
									<Grid
										container
										style={{ marginBottom: '2%' }}
										justify="center"
									>
										<Button
											style={{ textTransform: 'none' }}
											disabled={activeStep === 0}
											className={classes.button}
											onClick={handleBack}
										>
											{t('back')}
										</Button>
										<Button
											style={{ textTransform: 'none' }}
											variant="contained"
											color="primary"
											className={classes.button}
											onClick={handleNext}
											disabled={stepTwo === false}
										>
											{activeStep === steps.length - 1 ? t('next') : t('next')}
										</Button>
									</Grid>
								</div>
							</div>
						)}
					</div>
				</div>
			</Dialog>
		</>
	);
}

function UserData() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	// const idStore = useSelector((state) => state.saciPets.petSelected);
	const nombreStore = useSelector(
		(state) => state.adoptMeForm.formData.nombre_adoptante
	);
	const idUsuarioStore = useSelector((state) => state.login.user.id);
	const { id_pais } = useSelector((state) => state.login.user);
	const correoStore = useSelector((state) => state.userPets.userPetData.email);
	const correoLoginStore = useSelector(
		(state) => state.adoptMeForm.formData.correo
	);
	const telefonoStore = useSelector(
		(state) => state.adoptMeForm.formData.telefono
	);
	const direccionStore = useSelector(
		(state) => state.adoptMeForm.formData.direccion_adoptante
	);
	const idUndeStore = useSelector(
		(state) => state.adoptMeForm.formData.id_codigo
	);
	const idCodigoStore = useSelector(
		(state) => state.adoptMeForm.formData.localidad
	);
	const ocupacionStore = useSelector(
		(state) => state.adoptMeForm.formData.ocupacion
	);
	const estadoCivilStore = useSelector(
		(state) => state.adoptMeForm.formData.estado_civil
	);
	const { departments, cities } = useSelector((state) => state.adoptFormData);
	const { formData } = useSelector((state) => state.adoptMeForm);
	const { userPetData, onOutputRequestPage } = useSelector(
		(state) => state.userPets
	);
	const { showOutReqPets } = useSelector((state) => state.saciPets);
	const [allowCities, setAllowCities] = useState(false);
	const classes = adoptMeFormStyles();
	const [departmentValue, setDepartmentValue] = useState('');
	const [cityValue, setCityValue] = useState('');
	const [userData, setUserData] = useState({
		nombre_adoptante: `${nombreStore}`,
		direccion_adoptante: `${direccionStore}`,
		id_codigo: `${idUndeStore}`,
		localidad: `${idCodigoStore}`,
		telefono: `${telefonoStore}`,
		correo: onOutputRequestPage ? `${correoStore}` : `${correoLoginStore}`,
		ocupacion: `${ocupacionStore}`,
		estado_civil: `${estadoCivilStore}`,
		id_mascota: `${userPetData.id_mascota}`,
		id: `${idUsuarioStore}`
	});
	const {
		nombre_adoptante,
		correo,
		direccion_adoptante,
		localidad,
		id_codigo,
		telefono,
		ocupacion,
		estado_civil
	} = userData;
	const idCodigoData = {
		id_codigo: `${id_codigo}`
	};

	useEffect(() => {
		if (showOutReqPets) {
			setUserData({
				nombre_adoptante: `${userPetData.nombre_adoptante}`,
				direccion_adoptante: `${userPetData.direccion_adoptante}`,
				id_codigo: `${userPetData.ciudad}`,
				localidad: `${userPetData.localidad}`,
				telefono: `${userPetData.telefono}`,
				correo: `${userPetData.email}`,
				ocupacion: `${userPetData.ocupacion}`,
				estado_civil: `${userPetData.estado_civil}`,
				id_mascota: `${userPetData.id_mascota}`,
				id: `${idUsuarioStore}`
			});
		}
	}, []);

	useEffect(() => {
		if (departmentValue.length !== 0) {
			let data = departments.filter(
				(item) => titleCase(item.descripcion) === departmentValue
			);
			if (data.length !== 0) {
				let objectData = data[0];
				setUserData({ ...userData, id_codigo: objectData.id_codigo });
			}
		}
	}, [departmentValue]);

	useEffect(() => {
		if (cityValue.length !== 0) {
			let data = cities.filter(
				(item) => titleCase(item.descripcion) === cityValue
			);
			if (data.length !== 0) {
				let objectData = data[0];
				setUserData({ ...userData, localidad: objectData.id_codigo });
			}
		}
	}, [cityValue]);

	useEffect(() => {
		if (showOutReqPets) {
			if (userData.id_codigo.length !== 0) {
				let filterData = departments.filter(
					(item) => item.id_codigo === id_codigo
				);
				setDepartmentValue(filterData[0].descripcion);
			}
		} else {
			if (userData.id_codigo.length !== 0) {
				let filterData = departments.filter(
					(item) => item.id_codigo === userData.id_codigo
				);
				setDepartmentValue(filterData[0].descripcion);
			}
		}
	}, [formData]);

	useEffect(() => {
		if (showOutReqPets) {
			if (userData.localidad.length !== 0) {
				let filterData = cities.filter(
					(item) => item.id_codigo === userData.localidad
				);
				if (filterData.length !== 0) {
					setCityValue(filterData[0].descripcion);
				}
			}
		} else {
			if (userData.localidad.length !== 0) {
				let filterData = cities.filter(
					(item) => item.id_codigo === userData.localidad
				);
				if (filterData.length !== 0) {
					setCityValue(filterData[0].descripcion);
				}
			}
		}
	}, [formData, cities]);

	useEffect(() => {
		dispatch(get_department_data_action({ id_codigo: id_pais }));
	}, []);

	useEffect(() => {
		if (cities.length !== 0) {
			setAllowCities(true);
		} else {
			setAllowCities(false);
		}
	}, [cities]);

	useEffect(() => {
		if (id_codigo) {
			dispatch(get_city_data_action(idCodigoData));
		}
	}, [id_codigo]);

	const handleChange = (e) => {
		setUserData({
			...userData,
			[e.target.name]: e.target.value
		});
	};
	useEffect(() => {
		if (
			nombre_adoptante.length &&
			correo.length &&
			direccion_adoptante.length &&
			id_codigo.length &&
			localidad.length &&
			telefono.length &&
			estado_civil.length &&
			ocupacion.length !== 0
		) {
			dispatch(enable_step_two_action(true));
			dispatch(
				get_adopt_me_form_action({
					...formData,
					nombre_adoptante: nombre_adoptante,
					correo: correo,
					direccion_adoptante: direccion_adoptante,
					id_codigo: id_codigo,
					localidad: localidad,
					telefono: telefono,
					estado_civil: estado_civil,
					ocupacion: ocupacion
				})
			);
		} else {
			dispatch(enable_step_two_action(false));
		}
	}, [userData]);

	const MenuPropsCustom = {
		getContentAnchorEl: null,
		anchorOrigin: {
			vertical: 'bottom'
		}
	};

	const MenuPropsCustom2 = {
		PaperProps: {
			style: {
				height: 230,
				width: 250
			}
		},
		getContentAnchorEl: null,
		anchorOrigin: {
			vertical: 'bottom'
		}
	};

	return (
		<div>
			<Grid className={classes.userDataContainer}>
				<Grid container justify="center">
					<Grid item style={{ textAlign: 'center' }}>
						<Typography variant="h6">{t('personal-details')}</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={1} style={{ marginTop: '2%' }}>
					<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
						<TextField
							label={t('full-name')}
							type="text"
							name="nombre_adoptante"
							variant="outlined"
							value={nombre_adoptante}
							onChange={handleChange}
							fullWidth
						/>
					</Grid>
					<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
						<TextField
							label={t('street-address')}
							type="text"
							name="direccion_adoptante"
							variant="outlined"
							value={direccion_adoptante}
							onChange={handleChange}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={4}>
						<Autocomplete
							fullWidth
							inputValue={departmentValue}
							onInputChange={(event, newInputValue) => {
								setDepartmentValue(newInputValue);
								setCityValue('');
							}}
							options={departments}
							MenuProps={MenuPropsCustom2}
							getOptionLabel={(option) => titleCase(option.descripcion)}
							renderInput={(params) => (
								<TextField
									{...params}
									label={t('department')}
									variant="outlined"
								/>
							)}
							classes={{
								noOptions: classes.noOptions
							}}
							noOptionsText={t('department-not-found')}
							closeIcon={false}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={6} lg={4}>
						<Autocomplete
							fullWidth
							inputValue={cityValue}
							disabled={allowCities === false}
							onInputChange={(event, newInputValue) => {
								setCityValue(newInputValue);
							}}
							options={cities}
							MenuProps={MenuPropsCustom2}
							getOptionLabel={(option) => titleCase(option.descripcion)}
							renderInput={(params) => (
								<TextField
									{...params}
									label={t('municipality')}
									variant="outlined"
								/>
							)}
							classes={{
								noOptions: classes.noOptions
							}}
							noOptionsText={t('municipality-not-found')}
							closeIcon={false}
						/>
					</Grid>
					<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
						<TextField
							label={t('telephone')}
							type="text"
							name="telefono"
							variant="outlined"
							value={telefono}
							onChange={handleChange}
							fullWidth
						/>
					</Grid>
					<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
						<TextField
							label={t('email')}
							type="email"
							name="correo"
							variant="outlined"
							value={correo}
							onChange={handleChange}
							fullWidth
						/>
					</Grid>
					<Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
						<TextField
							label={t('occupation')}
							type="text"
							name="ocupacion"
							variant="outlined"
							onChange={handleChange}
							fullWidth
							value={ocupacion}
						/>
					</Grid>
					<Grid item xl={4} lg={4} md={6} xs={12} sm={12}>
						<FormControl variant="outlined" fullWidth>
							<InputLabel id="demo-simple-select-outlined-label">
								{t('civil-status')}
							</InputLabel>
							<Select
								labelId="demo-simple-select-outlined-label"
								id="demo-simple-select-outlined"
								name="estado_civil"
								label={t('civil-status')}
								onChange={handleChange}
								value={estado_civil}
								MenuProps={MenuPropsCustom}
							>
								<MenuItem value="soltero">{t('married')}</MenuItem>
								<MenuItem value="casado">{t('single')}</MenuItem>
								<MenuItem value="divorciado">{t('divorced')}</MenuItem>
								<MenuItem value="union_libre">{t('free-union')}</MenuItem>
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}

function AdoptQuestions() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const classes = adoptMeFormStyles();
	const { formData } = useSelector((state) => state.adoptMeForm);
	const pregunta_1_store = useSelector(
		(state) => state.adoptMeForm.formData.pregunta_1
	);
	const pregunta_2_store = useSelector(
		(state) => state.adoptMeForm.formData.pregunta_2
	);
	const pregunta_3_store = useSelector(
		(state) => state.adoptMeForm.formData.pregunta_3
	);
	const pregunta_4_store = useSelector(
		(state) => state.adoptMeForm.formData.pregunta_4
	);
	const pregunta_5_store = useSelector(
		(state) => state.adoptMeForm.formData.pregunta_5
	);
	const pregunta_6_store = useSelector(
		(state) => state.adoptMeForm.formData.pregunta_6
	);
	const { procedure } = useSelector((state) => state.login);
	const [questionData, setQuestionData] = useState({
		pregunta_1: `${pregunta_1_store}`,
		pregunta_2: `${pregunta_2_store}`,
		pregunta_3: `${pregunta_3_store}`,
		pregunta_4: `${pregunta_4_store}`,
		pregunta_5: `${pregunta_5_store}`,
		pregunta_6: `${pregunta_6_store}`
	});

	const {
		pregunta_1,
		pregunta_2,
		pregunta_3,
		pregunta_4,
		pregunta_5,
		pregunta_6
	} = questionData;

	const handleChange = (e) => {
		setQuestionData({
			...questionData,
			[e.target.name]: e.target.value
		});
	};

	useEffect(() => {
		if (
			pregunta_1.length ||
			pregunta_2.length ||
			pregunta_3.length ||
			pregunta_4.length ||
			pregunta_5.length ||
			pregunta_6.length !== 0
		) {
			dispatch(
				get_adopt_me_form_action({
					...formData,
					pregunta_1: pregunta_1,
					pregunta_2: pregunta_2,
					pregunta_3: pregunta_3,
					pregunta_4: pregunta_4,
					pregunta_5: pregunta_5,
					pregunta_6: pregunta_6
				})
			);
		}
	}, [pregunta_1, pregunta_2, pregunta_3, pregunta_4, pregunta_5, pregunta_6]);

	const MenuPropsCustom = {
		getContentAnchorEl: null,
		anchorOrigin: {
			vertical: 'bottom'
		}
	};

	return (
		<>
			<Grid className={classes.userDataContainer}>
				{procedure === 1 ? (
					<Grid container justify="center">
						<Grid item style={{ textAlign: 'center' }}>
							<Typography align="center" variant="h5">
								{t('adoption-about')}
							</Typography>
						</Grid>
						<Grid container spacing={1} style={{ marginTop: '2%' }}>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<TextField
									label={t('adopted-question-1')}
									type="text"
									name="pregunta_1"
									variant="outlined"
									fullWidth
									value={pregunta_1}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel
										id="demo-simple-select-outlined-label"
										className={classes.inputs}
									>
										{t('adopted-question-2')}
									</InputLabel>
									<Select
										name="pregunta_2"
										label={t('adopted-question-2')}
										fullwidth
										value={pregunta_2}
										onChange={handleChange}
										MenuProps={MenuPropsCustom}
									>
										<MenuItem value="Si">{t('yes')}</MenuItem>
										<MenuItem value="No">{t('not')}</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel
										id="demo-simple-select-outlined-label"
										className={classes.inputs}
									>
										{t('adopted-question-3')}
									</InputLabel>
									<Select
										name="pregunta_3"
										label={t('adopted-question-3')}
										fullwidth
										value={pregunta_3}
										onChange={handleChange}
										MenuProps={MenuPropsCustom}
									>
										<MenuItem value="Si">{t('yes')}</MenuItem>
										<MenuItem value="No">{t('not')}</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel
										id="demo-simple-select-outlined-label"
										className={classes.inputs}
									>
										{t('adopted-question-4')}
									</InputLabel>
									<Select
										name="pregunta_4"
										label={t('adopted-question-4')}
										fullwidth
										value={pregunta_4}
										onChange={handleChange}
										MenuProps={MenuPropsCustom}
									>
										<MenuItem value="Si">{t('yes')}</MenuItem>
										<MenuItem value="No">{t('not')}</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel
										id="demo-simple-select-outlined-label"
										className={classes.inputs}
									>
										{t('adopted-question-5')}
									</InputLabel>
									<Select
										name="pregunta_5"
										label={t('adopted-question-5')}
										fullwidth
										value={pregunta_5}
										onChange={handleChange}
										MenuProps={MenuPropsCustom}
									>
										<MenuItem value="Si">{t('yes')}</MenuItem>
										<MenuItem value="No">{t('not')}</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<FormControl variant="outlined" fullWidth>
									<InputLabel
										id="demo-simple-select-outlined-label"
										className={classes.inputs}
									>
										{t('adopted-question-6')}
									</InputLabel>
									<Select
										name="pregunta_6"
										label={t('adopted-question-6')}
										fullwidth
										value={pregunta_6}
										onChange={handleChange}
										MenuProps={MenuPropsCustom}
									>
										<MenuItem value="Si">{t('yes')}</MenuItem>
										<MenuItem value="No">{t('not')}</MenuItem>
									</Select>
								</FormControl>
							</Grid>
						</Grid>
					</Grid>
				) : procedure === 2 ? (
					<Grid container justify="center">
						<Typography align="center" color="secondary" variant="h6">
							{t('recovered-about')}
						</Typography>
						<Grid container spacing={1} style={{ marginTop: '2%' }}>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<TextField
									label={t('recovered-question-1')}
									type="text"
									name="pregunta_1"
									variant="outlined"
									fullWidth
									value={pregunta_1}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<TextField
									label={t('recovered-question-2')}
									type="tel"
									name="pregunta_2"
									variant="outlined"
									fullWidth
									value={pregunta_2}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<TextField
									label={t('recovered-question-3')}
									type="number"
									name="pregunta_3"
									variant="outlined"
									fullWidth
									value={pregunta_3}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<TextField
									fullWidth
									variant="outlined"
									name="pregunta_4"
									placeholder={t('recovered-question-4')}
									multiline
									rows={4}
									value={pregunta_4}
									onChange={handleChange}
								/>
							</Grid>
						</Grid>
					</Grid>
				) : procedure === 3 ? (
					<Grid container justify="center">
						<Typography align="center" color="secondary" variant="h6">
							{t('lost-about')}
						</Typography>
						<Grid container spacing={1} style={{ marginTop: '2%' }}>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<TextField
									label={t('lost-question-1')}
									type="text"
									name="pregunta_1"
									variant="outlined"
									fullWidth
									value={pregunta_1}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<TextField
									label={t('lost-question-2')}
									type="tel"
									name="pregunta_2"
									variant="outlined"
									fullWidth
									value={pregunta_2}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
								<TextField
									fullWidth
									variant="outlined"
									name="pregunta_3"
									placeholder={t('lost-question-3')}
									multiline
									rows={4}
									value={pregunta_3}
									onChange={handleChange}
								/>
							</Grid>
						</Grid>
					</Grid>
				) : null}
			</Grid>
		</>
	);
}
