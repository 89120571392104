/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

//components
import Header from '../components/SaciComponents/Header';
import RequestForm from '../components/SaciComponents/UserComponents/AdoptRequest/RequestForm';
import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { set_on_entry_request_page } from '../redux/actions/userPetsAction';
import TabsRequest from '../components/SaciComponents/UserComponents/AdoptRequest/TabsRequest';

export default function MyAdoptRequest() {
	const { nombres } = useSelector((state) => state.login.user);
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {
		if (nombres.length === 0) {
			history.push('/');
		}
	}, [nombres]);

	useEffect(() => {
		dispatch(set_on_entry_request_page(true));
	}, []);

	const theme = useTheme();

	const matches = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<>
			<Header />
			<Grid
				container
				style={{ height: '100vh', width: '100%' }}
				justify="center"
				spacing={matches ? 0 : 10}
			>
				<Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
					<TabsRequest />
				</Grid>
				<Grid item xs={12} sm={8} md={8} lg={9} xl={10}>
					<RequestForm />
				</Grid>
			</Grid>
		</>
	);
}
