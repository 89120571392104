/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

// import { Link } from 'react-router-dom';

// Form Validation
import { useForm } from 'react-hook-form';

// Dispach Redux
import { useDispatch, useSelector } from 'react-redux';

// redux Actions
import {
	loginNormalAction,
	login_error_clean_action,
	login_dialog_close_action,
	set_forgot_password_modal_action
} from '../redux/actions/loginAction';
import { loginFacebookAction } from '../redux/actions/facebookAction';
import Loader from './Loader';

// Material UI
import { TextField, Grid, Button, Typography } from '@material-ui/core';

// Icons
import iconEmail from '../assets/icons/email-final.svg';
import iconPassword from '../assets/icons/lock-final.svg';
import iconSend from '../assets/icons/send-final.svg';
import iconFacebook from '../assets/icons/facebook-final.svg';
import iconGoogle from '../assets/icons/google-final.svg';

// Styles
import loginStyles from './../assets/css/js/loginStyles';

// Google Button
import GoogleLogin from 'react-google-login';

// Facebook Button
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

// Redux Dialog Rwegister Open
import {
	register_dialog_open_action
} from '../redux/actions/registerAction';

import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { loginGoogleAction } from '../redux/actions/googleAction';
import axios from 'axios';
import axiosClient from '../configAxios/axios';

////////////////////////////////////////////////////////////////////////////////////
export default function Login() {
	const [t] = useTranslation('global');
	// Styles
	const classes = loginStyles();

	// Validation Form Login
	const { register, errors, handleSubmit } = useForm({
		mode: 'onChange'
	});
	// const [googleData, setGoogleData] = useLocalStorage('google-session', '');

	// Field Validation
	const [newUser, setnewUser] = useState({
		password: '',
		correo: '',
		origen_cuenta: 'Registro_normal'
	});

	const handleChange = (event) => {
		const { name, value } = event.target;
		setnewUser({ ...newUser, [name]: value });
	};

	const onSubmit = (data, e) => {
		_handleSubmit({ ...newUser });
		e.target.reset();
	};

	// redux implementation
	const { loader, error, errorGoogle, alertPassword, user } = useSelector((state) => state.login);

	const dispatch = useDispatch();

	// redux Actions
	const _handleSubmit = async (data) => {
		dispatch(loginNormalAction(data, user.token));
	};

	const responseGoogle = (data) => {
		if (data.googleId) {
			dispatch(loginGoogleAction(data));
		}
	};

	const responseFacebook = (data) => {
		if (data.userID) {
			dispatch(loginFacebookAction(data));
		}
	};

	const openRegister = () => {
		dispatch(login_dialog_close_action());
		dispatch(register_dialog_open_action());
	};

	function navigate (url) {
		window.location.href = url
	}

	async function auth () {
		const response = await axiosClient.get('/generateAuthUrl')
		//const data = await response.json()
		//console.log(response)
		navigate(response.data.url)
	}

	async function authFB () {
		const response = await axiosClient.get('/generateAuthUrlFacebook')
		//const data = await response.json()
		//console.log(response)
		navigate(response.data.url)
	}

	useEffect(() => {
		if (error) {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: alertPassword,
				confirmButtonColor: '#63C132'
			}).then(() => {
				dispatch(login_error_clean_action());
			});
		}
	}, [error]);

	useEffect(() => {
		if (errorGoogle) {
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: alertPassword,
				confirmButtonColor: '#63C132'
			}).then(() => {
				dispatch(login_error_clean_action());
			});
		}
	}, [errorGoogle]);

	return (
		<>
			{loader && <Loader />}
			<Grid container className={classes.containerForm}>
				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<Typography
						align="center"
						variant="h4"
						gutterBottom
						className={classes.titleForm}
					>
						{t('log-in')}
					</Typography>
					<Grid container alignItems="center" spacing={2}>
						<Grid item xs={2} className={classes.iconsCenter}>
							<img src={iconEmail} alt="Email" className={classes.icons1} />
						</Grid>
						<Grid item xs={10}>
							<TextField
								label={t('email')}
								type="email"
								name="correo"
								variant="outlined"
								fullWidth
								inputRef={register({
									required: { value: true, message: t('complete-validation') },
									pattern: {
										value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
										message: t('email-validation')
									}
								})}
								helperText={errors?.correo?.message}
								error={errors?.correo?.message ? true : false}
								onChange={handleChange}
							/>
						</Grid>
						<Grid item xs={2} className={classes.iconsCenter}>
							<img
								src={iconPassword}
								alt="Contraseña"
								className={classes.icons2}
							/>
						</Grid>
						<Grid item xs={10}>
							<TextField
								label={t('password')}
								type="password"
								name="password"
								variant="outlined"
								fullWidth
								inputRef={register({
									required: { value: true, message: t('complete-validation') },
									pattern: {
										value: /^.{5,15}$/,
										message: t('password-validation')
									}
								})}
								helperText={errors?.password?.message}
								error={errors?.password?.message ? true : false}
								onChange={handleChange}
							/>
						</Grid>
					</Grid>
					<Grid container justify="flex-end">
						{/* <Link style={{ textDecoration: 'none', filter: 'contrast(1)' }}> */}
						<Button
							onClick={() => 	dispatch(set_forgot_password_modal_action({
								state: true,
								tipo: 'Restablecer'
							}))}
							variant="text"
							size="small"
							className={classes.buttonSecondary1}
						>
							{t('did-you-password')}
						</Button>
						{/* </Link> */}
					</Grid>
					<Grid container justify="center">
						<Button
							className={classes.buttonPrimary}
							variant="contained"
							size="large"
							endIcon={
								<img src={iconSend} alt="LogIn" className={classes.icons1} />
							}
							fullWidth
							type="submit"
						>
							{t('login')}
						</Button>
						<span className={classes.containerLine}>
							──────────── O ────────────
						</span>
						{/* <FacebookLogin
							appId="356652789372805"
							autoLoad={false}
							fields="name,email,picture"
							callback={responseFacebook}
							render={(renderProps) => ( */}
						{/* 		<Button
									className={classes.buttonFacebook}
									variant="contained"
									size="large"
									fullWidth
									startIcon={
										<img
											src={iconFacebook}
											alt="Facebook"
											className={classes.icons2}
										/>
									}
									onClick={authFB}
								>
									<Grid container justify="center">
										{t('login-facebook')}
									</Grid>
								</Button> */}
					{/* 		)}
						/> */}
					{/* 	<GoogleLogin
							clientId="494877024211-47b2uf07s91dc0cos4rtec805knm8ls5.apps.googleusercontent.com"
							buttonText="Login"
							onSuccess={responseGoogle}
							onFailure={responseGoogle}
							cookiePolicy={'single_host_origin'}
							render={(renderProps) => ( */}
								<Button
									className={classes.buttonGmail}
									variant="contained"
									size="large"
									fullWidth
									startIcon={
										<img
											src={iconGoogle}
											alt="Google"
											className={classes.icons1}
										/>
									}
									onClick={auth}
								>
									<Grid container justify="center">
										{t('login-google')}
									</Grid>
								</Button>
						{/* 	)}
						/> */}
						<Button
							variant="text"
							size="small"
							className={classes.buttonSecondary2}
							onClick={openRegister}
						>
							{t('dont-account')}
						</Button>
					</Grid>
				</form>
			</Grid>
		</>
	);
}
