import {
	CLEAN_PETS,
	GET_PETS_NO_FILTER,
	GET_SACI_PETS,
	GET_SACI_PETS_FILTERS,
	GET_SACI_EMPTY_PETS,
	NO_USER_PETS,
	PAGE_SACI_PETS,
	SELECT_PET_DATA,
	SELECT_PET_FORM_DATA,
	SET_ADOPT_ME_DIALOG,
	SET_ADOPT_ME_DIALOG_PROCEDURE,
	SET_OUTPUT_REQ_PETS_STATE,
	SET_PET_DATA_DIALOG,
	SET_UNLOGGED_MODAL,
	SET_USER_PETS_STATE,
	SHOW_OUTPUT_REQ_PETS,
	SHOW_USER_PETS,
	GET_SACI_PETS_VALIDATE,
	GET_SACI_PETS_VALIDATE_CLEAN
} from '../types';
import { getSaciPets } from '../../configAxios/saciPets';

// export function get_saci_pets_action(filters) {
// 	return async (dispatch) => {
// 		try {
// 			const response = await getSaciPets(filters);

// 			// let filter = response.data.mascotas

// 			dispatch({
// 				type: GET_SACI_PETS,
// 				payload: response.data.mascotas
// 			});
// 			alert('exito');
// 		} catch (error) {
// 			// console.log(error);
// 			alert('error');
// 		}
// 	};
// }

export const get_saci_pets_action = (filters) => async (dispatch) => {
	try {
		const response = await getSaciPets(filters);

		const filterResponse = response.data.mascotas.reverse()

		let filter = [];

		if (filters.id_tipo_mascota === false) {
			filter = filterResponse;
		}

		if (filters?.length > 0) {
			filter = filters;
		}

		if (filters.id_pais) {
			filter = filterResponse.filter(
				(pets) => pets.id_pais === filters.id_pais
			);
			dispatch({
				type: GET_SACI_PETS_VALIDATE,
				payload: filter
			});
		}

		if (filters.searchTerm) {
			filter = filterResponse.filter(
				(pets) =>
					(pets.nombre_mascota
						.toLowerCase()
						.includes(filters.searchTerm.toLocaleLowerCase()) ||
						pets.raza
							.toLowerCase()
							.includes(filters.searchTerm.toLocaleLowerCase())) &&
					(filters.id_pais ? pets.id_pais === filters.id_pais : true)
			);
		}

		if (filters.searchTerm === '' && !filters.id_pais) {
			filter = filterResponse;
		}

		if (filters.searchTerm === '' && filters.id_pais) {
			filter = filterResponse.filter(
				(pets) => pets.id_pais === filters.id_pais
			);
		}

		dispatch({
			type: GET_SACI_PETS_FILTERS,
			payload: filterResponse
		});

		dispatch({
			type: GET_SACI_PETS,
			payload: filter
		});
	} catch (error) {
		console.log(error);
		// alert('no existe mascota')
		dispatch({
			type: GET_SACI_EMPTY_PETS
		})
	}
};

export const get_saci_pets_validate_clean_Action = () => async (dispatch) => {
	dispatch({
		type: GET_SACI_PETS_VALIDATE_CLEAN,
		payload: false
	});
}

export const page_saci_pets_action = (slicePets) => async (dispatch) => {
	dispatch({
		type: PAGE_SACI_PETS,
		payload: slicePets
	});
};

export const show_user_pets_action = (userPets) => async (dispatch) => {
	dispatch({
		type: SHOW_USER_PETS,
		payload: userPets
	});
};

export const select_pet_action = (id_mascota) => async (dispatch) => {
	dispatch({
		type: SELECT_PET_DATA,
		payload: id_mascota
	});
};

export const select_pet_form_action = (id_formulario) => async (dispatch) => {
	dispatch({
		type: SELECT_PET_FORM_DATA,
		payload: id_formulario
	});
};

export const adopt_me_dialog_action = (dialogState) => async (dispatch) => {
	dispatch({
		type: SET_ADOPT_ME_DIALOG,
		payload: dialogState
	});
};

export const adopt_me_dialog_procedure_action =
	(procedure) => async (dispatch) => {
		// console.log(procedure);
		dispatch({
			type: SET_ADOPT_ME_DIALOG_PROCEDURE,
			payload: procedure
		});
	};

export const pet_data_dialog_action = (dialogDataState) => async (dispatch) => {
	dispatch({
		type: SET_PET_DATA_DIALOG,
		payload: dialogDataState
	});
};

export const unlogged_modal_action = (state) => async (dispatch) => {
	dispatch({
		type: SET_UNLOGGED_MODAL,
		payload: state
	});
};

export const get_saci_pets_filters_races_action =
	(filtersRacesCards) => async (dispatch) => {
		try {
			// console.log(filtersRacesCards);
			dispatch({
				type: GET_SACI_PETS,
				payload: filtersRacesCards
			});
		} catch (error) {
			console.log(error);
		}
	};

export const get_saci_pets_filters_citys_action =
	(filtersCityCards) => async (dispatch) => {
		try {
			// console.log(filtersCityCards);
			dispatch({
				type: GET_SACI_PETS,
				payload: filtersCityCards
			});
		} catch (error) {
			console.log(error);
		}
	};

// export const get_saci_pets_no_filters_action = () => async (dispatch) => {
// 	try {
// 		const response = await getSaciPets();
// 		dispatch({
// 			type: GET_PETS_NO_FILTER,
// 			payload: response
// 		});
// 	} catch (e) {
// 		console.error(e);
// 	}
// };

export const no_user_pets_action = () => async (dispatch) => {
	dispatch({
		type: NO_USER_PETS
	});
};

export const show_out_request_pets_action = (petData) => async (dispatch) => {
	dispatch({
		type: SHOW_OUTPUT_REQ_PETS,
		payload: petData
	});
};

export const set_user_pets_state_action = (state) => async (dispatch) => {
	dispatch({
		type: SET_USER_PETS_STATE,
		payload: state
	});
};

export const set_on_request_page_state_action = (state) => async (dispatch) => {
	dispatch({
		type: SET_OUTPUT_REQ_PETS_STATE,
		payload: state
	});
};

export const clean_pets_action = () => async (dispatch) => {
	dispatch({
		type: CLEAN_PETS
	});
};
