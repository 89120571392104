import { useState } from 'react';

export const useInputAdornment = () => {
	const [showPassword1, setShowPassword_1] = useState(false);
	const handleClickShowPassword1 = () => setShowPassword_1(!showPassword1);
	const handleMouseDownPassword1 = () => setShowPassword_1(!showPassword1);

	const [showPassword2, setShowPassword_2] = useState(false);
	const handleClickShowPassword2 = () => setShowPassword_2(!showPassword2);
	const handleMouseDownPassword2 = () => setShowPassword_2(!showPassword2);

	return {
		showPassword1,
		handleClickShowPassword1,
		handleMouseDownPassword1,
		showPassword2,
		handleClickShowPassword2,
		handleMouseDownPassword2
	};
};
