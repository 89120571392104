/* eslint-disable react-hooks/exhaustive-deps */
import {
	Checkbox,
	Grid,
	InputAdornment,
	TextField,
	Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { userDescriptionStyles } from '../../../assets/css/js/userDescriptionStyles';
import {
	full_pet_description_action,
	get_form_data_action,
	not_full_pet_description_action,
	set_checkbox_contactData_action
} from '../../../redux/actions/adoptFormAction';

import 'react-phone-input-2/lib/material.css';
import clsx from 'clsx';

export default function UserDescription() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const classes = userDescriptionStyles();
	const { telefono } = useSelector((state) => state.login.user);
	const { descriptionData } = useSelector((state) => state.adoptFormData);
	const { showUserPets } = useSelector((state) => state.saciPets);
	const correo_store = useSelector((state) => state.login.user.correo);
	const { activeStepState } = useSelector((state) => state.adoptFormData);
	const { checkBoxContactData } = useSelector((state) => state.adoptFormData);

	const [userData, setUserData] = useState({
		telefono1: `${telefono}`,
		telefono2: '',
		correo: `${correo_store}`
	});

	const { telefono1, telefono2, correo } = userData;

	const checkboxTelefono_1 = checkBoxContactData.telefono1;

	const checkboxTelefono_2 = checkBoxContactData.telefono2;

	const checkboxCorreo = checkBoxContactData.correo;

	const [inputsCheckBox, setInputsCheckBox] = useState({
		telefono1: checkboxTelefono_1,
		telefono2: checkboxTelefono_2,
		correo: checkboxCorreo
	});

	const handleChange = ({ target }) => {
		setUserData({
			...userData,
			[target.name]: target.value
		});
	};

	let code = telefono.split(' ');

	// const handleChangePhone = ({ target }) => {
	// 	setUserData({ ...userData, [target.name]: code[0] + ' ' + target.value });
	// };

	const handleChangeCheckBox = ({ target }) => {
		setInputsCheckBox({ ...inputsCheckBox, [target.name]: target.checked });
		dispatch(
			set_checkbox_contactData_action({
				...checkBoxContactData,
				[target.name]: target.checked
			})
		);
	};
	useEffect(() => {
		if (showUserPets) {
			setUserData({
				telefono1: descriptionData.telefono1?.length
					? descriptionData.telefono1
					: '',
				telefono2: descriptionData.telefono2?.length
					? descriptionData.telefono2
					: '',
				correo: descriptionData.correo?.length ? descriptionData.correo : ''
			});
			set_checkbox_contactData_action({
				...checkBoxContactData,
				telefono1: descriptionData.telefono1?.length ? true : false,
				telefono2: descriptionData.telefono2?.length ? true : false,
				correo: descriptionData.correo?.length ? true : false
			});
		} else {
			setUserData({
				...userData,
				telefono1: descriptionData.telefono1?.length
					? descriptionData.telefono1
					: `${telefono}`,
				telefono2: descriptionData.telefono2?.length
					? descriptionData.telefono2
					: '',
				correo: descriptionData.correo?.length
					? descriptionData.correo
					: `${correo_store}`
			});
			set_checkbox_contactData_action({
				...checkBoxContactData,
				telefono1: descriptionData.telefono1?.length ? true : false,
				telefono2: descriptionData.telefono2?.length ? true : false,
				correo: descriptionData.correo?.length ? true : false
			});
		}
	}, []);
	useEffect(() => {
		if (activeStepState === 4) {
			if (
				checkBoxContactData.telefono1 ||
				checkBoxContactData.telefono2 ||
				checkBoxContactData.correo
			) {
				dispatch(
					get_form_data_action({
						...descriptionData,
						telefono1: checkBoxContactData.telefono1 ? telefono1 : '',
						telefono2: checkBoxContactData.telefono2 ? telefono2 : '',
						correo: checkBoxContactData.correo ? correo : ''
					})
				);
			}
		}
	}, [activeStepState]);

	const { register, errors } = useForm({
		mode: 'onChange'
	});

	const [checkForm, setCheckForm] = useState(false);

	useEffect(() => {
		if (!errors.telefono1 && !errors.telefono2 && !errors.correo) {
			setCheckForm(true);
		} else {
			setCheckForm(false);
		}
	}, [errors.telefono1, errors.telefono2, errors.correo]);

	useEffect(() => {
		if (checkForm) {
			dispatch(full_pet_description_action());
		} else {
			dispatch(not_full_pet_description_action());
		}
	}, [checkForm]);

	return (
		<>
			<Grid container spacing={2} className={classes.root}>
				<Grid item xs={12}>
					<Typography variant="h6" gutterBottom>
						{t('contact-information')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography gutterBottom>{t('additional-information')}</Typography>
				</Grid>
				<Grid item xs={2}>
					<Checkbox
						color="primary"
						name="telefono1"
						checked={inputsCheckBox.telefono1}
						onChange={handleChangeCheckBox}
						inputRef={register}
					/>
				</Grid>
				<Grid item xs={9}>
					<TextField
						label={t('main-phone')}
						type="tel"
						name="telefono1"
						variant="outlined"
						fullWidth
						defaultValue={telefono1.split(' ')[1]}
						disabled={inputsCheckBox.telefono1 === false}
						onChange={handleChange}
						inputRef={register({
							required: {
								value: checkboxTelefono_1 && true,
								message: t('complete-validation')
							},
							pattern: {
								value: checkboxTelefono_1 && /^\d{7,12}$/,
								message: t('phone-validation')
							}
						})}
						helperText={errors?.telefono1?.message}
						error={errors?.telefono1?.message ? true : false}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<span
										className={clsx({
											[classes.numberActive]: inputsCheckBox.telefono1,
											[classes.numberDesactive]: !inputsCheckBox.telefono1
										})}
									>
										{'+'}
										{code[0]}
									</span>
								</InputAdornment>
							)
						}}
					/>
				</Grid>
				<Grid item xs={2}>
					<Checkbox
						color="primary"
						name="telefono2"
						checked={inputsCheckBox.telefono2}
						onChange={handleChangeCheckBox}
						inputRef={register}
					/>
				</Grid>
				<Grid item xs={9}>
					<TextField
						label={t('secondary-phone')}
						type="tel"
						name="telefono2"
						variant="outlined"
						fullWidth
						defaultValue={telefono2}
						disabled={inputsCheckBox.telefono2 === false}
						onChange={handleChange}
						inputRef={register({
							required: {
								value: checkboxTelefono_2 && true,
								message: t('complete-validation')
							},
							pattern: {
								value: checkboxTelefono_2 && /^\d{7,12}$/,
								message: t('phone-validation')
							}
						})}
						helperText={errors?.telefono2?.message}
						error={errors?.telefono2?.message ? true : false}
					/>
				</Grid>
				<Grid item xs={2}>
					<Checkbox
						color="primary"
						name="correo"
						checked={inputsCheckBox.correo}
						onChange={handleChangeCheckBox}
						inputRef={register}
					/>
				</Grid>
				<Grid item xs={9}>
					<TextField
						label={t('email')}
						type="email"
						name="correo"
						variant="outlined"
						fullWidth
						defaultValue={correo}
						disabled={inputsCheckBox.correo === false}
						onChange={handleChange}
						inputRef={register({
							required: {
								value: checkboxCorreo && true,
								message: t('complete-validation')
							},
							pattern: {
								value:
									checkboxCorreo &&
									/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
								message: t('email-validation')
							}
						})}
						helperText={errors?.correo?.message}
						error={errors?.correo?.message ? true : false}
					/>
				</Grid>
			</Grid>
		</>
	);
}
