import { createMuiTheme } from '@material-ui/core/styles';

const mainTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#63c132',
		},
		secondary: {
			main: '#1E3F20'
		}
	},
	typography: {
		fontFamily: `'Poppins', 'sans-serif'`
	}
});

export const mainColor = mainTheme.palette.primary.main;
export const secondaryColor = mainTheme.palette.secondary.main;
export default mainTheme;
