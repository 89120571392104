/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import petRequestStyles from '../../../../assets/css/js/petRequestStyles';

import {
	Avatar,
	Button,
	Grid,
	Paper,
	Typography,
	useMediaQuery
} from '@material-ui/core';

import VisibilityIcon from '@material-ui/icons/Visibility';

import { useTheme } from '@material-ui/core/styles';

import { set_selected_request_action } from '../../../../redux/actions/userPetsAction';
import { useTranslation } from 'react-i18next';

export const PetRequest = ({ AdoptionRequest }) => {
	const [t] = useTranslation('global');
	const classes = petRequestStyles();

	const dispatch = useDispatch();

	const { petRequestArray } = useSelector((state) => state.userPets);

	const [requestId, setRequestId] = useState(null);

	useEffect(() => {
		if (requestId !== null) {
			let selectedRequest = petRequestArray.filter(
				(item) => item.id_formulario === requestId
			)[0];
			dispatch(set_selected_request_action(selectedRequest));
			setRequestId(null);
		}
	}, [requestId]);

	let accept = petRequestArray?.filter(
		(item) => item.solicitud_adopcion === AdoptionRequest
	);

	if (AdoptionRequest === undefined) {
		accept = petRequestArray;
	}

	const baseURL = process.env.REACT_APP_BACKEND_URL;

	const theme = useTheme();

	const matches = useMediaQuery(theme.breakpoints.down('sm'));

	return (
		<Grid
			container
			className={classes.root}
			justify="center"
			style={{
				height: matches ? 'auto' : 200,
				overflowY: 'auto',
				overflowX: 'hidden'
			}}
			spacing={3}
		>
			{accept?.length ? (
				accept.map((item) => (
					<Grid
						item
						key={item.id_formulario}
						xs={12}
						sm={8}
						md={6}
						lg={6}
						xl={4}
					>
						<Paper className={classes.paper}>
							<Grid container spacing={2}>
								<Grid item container alignItems="center">
									<Grid item xs container direction="column" spacing={2}>
										<Grid item xs>
											<Typography gutterBottom variant="subtitle1">
												{t('information-pet')}
											</Typography>
											<Typography
												variant="body2"
												color="textSecondary"
												gutterBottom
											>
												<span>{t('name')}: </span>
												<span style={{ color: '#63C132' }}>
													{item.nombre_mascota}
												</span>
											</Typography>
											<Typography variant="body2" color="textSecondary">
												<span>Estado: </span>

												{item.solicitud_adopcion === true ? (
													<span style={{ color: '#63C132' }}>
														{t('status-accepted')}
													</span>
												) : item.solicitud_adopcion === false ? (
													<span style={{ color: '#63C132' }}>
														{t('status-rejected')}
													</span>
												) : item.solicitud_adopcion === null ? (
													<span style={{ color: '#63C132' }}>
														{t('status-pending')}
													</span>
												) : null}
											</Typography>
										</Grid>

										<Grid item>
											<Button
												variant="contained"
												size="small"
												color="primary"
												style={{ color: '#fff' }}
												onClick={() => setRequestId(item.id_formulario)}
												startIcon={<VisibilityIcon />}
											>
												{t('see-data')}
											</Button>
										</Grid>
									</Grid>
									<Grid item>
										<Avatar
											src={`${baseURL}${item.id_foto}.jpg`}
											style={{ width: 100, height: 100 }}
											title="Pet Image"
										/>
									</Grid>
								</Grid>
							</Grid>
						</Paper>
					</Grid>
				))
			) : (
				<Grid container alignItems="center">
					<Grid item xs={12}>
						<Typography align="center">
							{/* No tienes solicitudes{' '}
							{(AdoptionRequest === true && 'aceptadas') ||
								(AdoptionRequest === false && 'rechazadas') ||
								(AdoptionRequest === null && 'pendientes')}{' '}
							actualmente. */}
							{(AdoptionRequest === true && t('no-currently-accepted')) ||
								(AdoptionRequest === false && t('no-currently-rejected')) ||
								(AdoptionRequest === null && t('no-currently-pending')) ||
								(AdoptionRequest === undefined && t('no-currently-received'))
								}
						</Typography>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};
